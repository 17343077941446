import { authHeader } from './authHeader.js';
import {userService} from './userService.js'

var config = require('./config.js');

export const checklistService = {
    getAllTemplates,
    getTemplate,
    createTemplate,
    updateTemplate,
    deleteTemplate,
    updateTemplate,
    duplicateTemplateCcode,

    getValue,
    createValue,
    updateValue,
    deleteValue,
};

function getValue(tid) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + '/api/checklistvalue/' + tid, requestOptions).then(handleResponse);
}

function createValue(act) {
    //console.log(resource);
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(act)   
    };
    return fetch(config.app.url + '/api/checklistvalue/' , requestOptions).then(handleResponse);
}

function updateValue(act, id) {
    //console.log(asset);
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(act)     
    };
    return fetch(config.app.url + '/api/checklistvalue/' + id , requestOptions).then(handleResponse);
}

function deleteValue(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        credentials: 'include',
    };
    return fetch(config.app.url + '/api/checklistvalue/' + id , requestOptions).then(handleResponse);
}

////

function duplicateTemplateCcode(code, tid) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + '/api/checklisttemplatedeuplicate/' + tid + '/code/' + code, requestOptions).then(handleResponse);
}

function getTemplate(tid) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + '/api/checklisttemplate/' + tid, requestOptions).then(handleResponse);
}

function getAllTemplates() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + '/api/checklisttemplates' , requestOptions).then(handleResponse);
}

function createTemplate(act) {
    //console.log(resource);
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(act)   
    };
    return fetch(config.app.url + '/api/checklisttemplate/' , requestOptions).then(handleResponse);
}

function updateTemplate(act, id) {
    //console.log(asset);
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(act)     
    };
    return fetch(config.app.url + '/api/checklisttemplate/' + id , requestOptions).then(handleResponse);
}

function deleteTemplate(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        credentials: 'include',
    };
    return fetch(config.app.url + '/api/checklisttemplate/' + id , requestOptions).then(handleResponse);
}

function handleResponse(response) {
    if (response.status === 401) {
        return 401;
    }
    return response.text().then(text => {
        //console.log(text);
        if (text && text !== 'null' && text !== 'undefined') {
            const data = text && JSON.parse(text);
            if (!response.ok) {
                if (response.status === 401) {
                    // auto logout if 401 response returned from api
                    userService.logout();
                    //location.reload(true);
                }

                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }
            //console.log(data.data);
            return data.data;
        }
        return null; 
    }
    );
}

