import { authHeader } from './authHeader.js';
import {userService} from './userService.js'

var config = require('./config.js');

export const providerService = {
    getProviders,
    getProvider,
    addProvider,
    updateProvider,
    removeProvider,    
    getNonBlackListedProviders,
};

function getProviders() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + `/api/providers`, requestOptions).then(handleResponse);
};

function getNonBlackListedProviders() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + `/api/providersnonblacklisted`, requestOptions).then(handleResponse);
};

function getProvider(providerid) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + `/api/provider/` + providerid, requestOptions).then(handleResponse);
};

function addProvider(provider) {
    //console.log(resource);
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(provider)   
    };

    return fetch(config.app.url + '/api/provider', requestOptions).then(handleResponse);
}

function updateProvider(provider, providerid) {
    //console.log(asset);
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(provider)     
    };

    return fetch(config.app.url + '/api/provider/' + providerid, requestOptions).then(handleResponse);    

}

function removeProvider(providerid) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        credentials: 'include',
    };
    return fetch(config.app.url + '/api/provider/' + providerid, requestOptions).then(handleResponse);
}


function handleResponse(response) {
    if (response.status === 401) {
        return 401;
    }
    return response.text().then(text => {
        //console.log(text);
        if (text && text !== 'null' && text !== 'undefined') {
            const data = text && JSON.parse(text);
            if (!response.ok) {
                if (response.status === 401) {
                    // auto logout if 401 response returned from api
                    userService.logout();
                    //location.reload(true);
                }

                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }
            //console.log(data);
            return data.data;
        }
        return null; 
    }
    );
}

