import React from "react";
import { Card, Button, Form, Col } from 'react-bootstrap';
import { Redirect } from "react-router-dom";
import { assetService } from '../../service/assetService';
import { providerService } from '../../service/providerService';
import DatePicker from "react-datepicker";


class AssetEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        id: undefined,
        description: undefined,
        model: undefined,
        serial_no: undefined,
        manufacturer: undefined,
        lotnumber: undefined,
        addressid: undefined,
        assetnumber: undefined,
        asset_type: undefined,
        asset_status: undefined,
        vendorid: undefined,
        supplier: undefined,
        acquisiondate: undefined,
        warrantyexpiration: undefined,
        //picture: undefined,
        //document: undefined,
        serviceproviderid: undefined,
        serviceprovider: undefined,  
        
        gobackto: undefined,
        clientid: undefined,
        assettypes: undefined,
        assetstatus: undefined,

        ////
        providers: undefined,
    }
  }

  componentDidMount() {  

    let config = JSON.parse(localStorage.getItem('config'));
    this.setState({assettypes: JSON.parse(config.assettypes),
                   assetstatus: JSON.parse(config.assetstatus)
    });


    const {id} = this.props.match.params;
    const {asset} = this.props.match.params;
    this.state.clientid = id;
    this.state.id = asset;

    if (this.state.id) {
      assetService.getAsset(this.state.id).then(asst => {
        if (asst && asst === 401) {
          this.props.history.push("/login");
        } else {  
          console.log(asst);              
          this.setState({
            description: asst.description,
            model: asst.model,
            serial_no: asst.serial_no,
            manufacturer: asst.manufacturer,
            lotnumber: asst.lotnumber,
            addressid: asst.addressid,
            assetnumber: asst.assetnumber,
            asset_type: asst.asset_type,
            asset_status: asst.asset_status,
            vendorid: asst.vendorid,
            supplier: asst.supplier,
            acquisiondate: asst.acquisiondate,
            warrantyexpiration: asst.warrantyexpiration,
            //picture: undefined,
            //document: undefined,
            serviceproviderid: asst.serviceproviderid,
            serviceprovider: asst.serviceprovider, 
          });
          this.getNonBlackListedProviders();
        }
      });
    } 
  }

  getNonBlackListedProviders() {
    providerService.getNonBlackListedProviders().then(asst => {
      if (asst && asst === 401) {
        this.props.history.push("/login");
      } else {  
        this.setState({providers: asst});
      }
    });
  }
  
  handleInputChange = (event) => {    
    const target = event.target;
    const name = target.id;    
    this.setState({
      [name]: target.value
    });    
  }


  handleSubmit = (event) => {
    event.preventDefault();
    
    let asst = {};
    if (this.state.id) {
      asst.id = this.state.id;
    }
    asst.description = this.state.description;
    asst.model = this.state.model;
    asst.serial_no = this.state.serial_no;
    asst.manufacturer = this.state.manufacturer;
    asst.lotnumber = this.state.lotnumber;
    asst.addressid = this.state.addressid;
    asst.assetnumber = this.state.assetnumber;
    asst.asset_type = this.state.asset_type;
    asst.asset_status = this.state.asset_status;
    asst.vendorid = this.state.vendorid;
    asst.supplier = this.state.supplier;
    asst.clientid = this.state.clientid;
    asst.acquisiondate = this.state.acquisiondate;
    asst.warrantyexpiration = this.state.warrantyexpiration;
    //picture: undefined,
    //document: undefined,
    //alert(this.state.serviceproviderid, this.state.serviceprovider);
    if (this.state.serviceproviderid && this.state.serviceproviderid.startsWith('Choose')) {
      this.state.serviceproviderid = null;
      asst.serviceproviderid = null;
      this.state.serviceprovider = null;
    } else {
      asst.serviceproviderid = this.state.serviceproviderid;
      if (this.state.serviceproviderid) {
        if (this.state.providers && this.state.providers.length > 0) {
          for (let i=0; i<this.state.providers.length; i++) {
            if (this.state.providers[i].id === this.state.serviceproviderid) {
              this.state.serviceprovider = this.state.providers[i].name;
              break;
            }
          }
        }
      }
    }
    asst.serviceprovider = this.state.serviceprovider; 
    if (!this.state.serial_no) {
      if (this.state.id) {
        assetService.updateAsset(asst, this.state.id).then(ast => {
          if (ast && ast === 401) {
            this.props.history.push("/login");
          } else {  
            this.setState({gobackto: '/assetdetail/' + this.state.clientid + '/asset/' + this.state.id});   
            this.props.history.goBack();
          }
        });
      } else {
        assetService.addNewAsset(asst).then(ast => {
          if (ast && ast === 401) {
            this.props.history.push("/login");
          } else {  
            //this.setState({gobackto: '/assetdetail/' + this.state.clientid + '/asset/' + this.state.id});   
            this.props.history.goBack();
          }
        });
      }

    } else {
      assetService.lookupSerialNo(this.state.serial_no).then(lookup => {
        console.log(lookup);
        if (this.state.id) {
          // update an asset case...
          let update = false;
          if (!lookup || lookup.length === 0) {
            update = true;
          } else if (lookup && lookup.length > 0 && lookup[0].id && lookup[0].id === this.state.id) {          
            // We found the serial number - but it is the one which is being updated!
            update = true;          
          } else {
            // You are trying to change the serial no which is alreadt taken...
            alert('The serial no # ' + this.state.serial_no + ' already exists. Please enter new one');
          }
          if (update) {
            assetService.updateAsset(asst, this.state.id).then(ast => {
              if (ast && ast === 401) {
                this.props.history.push("/login");
              } else {  
                this.setState({gobackto: '/assetdetail/' + this.state.clientid + '/asset/' + this.state.id});   
                this.props.history.goBack();
              }
            });
          }
        } else {
          // insert new asset case...
          if (lookup && lookup.length > 0 && lookup[0].id ) {
            // this serial number already exists in the database
            alert('The serial no # ' + this.state.serial_no + ' already exists. Please enter new one');
          } else {
            // create the asset
            assetService.addNewAsset(asst).then(ast => {
              if (ast && ast === 401) {
                this.props.history.push("/login");
              } else {  
                //this.setState({gobackto: '/assetdetail/' + this.state.clientid + '/asset/' + this.state.id});   
                this.props.history.goBack();
              }
            });
          }
        }
        
      });
    }
  }

  goBack = () => {
    this.props.history.goBack();
  }
  
  setAcquisionDate = (date) => {
    this.setState({acquisiondate: date});
  }

  setWarrantyExpiration = (date) => {
    this.setState({warrantyexpiration: date});
  }

  getLotTitle() {
    let config = JSON.parse(localStorage.getItem('config'));
    ////
    if (config.other) {
      let tmp = JSON.parse(config.other);      
      if (tmp.assetlot) {
        return tmp.assetlot;
      }  else {
        return 'Lot #';
      }
    } else {
      return 'Lot #';
    }
  }

  render() {
    if (this.state.gobackto) {
      return <Redirect to={this.state.gobackto} />
    }  

    let astOpt = [];
    astOpt.push(<option>Choose...</option>);
    if (this.state.assettypes && this.state.assettypes.length > 0) {
      for (let i=0; i<this.state.assettypes.length; i++) {
        if (this.state.asset_type && this.state.asset_type === this.state.assettypes[i]) {
          astOpt.push(<option selected>{this.state.assettypes[i]}</option>);
        } else {
          astOpt.push(<option>{this.state.assettypes[i]}</option>);
        }
        
      }
    }

    let assOpt = [];
    assOpt.push(<option>Choose...</option>);
    let selected = false;
    if (this.state.assetstatus && this.state.assetstatus.length > 0) {
      for (let i=0; i<this.state.assetstatus.length; i++) {
        if (this.state.asset_status && this.state.asset_status === this.state.assetstatus[i]) {
          assOpt.push(<option selected>{this.state.assetstatus[i]}</option>);
          selected = true;
        } else {
          assOpt.push(<option>{this.state.assetstatus[i]}</option>);
        }        
      }
    }

    if (!selected && assOpt.length > 1 && this.state.assetstatus.length > 0) {
      assOpt[1] = <option selected>{this.state.assetstatus[0]}</option>
      this.state.asset_status = this.state.assetstatus[0];
    }

    let providers = [];
    providers.push(<option>Choose...</option>);
    if (this.state.providers && this.state.providers.length > 0) {
      for (let i=0; i<this.state.providers.length; i++) {
        if (this.state.serviceproviderid && this.state.serviceproviderid === this.state.providers[i].id) {
          providers.push(<option selected value={this.state.providers[i].id}>{this.state.providers[i].name}</option>);          
        } else {
          providers.push(<option value={this.state.providers[i].id}>{this.state.providers[i].name}</option>);          
        }        
      }
    }

    return (
      <div className="content-screen" style={{height: '100%', overflow: 'scroll'}}>
      
        <Card style={{ width: '40rem' }}>
          { this.state.description &&
              <Card.Header style={{fontWeight: 'bold'}}>Editing Asset: {this.state.description}</Card.Header>
          }
          { !this.state.description &&
              <Card.Header style={{fontWeight: 'bold'}}>Adding Asset</Card.Header>
          }
          <Card.Body>            
            <div>
                <Form onSubmit={this.handleSubmit}>

                  <Form.Row>
                    <Form.Group as={Col} controlId="description" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Description</Form.Label>
                      <Form.Control required="true" placeholder="Enter asset description" defaultValue={this.state.description} />
                    </Form.Group>
                  </Form.Row> 

                  <Form.Row>
                    <Form.Group as={Col} controlId="assetnumber" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Asset Tag</Form.Label>
                      <Form.Control  placeholder="Enter asset tag" defaultValue={this.state.assetnumber} />
                    </Form.Group>
                  </Form.Row> 

                  <Form.Row>
                    <Form.Group as={Col} controlId="model" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Model</Form.Label>
                      <Form.Control placeholder="Enter model" defaultValue={this.state.model} />
                    </Form.Group>
                  </Form.Row> 

                  <Form.Row>
                    <Form.Group as={Col} controlId="serial_no" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Serial No</Form.Label>
                      <Form.Control  placeholder="Enter serial #" defaultValue={this.state.serial_no} />
                    </Form.Group>
                  </Form.Row> 

                  <Form.Row>
                    <Form.Group as={Col} controlId="manufacturer" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Manufacturer</Form.Label>
                      <Form.Control placeholder="Enter manufacturer" defaultValue={this.state.manufacturer} />
                    </Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Form.Group as={Col} controlId="lotnumber" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>{this.getLotTitle()}</Form.Label>
                      <Form.Control placeholder="Enter asset lot or ID number" defaultValue={this.state.lotnumber} />
                    </Form.Group>
                  </Form.Row>

                  <Form.Row>
                     <Form.Group as={Col} controlId="acquisiondate">
                      <Form.Label  style={{fontSize: "14px"}}>Acquisition Date</Form.Label>  
                      <div>
                      <DatePicker style={{displayValue: 'block'}}
                          selected={this.state.acquisiondate ? new Date(this.state.acquisiondate) : null}
                          onChange={date => this.setAcquisionDate(date)}
                          dateFormat="MMM d, yyyy"
                          placeholderText="Enter a date"                          
                      /> </div>                   
                    </Form.Group>
                  </Form.Row> 
                  <Form.Row>                    
                    <Form.Group as={Col} controlId="warrantyexpiration">
                      <Form.Label  style={{fontSize: "14px"}}>Warranty Expiration Date</Form.Label>  
                      <div>
                      <DatePicker style={{displayValue: 'block'}}
                          selected={this.state.warrantyexpiration ? new Date(this.state.warrantyexpiration) : null}
                          onChange={date => this.setWarrantyExpiration(date)}
                          dateFormat="MMM d, yyyy"
                          placeholderText="Enter a date"                          
                      /> </div>                   
                    </Form.Group>

                  </Form.Row> 
                    <Form.Row>
                    <Form.Group as={Col} controlId="asset_type" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Asset Type</Form.Label>
                      <Form.Control as="select" defaultValue={this.state.asset_type}>
                            {astOpt}
                      </Form.Control> 
                    </Form.Group>

                  </Form.Row>
                  <Form.Row>                   
                    <Form.Group as={Col} controlId="asset_status" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Asset Status</Form.Label>
                      <Form.Control as="select" defaultValue={this.state.asset_status}>
                            {assOpt}
                      </Form.Control> 
                    </Form.Group>

                  </Form.Row> 
                  
                  <Form.Row>
                    <Form.Group as={Col} controlId="supplier" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Supplier</Form.Label>
                      <Form.Control  placeholder="Enter suuplier" defaultValue={this.state.supplier} />
                    </Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Form.Group as={Col} controlId="serviceproviderid" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Service Provider</Form.Label>
                      {/* <Form.Control placeholder="Enter service provider" defaultValue={this.state.serviceprovider} /> */}
                      <Form.Control as="select" defaultValue={this.state.serviceproviderid}>
                            {providers}
                      </Form.Control> 
                    </Form.Group>
                  </Form.Row> 

                  <div style={{float: "left"}} >                                       
                    <Button variant="outline-primary mr-2" size="sm" type="submit">Save</Button>  
                    <Button variant="outline-primary" size="sm" onClick={() => {this.goBack()}}>Cancel</Button>                            
                  </div>
              </Form>
            </div>
          </Card.Body>
          <Card.Footer className="text-muted">&nbsp;</Card.Footer>       
        </Card>      

      </div>
    );
  }
}


export default AssetEdit;