import { authHeader } from './authHeader.js';
import {userService} from './userService.js'

var config = require('./config.js');

export const quoteService = {
    getQt,
    addNewQt,
    updateQt,
    deleteQt,
    getQtLineItem,
    addOrUpdateQtLineItem,
    deleteQtItem
};

function getQt(qtid) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + '/api/estimate/' + qtid , requestOptions).then(handleResponse);
}

function addNewQt(qt, serviceid) {    
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(qt)   
    };
    return fetch(config.app.url + '/api/estimate/' + serviceid, requestOptions).then(handleResponse);
}

function updateQt(resource) {
    //console.log(resource);
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(resource)     
    };
    return fetch(config.app.url + '/api/estimate/' + resource.id , requestOptions).then(handleResponse);
}

function deleteQt(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        credentials: 'include',
    };
    return fetch(config.app.url + '/api/estimate/' + id , requestOptions).then(handleResponse);
}

function getQtLineItem(id, qtid) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + '/api/quoteitem/' + id + '/quote/' + qtid, requestOptions).then(handleResponse);
}

function addOrUpdateQtLineItem(qtid, item) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',  
        body: JSON.stringify(item)  
    };
    return fetch(config.app.url + '/api/quoteitem/' + qtid, requestOptions).then(handleResponse);
}

function deleteQtItem(id, qtid) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        credentials: 'include',
    };
    return fetch(config.app.url + '/api/quoteitem/' + id + '/quote/' + qtid , requestOptions).then(handleResponse);
}

function handleResponse(response) {
    if (response.status === 401) {
        return 401;
    }
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                //location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        //console.log(data.data);
        return data.data;
    });
}

