import React from "react";
import { render } from "react-dom";
import { resourceService } from '../../service/resourceService';
import { servicerequestService } from '../../service/servicerequestService';
import { timesheetService } from '../../service/timesheetService';
import utility from '../../service/utility'
import { Card, Button, InputGroup, FormControl, Form, Col, Modal } from 'react-bootstrap';
import { Redirect } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SelectSearch from 'react-select-search';

import moment from 'moment';

class AddTimeSheet extends React.Component {  
  constructor(props) {
    super(props);
    this.state = {
      resourcename: undefined,
      orderdesc: undefined,

      orders: [],
      resources: [],

      peopleid: undefined,                  
      serviceid: undefined,
      timesheetstatus: undefined,
      scheduledate: undefined,
      starttime: undefined,
      endtime: undefined,
      actualservicedeliverydate: new Date(),
      actualtotalservicehour: undefined,
      actualtotalservicemin: undefined,
      note: undefined,
      ////    

    }
  }

  componentDidMount() {  
    // get active resource list
    resourceService.getResourceList().then(res => {
      if (res && res === 401) {
        this.props.history.push("/login");
      } else {
        //console.log(res);
        let rr = [];
        if (res && res.length > 0) {
          for (let i=0; i<res.length; i++) {
            let obj = {};
            obj.value = res[i].id;
            obj.name = (res[i].fname ? res[i].fname + ' ' : '') + (res[i].lname ? res[i].lname : '');
            rr.push(obj);
          }
        }
        //console.log(rr);        
        this.setState({ resources: rr });        
        // get service orders...
        servicerequestService.getServiceRequestList().then(res => {
          if (res && res === 401) {
            this.props.history.push("/login");
          } else {
            //console.log('testing...serviceorders...')
            //console.log(dataobjs);
            let rr = [];
            if (res && res.length > 0) {
              for (let i=0; i<res.length; i++) {
                let obj = {};
                obj.value = res[i].id;
                obj.name = '#' + res[i].id + ' - ' + res[i].detail;
                rr.push(obj);
              }
            } 
            //this.state.orders = rr;
            this.setState({ orders: rr });
          }
        });

        ////
      }
    });

  }

  handleInputChange = (event) => {    
    const target = event.target;
    const name = target.id;    
    this.setState({
      [name]: target.value
    });    
  }
  
  handleSubmit = (event) => {
    event.preventDefault();
    if (!this.state.peopleid) {
      alert('You must select resource');
    }
    else if (!this.state.serviceid) {
      alert('You must select service order');
    } else {
      let ts = {};
      ts.peopleid = this.state.peopleid;
      ts.serviceid = this.state.serviceid;
      ts.timesheetstatus = 1;
      ts.archive = false;    
      ts.actualservicedeliverydate = new Date(this.state.actualservicedeliverydate).toISOString();
      ts.actualtotalservicehour = (this.state.actualtotalservicehour ? this.state.actualtotalservicehour : 0);
      ts.actualtotalservicemin = (this.state.actualtotalservicemin ? this.state.actualtotalservicemin : 0);
      ts.note = this.state.note;

      timesheetService.createTimeSheet(ts).then(resources => {
        if (resources && resources === 401) {
          this.props.history.push("/login");
        } else {
          this.setState({gobackto: '/timesheet'});        
        }
      });
    }
  }

  handleCancel = (event) => {
    this.setState({gobackto: '/timesheet'});        
  }

  setDate = (date) => {
    this.setState({actualservicedeliverydate: date});
  }

selectResource = (e) => {
    //console.log(e);
    //alert(e.value + ' : ' + e.name);
    let r = {};
    r.id = e.value;
    r.name = e.name;
    this.setState({resourcename: r.name, peopleid: r.id});
}

selectOrder = (e) => {
  let r = {};
  r.id = e.value;
  r.name = e.name;
  this.setState({orderdesc: r.name, serviceid: r.id});
}

 
  render() {

    if (this.state.gobackto) {
      return <Redirect to={this.state.gobackto} />
    }    
    
    if (this.state.resources.length === 0) {
      return (<div></div>);
    }

    return (
      <div className="content-screen">    
        <Card style={{ width: '36rem' }}>
          <Card.Header>                       
            Add a TimeSheet
          </Card.Header>
          <Card.Body>      

              <Form onSubmit={this.handleSubmit}>
                  <Form.Row>
                    <div>
                        <SelectSearch options={this.state.resources} 
                                      value={this.state.peopleid} name={this.state.resourcename} placeholder="Select resource" 
                                      height={172} 
                                      onChange={this.selectResource}/>
                        
                        <SelectSearch options={this.state.orders} 
                                      value={this.state.serviceid} name={this.state.orderdesc} placeholder="Select service order" 
                                      height={172} 
                                      onChange={this.selectOrder}/>

                    </div>
                  </Form.Row>
                  <Form.Row>
                  <Form.Group as={Col} controlId="actualservicedeliverydate">
                      <Form.Label  style={{fontSize: "14px"}}>Actual service delivery date</Form.Label>  
                      <div>
                      <DatePicker style={{displayValue: 'block'}}
                          selected={this.state.actualservicedeliverydate}
                          onChange={date => this.setDate(date)}
                          dateFormat="MMM d, yyyy HH:mm"
                          placeholderText="Enter a date"
                          
                      /> </div>                   
                    </Form.Group>
                    
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="actualtotalservicehour" onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Hours</Form.Label>
                      <Form.Control type="number" placeholder="Enter only hours" defaultValue={this.state.actualtotalservicehour}></Form.Control>            
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                  <Form.Group as={Col} controlId="actualtotalservicemin" onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Minutes</Form.Label>
                      <Form.Control type="number" placeholder="Enter only minutes" defaultValue={this.state.actualtotalservicemin}></Form.Control>            
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="note" onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Note</Form.Label>
                      <Form.Control placeholder="Enter remarks" defaultValue={this.state.note}></Form.Control>            
                    </Form.Group>
                  </Form.Row>
                   <div style={{float: "left"}} > 
                    <Button type="submit" variant="outline-primary  mr-3" size="sm">Save</Button>                      
                    <Button onClick={this.handleCancel} variant="outline-primary" size="sm" >Cancel</Button>                                        
                    </div>
              </Form>
              
          </Card.Body>
        </Card>
      </div>
    );
  }
}

export default AddTimeSheet;