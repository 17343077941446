import React from "react";
import { render } from "react-dom";
import { quoteService } from '../../service/quoteService';
import { inventoryService } from '../../service/inventoryService';
import utility from '../../service/utility'
import { Card, Button, InputGroup, FormControl, Form, Col, Modal } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { Redirect } from "react-router-dom";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory from 'react-bootstrap-table2-editor';

class AddLineItemQt extends React.Component {  
  constructor(props) {
    super(props);
    this.state = {
      serviceid: undefined,

      id: undefined,      
      quoteId: undefined,
      itemId: undefined,
      item: undefined,
      description: undefined,
      qty: undefined,
      listprice: undefined,
      discount: undefined,
      unitprice: undefined,
      tax1: undefined,
      tax01: undefined,
      scheduledShipDate: undefined,
      companyId: undefined,
      
      ////
      edititem: undefined,
      origitem: undefined,
      origItemId: undefined,
      // editdescription: undefined,
      // editqty: undefined,      
      // editdiscount: undefined,
      // editunitPrice: undefined,
      // edittax1: undefined,
      // edittax01: undefined,
      ///

      // searchitemId: undefined,
      // searchitem: undefined,
      // searchdescription: undefined,
      // searchqty: undefined,
      // searchlistPrice: undefined,
      // searchdiscount: undefined,
      // searchunitPrice: undefined,

      taxOptions:[],
      mouOptions: [],
      gobackto: undefined,
      searchmodal: false,
      invitems: [],
      invcolumns: [
        {
          dataField: 'id',
          text: 'id',
          hidden: true
        }, {
          dataField: 'item',
          text: 'Item',
          hidden: false,
          headerStyle: {
            backgroundColor: '#D9D8D9'
          },
          formatter: (cellContent, row) => {
            let config = JSON.parse(localStorage.getItem('config'));
            //console.log(config);
            let st = JSON.parse(config.serviceorderstatuses);
            return (
              <h6><small>
                {row.item &&
                <div className="label label-success">{row.item}</div>
                }
                {row.description &&
                <div className="label label-success">{row.description}</div>
                }                
              </small></h6>
            );            
          }
        }, {
          dataField: 'description',
          text: 'Description',
          hidden: true,
          headerStyle: {
            backgroundColor: '#D9D8D9'
          }
        }, {
          dataField: 'inventory',
          text: 'Quantity',
          hidden: true,
          headerStyle: {
            backgroundColor: '#D9D8D9'
          }
        },{
          dataField: 'discount',
          text: 'Discount',
          hidden: true,
          headerStyle: {
            backgroundColor: '#D9D8D9'
          }
        },{
          dataField: 'unitofmeasure',
          text: 'UoM',
          hidden: true,
          headerStyle: {
            backgroundColor: '#D9D8D9'
          }
        },{
          dataField: 'actualunitcost',
          text: 'Purchased Cost',
          hidden: true,
          headerStyle: {
            backgroundColor: '#D9D8D9'
          }
        }, {
          dataField: 'unitcost',
          text: 'Qty and Unit Price',
          hidden: false,
          headerStyle: {
            backgroundColor: '#D9D8D9'
          },
          formatter: (cellContent, row) => {
            let config = JSON.parse(localStorage.getItem('config'));
            //console.log(config);
            let st = JSON.parse(config.serviceorderstatuses);
            let qt = 0;
            if (row.inventory) {
              qt = row.inventory;
            }
            let up = 0;
            if (row.unitcost) {
              up = Number(row.unitcost).toFixed(2);
            }
            return (
              <h6><small>              
                {
                <div className="label label-success">Qty: {qt} </div>
                }
                {
                <div className="label label-success">Unit Price: {up}</div>
                }
              </small></h6>
            );            
          }
        }],
        selectInvRow: {
          mode: 'radio',
          clickToSelect: true,
          hideSelectColumn: true,
          bgColor: '#adb5bd',
          onSelect: (row, isSelect, rowIndex, e) => {
            this.handleSelectedLineItem(row);
          }
        },
        selectedRow: undefined,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);   
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSelectItem = this.handleSelectItem.bind(this);
    this.handleSelectItemClose = this.handleSelectItemClose.bind(this);
    this.handleSelectedLineItem = this.handleSelectedLineItem.bind(this);
    this.searchItems = this.searchItems.bind(this);
  }

  componentDidMount() {  
    
    let usr = JSON.parse(localStorage.getItem('user'));
    if (usr.config.mou) {
      this.setState({mouOptions: usr.config.mou});
    }
    if (usr.config.tax) {
      this.setState({taxOptions: usr.config.tax});
    }

    const { id } = this.props.match.params; //quoteId
    const { item } = this.props.match.params; //itemid
    const { serviceid } = this.props.match.params; //serviceid

    this.state.id = item;
    this.state.quoteId = id;
    this.state.serviceid = serviceid;

    //console.log(this.state.id, this.state.quoteId);
    if (item) {
      // get the sales order item from the database...
      quoteService.getQtLineItem(item, id).then(solitm => {
        if (solitm && solitm === 401) {
          this.props.history.push("/login");
        } else if (solitm.lineItem && solitm.lineItem.length > 0) {
          this.setState({
            id: solitm.lineItem[0].id,      
            quoteId: id,
            itemId: solitm.lineItem[0].itemId,   
            origItemId: solitm.lineItem[0].itemId,         
            item: solitm.lineItem[0].item,            
            origitem: solitm.lineItem[0].item,
            description: solitm.lineItem[0].description,
            qty: solitm.lineItem[0].qty,
            listPrice: solitm.lineItem[0].listPrice,
            discount: solitm.lineItem[0].discount,
            unitPrice: solitm.lineItem[0].unitPrice,
            tax1: solitm.lineItem[0].tax1,
            tax01: solitm.lineItem[0].tax01
          });
        }
      });
    }
  }

  handleInputChange(event) {
    const target = event.target;
    //alert(target.id + ', ' + target.value)
    const name = target.id;
    //alert(name + ', ' + target.value);
    if (name === 'tax01' && target.value === "Choose...") {
      this.setState({tax1: undefined,
                     tax01: undefined})
    }    
    else {
      this.setState({
        [name]: target.value
      });
      if (name === 'tax01') {
        //alert(JSON.stringify(this.state.taxOptions));
        for (let i=0; i<this.state.taxOptions.length; i++) {
          let o = this.state.taxOptions[i];
          if (o["name"] === target.value) {
            //alert(o["rate"]);         
            this.setState({tax1: o["rate"]});         
          }
        }
      } 
    }
  }
  
  handleSubmit(event) {
    event.preventDefault();
    //alert('handleSubmit...:' + this.state.quoteId);
    let item = {};
    if (this.state.unitPrice) {
      this.state.unitPrice = Number(this.state.unitPrice).toFixed(2);
    }
    
    if (this.state.id) {
      // this is an update operation...
      // did user change item?
      if (this.state.item != this.state.origitem) {
        // user changed the item - this means itemid must be changed
        if (this.state.itemId && this.state.origItemId && (this.state.itemId === this.state.origItemId)) {
          // user directly change the item code without getting it from inventory
          this.state.itemId = undefined;
        } 
      }
    }
    

    item.id = this.state.id;      
    item.quoteId = this.state.quoteId;
    item.itemId = this.state.itemId;
    item.item = this.state.item;
    item.description = this.state.description;
    item.qty = this.state.qty;
    item.listPrice = this.state.listPrice;
    item.discount = this.state.discount;
    item.unitPrice = this.state.unitPrice;
    item.tax1 = this.state.tax1;
    item.tax01 = this.state.tax01;
    item.scheduledShipDate = this.state.scheduledShipDate;
    //item.companyId = this.state.id;    
    //alert(JSON.stringify(item));
    quoteService.addOrUpdateQtLineItem(this.state.quoteId, item).then(solitm => {
      if (solitm && solitm === 401) {
        this.props.history.push("/login");
      } else {
        //goback...        
        this.setState({gobackto: '/ecquote/' + this.state.quoteId + '/serviceid/' + this.state.serviceid});        
      }
    });
  }

  handleDelete(event) {
 
    quoteService.deleteQtItem(this.state.id, this.state.quoteId).then(solitm => {
      if (solitm && solitm === 401) {
        this.props.history.push("/login");
      } else if (solitm.id) {
        //goback...
        this.setState({gobackto: '/ecquote/' + this.state.quoteId + '/serviceid/' + this.state.serviceid});        
      }
    });
  }

  handleCancel(event) {
    this.setState({gobackto: '/ecquote/' + this.state.quoteId + '/serviceid/' + this.state.serviceid});        
  }

  searchItems = () => {
    //alert(this.state.item);
    //inventoryService
    inventoryService.searchItem(this.state.edititem).then(items => {
      if (items && items === 401) {
        this.props.history.push("/login");
      } else {
        this.setState({
          invitems: items,
          searchmodal: !this.state.searchmodal});            
      }
    });    
    
  }

  handleSelectItemClose = () => {
    this.setState({searchmodal: !this.state.searchmodal});
  }

  handleSelectItem = () => {
    if (this.state.selectedRow && this.state.selectedRow.id) {
      //alert(this.state.selectedRow.unitCost);
      this.setState({
        item: this.state.selectedRow.item,
        itemId: this.state.selectedRow.id,         
        description: this.state.selectedRow.description,
        qty: this.state.selectedRow.inventory,
        listPrice: this.state.selectedRow.actualunitcost,
        discount: this.state.selectedRow.discount,
        unitPrice: this.state.selectedRow.unitcost,
        searchmodal: !this.state.searchmodal
      });
      //alert(this.state.selectedRow.item + ',' + this.state.edititem + ', ' + this.state.description);
    } else {
      this.setState({        
        searchmodal: !this.state.searchmodal
      });
    }    
  }

  handleSelectedLineItem = (row) => {
    //alert(JSON.stringify(row));
    this.setState({selectedRow: row});
    
    // this.setState({
    //   itemId: row.id,   
    //   item: row.item,
    //   description: row.description,
    //   qty: row.inventory,
    //   listPrice: row.actualUnitCost,
    //   discount: row.discount,
    //   unitPrice: Number(row.unitCost).toFixed(2)
    // });
  }

  render() {

    if (this.state.gobackto) {
      return <Redirect to={this.state.gobackto} />
    }

    let txOpt = [];
    txOpt.push(<option>Choose...</option>);
    if (this.state.taxOptions) {      
      for (let i=0; i<this.state.taxOptions.length; i++) {
        let obj = this.state.taxOptions[i];
        //console.log(obj);
        // txOpt.push(<option key={obj.name} value={obj.name}>{obj.name}</option>);
        if (this.state.tax01 && this.state.tax01 === obj.name) {
          txOpt.push(<option value={obj.name} selected>{obj.name}</option>);
        } else {
          txOpt.push(<option value={obj.name}>{obj.name}</option>);
        }
      }
    }
    
    return (
      <div className="content-screen">    
        <Card style={{ width: '36rem' }}>
          <Card.Header>
            
            <Form>
                  <div style={{width: "70%", display: "inline-block"}}>
                  { !this.state.id &&   
                  <Form.Group as={Col} style={{marginBottom: '1px'}} controlId="edititem" onChange={this.handleInputChange}>
                      <InputGroup>
                      <Form.Control placeholder="Search item in inventory" defaultValue={this.state.edititem} />  
                      <InputGroup.Append>
                          <Button onClick={this.searchItems} variant="outline-primary">Search</Button>
                      </InputGroup.Append>
                      </InputGroup>
                  </Form.Group>
                  }
                  </div>
                  <div style={{float: 'right', display: "inline-block"}}>
                      <Button className="btn-sm" variant="link" style={{color: 'red', textDecorationLine: 'underline', textDecorationColor: 'red'}} onClick={() => {if(window.confirm('Remove this line item?')){this.handleDelete()};}}>delete</Button>
                  </div>
                 
            </Form>
            
          </Card.Header>
          <Card.Body>      

              <Form noValidate onSubmit={this.handleSubmit}>
              <Form.Row></Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="item" onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Item #</Form.Label>
                      {this.state.id ?
                      <Form.Control disabled defaultValue={this.state.item} />                        
                      : <Form.Control placeholder="Enter item code" defaultValue={this.state.item} />                        
                      }
                    </Form.Group>
                    {/* <Form.Group as={Col} controlId="edititem" onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Item #</Form.Label>
                      <InputGroup>
                      <Form.Control placeholder="Enter item code" defaultValue={this.state.edititem} />  
                      <InputGroup.Append>
                          <Button onClick={this.searchItems} variant="secondary">Search</Button>
                      </InputGroup.Append>
                      </InputGroup>
                    </Form.Group> */}
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="description" onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Description</Form.Label>
                      <Form.Control required="true" placeholder="Enter item description" defaultValue={this.state.description}></Form.Control>            
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="qty" onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Quantity</Form.Label>
                      <Form.Control required="true" type="Number" placeholder="Enter item quantity" defaultValue={this.state.qty}></Form.Control>            
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="unitPrice" onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Unit Price</Form.Label>
                      <Form.Control required="true" type="Number" placeholder="Enter unit price" defaultValue={this.state.unitPrice}></Form.Control>            
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="discount" onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Discount</Form.Label>
                      <Form.Control type="Number" placeholder="Enter item discount" defaultValue={this.state.discount}></Form.Control>                        
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="tax01" onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Tax</Form.Label>
                      <Form.Control as="select" defaultValue={this.state.tax01} ref="templateSelect">
                            {txOpt}                            
                      </Form.Control>            
                    </Form.Group>
                   </Form.Row>
                   <div style={{float: "left"}} > 
                    
                    {this.state.id && 
                      <Button type="submit" variant="outline-primary  mr-3" size="sm" >Update</Button>
                      }
                    {!this.state.id && 
                      <Button type="submit" variant="outline-primary  mr-3" size="sm">Save</Button>
                      } 
                      <Button onClick={this.handleCancel} variant="outline-primary  mr-3" size="sm" >Cancel</Button>
                    </div>
              </Form>
              
          </Card.Body>
          
        </Card>

        <>
                <Modal show={this.state.searchmodal} onHide={this.handleSelectItemClose.bind(this)}
                  
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Header closeButton>              
                    <Modal.Title>Select an inventory item</Modal.Title>              
                  </Modal.Header>
                  <Modal.Body>
                        <ToolkitProvider
                        bootstrap4
                        keyField='id'
                        data={ this.state.invitems }
                        columns={ this.state.invcolumns }
                        search                        
                      >
                        {
                          props => (
                            <div> 
                                                       
                              <BootstrapTable style={{scrollY: "200px",scrollCollapse: true}}
                                bootstrap4
                                { ...props.baseProps }
                                { ...props.paginationFactory }
                                { ...props.striped }
                                { ...props.defaultEquipSorted }
                                selectRow={ this.state.selectInvRow }
                                //pagination={ paginationFactory( {sizePerPage: 6}) }                     
                                pagination={ paginationFactory({hideSizePerPage: true}) }                     
                                bordered = {false} 
                                condensed = {true}  
                                //defaultSorted = { this.state.defaultSorted } 
                                cellEdit={ cellEditFactory({ mode: 'click', blurToSave: true }) }
                              />
                            </div>
                          )
                        }
                      </ToolkitProvider>

                  </Modal.Body>
                  <Modal.Footer style={{justifyContent: 'left'}}>
                  <div style={{float: "left"}} > 
                    <Button variant="outline-primary mr-3" onClick={this.handleSelectItem.bind(this)}>
                      Select
                    </Button>
                    <Button variant="outline-primary" onClick={this.handleSelectItemClose.bind(this)}>
                      Cancel
                    </Button>
                      
                    </div>                  
                  </Modal.Footer>
                </Modal>
                </>

      </div>
    );
  }
}

export default AddLineItemQt;