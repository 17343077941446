import React from "react";
import { Card, Button, Form, Col } from 'react-bootstrap';
import { Redirect } from "react-router-dom";
import { customerService } from '../../service/customerService';


class JobAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        id: undefined,
        parentCustomerId: undefined,
        jobnumber: undefined,
        jobdescription: undefined,
    }
  }

  componentDidMount() {  

    let config = JSON.parse(localStorage.getItem('config'));
    this.setState({assettypes: JSON.parse(config.assettypes),
                   assetstatus: JSON.parse(config.assetstatus)
    });


    //const {id} = this.props.match.params; // this is the ID for the job if we are editing it
    const {parentid} = this.props.match.params; // this is the ID for the customer which parent to this job
    this.setState({parentCustomerId: parentid});
  }
  
  handleInputChange = (event) => {    
    const target = event.target;
    const name = target.id;    
    this.setState({
      [name]: target.value
    });    
  }


  handleSubmit = (event) => {
      event.preventDefault();
      
      let job = {};
      job.id = this.state.parentCustomerId;
      job.jobNumber = this.state.jobnumber;
      job.jobDescription = this.state.jobdescription;

      customerService.addJob(job).then(ast => {
        if (ast && ast === 401) {
          this.props.history.push("/login");
        } else {  
          this.setState({gobackto: '/joblist/' + this.state.parentCustomerId});   
          this.props.history.goBack();
        }
      });
  }


  goBack = () => {
    this.props.history.goBack();
  }
  
  render() {
    if (this.state.gobackto) {
      return <Redirect to={this.state.gobackto} />
    }  

    
    return (
      <div className="content-screen" style={{height: '100%', overflow: 'scroll'}}>
      
        <Card style={{ width: '40rem' }}>
          { this.state.id &&
              <Card.Header style={{fontWeight: 'bold'}}>Editing Job</Card.Header>
          }
          { !this.state.id &&
              <Card.Header style={{fontWeight: 'bold'}}>Adding Job - Only if you use Job in Quickbooks</Card.Header>
          }
          <Card.Body>            
            <div>
                <Form onSubmit={this.handleSubmit}>

                <Form.Row>
                    <Form.Group as={Col} controlId="jobnumber" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Job Number</Form.Label>
                      <Form.Control  required="true" placeholder="Enter job number" defaultValue={this.state.jobnumber} />
                    </Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Form.Group as={Col} controlId="jobdescription" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Description</Form.Label>
                      <Form.Control  placeholder="Enter job description" defaultValue={this.state.description} />
                    </Form.Group>
                  </Form.Row> 

                  <div style={{float: "left"}} >                                       
                    <Button variant="outline-primary mr-2" size="sm" type="submit">Save</Button>  
                    <Button variant="outline-primary" size="sm" onClick={() => {this.goBack()}}>Cancel</Button>                            
                  </div>
              </Form>
            </div>
          </Card.Body>
          <Card.Footer className="text-muted">&nbsp;</Card.Footer>       
        </Card>      

      </div>
    );
  }
}


export default JobAdd;