import React from "react";

class Setting extends React.Component {
  render() {
    return (
        <div>
            Setting
        </div>
      
    );
  }
}

export default Setting;