import { authHeader } from './authHeader.js';
import {userService} from './userService.js'

var config = require('./config.js');

export const purchaseService = {
    getPo,
    addNewPo,
    updatePo,
    deletePo,
    getPoLineItem,
    addOrUpdatePoLineItem,
    deletePoItem,
    getPosForService,
};

function getPosForService(serviceid) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + '/api/purchaseordersfororder/' + serviceid , requestOptions).then(handleResponse);
}

function getPo(poid) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + '/api/purchaseorder/' + poid , requestOptions).then(handleResponse);
}

function addNewPo(po, serviceid) {    
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(po)   
    };
    return fetch(config.app.url + '/api/purchaseorder/' + serviceid, requestOptions).then(handleResponse);
}

function updatePo(resource) {
    //console.log(resource);
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(resource)     
    };
    return fetch(config.app.url + '/api/purchaseorder/' + resource.id , requestOptions).then(handleResponse);
}

function deletePo(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        credentials: 'include',
    };
    return fetch(config.app.url + '/api/purchaseorder/' + id , requestOptions).then(handleResponse);
}

function getPoLineItem(id, orderid) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + '/api/purchaseorderitem/' + id + '/order/' + orderid, requestOptions).then(handleResponse);
}

function addOrUpdatePoLineItem(poid, item) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',  
        body: JSON.stringify(item)  
    };
    return fetch(config.app.url + '/api/purchaseorderitem/' + poid, requestOptions).then(handleResponse);
}

function deletePoItem(id, poid) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        credentials: 'include',
    };
    return fetch(config.app.url + '/api/purchaseorderitem/' + id + '/order/' + poid , requestOptions).then(handleResponse);
}

function handleResponse(response) {
    if (response.status === 401) {
        return 401;
    }
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                //location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        //console.log(data.data);
        return data.data;
    });
}

