import React from "react";
import { servicerequestService } from '../../service/servicerequestService';
import { scheduleService } from '../../service/scheduleService';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import utility from '../../service/utility'

class ServiceResources extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        serviceid: undefined,
        resources: [],
        columns: [{
          dataField: 'id',
          text: 'id',
          hidden: true
        },        
        {
          dataField: 'fname',
          text: 'First Name',
          hidden: true,          
        },{
          dataField: 'lname',
          text: 'Resource',
          sort: true,
          headerStyle: {
            backgroundColor: '#D9D8D9'
          },
          formatter: (cellContent, row) => {
            let name = (row.fname ? row.fname : '') + ' ' + (row.lname ? row.lname : '');
            let tdiff = Math.abs((row.endtime ? row.endtime : 0) - (row.starttime ? row.starttime : 0));
            if (tdiff && tdiff > 0) {
              tdiff = tdiff * 60;
            }            
            let tm = tdiff/60 + ' h ' +  tdiff %60 + ' m';
            let contact = '' + (row.email ? row.email : '') + (row.phone ? 'Ph ' + row.phone : '');
            let desc = (row.scheduledate ? utility.utcToLocalDateTime(row.scheduledate) + ' ': '') + ' for ' + tm; 
            return (
              <>
              <h5>
                <small className="label label-success"> {name}</small>
              </h5>
              <h5>
              <small className="label label-success"> {desc}</small>
            </h5>
            <h5>
              <small className="label label-success"> {contact}</small>
            </h5>
            </>
            );            
          }
        } , {
          dataField: 'phone',
          text: 'Phone',
          hidden: true,
          headerStyle: {
            backgroundColor: '#D9D8D9'
          }
        } , {
          dataField: 'email',
          hidden: 'Position',
          hide: true,          
        } , {
          dataField: 'amleader',
          text: 'Is Leader?',
          headerStyle: {
            backgroundColor: '#D9D8D9'
          },
          editor: {
            type: Type.CHECKBOX,
            value: 'true:false'
          }          
        } 
      ],
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.setState({serviceid: id});
    servicerequestService.getServiceOrderLeaderHelpers(id).then(resources => {
      if (resources && resources === 401) {
        this.props.history.push("/login");
      } else {
        this.setState({ resources })
      }
    });
    
  }

  handleCancel = () => {
    this.props.history.goBack();
  };

  handleSave = () => {
    
    let noTrue = Number(0);
    let plpid = 0;
    if (this.state.resources && this.state.resources.length > 0) {
      this.state.resources.forEach(res => {

        if (res.amleader === 'true') {
          res.amleader = true;
        } else if (res.amleader === 'false') {
          res.amleader = false;
        }
        if (res.amleader === true) {
          noTrue = noTrue + 1;  
          plpid = res.id;  
          console.log('plpid', plpid)      
        }
      });
    }
    if (noTrue === 0) {
      alert('You must set at least one resource as leader');
    } else if (noTrue > 1) {
      alert('You set more than one resource as leader. You can only set one resource as a leader.');
    } else {
      scheduleService.updateLeader(this.state.serviceid, plpid).then(resources => {
        if (resources && resources === 401) {
          this.props.history.push("/login");
        } else {
          this.props.history.goBack();
        }
      });
    }
  }

  updateResource = (row) => {
    if (this.state.resources && this.state.resources.length > 0) {
      this.state.resources.forEach(res => {
        if (res.id === row.id) {
          res.amleader = row.amleader;
        }
      });
    }
  }
  
  render() {
    return (       
        
        <div className="content-screen">

        <BootstrapTable
          keyField="id"
          data={ this.state.resources }
          columns={ this.state.columns }
          cellEdit={ cellEditFactory({ 
            mode: 'click', 
            blurToSave: true,
            afterSaveCell: (oldValue, newValue, row, column) => { this.updateResource(row)}
            }) 
          }
        />
        <div style={{float: "right"}} >    
            <button className="btn btn-primary ml-3" onClick={ this.handleSave }>Save</button>  
            <button className="btn btn-secondary ml-3" onClick={ this.handleCancel }>Cancel</button>
        </div>

        
      </div>  
    );
  }
}

export default ServiceResources;