import React from "react";
import { render } from "react-dom";
import { activityService } from '../../service/activityService';
import { checklistService } from '../../service/checklistService';
import { assetService } from '../../service/assetService';

import { Card, Button, InputGroup, Form, Col, Modal } from 'react-bootstrap';
import { Redirect } from "react-router-dom";
import DatePicker from "react-datepicker";

import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';


import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Instructions from './includes/view/Instructions'
import TextInput from './includes/view/TextInput'
import CheckBox from './includes/view/CheckBox'
import SubLabelTextInput from './includes/view/SubLabelTextInput'

import '../../css/Form.css';

class ChecklistFormView extends React.Component {  
  constructor(props) {
    super(props);
    this.state = {                      
      serviceid: undefined,
      id: undefined, //template
      type: 1,
      detail: undefined,      
      checked: false,
      checklistforms: [],
      checklistid: undefined,    
      assetid: undefined,
      orderequips: [],
      //
      form: {},
      namelabel: "",
      name: "",
      remarklabel: "",
      Remark: "",
      descriptionlabel: "",
      Description: "",
      codelabel: "",
      code: "",
      schedulelabel: "",
      schedule: [],
      datelabel: "",
      checklist: ["Version", "Description", "Schedule", "Date", "Remark", "TechnicianSignature", "CustomerSignature"],
      Date: new Date()
      
    }
  }

  componentDidMount() {  
    //console.log(this.props.match.params)
    const { id } = this.props.match.params; 
    if (id) {
      this.state.id = id;
      checklistService.getTemplate(id) 
      .then(val => {
        if (val && val === 401) {
          this.props.history.push("/login");
        } else {                   
          //console.log(JSON.parse(val.template));
          this.processform(JSON.parse(val.template))
          }
      });
    }
  }

  processform = result => {

    //console.log(result);

        this.state.checklist.forEach(function(check) {
            if (!result.hasOwnProperty(check)) {
                result[check] = {};
            }
        })        
        let namelabel = Object.keys(result.Name)[0];
        let remarklabel = Object.keys(result.Remark)[0];
        let descriptionlabel = Object.keys(result.Description)[0];
        let codelabel = Object.keys(result.Code)[0];
        let schedulelabel = Object.keys(result.Schedule)[0];
        let datelabel = Object.keys(result.Date)[0];
        let techsignaturelabel = Object.keys(result.TechnicianSignature)[0];
        let custsignaturelabel = Object.keys(result.CustomerSignature)[0];
        //console.log('custsignaturelabel', custsignaturelabel);
        //console.log('result.CustomerSignature[custsignaturelabel]', result.CustomerSignature[custsignaturelabel]);
        this.setState({
            namelabel: namelabel,
            name: result.Name[namelabel],
            remarklabel: remarklabel,
            Remark: result.Remark[remarklabel],
            descriptionlabel: descriptionlabel,
            Description: result.Description[descriptionlabel],
            codelabel: codelabel,
            code: result.Code[codelabel],
            schedulelabel: schedulelabel,
            schedule: (result.Schedule && result.Schedule[schedulelabel] ? result.Schedule[schedulelabel].split(",") : []),
            scheduleselect: (result.Schedule['selection'] ? result.Schedule['selection'] : undefined),
            datelabel: datelabel,
            Date: ((result.Date[datelabel] && result.Date[datelabel] !== "now") ? new Date(result.Date[datelabel]) : new Date()),
            techsignaturelabel: techsignaturelabel,
            TechnicianSignature: result.TechnicianSignature[techsignaturelabel],
            custsignaturelabel: custsignaturelabel,
            CustomerSignature: result.CustomerSignature[custsignaturelabel],
            form: result,
            loaded: true
        });
        this.updateProperty = this.updateProperty.bind(this);
        
}


processformType = formproperty => {
  //console.log('processformType', formproperty);
    switch(formproperty.type) {
        case "instructions":
            return <Instructions instruction={formproperty.value} key={formproperty.value} />;
        case "textinput":
            return <TextInput property={formproperty} label={formproperty.label} unit={formproperty.unit} key={formproperty.label} update={this.updateProperty} />
         case "checkbox":
             return <CheckBox property={formproperty} label={formproperty.value} key={formproperty.value} update={this.updateProperty} />;
         case "sublabeltextinput":
             return <SubLabelTextInput property={formproperty} label={formproperty.label} key={formproperty.label} sublabels={formproperty.sublabels} update={this.updateProperty} />;
        default:
            return '';
    }
}

renderProperties = id => {
    //console.log('renderProperties', id);
    let property = [];
    let formproperties = this.state.form.formproperties;
    formproperties.forEach(function(formproperty) {
        if (formproperty.formsectionid === id) {
            property.push(this.processformType(formproperty));

        }
    }.bind(this));
    return property;
}

updateProperty = (event, type, id, key, label, unit) => {    
    //console.log('updateProperty...............................event.target', event.target);
    let form = this.state.form;
    let formproperties = form.formproperties;
    //console.log('updateProperty...', formproperties);
    for (var i = 0; i < formproperties.length; i++) {
        if (formproperties[i].formsectionid === id && formproperties[i].type === type) {
            if (key === "label") {
              if (formproperties[i].label === label) {
                formproperties[i].value = (event.target.value ? event.target.value : formproperties[i].value);
              }
            }
            if (key === "unit") {
              if (formproperties[i].label === label) {
                formproperties[i].unitvalue = event.target.value;
                //formproperties[i].unit = event.target.value;
              }
            }
            if (key === "check") {
                formproperties[i].checked = event.target.checked;
            }
            if (key === "sublabel") {
                let sublabels = formproperties[i].sublabels;
                   for (var l = 0; l < sublabels.length; l++) {
                       if (Object.keys(sublabels[l])[0] === label) {
                           let oo = {};
                           oo.value = event.target.value;
                           oo.unit = unit;
                           sublabels[l][label] = oo;
                           //sublabels[l][label] = event.target.value;
                           //console.log(sublabels[l]);
                       }
                   }
            }
        }
    }
    this.setState(prevState => ({
        ...prevState,
        form: form
    }));
}

handleSchSelectChange = event => {
    let selection = this.state.scheduleselect;
    selection = event.target.value;
    let form = this.state.form;
    form["Schedule"].selection = selection;
    //console.log('handleSchSelectChange', selection);
    this.setState(prevState => ({
        ...prevState,
        form: form,
        scheduleselect: selection
    }));

}

updateValue = (event, fieldkey) => {
    
    //let description = this.state.description
    let form = this.state.form;
    let field = form[fieldkey];
    let fieldlabel = Object.keys(field)[0];
    field[fieldlabel] = event.target.value;
    //console.log(fieldkey, 'field', field, 'fieldlabel', fieldlabel, 'field[fieldlabel]', field[fieldlabel]);
    //console.log(form);
    this.setState(prevState => ({
        ...prevState,
        form: form,
        [fieldkey]: field[fieldlabel],
        //description: description,
    }));    
    
}



  handleInputChange = (event) => {    
    const target = event.target;
    const name = target.id;    
    this.setState({
      [name]: target.value
    });    
  }

  handleSelectChange = (event) => {    
    const target = event.target;
    const name = target.id;    
    this.setState({
      [name]: target.value,
      detail: "",
    });    
  }
  
  handleChecklistSelectInputChange = (event) => {    
    const target = event.target;
    const name = target.id;    
    this.setState({
      [name]: target.value
    });
    
    for (let i=0; i<this.state.checklistforms.length; i++) {
      let v = this.state.checklistforms[i];
      //console.log(v, Object.keys(v.Code), v.Code[Object.keys(v.Code)], target.value);
      if (target.value === v.Code[Object.keys(v.Code)]) {
        this.setState({
          namelabel: "",
          name: "",
          remarklabel: "",
          Remark: "",
          descriptionlabel: "",
          Description: "",
          codelabel: "",
          code: "",
          schedulelabel: "",
          schedule: "",
          scheduleselect: "",
          datelabel: "",
          techsignaturelabel: "",
          TechnicianSignature: "",
          custsignaturelabel: "",
          CustomerSignature: "",
          loaded: true
      });
        this.processform(v);
        return;
      }
    }    
  }


  handleCancel = (event) => {
    this.setState({gobackto: '/checklistformlist'});          
  }

  deleteTemplate = () => {
    checklistService.deleteTemplate(this.state.id) 
      .then(val => {
        if (val && val === 401) {
          this.props.history.push("/login");
        } else {                   
          this.props.history.goBack();
        }
      });
  }

  editTemplate = () => {
    this.props.history.push("/checklistformedit/" + this.state.id );
  }

  handleChecked = () => {
    this.setState({checked: !this.state.checked});
    //setChecked((prev) => !prev);
  };

  setDate = (date, fieldkey) => {
    let form = this.state.form;
    let field = form[fieldkey];
    let fieldlabel = Object.keys(field)[0];
    field[fieldlabel] = date;
    this.setState(prevState => ({
        ...prevState,
        form: form,
        [fieldkey]: field[fieldlabel],
    }));
  }
 
  render() {

    const {
      form,
      namelabel,
      name,
      remarklabel,
      Remark,
      descriptionlabel,
      Description,
      codelabel,
      code,
      schedulelabel,
      schedule,
      datelabel,
      Date,
      techsignaturelabel,
      TechnicianSignature,
      custsignaturelabel,
      CustomerSignature,
      scheduleselect } = this.state;
      const ITEM_HEIGHT = 48;
      const ITEM_PADDING_TOP = 30;
      const MenuProps = {
        PaperProps: {
            style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
            },
        },
        };
    //console.log('render description', this.state.Description,  Description);

    if (this.state.gobackto) {
      return <Redirect to={this.state.gobackto} />
    }    
    
    let opt = [];
    if (this.state.type === 1) {
      opt.push(<option value="1" selected>Service Note</option>);    
    } else {
      opt.push(<option value="1" >Service Note</option>);
    }

    if (this.state.type === 2) {
      opt.push(<option value="2" selected>Customer Note</option> );
    } else {
      opt.push(<option value="2" >Customer Note</option> );            
    }

    if (this.state.type === 3) {
      opt.push(<option value="3" selected>Other Note</option>);                
    } else {
      opt.push(<option value="3" >Other Note</option>);                
    }

    let chkform = [];
    chkform.push(<option value=" ">Choose...</option>);
    if (this.state.checklistforms && this.state.checklistforms.length > 0) {
      for (let k=0; k<this.state.checklistforms.length; k++) {
        if (this.state.code && this.state.code === this.state.checklistforms[k].Code.Code) {
          chkform.push(<option selected>{this.state.checklistforms[k].Code.Code}</option>);                
        } else {
          chkform.push(<option >{this.state.checklistforms[k].Code.Code}</option>);                
        }
      }
    }

    
    let asstsel = [];
    asstsel.push(<option value="-1">Choose...</option>);                
    if (this.state.orderequips && this.state.orderequips.length > 0) {
      for (let k=0; k<this.state.orderequips.length; k++) {
        if (this.state.assetid && this.state.assetid === this.state.orderequips[k].id) {
          asstsel.push(<option selected value={this.state.orderequips[k].id}>{this.state.orderequips[k].serial_no} - {this.state.orderequips[k].description}</option>);                
        } else {
          asstsel.push(<option value={this.state.orderequips[k].id}>{this.state.orderequips[k].serial_no} - {this.state.orderequips[k].description}</option>);                
        }
      }
    }

    //console.log('form', form);
    return (
      <div className="content-screen">    
        <Card style={{ minWidth: '36rem' }}>
          <Card.Header>                       
            
            <h5>
              <small>Form View</small>
              <div style={{float: 'right'}}>
                { this.state.id && this.state.type && this.state.type ===1 &&
                <>
                <Button className="btn-sm" variant="link"  style={{color: 'blue'}} onClick={() => {this.editTemplate()}}>edit</Button>&nbsp;&nbsp;
                <Button className="btn-sm" variant="link"  style={{color: 'red'}} onClick={() => {if(window.confirm('Remove the form?')){this.deleteTemplate()};}}>delete</Button>
                </>
                }
              </div>
            </h5>           
          </Card.Header>
          <Card.Body>      

              <Form >
                    
                  <div>                                    

                      <Form.Row>
                      <Form.Group as={Col} controlId="code" onChange={this.handleChecklistSelectInputChange}>
                        <Form.Label style={{fontSize: "14px"}}>Checklist code</Form.Label>
                        <Form.Control as="select" disabled >                        
                            {chkform}           
                        </Form.Control> 
                      </Form.Group>
                    </Form.Row>                                                                
                                       
                      <div className="formContainer">
                          <div className="formContent">
                              <div className="enterField">
                                  <TextField disabled className="enterItem" value={name} label={namelabel} />
                                  <TextField disabled className="enterItem" value={code} label={codelabel} />
                              </div>
                              <div className="enterField">
                                  <TextField disabled className={(descriptionlabel ? "enterItem" : "hidefield")} value={Description} label={descriptionlabel} onChange={(e) => this.updateValue(e, "Description")} />
                                  
                              </div>
                              <div className="enterField selectField">
                                <FormControl className={"selectScheduleFormControl" + (schedule.length > 1 ? "" : " hidefield")}>
                                <InputLabel className="selectInputLabel" id="schedule-label">{schedulelabel}</InputLabel>
                                    <Select
                                        labelId="schedule-label"
                                        value={scheduleselect}
                                        onChange={this.handleSchSelectChange}
                                        input={<Input />}
                                        displayEmpty
                                        MenuProps={MenuProps}
                                    >
                                        {schedule.map((item) => (
                                            <MenuItem key={item} value={item}>
                                                {item}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>                                  
                                    <div>
                                      <DatePicker disabled className={(datelabel ? "enterItem" : "hidefield")}  style={{displayValue: "block", marginTop: "16px"}}
                                          selected={Date}
                                          onChange={date => this.setDate(date, "Date")}
                                          dateFormat="MMM d, yyyy HH:mm"
                                          placeholderText="Enter a date"
                                          
                                      /> 
                                    </div>                                  
                              </div>                              
                              {form.formsections && form.formsections.map(section => (
                                  <div className="enterField enterSection" key={section.id + section.name}>
                                      <div>
                                      {section.name}
                                     </div>
                                      <div className="propertyEntryField">
                                          {this.renderProperties(section.id)}
                                      </div>
                                  </div>
                              ))}
                              <div className="enterField enterSection">
                                  <div>
                                  {remarklabel}
                                  </div>
                                  <TextareaAutosize disabled className={(remarklabel ? "enterItem" : "hidefield")}  aria-label="minimum height" rowsMin={2}  value={Remark}  onChange={(e) => this.updateValue(e, "Remark")}/>
                                  {/* <TextField className={(remarklabel ? "enterItem" : "hidefield")} value={Remark} label={remarklabel} onChange={(e) => this.updateValue(e, "Remark")} /> */}
                              </div>
                              <div className="enterField">
                                  <TextField disabled className={(techsignaturelabel ? "enterItem" : "hidefield")} value={TechnicianSignature} label={techsignaturelabel} onChange={(e) => this.updateValue(e, "TechnicianSignature")} />
                                  <TextField disabled className={(custsignaturelabel ? "enterItem" : "hidefield")} value={CustomerSignature} label={custsignaturelabel} onChange={(e) => this.updateValue(e, "CustomerSignature")} />
                              </div>
                          </div>
                          {/* <Button
                              className="saveButton"
                              variant="contained"
                              color="primary"
                              onClick={this.saveform}
                            >
                              Save
                            </Button>     */}
                       </div>                       
                    
                  </div>

                   <div style={{float: "left"}} >                                          
                    <Button onClick={this.handleCancel} variant="outline-primary" size="sm" >Cancel</Button>                                        
                    </div>
              </Form>
              
          </Card.Body>
        </Card>
      </div>
    );
  }
}

export default ChecklistFormView;