import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import "./../../App.css";
import { Image } from 'react-bootstrap';
import { userService } from '../../service/userService';
import { configService } from '../../service/configService';

class Login extends React.Component {
    constructor(props) {
        super(props);

        userService.logout();

        this.state = {
            username: '',
            password: '',
            submitted: false,
            loading: false,
            error: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({ submitted: true });
        // stop here if form is invalid
        if (!(this.state.username && this.state.password)) {
            return;
        }

        this.state.username = this.state.username.toLowerCase();
        this.state.username = this.state.username.trim();

        const { username, password, returnUrl } = this.state;

        this.setState({ loading: true });
        userService.login(username, password)
            .then(
                user => {
                    configService.getConfig()
                    .then( cnf => {
                    const { from } = this.props.location.state || { from: { pathname: "/" } };
                    this.props.history.push(from);
                    })
                },
                error => this.setState({ error: 'Login failed. Please verify username and password and retry.', loading: false })
            );
    }

    render() {
        localStorage.removeItem('user');
        const { username, password, submitted, loading, error } = this.state;
        return (
            
            <div className="login-form">                
                <h4 style={{textAlign: 'center'}}>Field Service Work Order</h4>
                <form name="form" onSubmit={this.handleSubmit} style={{marginTop: '30px'}}>
                    <div style={{color: 'red'}}>{this.state.error}</div>
                    <div className={'form-group' + (submitted && !username ? ' has-error' : '')}>
                        <label htmlFor="username">Username</label>
                        <input type="text" className="form-control" name="username" value={username} onChange={this.handleChange} />
                        {submitted && !username &&
                            <div className="help-block">Username is required</div>
                        }
                    </div>
                    <div className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                        <label htmlFor="password">Password</label>
                        <input type="password" className="form-control" name="password" value={password} onChange={this.handleChange} />
                        {submitted && !password &&
                            <div className="help-block">Password is required</div>
                        }
                    </div>
                    <div className="form-group">
                        <button className="btn btn-primary" disabled={loading}>Login</button> 
                        <Image style={{marginTop: '16px', float: 'right', height: '14px', width: '61px'}} src="./datamoto_logo.jpg" />                       
                    </div>                    
                </form>
            </div>
            
        );
    }
}
export default Login;