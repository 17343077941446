import React from "react";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { Form, Button, Col } from 'react-bootstrap';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { Multiselect } from "multiselect-react-dropdown";
import { resourceService } from '../../service/resourceService';

class ResourceDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fname: undefined,
      employementtype: undefined,
      unavailabefrom: undefined,
      unavailabeto: undefined,
      id: undefined,
      employeenumber: undefined,
      
      lname: undefined,
      gender: undefined,
      phone: undefined,
      email: undefined,
      position: undefined,
      department: undefined,
      manager: undefined,
      region: undefined,
      subregion: undefined,
      skills: [],
      picture: undefined,
      status: undefined,
      address: undefined,
      city: undefined,
      zip: undefined,
      state: undefined,
      country: undefined,
      workinghrstart: undefined,
      workinghrend: undefined,
      ismachine: undefined,
      isteam: undefined,
      externalid: undefined,
      lastsyncupdate: undefined,

      // below this line - these props are nor part of the resource object
      regular: undefined,
      contractor: undefined,
      temporary: undefined,
      equipment: undefined,
      skillOption: [],
      selectedSkills:[]
    };

    this.handleCancel = this.handleCancel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);   
    this.handleInputChange = this.handleInputChange.bind(this);       
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    //console.log(id);    

    let config = JSON.parse(localStorage.getItem('config'));
    let peopleskills = JSON.parse(config.peopleskills);
    let options = [];
    for (let i=0; i<peopleskills.length; i++) {
      let s = {};
      s.name=   peopleskills[i].skill;
      s.description = peopleskills[i].description; 
      options.push(s);     
    }
    this.setState({skillOption: options});

    this.setState({employementtype: 1, regular: true, contractor: false, temporary: false, equipment: false})    
    
    if (id) {
      resourceService.getResource(id)
        .then(resource => {
          this.setState({ 
            fname: resource.fname,
            employementtype: resource.employementtype,            
            unavailabefrom: resource.unavailabefrom != null ? new Date(resource.unavailabefrom) : null, 
            unavailabeto: resource.unavailabeto != null ? new Date(resource.unavailabeto) : null, 
            id: resource.id,
            employeenumber: resource.employeenumber,
            
            lname: resource.lname,
            gender: resource.gender,
            phone: resource.phone,
            email: resource.email,
            position: resource.position,
            department: resource.department,
            manager: resource.manager,
            region: resource.region,
            subregion: resource.subregion,
            skills: resource.skills != null ? JSON.parse(resource.skills) : [],
            picture: resource.picture,
            status: resource.status,
            address: resource.address,
            city: resource.city,
            zip: resource.zip,
            state: resource.state,
            country: resource.country,
            workinghrstart: resource.workinghrstart,
            workinghrend: resource.workinghrend,
            ismachine: resource.ismachine,
            isteam: resource.isteam,
          })
          if (this.state.employementtype && this.state.employementtype === 2) {
            this.setState({regular: false, contractor: true, temporary: false, equipment: false})
          } else if (this.state.employementtype && this.state.employementtype === 3) {
            this.setState({regular: false, contractor: false, temporary: true, equipment: false})
          } else if (this.state.employementtype && this.state.employementtype === 4) {
            this.setState({regular: false, contractor: false, temporary: false, equipment: true})
          } else {
            this.setState({employementtype: 1, regular: true, contractor: false, temporary: false, equipment: false})
          }
        });   
    }
    
  }
  
  handleInputChange(event) {
    const target = event.target;
    const name = target.id;
    if (target.type === 'radio') {

      if (name === "custom-inline-radio-2") {
        this.state.employementtype = 2;
      } else if (name === "custom-inline-radio-3") {
        this.state.employementtype = 3;
      } else if (name === "custom-inline-radio-4") {
        this.state.employementtype = 4;
      }else  {
        this.state.employementtype = 1;
      }
    } else {
      this.setState({
        [name]: target.value
      });
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    let tmp = JSON.stringify(this.state);
    let res = JSON.parse(tmp);
    res.skillOption = null;
    res.selectedSkills = null;
    res.regular = null;
    res.contractor = null;
    res.temporary = null;
    res.equipment = null;

    delete res.skillOption;
    delete res.selectedSkills;
    delete res.regular;
    delete res.contractor;
    delete res.temporary;
    delete res.equipment;

    if (res.skills) {
      res.skills = JSON.stringify(res.skills);
    }

    if (res.id) {
      resourceService.updateResource(res)
        .then(resource => {
          if (resource && resource === 401) {
            this.props.history.push("/login");
          } else {
            var { from } = this.props.location.state || { from: { pathname: "/resources" } };
            this.props.history.push(from);
          }
        });
    } else {
      resourceService.addNewResource(res)
        .then(resource => {
          if (resource && resource === 401) {
            this.props.history.push("/login");
          } else {
            var { from } = this.props.location.state || { from: { pathname: "/resources" } };
            this.props.history.push(from);
          }
        });
    }
  };
  
  handleCancel = (event) => {
    event.preventDefault();
    var { from } = this.props.location.state || { from: { pathname: "/resources" } };
    this.props.history.push(from);
  }

  handleDelete = (event) => {
    event.preventDefault();
    if (this.state.id) {
      let resource = {};
      resource.status = 2;
      resource.id = this.state.id
      resourceService.updateResource(resource)
        .then(resource => {
          if (resource && resource === 401) {
            this.props.history.push("/login");
          } else {
            var { from } = this.props.location.state || { from: { pathname: "/resources" } };
            this.props.history.push(from);
          }
        });      
    }
  }

  setUnavailabefrom = (date) => {
    this.setState({unavailabefrom: date});
  }

  setUnavailabeto = (date) => {
    this.setState({unavailabeto: date});
  }

  setMultiSkills = (option) => {
    //console.log(option[0]);    
    this.state.skills = option;
    //console.log(this.state.skills);
  }

  render() {
    //console.log(this.state.regular)
    //console.log(this.state.contractor)
    //console.log(this.state.temporary)
    //console.log(this.state.equipment)
    return (   
      <div className="content-screen" style={{padding: '10px'}}>
        <div style={{width: '72rem'}}>
        <span style={{fontWeight: 'bold'}}> Edit Resource </span>
      <Form onSubmit={this.handleSubmit} style={{marginTop: '20px'}}>        
        
        {['radio'].map(type => (
          <div key={`custom-inline-${type}`} className="mb-3">
            <Form.Check style={{fontSize: "14px"}}
              custom
              inline
              label="Regular employee"
              type={type}
              id={`custom-inline-${type}-1`}              
              name="emptype" 
              defaultChecked={this.state.regular}
              onChange={this.handleInputChange}
            />
            <Form.Check style={{fontSize: "14px"}}
              custom
              inline
              label="Contractor employee"
              type={type}
              id={`custom-inline-${type}-2`}
              name="emptype"
              defaultChecked={this.state.contractor}
              onChange={this.handleInputChange}
            />
            <Form.Check style={{fontSize: "14px"}}
              custom
              inline
              label="Temporary employee"
              type={type}
              id={`custom-inline-${type}-3`}
              name="emptype"
              defaultChecked={this.state.temporary}
              onChange={this.handleInputChange}
            />
            <Form.Check style={{fontSize: "14px"}}
              custom
              inline
              label="Equipment"
              type={type}
              id={`custom-inline-${type}-4`}
              name="emptype" 
              defaultChecked={this.state.equipment}
              onChange={this.handleInputChange}
            />
          </div>
        ))}        

        <Form.Row>
          <Form.Group as={Col} controlId="fname" 
              onChange={this.handleInputChange}>
            <Form.Label style={{fontSize: "14px"}}>First Name</Form.Label>
            <Form.Control size="sm" required="true" placeholder="Enter first name" defaultValue={this.state.fname} />
          </Form.Group>

          <Form.Group as={Col} controlId="lname" onChange={this.handleInputChange}>
            <Form.Label style={{fontSize: "14px"}}>Last Name</Form.Label>
            <Form.Control size="sm" placeholder="Enter last name" defaultValue={this.state.lname} />
          </Form.Group>
       
          <Form.Group as={Col} controlId="position" onChange={this.handleInputChange}>
            <Form.Label style={{fontSize: "14px"}}>Position</Form.Label>
            <Form.Control size="sm" placeholder="Enter position/title" defaultValue={this.state.position} />
          </Form.Group>
        </Form.Row>

        <Form.Row>  
          <Form.Group as={Col} controlId="phone" onChange={this.handleInputChange}>
            <Form.Label style={{fontSize: "14px"}}>Phone</Form.Label>
            <Form.Control size="sm" placeholder="Enter phone" defaultValue={this.state.phone}/>
          </Form.Group>

          <Form.Group as={Col} controlId="email" onChange={this.handleInputChange}>
            <Form.Label style={{fontSize: "14px"}}>Email</Form.Label>
            <Form.Control size="sm" type="email" placeholder="Enter email" defaultValue={this.state.email} />
          </Form.Group>

          <Form.Group as={Col} controlId="manager" onChange={this.handleInputChange}>
            <Form.Label style={{fontSize: "14px"}}>Manager</Form.Label>
            <Form.Control size="sm"  placeholder="Enter manager" defaultValue={this.state.manager} />
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} controlId="department" onChange={this.handleInputChange}>
            <Form.Label style={{fontSize: "14px"}}>Department</Form.Label>
            <Form.Control size="sm"  placeholder="Enter department" defaultValue={this.state.department} />
          </Form.Group>
          <Form.Group as={Col} controlId="region" onChange={this.handleInputChange}>
            <Form.Label style={{fontSize: "14px"}}>Region</Form.Label>
            <Form.Control size="sm"  placeholder="Enter region" defaultValue={this.state.region}/>
          </Form.Group>
          <Form.Group as={Col} controlId="subregion" onChange={this.handleInputChange}>
            <Form.Label style={{fontSize: "14px"}}>Sub Region</Form.Label>
            <Form.Control size="sm"  placeholder="Enter sub-region" defaultValue={this.state.subregion}/>
          </Form.Group>                    
        </Form.Row>

        <Form.Row>
          
          <Form.Group controlId="skills">
            <Form.Label style={{fontSize: "14px"}}>Skills</Form.Label>
            {/* <Form.Control size="sm" placeholder="Enter skills" /> */}
            <Multiselect size="sm"
              options={this.state.skillOption} 
              displayValue="name" 
              selectedValues={this.state.skills} 
              onSelect={this.setMultiSkills}
            />
          </Form.Group>  
          <Form.Group as={Col} controlId="workinghrstart" onChange={this.handleInputChange}>
            <Form.Label style={{fontSize: "14px"}}>Start hour</Form.Label>
            <Form.Control size="sm"  placeholder="Enter hour: 1 to 24" defaultValue={this.state.workinghrstart}/>
          </Form.Group>

          <Form.Group as={Col} controlId="workinghrend" onChange={this.handleInputChange}>
            <Form.Label style={{fontSize: "14px"}}>End hour</Form.Label>
            <Form.Control size="sm"  placeholder="Enter hour: 1 to 24" defaultValue={this.state.workinghrend}/>
          </Form.Group>
          
        </Form.Row>

        {/* <Form.Row>
          <Form.Group id="formGridCheckbox">
            <Form.Check type="radio" label="Regular employee" />
            <Form.Check type="radio" label="Temporary employee" />
            <Form.Check type="radio" label="Equipment" />
          </Form.Group>
        </Form.Row> */}

         <Form.Row>
          <Form.Group as={Col} controlId="address" onChange={this.handleInputChange}>
            <Form.Label style={{fontSize: "14px"}}>Address</Form.Label>
            <Form.Control size="sm" placeholder="Street address" defaultValue={this.state.address}/>
          </Form.Group>
          
          <Form.Group as={Col} controlId="city" onChange={this.handleInputChange}>
            <Form.Label style={{fontSize: "14px"}}>City</Form.Label>
            <Form.Control size="sm" placeholder="City" defaultValue={this.state.city} />
          </Form.Group>
          <Form.Group as={Col} controlId="state" onChange={this.handleInputChange}>
            <Form.Label style={{fontSize: "14px"}}>State</Form.Label>
            <Form.Control size="sm" placeholder="State" defaultValue={this.state.state} />
            
            {/* <Form.Control size="sm" as="select" defaultValue={this.state.state}>
              <option>Choose...</option>
              <option>...</option>
            </Form.Control> */}
          </Form.Group>
        </Form.Row>
        
        <Form.Row>
          <Form.Group as={Col} controlId="zip" onChange={this.handleInputChange}>
            <Form.Label style={{fontSize: "14px"}}>Zip</Form.Label>
            <Form.Control size="sm" placeholder="Zip" defaultValue={this.state.zip}/>
          </Form.Group>          
          <Form.Group as={Col} controlId="unavailabefrom">
            <Form.Label style={{fontSize: "14px"}}>Unavailabe from</Form.Label>            
            <DatePicker
                selected={this.state.unavailabefrom}
                onChange={date => this.setUnavailabefrom(date)}
                dateFormat="MMM d, yyyy"
                placeholderText="Enter a date"
                size="sm"
            />            
          </Form.Group>
          <Form.Group as={Col} controlId="unavailabeto">
            <Form.Label style={{fontSize: "14px"}}>Unavailabe to</Form.Label>
            <DatePicker 
                selected={this.state.unavailabeto}
                onChange={date => this.setUnavailabeto(date)}
                dateFormat="MMM d, yyyy"
                placeholderText="Select a date"
                size="sm"
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Button variant="outline-primary" type="submit">Save</Button>
          <Button variant="outline-primary ml-3" type="button"  onClick={this.handleCancel}>Cancel</Button>
          <Button variant="outline-primary ml-3" type="button"  onClick={this.handleDelete}>Delete</Button>
        </Form.Row>
      </Form>
      </div>
    </div>
    );
  }
}

export default ResourceDetail;