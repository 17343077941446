import React from "react";

class Analytics extends React.Component {
  render() {
    return (
        <div>
            Analytics
        </div>
      
    );
  }
}

export default Analytics;