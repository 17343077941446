import React from "react";
import { catalogService } from '../../service/catalogService';
import { inventoryService } from '../../service/inventoryService';
import { Card, Button, InputGroup, Form, Col, Modal } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { Redirect } from "react-router-dom";
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory from 'react-bootstrap-table2-editor';

var config = require('../../service/config');

class VendorCatalogLineItem extends React.Component {  
  constructor(props) {
    super(props);
    this.state = {
      id: undefined,  
      vendorid: undefined,
      itemid: undefined,
      vendorcode: undefined,
      description: undefined,
      unitprice: undefined,
      currency: undefined,
      countryoforigin: undefined,
      otherinfo: undefined,
      available: undefined,

      gobackto: undefined,
      searchmodal: false,
      invitems: [],
      invcolumns: [
        {
          dataField: 'id',
          text: 'id',
          hidden: true
        }, {
          dataField: 'item',
          text: 'Item',
          hidden: false,
          headerStyle: {
            backgroundColor: '#D9D8D9'
          },
          formatter: (cellContent, row) => {
            let config = JSON.parse(localStorage.getItem('config'));
            //console.log(config);
            let st = JSON.parse(config.serviceorderstatuses);
            return (
              <h6><small>
                {row.item &&
                <div className="label label-success">{row.item}</div>
                }
                {row.description &&
                <div className="label label-success">{row.description}</div>
                }                
              </small></h6>
            );            
          }
        }, {
          dataField: 'description',
          text: 'Description',
          hidden: true,
          headerStyle: {
            backgroundColor: '#D9D8D9'
          }
        }, {
          dataField: 'inventory',
          text: 'Quantity',
          hidden: true,
          headerStyle: {
            backgroundColor: '#D9D8D9'
          }
        },{
          dataField: 'discount',
          text: 'Discount',
          hidden: true,
          headerStyle: {
            backgroundColor: '#D9D8D9'
          }
        },{
          dataField: 'unitofmeasure',
          text: 'UoM',
          hidden: true,
          headerStyle: {
            backgroundColor: '#D9D8D9'
          }
        },{
          dataField: 'actualunitcost',
          text: 'Purchased Cost',
          hidden: true,
          headerStyle: {
            backgroundColor: '#D9D8D9'
          }
        }, {
          dataField: 'unitcost',
          text: 'Qty and Unit Price',
          hidden: false,
          headerStyle: {
            backgroundColor: '#D9D8D9'
          },
          formatter: (cellContent, row) => {
            let config = JSON.parse(localStorage.getItem('config'));
            //console.log(config);
            let st = JSON.parse(config.serviceorderstatuses);
            let qt = 0;
            if (row.inventory) {
              qt = row.inventory;
            }
            let up = 0;
            if (row.unitcost) {
              up = Number(row.unitcost).toFixed(2);
            }
            let vp = 0;
            if (row.actualunitcost) {
              vp = Number(row.actualunitcost).toFixed(2);
            }
            return (
              <h6><small>              
                {
                <div className="label label-success">Qty: {qt} </div>
                }
                {
                <div className="label label-success">Unit Price: {up}</div>
                }
                {
                <div className="label label-success">Purchase Price: {vp}</div>
                }
              </small></h6>
            );            
          }
        }],
        selectInvRow: {
          mode: 'radio',
          clickToSelect: true,
          hideSelectColumn: true,
          bgColor: '#adb5bd',
          onSelect: (row, isSelect, rowIndex, e) => {
            this.handleSelectedLineItem(row);
          }
        },
        selectedRow: undefined,
        curropt: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);   
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSelectItem = this.handleSelectItem.bind(this);
    this.handleSelectItemClose = this.handleSelectItemClose.bind(this);
    this.handleSelectedLineItem = this.handleSelectedLineItem.bind(this);
    this.searchItems = this.searchItems.bind(this);
  }

  componentDidMount() {  
    
    let usr = JSON.parse(localStorage.getItem('user'));
    if (usr.config.mou) {
      this.setState({mouOptions: usr.config.mou});
    }

    const { id } = this.props.match.params; //vendor id
    const { item } = this.props.match.params; //line item id
    
    console.log('item', item);

    this.state.id = item;
    this.state.vendorid = id;

    //console.log(this.state.id, this.state.orderId);
    if (this.state.id) {
      // get the sales order item from the database...
      catalogService.getCatalog(this.state.id).then(solitm => {
        if (solitm && solitm === 401) {
          this.props.history.push("/login");
        } else if (solitm && solitm.id) {
          console.log(solitm);
          this.setState({
            id: solitm.id,  
            vendorid: solitm.vendorid,
            itemid: solitm.itemid,
            vendorcode: solitm.vendorcode,
            description: solitm.description,
            unitprice: solitm.unitprice,
            currency: solitm.currency,
            countryoforigin: solitm.countryoforigin,
            otherinfo: solitm.otherinfo,
            available: solitm.available,
          });

          let t = [];
          for (let i=0; i<config.currencies.length; i++) {
            //console.log(this.state.categoryOption[i]);
            if (this.state.currency === config.currencies[i].value) {
              t.push(<option selected value={config.currencies[i].value}>{config.currencies[i].label}</option>);
            } else {
              t.push(<option value={config.currencies[i].value}>{config.currencies[i].label}</option>);
            }
          }
          this.setState({curropt : t});
        }
      });
    } else {
      let t = [];
      for (let i=0; i<config.currencies.length; i++) {
        //console.log(this.state.categoryOption[i]);
        if (usr.config.currency === config.currencies[i].value) {
          t.push(<option selected value={config.currencies[i].value}>{config.currencies[i].label}</option>);
          this.state.currency = config.currencies[i].value;
        } else {
          t.push(<option value={config.currencies[i].value}>{config.currencies[i].label}</option>);
        }
      }
      this.setState({curropt : t});
    }

  }

  handleInputChange(event) {
    const target = event.target;
    const name = target.id;
    this.setState({
      [name]: target.value
    });
  }
  
  handleSubmit(event) {
    event.preventDefault();
    let item = {};
    if (this.state.unitprice) {
      this.state.unitprice = Number(this.state.unitprice).toFixed(2);
    }
    
    item.id = this.state.id; 
    item.vendorid = this.state.vendorid;
    item.itemid = this.state.itemid;
    item.vendorcode = this.state.vendorcode;
    item.description = this.state.description;
    item.unitprice = this.state.unitprice;
    item.currency = this.state.currency;
    item.countryoforigin = this.state.countryoforigin;
    item.otherinfo = this.state.otherinfo;
    item.available = this.state.available;

    if (item.id) {
        //update
        catalogService.updateCatalog(item, this.state.id).then(solitm => {
          if (solitm && solitm === 401) {
            this.props.history.push("/login");
          } else {
            //goback...        
            //this.setState({gobackto: '/eisordercreate/' + this.state.orderId + '/serviceid/' + this.state.serviceid});        
            this.props.history.goBack();
          }
        });
    } else {
        //create - first check if it already exists
        if (item.vendorid && item.itemid) {
          catalogService.getItemForVendor(item.vendorid, item.itemid).then(itm => {
            if (itm && itm === 401) {
              this.props.history.push("/login");
            } else {
              if (itm && itm.length > 0) {
                alert('This item already exists in the catalog.');
              } else {
                catalogService.addCatalog(item).then(solitm => {
                  if (solitm && solitm === 401) {
                    this.props.history.push("/login");
                  } else {
                    //goback...        
                    //this.setState({gobackto: '/eisordercreate/' + this.state.orderId + '/serviceid/' + this.state.serviceid});        
                    this.props.history.goBack();
                  }
                });
              }
            }
          });
        } else {
          alert('Catalog item must be searched from Inventory. It seems you did not search it from Inventory.');
        }
    }
  }

  handleDelete(event) {
 
    catalogService.removeCatalog(this.state.id).then(solitm => {
      if (solitm && solitm === 401) {
        this.props.history.push("/login");
      } else {
        this.props.history.goBack();
        //goback...
        //this.setState({gobackto: '/eisordercreate/' + this.state.orderId + '/serviceid/' + this.state.serviceid});        
      }
    });
  }

  handleCancel(event) {
    this.props.history.goBack();
    //this.setState({gobackto: '/eisordercreate/' + this.state.orderId + '/serviceid/' + this.state.serviceid});        
  }

  searchItems = () => {
    //alert(this.state.item);
    //inventoryService
    inventoryService.searchItem(this.state.edititem).then(items => {
      if (items && items === 401) {
        this.props.history.push("/login");
      } else {        
        this.setState({
          invitems: items,
          searchmodal: !this.state.searchmodal});            
      }
    });    
    
  }

  handleSelectItemClose = () => {
    this.setState({searchmodal: !this.state.searchmodal});
  }

  handleSelectItem = () => {
    if (this.state.selectedRow && this.state.selectedRow.id) {
      //alert(this.state.selectedRow.unitCost);
      this.setState({
        item: this.state.selectedRow.item,
        vendorcode: this.state.selectedRow.item,
        itemid: this.state.selectedRow.id,         
        description: this.state.selectedRow.description,
        unitprice: (this.state.selectedRow.actualunitcost ? this.state.selectedRow.actualunitcost : 0) ,
        searchmodal: !this.state.searchmodal
      });
      //alert(this.state.selectedRow.item + ',' + this.state.edititem + ', ' + this.state.description);
    } else {
      this.setState({        
        searchmodal: !this.state.searchmodal
      });
    }    
  }

  handleSelectedLineItem = (row) => {
    //alert(JSON.stringify(row));
    this.setState({selectedRow: row});
    
    // this.setState({
    //   itemId: row.id,   
    //   item: row.item,
    //   description: row.description,
    //   qty: row.inventory,
    //   listPrice: row.actualUnitCost,
    //   discount: row.discount,
    //   unitPrice: Number(row.unitCost).toFixed(2)
    // });
  }

  render() {

    if (this.state.gobackto) {
      return <Redirect to={this.state.gobackto} />
    }

    let txOpt = [];
    txOpt.push(<option>Choose...</option>);
    if (this.state.taxOptions) {      
      for (let i=0; i<this.state.taxOptions.length; i++) {
        let obj = this.state.taxOptions[i];
        //console.log(obj);
        // txOpt.push(<option key={obj.name} value={obj.name}>{obj.name}</option>);
        if (this.state.tax01 && this.state.tax01 === obj.name) {
          txOpt.push(<option value={obj.name} selected>{obj.name}</option>);
        } else {
          txOpt.push(<option value={obj.name}>{obj.name}</option>);
        }
      }
    }
    
    return (
      <div className="content-screen">    
        <Card style={{ width: '36rem' }}>
          <Card.Header>
            
            <Form>
                  <div style={{width: "70%", display: "inline-block"}}>
                  <Form.Group as={Col} style={{marginBottom: '1px'}} controlId="edititem" onChange={this.handleInputChange}>
                      <InputGroup>
                      <Form.Control placeholder="Search item in inventory" defaultValue={this.state.edititem} />  
                      <InputGroup.Append>
                          <Button onClick={this.searchItems} variant="outline-primary">Search</Button>
                      </InputGroup.Append>
                      </InputGroup>
                  </Form.Group>
                  </div>
                  <div style={{float: 'right', display: "inline-block"}}>
                      <Button className="btn-sm" variant="link"   onClick={() => {if(window.confirm('Remove this line item?')){this.handleDelete()};}}>delete</Button>
                  </div>
                 
            </Form>
            
          </Card.Header>
          <Card.Body>      

              <Form onSubmit={this.handleSubmit}>
              <Form.Row></Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="item" onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Item #</Form.Label>
                      <Form.Control required="true" disabled placeholder="Search item code in your inventory" defaultValue={this.state.item} />                        
                    </Form.Group>
                   
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="vendorcode" onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Vendor Code</Form.Label>
                      <Form.Control  placeholder="Enter vendor code" defaultValue={this.state.vendorcode} />                        
                    </Form.Group>
                   
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="description" onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Description</Form.Label>
                      <Form.Control  placeholder="Enter item description" defaultValue={this.state.description}></Form.Control>            
                    </Form.Group>
                  </Form.Row>
                  
                  <Form.Row>
                    <Form.Group as={Col} controlId="unitprice" onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Vendor Unit Price</Form.Label>
                      <Form.Control  type="Number" placeholder="Enter unit price" defaultValue={this.state.unitprice}></Form.Control>            
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="currency" onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Currency</Form.Label>
                      <Form.Control as="select" defaultValue={this.state.currency}>
                            {this.state.curropt}                            
                      </Form.Control>            
                    </Form.Group>
                   </Form.Row>
                   <div style={{float: "left"}} >                     
                    {this.state.id && 
                      <Button type="submit" variant="outline-primary  mr-3" size="sm" >Update</Button>
                      }
                    {!this.state.id && 
                      <Button type="submit" variant="outline-primary  mr-3" size="sm">Save</Button>
                      } 
                    <Button onClick={this.handleCancel} variant="outline-primary  mr-3" size="sm" >Cancel</Button>  
                    </div>
              </Form>
              
          </Card.Body>
          
        </Card>

        <>
                <Modal show={this.state.searchmodal} onHide={this.handleSelectItemClose.bind(this)}
                  
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Header closeButton>              
                    <Modal.Title>Select an inventory item</Modal.Title>              
                  </Modal.Header>
                  <Modal.Body>
                        <ToolkitProvider
                        bootstrap4
                        keyField='id'
                        data={ this.state.invitems }
                        columns={ this.state.invcolumns }
                        search                        
                      >
                        {
                          props => (
                            <div> 
                                                       
                              <BootstrapTable style={{scrollY: "200px",scrollCollapse: true}}
                                bootstrap4
                                { ...props.baseProps }
                                { ...props.paginationFactory }
                                { ...props.striped }
                                { ...props.defaultEquipSorted }
                                selectRow={ this.state.selectInvRow }
                                //pagination={ paginationFactory( {sizePerPage: 6}) }                     
                                pagination={ paginationFactory({hideSizePerPage: true}) }                     
                                bordered = {false} 
                                condensed = {true}  
                                //defaultSorted = { this.state.defaultSorted } 
                                cellEdit={ cellEditFactory({ mode: 'click', blurToSave: true }) }
                              />
                            </div>
                          )
                        }
                      </ToolkitProvider>

                  </Modal.Body>
                  <Modal.Footer style={{justifyContent: 'left'}}>
                  <div style={{float: "left"}}>
                    <Button variant="outline-primary mr-3" onClick={this.handleSelectItem.bind(this)}>
                      Select
                    </Button>
                    <Button variant="outline-primary" onClick={this.handleSelectItemClose.bind(this)}>
                      Cancel
                    </Button>                         
                    </div>               
                  </Modal.Footer>
                </Modal>
                </>

      </div>
    );
  }
}

export default VendorCatalogLineItem;