import { authHeader } from './authHeader.js';
import {userService} from './userService.js'

var config = require('./config.js');

export const uploadService = {
    uploadDocument,
    uploadImage,

    getImages,
    getDocuments,

    deleteFile,
    uploadOrderPicture,
    deleteFileOrder,
    uploadReport,
    deleteReport,

};

function getImages(assetid) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + `/api/image/` + assetid, requestOptions).then(handleResponse);
};

function getDocuments(assetid) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + `/api/document/` + assetid, requestOptions).then(handleResponse);
};

function uploadImage(data, filename, assetid, clientid, mimetype) {
    //console.log(resource);
    let formData  = new FormData();
    if (mimetype) {
        formData.append('photo', new Blob([data], {type: mimetype}), filename);    
    } else {
        formData.append('photo', new Blob([data]), filename);
    }
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',
        body: formData   
    };
    delete requestOptions.headers['Content-Type']; //let browser set this - mime parts needs boundary too
    return fetch(config.app.url + '/api/image/' + assetid + '/client/' + clientid, requestOptions).then(handleResponse);
}

function uploadDocument(data, filename, assetid, clientid) {
    //console.log(resource);
    let formData  = new FormData();
    formData.append('photo', new Blob([data]), filename);
    //formData.append('filename', filename);
  
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',
        body: formData   
    };
    delete requestOptions.headers['Content-Type']; //let browser set this - mime parts needs boundary too
    return fetch(config.app.url + '/api/document/' + assetid + '/client/' + clientid, requestOptions).then(handleResponse);
}

function uploadReport(data, filename, assetid, clientid) {
    //console.log(resource);
    let formData  = new FormData();
    formData.append('photo', new Blob([data]), filename);
    //formData.append('filename', filename);
  
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',
        body: formData   
    };
    delete requestOptions.headers['Content-Type']; //let browser set this - mime parts needs boundary too
    return fetch(config.app.url + '/api/documentreport/' + assetid + '/client/' + clientid, requestOptions).then(handleResponse);
}

function deleteReport(fileName, assetid, clientid) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        credentials: 'include',
    };
    return fetch(config.app.url + '/api/reportdel/' + assetid + '/client/' + clientid + '/doc/' + fileName, requestOptions).then(handleResponse);
}

function deleteFile(fileName, assetid, clientid, type) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        credentials: 'include',
    };
    return fetch(config.app.url + '/api/filedel/' + assetid + '/client/' + clientid + '/type/' + type + '/doc/' + fileName, requestOptions).then(handleResponse);
}

function uploadOrderPicture(data, filename, orderid, clientid, mimetype) {
    //console.log(resource);
    let formData  = new FormData();
    if (mimetype) {
        formData.append('photo', new Blob([data], {type: mimetype}), filename);    
    } else {
        formData.append('photo', new Blob([data]), filename);
    }
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',
        body: formData   
    };
    delete requestOptions.headers['Content-Type']; //let browser set this - mime parts needs boundary too
    return fetch(config.app.url + '/api/imageorder/' + orderid + '/client/' + clientid, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    if (response.status === 401) {
        return 401;
    }
    return response.text().then(text => {
        //console.log(text);
        if (text && text !== 'null' && text !== 'undefined') {
            const data = text && JSON.parse(text);
            if (!response.ok) {
                if (response.status === 401) {
                    // auto logout if 401 response returned from api
                    userService.logout();
                    //location.reload(true);
                }

                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }
            //console.log(data);
            return data.data;
        }
        return null; 
    }
    );
}

function deleteFileOrder(fileName, orderid, clientid, type) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        credentials: 'include',
    };
    return fetch(config.app.url + '/api/fileorderdel/' + orderid + '/client/' + clientid + '/type/' + type + '/doc/' + fileName, requestOptions).then(handleResponse);
}

