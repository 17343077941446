import React from 'react';
import Camera , { FACING_MODES} from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import { uploadService } from '../../service/uploadService';
import { Button } from 'react-bootstrap';


class Picture extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cameraon: true,
      assetid: undefined,
      orderid: undefined,
      clientid: undefined,
      dataUrl: undefined,
      isFullscreen: false,
      isImageMirror: true,
      cameraface: FACING_MODES.USER,
    }
  }

  componentDidMount() {
    //console.log(this.props.match.path);
    const {id} = this.props.match.params;

    if (id) {
      if (this.props.match.path.startsWith('/pictureord/')) {
        this.state.orderid = id;
      } else {
        this.state.assetid = id;
      }      
    }
    const {client} = this.props.match.params;
    
    if (client) {
      this.state.clientid = client;
    }
  }

  handleTakePhotoAnimationDone (dataUri) {
    console.log('tookPhoto', dataUri);
    this.setState({dataUri: dataUri});
    let byteString = atob(dataUri.split(',')[1]);
    // separate out the mime component
    let mimeString = dataUri.split(',')[0].split(':')[1].split(';')[0];

    let ab = new ArrayBuffer(byteString.length);
    let ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    //console.log(mimeString, ia);
    if (this.state.assetid) {
      let fname = 'a' + this.state.assetid + '.jpeg';
      uploadService.uploadImage(ia, fname, this.state.assetid, this.state.clientid, mimeString).then(dataobjs => {
        if (dataobjs && dataobjs === 401) {
          this.props.history.push("/login");
        } else {    
            this.setState({cameraon: false});
            this.props.history.goBack();                    
        }
      });
    } else {
      let fname = 'O' + this.state.orderid + '.jpeg';
      uploadService.uploadOrderPicture(ia, fname, this.state.orderid, this.state.clientid, mimeString).then(dataobjs => {
        if (dataobjs && dataobjs === 401) {
          this.props.history.push("/login");
        } else {    
            this.setState({cameraon: false});
            this.props.history.goBack();                    
        }
      });
    }
  }

  changeCamera = () => {
    if (this.state.cameraface === FACING_MODES.USER) {
      this.setState({cameraface: FACING_MODES.ENVIRONMENT});
      this.setState({isImageMirror: false});
    } else {
      this.setState({cameraface: FACING_MODES.USER});
      this.setState({isImageMirror: true});
    }
  }

  render() {
    return (
      <div>
        { this.state.cameraon ? 
        <Camera onTakePhotoAnimationDone = {this.handleTakePhotoAnimationDone.bind(this)}              
              idealResolution = {{width: 640, height: 540}}
              idealFacingMode = {this.state.cameraface}
              imageType = "jpg"
              isImageMirror = {this.state.isImageMirror}
              isSilentMode = {true}
              isFullscreen={this.state.isFullscreen} 
              
              />  : null }      
        {/* <Button variant="outline-primary" size="sm" onClick={() => {this.goBack()}}>Cancel</Button>        */}
        <div style={{alignContent: 'center'}}>
          <Button variant="outline-primary" size="sm" onClick={() => {this.changeCamera()}} >Change Camera</Button>
        </div>
      </div>      
    );
  }
}

export default Picture;