import React from "react";
import { Card, CardDeck, Button, Form, Col } from 'react-bootstrap';
import { scheduleService } from '../../service/scheduleService';
import "react-datepicker/dist/react-datepicker.css";

// This is use for team member schedule close
class ScheduleClose extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scheduleid: undefined,
      /// this is for closing activity      
      activitydetail: undefined,

    }    
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.state.scheduleid = id;
  }

  
  handleInputChange = (event) => {    
    const target = event.target;
    const name = target.id;    
    this.setState({
      [name]: target.value
    });    
  }
  
  handleSubmit = (event) => {
    event.preventDefault();
    if (!this.state.scheduleid) {
      alert('Something is wrong...');
    } else {
          let obj = {};
          obj.note = this.state.activitydetail;
          scheduleService.closeScheduleNonLeader(this.state.scheduleid, obj).then(ord => {
            if (ord && ord === 401) {
              this.props.history.push("/login");
            } else {
                this.props.history.goBack();
            }
          })
      }        
  }

  setStartDate = (date) => {
    this.setState({newstartdate: date});
  }
  
  cancel = () => {
    this.props.history.goBack();
  }

  render() {
    
    return (
      <div className="content-screen">
      <CardDeck>
          <Card>
            {
            <Card.Header>Close the Schedule: {this.state.scheduleid}</Card.Header>
            }
          <Card.Body>                
            
            <div>
                <Form onSubmit={this.handleSubmit}>
                  
                  <Form.Row>
                  
                    <Form.Group as={Col} controlId="activitydetail" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Closing note</Form.Label>
                      <Form.Control as="textarea" required="true" placeholder="Enter the closing note" defaultValue={this.state.activitydetail} />
                    </Form.Group>
                  
                  </Form.Row> 
                  <Form.Row>
                    <Card.Text>&nbsp;</Card.Text>
                    <Card.Text>&nbsp;</Card.Text>
                  </Form.Row>                  
                  <Form.Row>
                    <Card.Text>&nbsp;</Card.Text>
                    <Card.Text>&nbsp;</Card.Text>
                  </Form.Row>
                  <Form.Row>
                    <div style={{float: "left"}} >                                       
                      <Button variant="secondary" onClick={this.cancel} type="button">Cancel</Button>                              
                      <Button variant="primary ml-3" type="submit">Save</Button>                              
                    </div>                                        
                  </Form.Row>
                  <Form.Row>
                    <Card.Text>&nbsp;</Card.Text>
                    <Card.Text>&nbsp;</Card.Text>
                  </Form.Row>
                  <Form.Row>
                    <Card.Text>&nbsp;</Card.Text>
                    <Card.Text>&nbsp;</Card.Text>
                  </Form.Row>
                  <Form.Row>
                    <Card.Text>&nbsp;</Card.Text>
                    <Card.Text>&nbsp;</Card.Text>
                  </Form.Row>
              </Form>
            </div>
          </Card.Body>       
          <Card.Footer>&nbsp;</Card.Footer>   
        </Card>        
      </CardDeck>
      </div>
    );
  }
}


export default ScheduleClose;