import React from "react";
import { Card, CardDeck, Modal, Button, InputGroup, FormControl, Form, Col } from 'react-bootstrap';
import { servicerequestService } from '../../service/servicerequestService';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

class ServiceCloseAndReschedule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: undefined,
      customerid: undefined,
      customername: undefined,
      city: undefined,
      detail: undefined,
      siteaddrid: undefined,
      billingaddrid: undefined,
      problemtype: undefined,
      type: undefined,
      priority: undefined,
      status: undefined,
      requesteddate: undefined,
      startdate: undefined,
      enddate: undefined,
      orderclosedate: undefined,
      otherinfo: undefined,
      assetid: undefined,
      assetdescription: undefined,
      assetmodel: undefined,
      assetserial: undefined,
      assetmanufacturer: undefined,
      assetnumber: undefined,
      skills: undefined,
      instruction: undefined,
      attachdocs: undefined,
      schduletime: undefined,
      leaderassigned: undefined,
      rootserviceid: undefined,
      immediaterescheduledfromid: undefined,
      newstartdate: undefined,
      newenddate: undefined,
      /// this is for closing activity      
      activitydetail: undefined,

    }    
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    servicerequestService.getServiceRequest(id)
    .then(order => {
      if (order && order === 401) {
        this.props.history.push("/login");
      } else {
        
          this.setState({ 
            id: order.id,
            customerid: order.customerid,
            customername: order.customername,
            city: order.city,
            detail: order.detail,
            siteaddrid: order.siteaddrid,
            billingaddrid: order.billingaddrid,
            problemtype: order.problemtype,
            type: order.type,
            priority: order.priority,
            status: order.status,
            requesteddate: order.requesteddate,
            startdate: order.startdate,
            enddate: order.enddate,
            orderclosedate: order.orderclosedate,
            otherinfo: order.otherinfo,
            assetid: order.assetid,
            assetdescription: order.assetdescription,
            assetmodel: order.assetmodel,
            assetserial: order.assetserial,
            assetmanufacturer: order.assetmanufacturer,
            assetnumber: order.assetnumber,
            skills: order.skills,            
            instruction: order.instruction,
            attachdocs: order.attachdocs,
            createdAt: order.createdAt,
            rootserviceid: order.rootserviceid,
            immediaterescheduledfromid: order.immediaterescheduledfromid,
          })
      }
    });
  }

  
  handleInputChange = (event) => {    
    const target = event.target;
    const name = target.id;    
    this.setState({
      [name]: target.value
    });    
  }

  
  handleSubmit = (event) => {
    event.preventDefault();
    if (!this.state.id) {
      alert('Something is wrong...');
    } else {
          // close the current order
          let oldorder = {};
          oldorder.id = this.state.id;
          oldorder.status = 99;
          servicerequestService.updateServiceRequest(oldorder, this.state.id).then(ord => {
            if (ord && ord === 401) {
              this.props.history.push("/login");
            } else {
              // add the closing activity...
              let user = JSON.parse(localStorage.getItem('user'));
              let activity = {
                "serviceid": this.state.id,
                "type": 4,
                "heading": "Closed",
                "detail" : this.state.activitydetail,
                "closeddate": new Date(),
                "userwholastupdated": user.username
              }
              servicerequestService.conditionalAddClosedActivity(activity, this.state.status).then(act => {
                if (act && act === 401) {
                  this.props.history.push("/login");
                } else if (this.state.newstartdate) {
                    // duplicate the order for rescheduling...
                    let rootid = this.state.id;
                    if (this.state.rootserviceid) {
                      rootid = this.state.rootserviceid;
                    } else if (this.state.immediaterescheduledfromid) {
                      rootid = this.state.immediaterescheduledfromid;
                    }

                    let order = {};
                    order.rootserviceid = rootid;
                    order.immediaterescheduledfromid = this.state.id;
                    order.customerid = this.state.customerid;
                    order.customername = this.state.customername;
                    order.city = this.state.city;
                    order.detail = this.state.detail;
                    order.siteaddrid = this.state.siteaddrid;
                    order.billingaddrid = this.state.billingaddrid;
                    order.problemtype = this.state.problemtype;
                    order.type = this.state.type;
                    order.priority = this.state.priority;
                    order.status = 1;
                    order.requesteddate = this.state.requesteddate;
                    order.startdate = this.state.newstartdate ;
                    order.enddate = this.state.newstartdate;
                    order.orderclosedate = this.state.orderclosedate;
                    order.otherinfo = this.state.otherinfo;
                    order.assetid = this.state.assetid;
                    order.assetdescription = this.state.assetdescription;
                    order.assetmodel = this.state.assetmodel;
                    order.assetserial = this.state.assetserial;
                    order.assetmanufacturer = this.state.assetmanufacturer;
                    order.assetnumber = this.state.assetnumber;
                    order.skills = this.state.skills;
                    order.instruction = this.state.instruction;
                    order.attachdocs = this.state.attachdocs;
                    //order.reportedby = this.state.detail;
                    //console.log(order);
                    servicerequestService.addServiceRequest(order).then(order => {
                      if (order && order === 401) {
                        this.props.history.push("/login");
                      } else {
                        // var { from } = this.props.location.state || { from: { pathname: "/servicedetail/" + order.id} };  
                        // this.props.history.push(from);
                        this.props.history.goBack();
                      }
                    })
                } else {
                  this.props.history.goBack();
                }
              });
            }
          })
      }        
  }

  setStartDate = (date) => {
    this.setState({newstartdate: date});
  }
  
  cancel = () => {
    this.props.history.goBack();
  }

  render() {
    
    return (
      <div className="content-screen">
      <CardDeck>
          <Card>
            {this.state.status != 99 &&
            <Card.Header>Close and Reschedule Order: {this.state.id}</Card.Header>
            }
            {this.state.status === 99 &&
            <Card.Header>Reschedule Order: {this.state.id}</Card.Header>
            }
          <Card.Body>                
            
            <div>
                <Form onSubmit={this.handleSubmit}>
                  
                  <Form.Row>
                  {this.state.status != 99 &&
                    <Form.Group as={Col} controlId="activitydetail" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Closing note</Form.Label>
                      <Form.Control as="textarea" required="true" placeholder="Enter the closing note" defaultValue={this.state.activitydetail} />
                    </Form.Group>
                  }
                  </Form.Row> 
                  <Form.Row>
                    <Card.Text>&nbsp;</Card.Text>
                    <Card.Text>&nbsp;</Card.Text>
                  </Form.Row>
                  <Form.Row>
                    <Card.Text>&nbsp;</Card.Text>
                    <Card.Text>Reschedule? If so the set the start date below</Card.Text>
                    <Card.Text>&nbsp;</Card.Text>
                  </Form.Row> 
                  <Form.Row>
                    <Form.Group as={Col} controlId="newstartdate">
                      <Form.Label  style={{fontSize: "14px"}}>Start date</Form.Label>  
                      <div>
                      <DatePicker style={{displayValue: 'block'}}
                          selected={this.state.newstartdate}
                          onChange={date => this.setStartDate(date)}
                          dateFormat="MMM d, yyyy HH:mm"
                          placeholderText="Enter a date"                          
                      /> </div>                   
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Card.Text>&nbsp;</Card.Text>
                    <Card.Text>&nbsp;</Card.Text>
                  </Form.Row>
                  <Form.Row>
                    <div style={{float: "left"}} >                                       
                      <Button variant="secondary" onClick={this.cancel} type="button">Cancel</Button>                              
                      <Button variant="primary ml-3" type="submit">Save</Button>                              
                    </div>                                        
                  </Form.Row>
                  <Form.Row>
                    <Card.Text>&nbsp;</Card.Text>
                    <Card.Text>&nbsp;</Card.Text>
                  </Form.Row>
                  <Form.Row>
                    <Card.Text>&nbsp;</Card.Text>
                    <Card.Text>&nbsp;</Card.Text>
                  </Form.Row>
                  <Form.Row>
                    <Card.Text>&nbsp;</Card.Text>
                    <Card.Text>&nbsp;</Card.Text>
                  </Form.Row>
              </Form>
            </div>
          </Card.Body>       
          <Card.Footer>&nbsp;</Card.Footer>   
        </Card>        
      </CardDeck>
      </div>
    );
  }
}


export default ServiceCloseAndReschedule;