import { authHeader } from './authHeader.js';

var config = require('./config.js');

export const configService = {
    getConfig,
    updateConfig
};

function getConfig() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',
    };
    let user;
    //console.log('before fetching...');
    return fetch(config.app.url + `/api/config`, requestOptions)
        .then((response)=> { 
            //console.log(response);
            //console.log(response.body);
            //console.log(response.headers["set-cookie"][0]);
            //console.log(response.headers['x-access-token']);
            
            
            return response.json() 
        })
        .then((json)=> 
        { 
            //console.log(json);
            let token =  json.data.token;
            let config = json.data[0];    
            //console.log(config);
            localStorage.setItem('config', JSON.stringify(config));            
            return user; 
        })                
}

function updateConfig(cfg) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(cfg) 
    };
    
    //console.log('before fetching...');
    return fetch(config.app.url + `/api/config/` + cfg.id, requestOptions)
        .then((response)=> { 
            return response.json() 
        })
        .then((json)=> 
        { 
            //console.log(json);
            let token =  json.data.token;
            let config = json.data[0];    
            //console.log(config);
            localStorage.setItem('config', JSON.stringify(config));            
            return json; 
        })       
}

