import { authHeader } from './authHeader.js';
import {userService} from './userService.js'

var config = require('./config.js');

export const assetService = {
    getAssetList,
    addNewAsset,
    updateAsset,
    delinlAssetToOrder,
    getAssetForOrder,
    linkAssetToOrder,
    getAssetLocationList,
    getAsset,
    getAssetLocation,
    addAssetLocation,
    updateAssetLocation,
    removeAssetLocation,
    lookupSerialNo,
    softDeleteAsset,
    getServiceReports,
    getAssets,
};

function getAssets() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + `/api/allassets`,  requestOptions).then(handleResponse);
}

function getServiceReports(assetid) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + `/api/serviceorderreports/` + assetid, requestOptions).then(handleResponse);
}

function softDeleteAsset(assetid) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + `/api/asset/` + assetid, requestOptions).then(handleResponse);
};

function lookupSerialNo(serialno) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + `/api/assetserialno/` + serialno, requestOptions).then(handleResponse);
};

function getAsset(assetid) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + `/api/assetbyid/` + assetid, requestOptions).then(handleResponse);
};

function linkAssetToOrder(assetid, serviceid) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + `/api/assetlinkservice/` + assetid + '/service/' + serviceid, requestOptions).then(handleResponse);
};

function getAssetForOrder(serviceid) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + `/api/assetforservice/` + serviceid, requestOptions).then(handleResponse);
};

function getAssetList(clientid) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + `/api/asset/` + clientid, requestOptions).then(handleResponse);
}

function getAssetLocationList(assetid) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + `/api/assetlocations/` + assetid, requestOptions).then(handleResponse);
}

function getAssetLocation(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + `/api/assetlocation/` + id, requestOptions).then(handleResponse);
}

function addNewAsset(resource, serviceid) {
    //console.log(resource);
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(resource)   
    };
    if (serviceid) {
        return fetch(config.app.url + '/api/asset/' + serviceid, requestOptions).then(handleResponse);
    } else {
        return fetch(config.app.url + '/api/asset', requestOptions).then(handleResponse);
    }
}

function updateAsset(asset, id, serviceid) {
    //console.log(asset);
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(asset)     
    };
    if (serviceid) {
        return fetch(config.app.url + '/api/asset/' + id + '/service/' + serviceid, requestOptions).then(handleResponse);    
    } else {
        return fetch(config.app.url + '/api/asset/' + id, requestOptions).then(handleResponse);
    }    
}

function delinlAssetToOrder(id, serviceid) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        credentials: 'include',
    };
    return fetch(config.app.url + '/api/asset/' + id + '/service/' + serviceid, requestOptions).then(handleResponse);
}

function addAssetLocation(location) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(location)   
    };
    return fetch(config.app.url + '/api/assetlocation', requestOptions).then(handleResponse);
}

function updateAssetLocation(location, id) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(location)     
    };
    return fetch(config.app.url + '/api/assetlocation/' + id , requestOptions).then(handleResponse);
}

function removeAssetLocation(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        credentials: 'include',
    };
    return fetch(config.app.url + '/api/assetlocation/' + id, requestOptions).then(handleResponse);
}


function handleResponse(response) {
    if (response.status === 401) {
        return 401;
    }
    return response.text().then(text => {
        //console.log(text);
        if (text && text !== 'null' && text !== 'undefined') {
            const data = text && JSON.parse(text);
            if (!response.ok) {
                if (response.status === 401) {
                    // auto logout if 401 response returned from api
                    userService.logout();
                    //location.reload(true);
                }

                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }
            //console.log(data);
            return data.data;
        }
        return null; 
    }
    );
}