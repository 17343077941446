import { authHeader } from './authHeader.js';
import {userService} from './userService.js'

var config = require('./config.js');

export const catalogService = {
    getCatalogs,
    getCatalog,
    addCatalog,
    updateCatalog,
    removeCatalog,    
    getCatalogsForItem,
    getItemForVendor,
    getCatalogItemList,
};

function getCatalogItemList() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + `/api/catalogitems`, requestOptions).then(handleResponse);
}

function getItemForVendor(vendorid, itemid) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + `/api/catalogvendoritem/` + vendorid + '/item/' + itemid, requestOptions).then(handleResponse);
};

function getCatalogsForItem(itemid) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + `/api/catalogsforitem/` + itemid, requestOptions).then(handleResponse);
};

function getCatalogs(vendorid) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + `/api/catalogs/` + vendorid, requestOptions).then(handleResponse);
};

function getCatalog(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + `/api/catalog/` + id, requestOptions).then(handleResponse);
};

function addCatalog(cat) {
    //console.log(resource);
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(cat)   
    };

    return fetch(config.app.url + '/api/catalog', requestOptions).then(handleResponse);
}

function updateCatalog(cat, id) {
    //console.log(asset);
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(cat)     
    };

    return fetch(config.app.url + '/api/catalog/' + id, requestOptions).then(handleResponse);    

}

function removeCatalog(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        credentials: 'include',
    };
    return fetch(config.app.url + '/api/catalog/' + id, requestOptions).then(handleResponse);
}


function handleResponse(response) {
    if (response.status === 401) {
        return 401;
    }
    return response.text().then(text => {
        //console.log(text);
        if (text && text !== 'null' && text !== 'undefined') {
            const data = text && JSON.parse(text);
            if (!response.ok) {
                if (response.status === 401) {
                    // auto logout if 401 response returned from api
                    userService.logout();
                    //location.reload(true);
                }

                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }
            //console.log(data);
            return data.data;
        }
        return null; 
    }
    );
}

