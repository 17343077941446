import React, { forwardRef } from "react";
import { Button} from 'react-bootstrap';
import { catalogService } from '../../service/catalogService';
import { customerService } from '../../service/customerService';
import { materialService } from '../../service/materialService';

import MaterialTable from "material-table";

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

class VendorLinkMaterial extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        vcatalog: true,
        materialid: undefined,
        itemid: undefined,
        vendors: [],
        
        columns: [
        {
          field: 'id',
          title: 'Vendor Id',          
          hidden: true,
        },
        {
          field: 'name',
          title: 'Vendor',          
        },
        {
          field: 'description',
          title: 'Description',
          render: rowData => {            
            return (
            <>  
            {rowData.vendorcode &&
            <div> {rowData.vendorcode}</div>
            }
            <div> {rowData.description ? rowData.description: ''}</div>
            </>
            );
          }  
        },
        {
          field: 'vendorcode',
          title: 'Vendor Code',
          hidden: true,
        }
        ,
        {
          field: 'unitprice',
          title: 'Unit Price',
          render: rowData => {            
            return (
            <>  
            <span> {rowData.unitprice ? Number(rowData.unitprice).toFixed(2) + ' (' + rowData.currency + ')': ''}</span>            
            </>
            );
          } 
        },
        {
          field: 'currency',
          title: 'Currency',
          hidden: true,
        },
               
      ],
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params; //material id
    const { item } = this.props.match.params; //material itemid

    if (!id || !item) {
      return;
    }

    this.state.materialid = id;
    this.state.itemid = item;
    
    if (this.state.vcatalog) {
      // get vendors from catolog     
      this.getVendorsFromCatalog();
    } else {
      // get all the vendors
      this.getVendorsFromVendorList();       
    }      
  }

  getVendorsFromCatalog() {
    this.state.vcatalog = true;
    catalogService.getCatalogsForItem(this.state.itemid).then(ven => {
      if (ven && ven === 401) {
        this.props.history.push("/login");
      } else {        
        this.setState({ vendors: ven })
      }
    })
  }

  getVendorsFromVendorList() {
    this.state.vcatalog = false;
    customerService.getVendorList().then(ven => {
      if (ven && ven === 401) {
        this.props.history.push("/login");
      } else {        
        this.setState({ vendors: ven })
      }
    }); 
  }

  handleAddNew = () => {
    this.props.history.push('/assetedit/' + this.state.clientid, '', this.state) ;               
  }

  vendorSelect = (row) => {
    let mat = {};
    let vendorid = row.vendorid;
    if (this.state.vcatalog === false) {
      vendorid = row.id;
      mat.catalogid = null;
    } else {
      mat.catalogid = row.id;
    }
    
    mat.id = this.state.materialid;
    mat.vendorid = vendorid;
    console.log('mat', mat);
    materialService.updateMaterial(mat, this.state.materialid).then(ven => {
      if (ven && ven === 401) {
        this.props.history.push("/login");
      } else {        
        this.props.history.goBack();
      }
    });

  }
  
  render() {
    let ttl = <div><span style={{fontWeight: 'bold', fontSize: '15px'}}>Vendors listed from Catalog who supplies the item</span><Button variant="link ml-4" onClick={() => {this.getVendorsFromVendorList()}} >Get Vendors from Vendor List</Button></div>
    let title = 'Vendors listed from Catalog';
    if (this.state.vcatalog === false) {      
      ttl = <div><span style={{fontWeight: 'bold', fontSize: '15px'}}>All vendors listed from Vendor List</span><Button variant="link ml-4" onClick={() => {this.getVendorsFromCatalog()}} >Get Vendors from Catalog</Button></div>
    }
    return (   

      <div className="content-screen">        
      <div style={{ width: '73rem' }}>
        {ttl}
        <MaterialTable
          icons={tableIcons}
          title=""
          columns={this.state.columns}
          data={this.state.vendors}
          //title="Demo Title"
          options={{
            search: true,
            searchFieldAlignment: 'left',
            sorting: true,
            //exportButton: true,
            //selection: true,
            pageSize: 6,
            headerStyle: {
              backgroundColor: '#edeff1',
              color: '#000',
              borderColor: '#9da6af',
            },              
                         
          }}           
          onRowClick={((evt, selectedRow) => {
            this.vendorSelect(selectedRow);
          })}
        />
      </div>
      <div>&nbsp;</div>
    </div> 
    );
  }
}

export default VendorLinkMaterial;