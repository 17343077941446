import React from "react";
import { Card, CardDeck, Modal, Button, InputGroup, FormControl, Form, Col } from 'react-bootstrap';

import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import { customerService } from '../../service/customerService';
import { assetService } from '../../service/assetService';
import { servicerequestService } from '../../service/servicerequestService';
import { providerService } from '../../service/providerService';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Multiselect } from "multiselect-react-dropdown";
import SelectSearch from 'react-select-search';
import moment from 'moment';

class ServiceCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        assettoserviceordercreate: false,
        modal: false,
        search: '',
        customername: '',
        customerid: undefined,
        city: undefined,
        dataobjs: [],
        addrobjs: [],
        columns: [{
          dataField: 'id',
          text: 'id',
          hidden: true
        }, {
          dataField: 'name',
          text: 'Name',
          sort: true,
          headerStyle: {
            backgroundColor: '#D9D8D9'
          }
        }
      ],
      selectRow: {
        mode: 'radio',
        clickToSelect: true,
        hideSelectColumn: true,
        bgColor: '#adb5bd',
        onSelect: (row, isSelect, rowIndex, e) => {    
          //alert(row.name);        
          this.setCustomer(row)
          //this.props.history.push('/skilldetail/' + row.skill);
        }
      },
      defaultSorted: [{
          dataField: 'name',
          order: 'asc'
      }],
      addrcolumns: [{
          dataField: 'id',
          text: 'id',
          hidden: true
        }, {
          dataField: 'addr1',
          text: 'Address',
          editable: false
        },  {
          dataField: 'contact',
          text: 'Contact',
          editor: {
            type: Type.CHECKBOX,
            value: 'Yes:No'
          }
        }, {
          dataField: 'site',
          text: 'Site',            
          editor: {
            type: Type.CHECKBOX,
            value: 'Yes:No',
          },
          afterSaveCell: (oldValue, newValue, row, column) => {
            //console.log('afterSaveCell.................');
            return true;
          }

        },          
      ],
      //billingaddrid: '',
      contactaddr: '',
      //siteaddrid: '',
      siteaddr: '',

      detail: undefined,
      siteaddrid: undefined,
      billingaddrid: undefined,
      problemtype: undefined,
      type: undefined,
      priority: undefined,
      status: 1,
      requesteddate: new Date(),
      startdate: new Date(),
      enddate: new Date(),
      orderclosedate: undefined,
      otherinfo: undefined,
      assetid: undefined,
      assetdescription: undefined,
      assetmodel: undefined,
      assetserial: undefined,
      assetmanufacturer: undefined,
      assetnumber: undefined,
      skills: undefined,
      instruction: undefined,
      attachdocs: undefined,
      reportedby: undefined,
      providerid: undefined,
      skillOption: [],
      categoryOption: [],
      typeOption: [],
      priorityOption: {},

      ////   
      addrid: undefined,
      addrfname: undefined, 
      addrlname: undefined, 
      addremail: undefined, 
      addrphone: undefined, 
      addraddr1: undefined, 
      addraddr2: undefined, 
      addrcity: undefined, 
      addrstate: undefined, 
      addrzip: undefined, 
      addrisacontact: false,

      addrsavemodal: false,

      ///
      managemyasset: false,
      serviceproviders: [],
      providername: '',
      ///
    }    
  }

  componentDidMount() {
    let config = JSON.parse(localStorage.getItem('config'));

    ////
    let cc = undefined;
    if (config.other) {
      let tmp = JSON.parse(config.other);
      if (tmp.managemyasset) {
        cc = tmp.managemyasset;
      }
    }
    ////

    let peopleskills = JSON.parse(config.peopleskills);
    let options = [];
    for (let i=0; i<peopleskills.length; i++) {
      let s = {};
      s.name=   peopleskills[i].skill;  
      s.id = peopleskills[i].skill;    
      options.push(s);     
    }
    //console.log(config);
    //console.log(JSON.parse(config.serviceordertypes));
    //console.log(JSON.parse(config.serviceorderpriotities));
    this.setState({skillOption: options, 
                    categoryOption: JSON.parse(config.serviceorderproblemtypes),
                    typeOption: JSON.parse(config.serviceordertypes),
                    priorityOption: JSON.parse(config.serviceorderpriotities),
                    managemyasset: cc,
    });

    // if we already know the customer and asset then set the customer and address

    const {id} = this.props.match.params;
    if (id) {
      this.state.assettoserviceordercreate = true;
      this.state.clientid = id;

      const {assetid} = this.props.match.params;
      this.state.assetid = assetid;

      customerService.getCustomer(this.state.clientid).then(cust => {
        //console.log(cust);
        if (cust) {
          let oo = {};
          oo.value = cust.id;
          oo.name = cust.name;
          let objs = [];
          objs.push(oo);
          this.state.dataobjs = objs;
          this.state.customername = cust.name;
          this.setCustomer({id: cust.id, name: cust.name});

          if (this.state.assetid) {
            assetService.getAsset(this.state.assetid).then(asst => {
              if (asst) {
                this.setEquipment(asst);
              }
            });
          }
        }
        this.getServiceProviders();
      });
    } else {
      customerService.getCustomerList().then(custs => {
        if (custs && custs === 401) {
          this.props.history.push("/login");
        } else {
          //console.log('get customer list')
          //console.log(custs)
          let tt = [];
          for (let i=0; i<custs.length; i++) {
            let oo = {};
            oo.value = custs[i].id;
            oo.name = custs[i].name;
            tt.push(oo);
          }
          this.setState({ dataobjs: tt })   
          this.getServiceProviders();       
        }
      });
    }
  }

  selectContactAddress  = (e) => {
    //alert(e.value + ' : ' + e.name);    
    this.setState({contactaddr: e.name, billingaddrid: e.value});
    let city = e.name.substring(e.name.indexOf(',') + 1);
    //console.log(city);
    city = city.substring(0, city.indexOf(','));
    //console.log(city);
    this.state.city = city;    
  }

  selectSiteAddress  = (e) => {
    //alert(e.value + ' : ' + e.name);
    this.setState({siteaddr: e.name, siteaddrid: e.value});
  }

  selectCustomer = (e) => {
      //console.log(e);
      //alert(e.value + ' : ' + e.name);
      let c = {};
      c.id = e.value;
      c.name = e.name;
      this.setCustomer(c);
  }

  selectServiceProvider = (e) => {
    this.state.providerid = e.value;
}

  setCustomer = (c) =>
  {
    //console.log('Hello: ' + c.name);
    this.setState({customername: c.name, customerid: c.id});
    customerService.getAddressList(c.id).then(addrs => {
      if (addrs && addrs === 401) {
        this.props.history.push("/login");
      } else {
        //console.log(addrs);
        let tmp = [];
        let isContactSet = false;
        for (let i=0; i<addrs.length; i++) {
          let o = {};
          let ad = {};
          o.contact = 'No';
          o.site = 'No';
          o.id = addrs[i].id;
          o.addr1 = addrs[i].addr1 + ", " + (addrs[i].city ? addrs[i].city + ', ': '') + 
                    (addrs[i].state ? addrs[i].state + ' ': '') +
                    (addrs[i].zip ? addrs[i].zip: '');
          
          if (addrs[i].is_a_contact === true && !isContactSet) {
            o.contact = 'Yes';
            o.site = 'Yes';
            isContactSet = true;
          }
          ad.name = addrs[i].addr1 + ", " + (addrs[i].city ? addrs[i].city + ', ': '') + 
                (addrs[i].state ? addrs[i].state + ' ': '') +
                (addrs[i].zip ? addrs[i].zip: '');          
          ad.value =  addrs[i].id;     
          
          tmp.push(ad);
          // if user is creating service order for an asset then he already knows the client and addresses
          if (this.state.assettoserviceordercreate) {
              if (addrs[i].is_a_contact === true) {
                this.selectContactAddress(ad);
                this.selectSiteAddress(ad);
              }
          }
        }
        this.setState({ addrobjs: tmp });
        //console.log(this.state.addrobjs);
        this.toggle();
      }
    });    
  }

  getServiceProviders = () => {
    
    providerService.getProviders().then(custs => {
      if (custs && custs === 401) {
        this.props.history.push("/login");
      } else {
        let tt = [];
        for (let i=0; i<custs.length; i++) {
          let oo = {};
          oo.value = custs[i].id;
          oo.name = custs[i].name;
          tt.push(oo);
        }
        this.setState({ serviceproviders: tt })          
      }
    });

  } 

  setEquipment = (c) => {
    this.setState({
      assetid: c.id,
      assetdescription: c.description,
      assetmodel: c.model,
      assetserial: c.serial_no,
      assetmanufacturer: c.manufacturer,
      assetnumber: c.assetnumber,
    });
  }

  searchSubmit = (event) =>
  {  
    //alert(this.state.search);
  }

  handleInputChange = (event) => {    
    const target = event.target;
    const name = target.id;    
    this.setState({
      [name]: target.value
    });    
  }

  setStartDate = (date) => {
    this.setState({startdate: date});
  }

  setEndDate = (date) => {
    this.setState({enddate: date});
  }

  setRequestedDate = (date) => {
    this.setState({requesteddate: date});
  }

  setMultiSkills = (option) => {
    //console.log(option);      
    let t = undefined;    
    this.state.skills = option;
    //console.log(this.state.skills);
  }

  getPriorityValue(name) {
    if (name) {
      let config = JSON.parse(localStorage.getItem('config'));
      let priority = JSON.parse(config.serviceorderpriotities);
      let keys = Object.keys(priority);
      for (let i=0; i<keys.length; i++) {
        if (priority[keys[i]] === name) {
          return keys[i];
        }
      }
    }
  }

  getUtcDate(d) {
    if (d) {
      /*
      console.log(d);
      let dd =  moment(d).getUtcDate();
      console.log(dd);
      return dd;*/
      return d;
    }
    return undefined;
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (!this.state.customerid) {
      alert('Select a Customer before saving this service request');
    } else if (!this.state.contactaddr) {
      alert('Select a billing contact address before saving this service request');
    } else {

      // save the service request and go back to the previous page
      let order = {};
      order.customerid = this.state.customerid;
      order.customername = this.state.customername;
      order.city = this.state.city;
      order.detail = this.state.detail;
      order.siteaddrid = this.state.siteaddrid;
      order.billingaddrid = this.state.billingaddrid;
      order.problemtype = this.state.problemtype;
      order.type = this.state.type;
      order.priority = this.getPriorityValue(this.state.priority);
      order.status = this.state.status;
      order.requesteddate = this.state.requesteddate;
      order.startdate = this.getUtcDate(this.state.startdate);
      order.enddate = this.state.enddate;
      order.orderclosedate = this.state.orderclosedate;
      order.otherinfo = this.state.otherinfo;
      order.assetid = this.state.assetid;
      order.assetdescription = this.state.assetdescription;
      order.assetmodel = this.state.assetmodel;
      order.assetserial = this.state.assetserial;
      order.assetmanufacturer = this.state.assetmanufacturer;
      order.assetnumber = this.state.assetnumber;
      order.skills = (this.state.skills ? JSON.stringify(this.state.skills) : undefined);
      order.instruction = this.state.instruction;
      order.attachdocs = this.state.attachdocs;
      order.providerid = this.state.providerid;
      //order.reportedby = this.state.detail;
      //console.log(order);
      servicerequestService.addServiceRequest(order, this.state.assetid).then(order => {
        if (order && order === 401) {
          this.props.history.push("/login");
        } else {
          var { from } = this.props.location.state || { from: { pathname: ("/servicedetail/" + order.id)} };
          this.props.history.push(from);
        }
      })
    }
    
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
    //console.log(this.state.addrobjs);
    if (this.state.addrobjs && this.state.addrobjs.length > 0){
      for (let i=0; i<this.state.addrobjs.length; i++) {
        if (this.state.addrobjs[i].contact === 'Yes') {
          this.setState({contactaddr: ' | Contact: ' + this.state.addrobjs[i].addr1, billingaddrid: this.state.addrobjs[i].id});
        }
        if (this.state.addrobjs[i].site === 'Yes') {
          this.setState({siteaddr: ' | Site: ' +this.state.addrobjs[i].addr1, siteaddrid: this.state.addrobjs[i].id});
        }
      }
    }    
  }

  handleAddrModalChange = (event) => {
    this.setState({   
      addrid: undefined,
      addrfname: undefined, 
      addrlname: undefined, 
      addremail: undefined, 
      addrphone: undefined, 
      addraddr1: undefined, 
      addraddr2: undefined, 
      addrcity: undefined, 
      addrstate: undefined, 
      addrzip: undefined,   
      addrisacontact: false,
      addrsavemodal: !this.state.addrsavemodal
    });
  }

  handleAddrSaveModal = (event) => {
    this.setState({
      addrsavemodal: !this.state.addrsavemodal
    });
  }

  handleAddressSaveSubmit = (event) => {
    //alert('handleAddressSaveSubmit...1');
    if (this.state.addrid) {
      this.updateAddress();
    } else {
      this.addNewAddress();
    }
    //alert('handleAddressSaveSubmit...2');
    this.handleAddrModalChange();
    //alert('handleAddressSaveSubmit...3');
    // this.setState({
    //   addrsavemodal: !this.state.addrsavemodal
    // });
  }

  updateAddress = () => {
    ////alert('updateAddress...')
    let address = {};
    address.id = this.state.addrid;
    address.fname = this.state.addrfname;
    address.lname = this.state.addrlname;
    address.email = this.state.addremail;
    address.phone = this.state.addrphone;
    address.addr1 = this.state.addraddr1;
    address.addr2 = this.state.addraddr2;
    address.city = this.state.addrcity;
    address.state = this.state.addrstate;
    address.zip = this.state.addrzip;
    address.is_a_contact = this.state.addrisacontact;

    customerService.updateAddress(address).then(addr => {
      if (addr && addr === 401) {
        this.props.history.push("/login");
      } else {
        // initialize the address list
        return;        
      }
    });
  }

  addNewAddress = () => {
    ////alert('addAddress..................>>')
    let address = {};
    address.clientid = this.state.customerid;
    address.fname = this.state.addrfname;
    address.lname = this.state.addrlname;
    address.email = this.state.addremail;
    address.phone = this.state.addrphone;
    address.addr1 = this.state.addraddr1;
    address.addr2 = this.state.addraddr2;
    address.city = this.state.addrcity;
    address.state = this.state.addrstate;
    address.zip = this.state.addrzip;
    address.is_a_contact = this.state.addrisacontact;

    customerService.addAddress(address).then(addr => {
      if (addr && addr === 401) {
        this.props.history.push("/login");
      } else {
        return;
      }
    });
  }

  handleEditBillingAddrModal = (event) => { 
    if(!this.state.billingaddrid) {
      alert('Please select a contact address first and then click edit-contact');
    } else {
    
      customerService.getAddress(this.state.billingaddrid).then(addr => {
        if (addr && addr === 401) {
          this.props.history.push("/login");
        } else {
          this.setState({
            addrid: this.state.billingaddrid,
            addrfname: addr.fname, 
            addrlname: addr.lname, 
            addremail: addr.email, 
            addrphone: addr.phone, 
            addraddr1: addr.addr1, 
            addraddr2: addr.addr2, 
            addrcity: addr.city, 
            addrstate: addr.state, 
            addrzip: addr.zip, 
            addrisacontact: addr.is_a_contact,
            addrsavemodal: !this.state.addrsavemodal,
          });
        }
      });
    }
  }

  handleEditSiteAddrModal = (event) => {
    if(!this.state.siteaddrid) {
      alert('Please select a site address first and then click edit-site');
    } else {
      customerService.getAddress(this.state.siteaddrid).then(addr => {
        if (addr && addr === 401) {
          this.props.history.push("/login");
        } else {
          this.setState({
            addrid: this.state.siteaddrid,
            addrfname: addr.fname, 
            addrlname: addr.lname, 
            addremail: addr.email, 
            addrphone: addr.phone, 
            addraddr1: addr.addr1, 
            addraddr2: addr.addr2, 
            addrcity: addr.city, 
            addrstate: addr.state, 
            addrzip: addr.zip, 
            addrisacontact: addr.is_a_contact,
            addrsavemodal: !this.state.addrsavemodal,
          });
        }
      });
    }
  }

  handleEditCustomer = () => {
    var { from } = this.props.location.state || { from: { pathname: ("/customerdetail/" + this.state.customerid)} };  
    this.props.history.push(from);
  }

  handleAddNewCustomer = () => {
    var { from } = this.props.location.state || { from: { pathname: "/customercreate" } };  
    this.props.history.push(from);
  }

  goBack =() => {
    this.props.history.goBack();
  }
  
  render() {
    let categoryOpt = [];
    categoryOpt.push(<option>Chosse...</option>);
    if (this.state.categoryOption && this.state.categoryOption.length > 0) {
      for (let i=0; i<this.state.categoryOption.length; i++) {
        //console.log(this.state.categoryOption[i]);
        categoryOpt.push(<option>{this.state.categoryOption[i]}</option>);
      }
    }

    let typeOpt = [];
    typeOpt.push(<option>Chosse...</option>);
    if (this.state.typeOption && this.state.typeOption.length > 0) {
      for (let i=0; i<this.state.typeOption.length; i++) {
        typeOpt.push(<option>{this.state.typeOption[i]}</option>);
      }
    }

    let typePrt = [];
    typePrt.push(<option>Chosse...</option>);
    if (this.state.priorityOption) {
      var keys = Object.keys(this.state.priorityOption);
      //console.log(keys)
      //console.log(this.state.priorityOption[keys[0]]);
      for (let i=0; i<keys.length; i++) {
        typePrt.push(<option key={keys[i]} value={this.state.priorityOption[keys[i]]}>{this.state.priorityOption[keys[i]]}</option>);
      }
    }

    return (
      <div className="content-screen" style={{height: '100%', overflow: 'scroll'}}>
      <CardDeck>
        <Card>
          <Card.Header>Customer and Service Provider
            
          </Card.Header>
          <Card.Body>
          <div>
        
          
          <SelectSearch options={this.state.dataobjs} 
                        value={this.state.customerid} name={this.state.customername} placeholder="Select customer" 
                        height={172} 
                        onChange={this.selectCustomer}/>
          
          <SelectSearch options={this.state.addrobjs} 
                        value={this.state.billingaddrid} name={this.state.contactaddr} placeholder="Select billing contact address" 
                        height={172} 
                        onChange={this.selectContactAddress}/>

          <SelectSearch options={this.state.addrobjs} 
                        value={this.state.siteaddrid} name={this.state.siteaddr} placeholder="Select site address" 
                        height={172} 
                        onChange={this.selectSiteAddress}/>   
                    
          
      </div>  
          <Card.Text>
          <div style={{float: 'left'}}>       
          <Button variant="outline-primary"   onClick={this.handleAddNewCustomer}>add customer</Button>            
          {this.state.customerid &&
          <Button variant="outline-primary ml-3"   onClick={this.handleEditCustomer}>edit customer</Button>         
          }
          </div> 
          </Card.Text>
          <Card.Text style={{marginTop: '120px'}}>
          { !this.state.managemyasset &&
          <div>
            <h5><small style={{marginBottom: '10px'}}>Select Preferred Service Provider</small></h5>
            <SelectSearch options={this.state.serviceproviders} 
                        value={this.state.providerid} name={this.state.providername} placeholder="Select service provider" 
                        height={172} 
                        onChange={this.selectServiceProvider}/>
          </div>
          }
          </Card.Text>
          </Card.Body> 
          <Card.Footer className="text-muted">&nbsp;</Card.Footer>       
        </Card>
        <Card>
          <Card.Header>Service Request</Card.Header>
          <Card.Body>
            
            <div>
                <Form onSubmit={this.handleSubmit}>
                  <Form.Row>
                    <Form.Group as={Col} controlId="detail" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Detail</Form.Label>
                      <Form.Control required="true" placeholder="Enter the detail" defaultValue={this.state.detail} />
                    </Form.Group>
                  </Form.Row> 

                  <Form.Row>
                    <Form.Group as={Col} controlId="problemtype" onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Category</Form.Label>
                      <Form.Control as="select" defaultValue={this.state.problemtype}>
                            {categoryOpt}
                            
                      </Form.Control>            
                    </Form.Group>
                    <Form.Group as={Col} controlId="type" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Type</Form.Label>
                      <Form.Control as="select" defaultValue={this.state.type}>
                            {typeOpt}
                      </Form.Control> 
                    </Form.Group>
                    <Form.Group as={Col} controlId="priority" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Priority</Form.Label>
                      <Form.Control as="select" defaultValue={this.state.priority}>
                          {typePrt}                 
                      </Form.Control> 
                    </Form.Group>          
                  </Form.Row>

                  <Form.Row>  
                    <Form.Group as={Col} controlId="requesteddate">
                      <Form.Label  style={{fontSize: "14px"}}>Customer requested date</Form.Label>  
                      <div>
                      <DatePicker style={{displayValue: 'block'}}
                          selected={this.state.requesteddate}
                          onChange={date => this.setRequestedDate(date)}
                          dateFormat="MMM d, yyyy HH:mm"
                          placeholderText="Enter a date"
                          
                      /> </div>                   
                    </Form.Group>

                    <Form.Group as={Col} controlId="startdate">
                      <Form.Label  style={{fontSize: "14px"}}>Start date</Form.Label>  
                      <div>
                      <DatePicker style={{displayValue: 'block'}}
                          selected={this.state.startdate}
                          onChange={date => this.setStartDate(date)}
                          dateFormat="MMM d, yyyy HH:mm"
                          placeholderText="Enter a date"
                          
                      /> </div>                   
                    </Form.Group>
                    
                    <Form.Group as={Col} controlId="enddate">
                      <Form.Label  style={{fontSize: "14px"}}>End date</Form.Label>
                      <div>
                      <DatePicker style={{displayValue: 'block'}}
                          selected={this.state.enddate}
                          onChange={date => this.setEndDate(date)}
                          dateFormat="MMM d, yyyy HH:mm"
                          placeholderText="Select a date"
                          
                      /></div>
                    </Form.Group>                    
                  </Form.Row>

                  <Form.Row>  
                    <Form.Group as={Col} controlId="instruction" onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Note for technician</Form.Label>
                      <Form.Control placeholder="Enter instructions" defaultValue={this.state.instruction}/>
                    </Form.Group>                    
                  </Form.Row>

                  <Form.Row>  
                    <Form.Group controlId="skills">
                      <Form.Label style={{fontSize: "14px"}}>Skills</Form.Label>
                      <Multiselect 
                        options={this.state.skillOption} 
                        displayValue="name" 
                        selectedValues={this.state.skills} 
                        onSelect={this.setMultiSkills}
                        onRemove={this.setMultiSkills}
                      />                      
                    </Form.Group>
                   
                    
                  </Form.Row>
                  <div style={{float: "left"}} >                                       
                    <Button variant="outline-primary mr-3" size="sm" type="submit">Save</Button>                              
                    <Button variant="outline-primary" size="sm" onClick={() => {this.goBack()}}>Cancel</Button>                              
                  </div>
              </Form>
            </div>
          </Card.Body>
          <Card.Footer className="text-muted">&nbsp;</Card.Footer>       
        </Card>
        
      </CardDeck>

      <>
              <Modal show={this.state.addrsavemodal} onHide={this.handleAddrModalChange.bind(this)}
                 
                 size="lg"
                 aria-labelledby="contained-modal-title-vcenter"
                 centered
               >
                <Modal.Header closeButton>              
                   <Modal.Title>Address</Modal.Title>              
                </Modal.Header>
                <Modal.Body>
                <Form onSubmit={this.handleAddressSaveSubmit}>
                  <Form.Row>
                    <Form.Group as={Col} controlId="addrfname" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>First Name</Form.Label>
                      <Form.Control placeholder="Enter first name" defaultValue={this.state.addrfname} />
                    </Form.Group>
                    <Form.Group as={Col} controlId="addrlname" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Last Name</Form.Label>
                      <Form.Control placeholder="Enter last name" defaultValue={this.state.addrlname} />
                    </Form.Group>
                    <Form.Group as={Col} id="addrisacontact" 
                        onChange={this.handleContactCheckBox}>
                      <Form.Label style={{fontSize: "14px"}}>Contact</Form.Label>
                      <Form.Check type="checkbox" checked={this.state.addrisacontact}/>                      
                    </Form.Group>
                  </Form.Row> 
                  <Form.Row>
                    <Form.Group as={Col} controlId="addremail" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Email</Form.Label>
                      <Form.Control type="email" placeholder="Enter email" defaultValue={this.state.addremail} />
                    </Form.Group>
                    <Form.Group as={Col} controlId="addrphone" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Phone</Form.Label>
                      <Form.Control type="phone" placeholder="Enter phone number" defaultValue={this.state.addrphone} />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="addraddr1" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Address</Form.Label>
                      <Form.Control placeholder="Enter street address" defaultValue={this.state.addraddr1} />
                    </Form.Group>
                    <Form.Group as={Col} controlId="addraddr2" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Address Cont'</Form.Label>
                      <Form.Control type="phone" placeholder="Enter address" defaultValue={this.state.addraddr2} />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="addrcity" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>City</Form.Label>
                      <Form.Control placeholder="Enter city" defaultValue={this.state.addrcity} />
                    </Form.Group>
                    <Form.Group as={Col} controlId="addrstate" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>State</Form.Label>
                      <Form.Control type="phone" placeholder="Enter state" defaultValue={this.state.addrstate} />
                    </Form.Group>
                    <Form.Group as={Col} controlId="addrzip" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Zip</Form.Label>
                      <Form.Control type="phone" placeholder="Enter state" defaultValue={this.state.addrzip} />
                    </Form.Group>
                  </Form.Row>
                  <div style={{float: "right"}} >                   
                  <Button variant="secondary mr-3" onClick={this.handleAddrModalChange.bind(this)}>
                      Close
                    </Button>
                    <Button type="submit" variant="primary" onClick={this.handleAddrSaveModal.bind(this)}>
                      Save
                    </Button> 
                  </div>
              </Form>
              </Modal.Body>
            </Modal>
            </> 

      </div>
    );
  }
}


export default ServiceCreate;