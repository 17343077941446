import React  from "react";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { Button, Card, Modal, Nav, Form, Col, ListGroup, Table } from 'react-bootstrap';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { Multiselect } from "multiselect-react-dropdown";
import { servicerequestService } from '../../service/servicerequestService';
import { customerService } from '../../service/customerService';
import { slareorderService } from '../../service/salesorderService';
import { invoiceService } from '../../service/invoiceService';
import { quoteService } from '../../service/quoteService';
import { assetService } from '../../service/assetService';
import { activityService } from '../../service/activityService';
import { materialService } from '../../service/materialService';
import { uploadService } from '../../service/uploadService';
import { providerService } from '../../service/providerService';
import { purchaseService } from '../../service/purchaseService';
import { signService } from '../../service/signService';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import util from '../../service/utility.js';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faAngleRight, faEdit} from "@fortawesome/free-solid-svg-icons";
import utility from '../../service/utility'
import moment from 'moment';
import '../../css/Chip.css';
import Chip from '@material-ui/core/Chip';
import { createPortal } from "react-dom";
import EditIcon from '@material-ui/icons/Edit';

const { SearchBar } = Search;



//import '@font-awesome/css/font-awesome.min.css';

class ServiceDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      companyid: undefined,
      //////////////
      id: undefined,
      customerid: undefined,
      customername: undefined,
      city: undefined,
      detail: undefined,
      siteaddrid: undefined,
      billingaddrid: undefined,
      problemtype: undefined,
      type: undefined,
      priority: undefined,
      status: undefined,
      requesteddate: undefined,
      startdate: undefined,
      enddate: undefined,
      orderclosedate: undefined,
      otherinfo: undefined,
      assetid: undefined,
      assetdescription: undefined,
      assetmodel: undefined,
      assetserial: undefined,
      assetmanufacturer: undefined,
      assetlotnumber: undefined,
      assetnumber: undefined,
      skills: undefined,
      instruction: undefined,
      attachdocs: undefined,
      scheduledate: undefined,
      providerid: undefined,
      resource: undefined,
      createdAt: undefined,
      ////
      providername: undefined,
      provideraddr1: undefined, 
      provideraddr2: undefined, 
      provideremailphone: undefined,
      providercontactname: undefined,
      ////
      costcenter: undefined,
      genpdf: undefined,

      orderequips: [],

      equipobjs: [],
      
      equipcolumns: [{
        dataField: 'id',
        text: 'id',
        hidden: true
      }, {
        dataField: 'description',
        text: 'Description',
        sort: true,
        searchable: true,
        headerStyle: {
          backgroundColor: '#D9D8D9'
        }
      }, {
        dataField: 'assetnumber',
        text: 'Asset Tag #',
        sort: true,
        searchable: true,
        headerStyle: {
          backgroundColor: '#D9D8D9'
        }
      }, {
        dataField: 'model',
        text: 'Model',
        sort: true,
        searchable: true,
        headerStyle: {
          backgroundColor: '#D9D8D9'
        }
      },
      {
        dataField: 'lotnumber',
        text: 'Lot #',
        sort: true,
        searchable: true,
        headerStyle: {
          backgroundColor: '#D9D8D9'
        }
      }, 
      {
        dataField: 'serial_no',
        text: 'Serial #',
        sort: true,
        searchable: true,
        headerStyle: {
          backgroundColor: '#D9D8D9'
        }
      }, {
        dataField: 'manufacturer',
        text: 'Manufacturer',
        sort: true,
        searchable: true,
        headerStyle: {
          backgroundColor: '#D9D8D9'
        }
      }
      
      
      ],
      selectEquipRow: {
        mode: 'radio',
        clickToSelect: true,
        hideSelectColumn: true,
        bgColor: '#adb5bd',
        onSelect: (row, isSelect, rowIndex, e) => {            
          this.setEquipment(row)
        }
      },
      defaultEquipSorted: [{
          dataField: 'description',
          order: 'asc'
      }],
      selectequipmodal: false,
      addrobjs: [],
      addrcolumns: [{
        dataField: 'id',
        text: 'id',
        hidden: true
      }, {
        dataField: 'fname',
        text: 'First Name',
        hidden: true,
      }, {
        dataField: 'lname',
        text: 'Last Name',
        hidden: true,
      }, {
        dataField: 'addr1',
        text: 'Address',
        hidden: true,
      }, {
        dataField: 'addr2',
        text: 'Address Cont',
        hidden: true,
      }, {
        dataField: 'city',
        text: 'City',
        hidden: true,
      }, {
        dataField: 'state',
        text: 'State',
        hidden: true,
      }, {
        dataField: 'zip',
        text: 'Zip',
        hidden: true,
      }, {
        dataField: 'email',
        text: 'Email',
        hidden: true,
      }, {
        dataField: 'phone',
        text: 'Phone',
        hidden: true,
      }, {
        dataField: 'fullname',
        text: 'Name',
        editable: false,
      }, {
        dataField: 'address',
        text: 'Address',
        editable: false,
      },  {
        dataField: 'is_a_contact',
        text: 'Contact',
        editable: false,
        formatter: (cellContent, row) => {
          let disp = "";
          if (row.is_a_contact === true) {
            disp = "Yes";
          }
          return (
            <h5>
              <small className="label label-success">{disp}</small>
            </h5>
          );            
        }        
      }          
      ],
      selectAddressRow: {
        mode: 'radio',
        clickToSelect: true,
        hideSelectColumn: true,
        bgColor: '#adb5bd',
        onSelect: (row, isSelect, rowIndex, e) => {            
          this.setSelectedAddress(row);
        }
      },
      selectaddressmodal: false,
      ////              
      selectedaddress: undefined,
      selectassetid: undefined,
      selectassetdescription: undefined,
      selectassetmodel: undefined,
      selectassetserial: undefined,
      selectassetmanufacturer: undefined,
      selectassetlotnumber: undefined,
      selectassetnumber: undefined,

      skilldisplay: undefined,
      skillSelected: undefined,
      skillOption: [],
      categoryOption: [],
      typeOption: [],
      priorityOption: {},
      statusOption: {},
      billaddr1: undefined,
      billaddr2: undefined,
      siteaddr1: undefined,
      siteaddr2: undefined,
      cardbody: undefined,
      newequipmentmodal: false,
      editordermodal: false,  
      selectcustaddr: undefined, 
      
      ////   
      addrid: undefined,
      addrfname: undefined, 
      addrlname: undefined, 
      addremail: undefined, 
      addrphone: undefined, 
      addraddr1: undefined, 
      addraddr2: undefined, 
      addrcity: undefined, 
      addrstate: undefined, 
      addrzip: undefined, 
      addrisacontact: false,

      addrsavemodal: false,
      contactname: undefined,

      //
      selectedassetid: undefined,
      billphone: undefined,
      sitephone: undefined,
      billemail: undefined,
      siteemail: undefined,

      ////

      lineobjs: [],
      linecolumns: [{
        dataField: 'id',
        text: 'id',
        hidden: true
      }, {
        dataField: 'item',
        text: 'Item',
        hidden: false,
        headerStyle: {
          backgroundColor: '#edeff1'
        },
        formatter: (cellContent, row) => {
          let config = JSON.parse(localStorage.getItem('config'));
          //console.log(config);
          let st = JSON.parse(config.serviceorderstatuses);
          return (
            <h6><small>
              {row.item &&
              <div className="label label-success">{row.item}</div>
              }
              {row.description &&
              <div className="label label-success">{row.description}</div>
              }
              {row.discount != undefined && 
              <div className="label label-success">Discount: {row.discount}%</div>
              }
              {row.tax01 &&
              <div className="label label-success">Tax: {row.tax1}% ({row.tax01})</div>
              }
              {row.warehouseloc &&
              <div className="label label-success">Warehouse: {row.warehouseloc}</div>
              }
            </small></h6>
          );            
        }
      },       
      {
        dataField: 'description',
        text: 'Description',
        hidden: true,
        headerStyle: {
          backgroundColor: '#edeff1'
        }
      }, {
        dataField: 'qty',
        text: 'Quantity',
        hidden: true,
        headerStyle: {
          backgroundColor: '#edeff1'
        }
      }, {
        dataField: 'unitprice',
        text: 'Qty and Unit Price',
        hidden: false,
        headerStyle: {
          backgroundColor: '#edeff1'
        },
        formatter: (cellContent, row) => {
          let config = JSON.parse(localStorage.getItem('config'));
          //console.log(config);
          let st = JSON.parse(config.serviceorderstatuses);
          let qt = 0;
          if (row.qty) {
            qt = row.qty;
          }
          let up = 0;
          if (row.unitprice) {
            up = Number(row.unitprice).toFixed(2);
          }
          return (
            <h6><small>              
              {
              <div className="label label-success">Qty: {qt} </div>
              }
              {
              <div className="label label-success">Unit Price: {up}</div>
              }
            </small></h6>
          );            
        } 
      }, 
      
      {
        dataField: 'name',
        text: 'Vendor',
        headerStyle: {
          backgroundColor: '#edeff1'
        },
        formatter: (cellContent, row) => {          
          let up = undefined;
          if (row.vendorprice && row.name) {
            up = Number(row.vendorprice).toFixed(2);
          }
          return (
            <h6><small>              
              {
              <div className="label label-success">{row.name} </div>
              }
              { up && 
              <div className="label label-success">Vendor Unit Price: {up} ({row.currency})</div>
              }
              { row.ponum && row.iteminpo  === true && 
              <Button variant="link" style={{paddingLeft: '0px'}} size="sm" onClick={() => {this.handleOpenPO(row)}} >PO# {row.ponum}</Button>                            
              }
            </small></h6>
          );            
        } 
      },
      {
        dataField: 'createdAt',
        text: 'Action',
        headerStyle: {
          backgroundColor: '#edeff1'
        } ,
        formatter: (cellContent, row) => {          
          return (
            <h6><small>
              { (!row.iteminpo || row.iteminpo === false) &&
              <>
              <Button variant="outline-primary mb-1" size="sm" onClick={() => {this.handleSelectedLineItem(row)}} >edit</Button>
              <Button variant="outline-primary mb-1 ml-3" size="sm" onClick={() => {this.handleFind(row)}} >find vendor</Button>              
              </>
              }
              { row.vendorid && (!row.iteminpo || row.iteminpo === false) && this.state.status != 99 && this.state.status != 5 && 
              <Button variant="outline-primary mb-1 ml-3" size="sm" onClick={() => {this.handleAddItemInPO(row)}} >add PO</Button>              
              }
            </small></h6>
          );            
        }
      },
      {
        dataField: 'vendorprice',
        text: 'Vendor Price',
        hidden: true,
        headerStyle: {
          backgroundColor: '#edeff1'
        }
      },
      {
        dataField: 'discount',
        text: 'Discount',
        hidden: true,
        headerStyle: {
          backgroundColor: '#edeff1'
        }
      }, {
        dataField: 'tax1',
        text: 'Tax',
        hidden: true,
        headerStyle: {
          backgroundColor: '#edeff1'
        }
      }],
      selectLineRow: {
        mode: 'radio',
        clickToSelect: true,
        hideSelectColumn: true,
        bgColor: '#adb5bd',
        onSelect: (row, isSelect, rowIndex, e) => {
          this.handleSelectedLineItem(row);
        }
      },
    };

    this.handleCancel = this.handleCancel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);   
    this.handleInputChange = this.handleInputChange.bind(this);      
    
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    //console.log('so',this.props);    
    let user = JSON.parse(localStorage.getItem('user'));
    this.state.companyid = JSON.parse(user.companyid);
    let config = JSON.parse(localStorage.getItem('config'));
    ////
    let cc = undefined;
    if (config.other) {
      let tmp = JSON.parse(config.other);
      if (tmp.costcenter) {
        cc = tmp.costcenter;
      }
    }
    ////
    let peopleskills = JSON.parse(config.peopleskills);
    let options = [];
    for (let i=0; i<peopleskills.length; i++) {
      let s = {};
      s.name=   peopleskills[i].skill;
      s.id = peopleskills[i].skill; 
      options.push(s);     
    }
    ////console.log(config);
    ////console.log(JSON.parse(config.serviceordertypes));
    ////console.log(JSON.parse(config.serviceorderpriotities));
    this.setState({skillOption: options, 
                    categoryOption: JSON.parse(config.serviceorderproblemtypes),
                    typeOption: JSON.parse(config.serviceordertypes),
                    priorityOption: JSON.parse(config.serviceorderpriotities),
                    statusOption:  JSON.parse(config.serviceorderstatuses),
                    costcenter: cc,
    });
    
    if (id) {
      this.loadServiceOrder(id);
    }
    
  }

  loadServiceOrder(id) {
    servicerequestService.getServiceRequest(id)
        .then(order => {
          if (order && order === 401) {
            this.props.history.push("/login");
          } else {
            
              //console.log(order);
              this.setState({ 
                id: order.id,
                customerid: order.customerid,
                customername: order.customername,
                city: order.city,
                detail: order.detail,
                siteaddrid: order.siteaddrid,
                billingaddrid: order.billingaddrid,
                problemtype: order.problemtype,
                type: order.type,
                priority: order.priority,
                status: order.status,
                requesteddate: order.requesteddate,
                startdate: order.startdate,
                enddate: order.enddate,
                orderclosedate: order.orderclosedate,
                scheduledate: order.scheduledate,
                resource: order.resource,
                otherinfo: order.otherinfo,
                assetid: order.assetid,
                assetdescription: order.assetdescription,
                assetmodel: order.assetmodel,
                assetserial: order.assetserial,
                assetmanufacturer: order.assetmanufacturer,
                //assetlotnumber: order.assetlotnumber,
                providerid: order.providerid,

                assetnumber: order.assetnumber,
                skills: order.skills,
                skillSelected: order.skills ? JSON.parse(order.skills) : undefined,
                skilldisplay: this.getSkillObject(order.skills),
                instruction: order.instruction,
                attachdocs: order.attachdocs,
                picture: order.picture,
                createdAt: order.createdAt,
              })

              if (this.state.attachdocs && this.state.attachdocs !== null) {
                let tt = this.state.attachdocs.split(",");
                for (let i=0; i<tt.length; i++) {
                  if (tt[i].startsWith('genorderpdf=')) {
                    this.setState({genpdf: tt[i].substring('genorderpdf='.length)});
                    break;
                  }
                }
              }
                        
              if (this.state.billingaddrid && this.state.siteaddrid && this.state.billingaddrid === this.state.siteaddrid) {
                customerService.getAddress(this.state.billingaddrid).then(addr => {
                  this.setBillingAddr(addr);
                  this.setSiteAddr(addr);
                });
              } else {
                if (this.state.billingaddrid) {
                  customerService.getAddress(this.state.billingaddrid).then(addr => {
                    this.setBillingAddr(addr);
                  });
                } 
                if (this.state.siteaddrid) {
                  customerService.getAddress(this.state.siteaddrid).then(addr => {
                    this.setSiteAddr(addr);
                  });
                }
              }
              this.getServiceProvider();
              this.getEquipments();
            }
            this.setState({cardbody: this.getDefaultCardBody()});
        });
  }

  setSelectedAddress(row) {
    this.setState({selectedaddress: row});
  }

  getSkillObject(skill) {
    if (skill) {
      let s = '';
      let sk = JSON.parse(skill);
      for (let i=0; i<sk.length; i++) {
        ////console.log(sk[i]);
        if (sk[i].name) {
          s = s + sk[i].name;
          if ((i+1) < sk.length) {          
            s = s + ', ';
          }
        }
      }
     
      ////console.log(s);
     
      return s;
    }
    return 'NA';
  }

  setBillingAddr(addrs) {
    let billaddr1 = addrs.addr1;
    let billaddr2 = (addrs.city ? addrs.city + ', ': '') + (addrs.state ? addrs.state + ' ': '') + (addrs.zip ? addrs.zip: '');
    let billaddr3 = (addrs.email? addrs.email + ' ': '') + (addrs.phone ? 'Ph: ' + addrs.phone : '');
    this.setState({billaddr1: billaddr1, billaddr2: billaddr2, billaddr3: billaddr3, contactname: ((addrs.fname ? addrs.fname + ' ' : '')  + (addrs.lname ? addrs.lname : '')),
          billemail: (addrs.email? addrs.email + ' ': ''), billphone: (addrs.phone ? addrs.phone : '')});
  }

  setSiteAddr(addrs) {
    let siteaddr1 = addrs.addr1;
    let siteaddr2 = (addrs.city ? addrs.city + ', ': '') + (addrs.state ? addrs.state + ' ': '') + (addrs.zip ? addrs.zip: '');
    let siteaddr3 = (addrs.email? addrs.email + ' ': '') + (addrs.phone ? 'Ph: ' + addrs.phone : '');
    this.setState({siteaddr1: siteaddr1, siteaddr2: siteaddr2, siteaddr3: siteaddr3,
      siteemail: (addrs.email? addrs.email + ' ': ''), sitephone: (addrs.phone ? addrs.phone : '')});
  }

  getServiceProvider() {
    if (this.state.providerid) {
      providerService.getProvider(this.state.providerid)
      .then(prv => {
        if (prv && prv === 401) {
          this.props.history.push("/login");
        } else {
          if (prv && prv.id) {
            let addrs = prv;
            let siteaddr1 = addrs.addr1;
            let siteaddr2 = (addrs.city ? addrs.city + ', ': '') + (addrs.state ? addrs.state + ' ': '') + (addrs.zip ? addrs.zip: '');
            let siteaddr3 = (addrs.email? addrs.email + ' ': '') + (addrs.phone ? 'Ph: ' + addrs.phone : '');
            this.setState({
              providername: prv.name,
              provideraddr1: siteaddr1, 
              provideraddr2: siteaddr2, 
              provideremailphone: siteaddr3,
              providercontactname: (prv.contact_first_name ? prv.contact_first_name + ' ' : '') + (prv.contact_last_name ? prv.contact_last_name : ''),
              });            
          }
        }
      });
    }
  }


  getMaterials() {
    materialService.getMaterials(this.state.id).then(mat => {
      if (mat && mat === 401) {
        this.props.history.push("/login");
      } else {
        //console.log(mat);
        purchaseService.getPosForService(this.state.id)
        .then(pos => {
          if (pos && pos === 401) {
            this.props.history.push("/login");
          } else { 
            if (pos && pos.length > 0) {
              for (let i=0; i<pos.length; i++) {
                this.setPurchaseOrderInMaterial(mat, pos[i]);
              }
              //console.log(mat);
              this.setState({
                lineobjs: mat,
              });
              this.setState({
                cardbody: this.getMaterialBody()
              });
             } else {
              this.setState({
                lineobjs: mat,
              });
              this.setState({
                cardbody: this.getMaterialBody()
              });
             }                        
          }
        });
      }
    });    
  }

  getEquipments() {
    assetService.getAssetForOrder(this.state.id).then(assets => {
      //console.log('aasets', assets);
      this.setState({
        orderequips: assets,
      });
    });
  }

  getEquipmentsAndBody() {
    assetService.getAssetForOrder(this.state.id).then(assets => {
      //console.log('getEquipmentsAndBody');
      this.setState({
        orderequips: assets
      });
      this.setState({
        cardbody: this.getEquipmentBody()
      });
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const name = target.id;
    if (target.type === 'radio') {

      if (name === "custom-inline-radio-2") {
        this.state.employementtype = 2;
      } else if (name === "custom-inline-radio-3") {
        this.state.employementtype = 3;
      } else if (name === "custom-inline-radio-4") {
        this.state.employementtype = 4;
      }else  {
        this.state.employementtype = 1;
      }
    } else {
      this.setState({
        [name]: target.value
      });
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    let tmp = JSON.stringify(this.state);
    let res = JSON.parse(tmp);
    res.skillOption = null;
    res.selectedSkills = null;
    res.regular = null;
    res.contractor = null;
    res.temporary = null;
    res.equipment = null;

    delete res.skillOption;
    delete res.selectedSkills;
    delete res.regular;
    delete res.contractor;
    delete res.temporary;
    delete res.equipment;

    if (res.skills) {
      res.skills = JSON.stringify(res.skills);
    }
    /*
    if (res.id) {
      resourceService.updateResource(res)
        .then(resource => {
          if (resource && resource === 401) {
            var { from } = this.props.location.state || { from: { pathname: "/login" } };  
            this.props.history.push(from);
          } else {
            var { from } = this.props.location.state || { from: { pathname: "/resources" } };
            this.props.history.push(from);
          }
        });
    } else {
      resourceService.addNewResource(res)
        .then(resource => {
          if (resource && resource === 401) {
            var { from } = this.props.location.state || { from: { pathname: "/login" } };  
            this.props.history.push(from);
          } else {
            var { from } = this.props.location.state || { from: { pathname: "/resources" } };
            this.props.history.push(from);
          }
        });
    }*/
  };
  
  handleCancel = (event) => {
    event.preventDefault();
    var { from } = this.props.location.state || { from: { pathname: "/resources" } };
    this.props.history.push(from);
  }

  handleDelete = (event) => {
    event.preventDefault();
    /*
    if (this.state.id) {
      let resource = {};
      resource.status = 2;
      resource.id = this.state.id
      resourceService.updateResource(resource)
        .then(resource => {
          if (resource && resource === 401) {
            var { from } = this.props.location.state || { from: { pathname: "/login" } };  
            this.props.history.push(from);
          } else {
            var { from } = this.props.location.state || { from: { pathname: "/resources" } };
            this.props.history.push(from);
          }
        });      
    }*/
  }

  setUnavailabefrom = (date) => {
    this.setState({unavailabefrom: date});
  }

  setUnavailabeto = (date) => {
    this.setState({unavailabeto: date});
  }

  setMultiSkills = (option) => {
    ////alert(option);
    ////console.log((option));    
    this.state.skillSelected = option;
    //////console.log(this.state.skills);
  }

  editBillingAddress = () => {

  }

  getPriority(p) {            
    if (Number(p) === 1) {
        return (<Chip label={utility.getPriorityName(p)} className="chip redchip" variant="default" style={{marginBottom: '-3px'}}/>);
    } else if (Number(p) === 2) {
        return (<Chip label={utility.getPriorityName(p)} className="chip yellowchip" variant="default" style={{marginBottom: '-3px'}} />);
    } else {
        return (<Chip label={utility.getPriorityName(p)} className="chip bluechip" variant="default" style={{marginBottom: '-3px'}} />);
    }
}

  getStatusName(key) {
    if (key) {
      let config = JSON.parse(localStorage.getItem('config'));
      let status = JSON.parse(config.serviceorderstatuses);
      return status[key];             
    }
    return 'NA';
  }

  getSkills(skill) {
    if (skill) {
      let s = '';
      let sk = skill;
      ////console.log('getSkills...')
      ////console.log(skill);
      /*
      //alert(typeof sk)
      if ((typeof sk) === 'string') {
        ////console.log(sk.split(','));
        return sk.split(',');
      }
      if (!Array.isArray(skill)) {
        sk = JSON.parse(skill);
      } */      
      sk = JSON.parse(skill);      
      ////console.log(sk);
      return sk;
      /*
      for (let i=0; i<sk.length; i++) {
        if (sk[i].name) {
          s = s + sk[i].name;
          if ((i+1) < sk.length) {          
            s = s + ', ';
          }
        }
      }
      ////console.log('s..........')
      ////console.log(s)
      return s;*/
    }
    //return 'NA';
  }

  getLocalDate(d) {
    if (d) {
      ////console.log(d);      
      let l = moment(d).local().format('MMM DD, YYYY HH:mm');
      ////console.log(l);      
      return l;
    }
    return 'NA';
  }

  handleOrderModal = (event) => {
    ////alert('handleOrderModal...')
    this.setState({
      editordermodal: !this.state.editordermodal
    });
    ////console.log(this.state.editordermodal);
  }

  getSelectedSkills = () => {
    if (this.state.skillSelected && this.state.skillSelected.length > 0) {
      return JSON.stringify(this.state.skillSelected);
    }
    return null;
  }

  handleOrderSaveSubmit = (e) => {
    e.preventDefault();
    
    ////console.log('priority: ' + this.state.priority);
    let order = {};            
    order.detail = this.state.detail;
    order.problemtype = this.state.problemtype;
    order.type = this.state.type;
    order.priority = this.getPriorityValue(this.state.priority);
    order.status = this.getStatusValue(this.state.status);
    order.requesteddate = this.state.requesteddate;
    order.startdate = this.state.startdate;
    order.enddate = this.state.enddate;
    order.orderclosedate = this.state.orderclosedate;
    order.otherinfo = this.state.otherinfo;
    order.instruction = this.state.instruction;
    ////console.log(this.state.skills);    
    ////console.log(JSON.stringify(this.state.skillSelected));
    order.skills = this.getSelectedSkills();

    ////console.log(order);
    let id = this.state.id;
    this.setState({editordermodal: !this.state.editordermodal});
    servicerequestService.updateServiceRequest(order, id).then(ord => {
      if (ord && ord === 401) {
        this.props.history.push("/login");
      } else {
        this.setState({          
          skills: order.skills,
          skilldisplay: this.getSkillObject(order.skills),
          priority: order.priority,
          status: order.status,

        });
        
        this.setState({
          cardbody: this.getDefaultCardBody()                      
        })
        // var { from } = { from: { pathname: "/servicedetail/" + this.state.id} };  
        // this.props.history.push(from);
      }
    });
  }

  setStartDate = (date) => {
    this.setState({startdate: date});
  }

  setEndDate = (date) => {
    this.setState({enddate: date});
  }

  setRequestedDate = (date) => {
    this.setState({requesteddate: date});
  }

  getStatusValue(name) {
    if (name) {
      if ((typeof name) === 'number') {
        return name;
      }
      let config = JSON.parse(localStorage.getItem('config'));
      let status = JSON.parse(config.serviceorderstatuses);
      let keys = Object.keys(status);
      for (let i=0; i<keys.length; i++) {
        if (status[keys[i]] === name) {
          return keys[i];
        }
      }
    }
  }

 getPriorityValue(name) {
    if (name) {
      if ((typeof name) === 'number') {
        return name;
      }
      let config = JSON.parse(localStorage.getItem('config'));
      let priority = JSON.parse(config.serviceorderpriotities);
      let keys = Object.keys(priority);
      for (let i=0; i<keys.length; i++) {
        if (priority[keys[i]] === name) {
          return keys[i];
        }
      }
    }
  }

  handleAddNewEquipment = (e) => {
    if (this.state.status && (this.state.status === 5 || this.state.status === 99)) {
      alert('You cannot edit asset or material for a closed order');
    } else {
      if (this.state.selectedassetid) {
        this.props.history.push('/assetedit/' + this.state.customerid + '/asset/' + this.state.selectedassetid, '', this.state) ;
      } else {      
        this.props.history.push('/assetedit/' + this.state.customerid, '', this.state) ;
      }
    }
  }

  handleAddNewEquipmentOrig = (e) => {
    if (!this.state.customerid) {
      //alert('Select a Customer before adding a new equipment for it');
    } else {
      if (this.state.selectedassetid && this.state.orderequips && this.state.orderequips.length > 0) {
        let found = false;
        for (let i=0; i<this.state.orderequips.length; i++) {
          //console.log(Number(this.state.orderequips[i].id), Number(this.state.selectedassetid));
          if (Number(this.state.orderequips[i].id) === Number(this.state.selectedassetid)) {
            found = true;
            this.setState({
              assetid: this.state.orderequips[i].id,
              assetdescription: this.state.orderequips[i].description,
              assetmodel: this.state.orderequips[i].model,
              assetmanufacturer: this.state.orderequips[i].manufacturer,
              assetlotnumber: this.state.orderequips[i].lotnumber,
              assetserial: this.state.orderequips[i].serial_no,
              assetnumber: this.state.orderequips[i].assetnumber,          
            });
          }
        }
        if (!found) {
          this.setState({
            assetid: undefined,
            assetdescription: undefined,
            assetmodel: undefined,
            assetmanufacturer: undefined,
            assetlotnumber: undefined,
            assetserial: undefined,
            assetnumber: undefined,          
          });
          this.props.history.push('/assetedit/' + this.state.customerid, '', this.state) ;
        }
      } else {
        this.setState({
          assetid: undefined,
          assetdescription: undefined,
          assetmodel: undefined,
          assetmanufacturer: undefined,
          assetlotnumber: undefined,
          assetserial: undefined,
          assetnumber: undefined,          
        });
        
        
      }

      this.setState({
        newequipmentmodal: !this.state.newequipmentmodal
      });
    }
  }

  removeEquipmentFromOrder(id){
    // if (!this.state.assetid) {
    //   return;
    // }

    // let order = {};
    // order.assetid = null;
    // order.assetdescription = null;
    // order.assetmodel = null;
    // order.assetserial = null;
    // order.assetmanufacturer = null;
    // order.assetnumber = null;
    // servicerequestService.updateServiceRequest(order, this.state.id)
    //     .then(ord => {
    //       if (ord && ord === 401) {
    //         var { from } = this.props.location.state || { from: { pathname: "/login" } };  
    //         this.props.history.push(from);
    //       } else {
    //         this.setState({
    //           assetid: undefined,
    //           assetdescription: undefined,
    //           assetmodel: undefined,
    //           assetserial: undefined,
    //           assetmanufacturer: undefined,
    //           assetnumber: undefined,
    //         });
    //         this.setState({
    //           cardbody: this.getEquipmentBody()                      
    //         });
    //       }
    //     });
    if (this.state.status && (this.state.status === 5 || this.state.status === 99)) {
      alert('You cannot edit asset or material for a closed order');
    } else {
      assetService.delinlAssetToOrder(id, this.state.id)
        .then(ord => {
          if (ord && ord === 401) {
            this.props.history.push("/login");
          } else {
            this.getEquipmentsAndBody();                          
          }
        });
      }
  }

  handleSaveOrUpdateSubmit = (e) => {
    e.preventDefault();
    if (this.state.assetid) {
      this.handleUpdateEquipmentSubmit();
    } else {
      this.handleAddnewEquipmentSubmit();
    }
  }

  handleAddnewEquipmentSubmit = (e) => {
    
    let asset = {};
    asset.clientid = this.state.customerid;
    asset.description = this.state.assetdescription;
    asset.model = this.state.assetmodel;
    asset.serial_no = this.state.assetserial;
    asset.manufacturer = this.state.assetmanufacturer;
    asset.lotnumber = this.state.assetlotnumber;
    asset.assetnumber = this.state.assetnumber;
    assetService.addNewAsset(asset, this.state.id)
        .then(ast => {
          if (ast && ast === 401) {
            this.props.history.push("/login");
          } else {
            this.setState({
              newequipmentmodal: !this.state.newequipmentmodal
            });
            
            this.loadServiceOrder(this.state.id);

            // this.setState({
  
            //   orderequips: this.getEquipments(),              
            // });
            // this.setState({
            //   cardbody: this.getEquipmentBody()                      
            // });   

            this.getEquipmentsAndBody();

            // let order = {};            
            // order.assetid = ast.id
            // order.assetdescription = this.state.assetdescription;
            // order.assetmodel = this.state.assetmodel;
            // order.assetmanufacturer = this.state.assetmanufacturer;
            // order.assetserial = this.state.assetserial;
            // order.assetnumber = this.state.assetnumber;

            // let id = this.state.id;
            // servicerequestService.updateServiceRequest(order, id).then(ord => {              
            //   if (ord && ord === 401) {
            //     var { from } = this.props.location.state || { from: { pathname: "/login" } };  
            //     this.props.history.push(from);
            //   } else {                
            //     this.setState({
  
            //       orderequips: this.getEquipments(),              
            //     });
            //     this.setState({
            //       cardbody: this.getEquipmentBody()                      
            //     });
            //   }
            // });
            
          }
        });
  }

  handleUpdateEquipmentSubmit = (e) => {    
    
    let asset = {};    
    asset.description = this.state.assetdescription;
    asset.model = this.state.assetmodel;
    asset.serial_no = this.state.assetserial;
    asset.manufacturer = this.state.assetmanufacturer;
    asset.lotnumber = this.state.assetlotnumber;
    asset.assetnumber = this.state.assetnumber;

    assetService.updateAsset(asset, this.state.assetid, this.state.id)
        .then(ast => {
          if (ast && ast === 401) {
            this.props.history.push("/login");
          } else {                          
            this.getEquipmentsAndBody();                                       
            this.setState({
              newequipmentmodal: !this.state.newequipmentmodal,                          
            }); 
          
            // let order = {};            
            // order.assetid = this.state.assetid;
            // order.assetdescription = this.state.assetdescription;
            // order.assetmodel = this.state.assetmodel;
            // order.assetmanufacturer = this.state.assetmanufacturer;
            // order.assetserial = this.state.assetserial;
            // order.assetnumber = this.state.assetnumber;
            // let id = this.state.id;
            // servicerequestService.updateServiceRequest(order, id).then(ord => {
            //   if (ord && ord === 401) {
            //     var { from } = this.props.location.state || { from: { pathname: "/login" } };  
            //     this.props.history.push(from);
            //   } else {
            //     this.setState({
            //       orderequips: this.getEquipments(),
            //     });
            //     this.setState({cardbody: this.getEquipmentBody()});
            //   }
            // });
            
          }
        });
  }

  setEquipment = (c) => {
    this.setState({
      selectassetid: c.id,
      selectassetdescription: c.description,
      selectassetmodel: c.model,
      selectassetserial: c.serial_no,
      selectassetmanufacturer: c.manufacturer,
      selectassetlotnumber: c.lotnumber,
      selectassetnumber: c.assetnumber,
    });
  }

  handleSelectEquipmentWithBackendCall = (event) => {
    if (this.state.status && (this.state.status === 5 || this.state.status === 99)) {
      alert('You cannot edit asset and material for a closed order');
    } else {
      assetService.getAssetList(this.state.customerid).then(assets => {
        this.setState({
          selectequipmodal: !this.state.selectequipmodal,
          equipobjs: assets
        });
      })
    }
  }

  handleSelectEquipment = (event) => {
    //event.preventDefault();
    if (!this.state.customerid) {
        alert('Select a Customer before selecting equipment for this service request');
    } else {
      // if this asset is already linked to this order then do nothing
      if (this.state.orderequips && this.state.orderequips.length > 0) {
        for (let i=0; i<this.state.orderequips.length; i++) {
          if (Number(this.state.orderequips[i].id) === Number(this.state.selectassetid)) {
            this.setState({
              selectequipmodal: !this.state.selectequipmodal,              
            });
            return;
          }
        }
      }
      //alert('handleSelectEquipment...');
      let order = {};
      order.assetid = this.state.selectassetid;
      order.assetdescription = this.state.selectassetdescription;
      order.assetmodel = this.state.selectassetmodel;
      order.assetserial = this.state.selectassetserial;
      order.assetmanufacturer = this.state.selectassetmanufacturer;
      order.assetlotnumber = this.state.selectassetlotnumber;
      order.assetnumber = this.state.selectassetnumber;
      //console.log('linking asset to order...');
      assetService.linkAssetToOrder(this.state.selectassetid, this.state.id)
          .then(ord => {
            if (ord && ord === 401) {
              this.props.history.push("/login");
            } else {
              this.getEquipmentsAndBody();
              this.setState({
                selectequipmodal: !this.state.selectequipmodal,
              });             
            }
          });
    }
  }

  handleSelectEquipmentClose = (event) => {
    this.setState({
      selectequipmodal: !this.state.selectequipmodal
    });
  }

  handleBillingSelect = (e) => {
    this.state.selectcustaddr = 'billing';
    this.handleSelectAddressWithBackendCall();
  }

  handleSiteSelect = (e) => {
    this.state.selectcustaddr = 'site';
    this.handleSelectAddressWithBackendCall();
  }

  handleSelectAddressWithBackendCall = (event) => {
    if (!this.state.customerid) {
      //alert('Select a Customer before selecting equipment for this service request');
    } else {
      customerService.getAddressList(this.state.customerid).then(addrs => {
        if (addrs && addrs === 401) {
          this.props.history.push("/login");
        } else {
          ////console.log(addrs);
                            
          for (let i=0; i<addrs.length; i++) {            
            addrs[i].address = (addrs[i].addr1 ? (addrs[i].addr1 + ", ") : '') + (addrs[i].city ? addrs[i].city + ', ': '') + 
                  (addrs[i].state ? addrs[i].state + ' ': '') +
                  (addrs[i].zip ? addrs[i].zip: '');          
            addrs[i].fullname =  (addrs[i].fname ? addrs[i].fname + ' ': '') + (addrs[i].lname ? addrs[i].lname : '');     
            
          }
          this.setState({ addrobjs: addrs });
          ////console.log(this.state.addrobjs);
          this.setState({
            selectaddressmodal: !this.state.selectaddressmodal
          });
        }
      });     
    }
  }

  handleSelectAddress = (event) => {
    if (this.state.selectcustaddr === 'billing') {
      this.handleBillingSelectAddress();
    } else {
      this.handleSiteSelectAddress();
    }
  }

  handleBillingSelectAddress = (event) => {
    //event.preventDefault();
    if (!this.state.customerid) {
      //alert('Select a Customer before selecting equipment for this service request');
    } else {

      let order = {};
      order.billingaddrid = this.state.selectedaddress.id;
            
      servicerequestService.updateServiceRequest(order, this.state.id)
          .then(ord => {
            if (ord && ord === 401) {
              this.props.history.push("/login");
            } else {
              this.setState({
                billingaddrid: order.billingaddrid,
                selectaddressmodal: !this.state.selectaddressmodal
              });  
              this.setBillingAddr(this.state.selectedaddress);
              this.state.selectedaddress = undefined;
            }
          });
    }
  }
  
  handleSiteSelectAddress = (event) => {
    //event.preventDefault();
    if (!this.state.customerid) {
      //alert('Select a Customer before selecting equipment for this service request');
    } else {

      let order = {};
      order.siteaddrid = this.state.selectedaddress.id;
            
      servicerequestService.updateServiceRequest(order, this.state.id)
          .then(ord => {
            if (ord && ord === 401) {
              this.props.history.push("/login");
            } else {
              this.setState({
                siteaddrid: this.state.siteaddrid,
                selectaddressmodal: !this.state.selectaddressmodal
              });  
              this.setSiteAddr(this.state.selectedaddress);            
              this.state.selectedaddress = undefined;
            }
          });
    }
  }

  handleSelectAddressClose = (event) => {
    this.setState({
      selectaddressmodal: !this.state.selectaddressmodal
    });
  }
  
  handleEditBillingAddrModal = (event) => { 
    //alert('handleEditBillingAddrModal....' + this.state.billingaddrid);   
    customerService.getAddress(this.state.billingaddrid).then(addr => {
      if (addr && addr === 401) {
        this.props.history.push("/login");
      } else {
        this.setState({
          addrid: this.state.billingaddrid,
          addrfname: addr.fname, 
          addrlname: addr.lname, 
          addremail: addr.email, 
          addrphone: addr.phone, 
          addraddr1: addr.addr1, 
          addraddr2: addr.addr2, 
          addrcity: addr.city, 
          addrstate: addr.state, 
          addrzip: addr.zip, 
          addrisacontact: addr.is_a_contact,
          addrsavemodal: !this.state.addrsavemodal,
        });
      }
    });
  }

  handleEditSiteAddrModal = (event) => {
    customerService.getAddress(this.state.siteaddrid).then(addr => {
      if (addr && addr === 401) {
        this.props.history.push("/login");
      } else {
        this.setState({
          addrid: this.state.siteaddrid,
          addrfname: addr.fname, 
          addrlname: addr.lname, 
          addremail: addr.email, 
          addrphone: addr.phone, 
          addraddr1: addr.addr1, 
          addraddr2: addr.addr2, 
          addrcity: addr.city, 
          addrstate: addr.state, 
          addrzip: addr.zip, 
          addrisacontact: addr.is_a_contact,
          addrsavemodal: !this.state.addrsavemodal,
        });
      }
    });
  }

  handleAddrModalChange = (event) => {
    this.setState({   
      addrid: undefined,
      addrfname: undefined, 
      addrlname: undefined, 
      addremail: undefined, 
      addrphone: undefined, 
      addraddr1: undefined, 
      addraddr2: undefined, 
      addrcity: undefined, 
      addrstate: undefined, 
      addrzip: undefined,   
      addrisacontact: false,
      addrsavemodal: !this.state.addrsavemodal
    });
  }

  handleAddrSaveModal = (event) => {
    this.setState({
      addrsavemodal: !this.state.addrsavemodal
    });
  }

  handleAddressSaveSubmit = (event) => {
    
    if (this.state.addrid) {
      this.updateAddress();
    } else {
      this.addNewAddress();
    }
    this.setState({
      addrsavemodal: !this.state.addrsavemodal
    });
  }

  updateAddress = () => {
    ////alert('updateAddress...')
    let address = {};
    address.id = this.state.addrid;
    address.fname = this.state.addrfname;
    address.lname = this.state.addrlname;
    address.email = this.state.addremail;
    address.phone = this.state.addrphone;
    address.addr1 = this.state.addraddr1;
    address.addr2 = this.state.addraddr2;
    address.city = this.state.addrcity;
    address.state = this.state.addrstate;
    address.zip = this.state.addrzip;
    address.is_a_contact = this.state.addrisacontact;

    customerService.updateAddress(address).then(addr => {
      if (addr && addr === 401) {
        this.props.history.push("/login");
      } else {
        // initialize the address list
        this.loadServiceOrder(this.state.id);
      }
    });
  }

  addNewAddress = () => {
    ////alert('addAddress..................>>')
    let address = {};
    address.clientid = this.state.customerid;
    address.fname = this.state.addrfname;
    address.lname = this.state.addrlname;
    address.email = this.state.addremail;
    address.phone = this.state.addrphone;
    address.addr1 = this.state.addraddr1;
    address.addr2 = this.state.addraddr2;
    address.city = this.state.addrcity;
    address.state = this.state.addrstate;
    address.zip = this.state.addrzip;
    address.is_a_contact = this.state.addrisacontact;

    customerService.addAddress(address).then(addr => {
      if (addr && addr === 401) {
        this.props.history.push("/login");
      } else {
      }
    });
  }

  deleteOrder = (e) => {    
    let order = {};
    order.id = this.state.id;
    order.deleted = true;
    servicerequestService.updateServiceRequest(order, this.state.id)
          .then(ord => {
            if (ord && ord === 401) {
              this.props.history.push("/login");
            } else {
              var { from } = this.props.location.state || { from: { pathname: "/services" } };
              this.props.history.push(from);
            }
      });
  }

  handleContactCheckBox = () => {
    this.setState({addrisacontact: !this.state.addrisacontact});
  }

  resourceDatail = () => {
    var { from } = this.props.location.state || { from: { pathname: "/resourcesforservice/" + this.state.id} };  
    this.props.history.push(from);
  }

  // getPriorityLabe = () => {
  //   if (this.getPriorityName(this.state.priority) === 'High') {
  //     return (
  //         <span className="high">High</span>                  
  //     )
  //   } else if (this.getPriorityName(this.state.priority) === 'Medium') {
  //     return (
  //         <span className="medium">Medium</span>
  //     );            
  //   } else if (this.getPriorityName(this.state.priority) === 'Low') {
  //     return (
  //         <span className="low">Low</span>
  //     );            
  //   } else {
  //     return (
  //       <span>NA</span>
  //     );
  //   }
  // }

  getDefaultCardBody() {
    return (<>
      {this.state.status != 99 && this.state.status != 5 &&
      <Button variant="outline-primary mb-1"  size="sm" onClick={this.handleOrderModal}>edit</Button>               
      }
      <Card.Text className="textunderline">{this.state.detail}</Card.Text>
      
      <div class="row" >
        <div class="col-md-4">
          <Card.Text>
          <div class="textgroup">
              <span class="textlabel">Status</span>
              <div className="textunderline" style={{fontWeight: 'bold'}}>{this.getStatusName(this.state.status)}</div>
            </div>
            <div class="textgroup">
              <span class="textlabel">Category</span>
              <div className="textunderline">{this.state.problemtype ? this.state.problemtype : 'NA'}</div>
            </div>
            <div class="textgroup">
              <span class="textlabel">Type</span>
              <div className="textunderline">{this.state.type ? this.state.type : 'NA'}</div>
            </div>
            <div class="textgroup">
              <span class="textlabel">Priority</span>
              <div className="textunderline">{this.getPriority(this.state.priority)}</div>
            </div>  
            {/* <Button variant="link"   style={{marginLeft: '-12px'}}  onClick={this.handleOrderModal}>edit</Button>           */}
          </Card.Text>
        </div>
        <div class="col-md-4">
          <Card.Text>
            <div class="textgroup">
              <span class="textlabel">Start Date</span>
              <div className="textunderline">{this.getLocalDate(this.state.startdate)}</div>
            </div>
            <div class="textgroup">
              <span class="textlabel">End Date</span>
              <div className="textunderline">{this.getLocalDate(this.state.enddate)}</div>
            </div>
            <div class="textgroup">
              <span class="textlabel">Scheduled for</span>
              <div className="textunderline" style={{fontWeight: 'bold'}}>{this.state.scheduledate ? util.utcToLocalDateTime(this.state.scheduledate): 'NA'}</div>
            </div>
            <div class="textgroup">
              <span class="textlabel">Leader/Resources</span>
              <div className="textunderline">{(this.state.resource && this.state.resource != " ")? util.wrapText(this.state.resource, 16) : 'NA'}
                  {this.state.status != 99 && this.state.status != 5 &&
                  <Button variant="link"  style={{float: 'right', marginTop: '-6px'}} onClick={this.resourceDatail}>                      
                      <FontAwesomeIcon icon={faEdit} className="mr-1 mb-4"/>
                  </Button>
                  }
              </div>
            </div>
          </Card.Text>
        </div> 
        <div class="col-md-4">
          <Card.Text>
          <div class="textgroup">
              <span class="textlabel">Notes</span>
              <div className="textunderline">{this.state.instruction ? this.state.instruction : 'NA'}</div>
            </div>
            <div class="textgroup">
              <span class="textlabel">Skills</span>
              <div className="textunderline">{this.state.skilldisplay}</div>
            </div>
          </Card.Text>
        </div>           
      </div>
      
      </>);
  }

  handleAddLineItem = (event) => {   
    if (this.state.status && (this.state.status === 5 || this.state.status === 99)) {
      alert('You cannot edit asset and material for a closed order');
    } else {
      var { from } = this.props.location.state || { from: { pathname: "/materialitem/" + this.state.id} };  
      this.props.history.push(from);  
    }
  }


  handleSelectedLineItem = (row) => {
    
    if (this.state.status && (this.state.status === 5 || this.state.status === 99)) {
      alert('You cannot edit asset and material for a closed order');
    } else {
      var { from } = this.props.location.state || { from: { pathname: "/materialitem/" + this.state.id + '/material/' +  row.id} };  
      this.props.history.push(from);  
    }
  }

  handleFind = (row) => {
    
    if (this.state.status && (this.state.status === 5 || this.state.status === 99)) {
      alert('You cannot edit asset and material for a closed order');
    } else {
      var { from } = this.props.location.state || { from: { pathname: "/vendormaterial/" + row.id + '/item/' +  row.itemid} };  
      this.props.history.push(from);  
    }
  }

  takePicture () {
    this.props.history.push('/pictureord/' + this.state.id + '/client/' + this.state.customerid, '', this.state) ;               
  }

  deleteImage(file) {
    uploadService.deleteFileOrder(file, this.state.id, this.state.customerid, 'img').then(dataobjs => {
      if (dataobjs && dataobjs === 401) {
        this.props.history.push("/login");
      } else {                      
          this.loadServiceOrder(this.state.id);
          this.getPictureBody();
      }
    });
  }

  getPictureBody() {
    let pics = (<>
      {this.state.status != 99 && this.state.status != 5 &&
      <Button variant="outline-primary mb-1" size="sm" onClick={this.takePicture.bind(this)}>take picture</Button>
      }
      <Card.Text>No picture is found</Card.Text>      
      </>);
    if (this.state.picture && this.state.picture != null) {
      var tmp = this.state.picture.split(',');
      pics = (<>
        {this.state.status != 99 && this.state.status != 5 &&
        <Button variant="outline-primary mb-1" size="sm" onClick={this.takePicture.bind(this)}>take picture</Button>
        }
        <div className="row">
        {tmp.map(listitem => (
          <div class="img-wrap">
          {this.state.status != 99 && this.state.status != 5 &&  
          <Button variant="link" onClick={() => {if(window.confirm('Remove the picture?')){this.deleteImage(listitem)};}}>
          <span class="close">&times;</span>
          </Button>
          }
          <a href={process.env.PUBLIC_URL + '/img/' + this.state.companyid + '/' + this.state.customerid + '/' + listitem}>
          <img width={191} className="mb-1" style={{marginLeft: '-10px'}} src={process.env.PUBLIC_URL + '/img/' + this.state.companyid + '/' + this.state.customerid + '/' + listitem} alt="asset pic"/>                  
          </a>
          </div>
      ))}
        </div>        
      </>);
    }
    return pics;
  }

  buildPoLineItem(mat) {
    let item = {};

    item.item = mat.item;
    item.itemId = mat.itemid;
    item.description = mat.description;
    if (mat.venditemdesc && mat.venditemdesc.length > 0) {
      item.description = mat.venditemdesc;
    }
    item.qty = mat.qty;
    item.unitPrice = mat.vendorprice;
    item.vendorItemCode = mat.vendorcode;
    item.discount = "0.0%";
    item.tax1 = 0;
    item.tax2 = 0;

    return item;
  }

  buildPo(mat) {
    let po = {};         
    po.currency = mat.currency; ////need this
    po.clientid = mat.vendorid;
    po.clientName = mat.name;
    po.ordertotal = 0.0;
    //po.requestedDate = new Date();
    po.status = 1; //DRAFT
    //po.vendorAddrId = mat.vendoraddressid; ////need this
    po.conversionRate = 1.0;
    //po.conversionDate = new Date();
    po.totalDiscount = 0.0;
    po.totalTax = 0.0;
    po.lumsumTax = false;
    po.lumsumDiscount = false;
    if (this.state.costcenter && this.state.costcenter.length > 0) {
      po.billingDept = this.state.costcenter;
    }
    return po;
      
  }

  setPurchaseOrderInMaterial(mat, po) { 
      //console.log(po);   
      if (po && po.lineItem) {        
        for (let b=0; b<po.lineItem.length; b++) {
          let itmcode = po.lineItem[b].item;
          for (let c=0; c<mat.length; c++) {
            if (itmcode === mat[c].item && Number(po.clientid) === Number(mat[c].vendorid)) {
              mat[c].iteminpo = true;              
              mat[c].poid = po.id;
              mat[c].ponum = po.poNum;
            }
          }
        }
      } 
      if (po) {
          for (let c=0; c<mat.length; c++) {
            //console.log(mat[c].poid, po.clientid, mat[c].vendorid);
            if (!mat[c].poid && Number(po.clientid) === Number(mat[c].vendorid)) {
              mat[c].poid = po.id;
              mat[c].ponum = po.poNum;
            }
          }
        }
  }

  compare( a, b ) {
    if ( a.vendorid < b.vendorid ){
      return -1;
    }
    if ( a.vendorid > b.vendorid ){
      return 1;
    }
    return 0;
  }

  addItemInPo = (ll, poid) => {
    let addmaterialtopo = [];
    //console.log('processPo ll.length', ll.length);
    for (let j=0; j<ll.length; j++) {
      //console.log('processPo ll[j].iteminpo', ll[j].iteminpo);
      if (!ll[j].iteminpo || ll[j] === false) {
        addmaterialtopo.push(ll[j]);
      }
    }

    //console.log('processPo addmaterialtopo.length', addmaterialtopo.length);
    if (addmaterialtopo.length > 0) {
      // add one by one all material items in to the poid
      
      for (let p=0; p<addmaterialtopo.length; p++) {
        let litem = this.buildPoLineItem(addmaterialtopo[p]);
        litem.poId = poid;
        //console.log('litem', litem);
        purchaseService.addOrUpdatePoLineItem(poid, litem)
            .then(ord => {
              if (ord && ord === 401) {
                this.props.history.push("/login");
              } else {   
                this.getMaterials();                   
              }
        });              
      }
      //refresh the page...
      this.getMaterials();
    }
  }

  createPo = (ll) => {
    let po = this.buildPo(ll[0]);
    customerService.getAddressList(po.clientid).then(addrs => {
      if (addrs && addrs.length > 0) {
        po.vendorAddrId = addrs[0].id;
        //console.log(po);
        purchaseService.addNewPo(po, this.state.id)
        .then(ord => {
              if (ord && ord === 401) {
                this.props.history.push("/login");
              } else {  
                //console.log(ord)
                this.addItemInPo(ll, ord.id);            
                //this.getMaterials();
              }
        });              
      }
    });    
  }

  processPo = (vendors) => {
      //console.log('processPo');
      vendors.sort(this.compare)
      // create one list for each vendor
      let vl = [];
      let vlist = [];
      vlist.push(vendors[0]);
      if (vendors.length > 1) {
        let flag = false;
        for (let i=1; i<vendors.length; i++) {
          if (vendors[i].vendorid ===  vendors[i-1].vendorid) {
            vlist.push(vendors[i]);
          } else {
            vl.push(vlist);
            vlist = [];
            flag = true;
          }
        }
        if (flag === false) {
          vl.push(vlist);
        }
      } else {
        vl.push(vlist);
      }
      
      // Now check whether any items on this vendor already has a PO
      for (let i=0; i<vl.length; i++) {
        let ll = vl[i];
        let anypo = false;
        let poid = undefined;
        for (let j=0; j<ll.length; j++) {
          if (ll[j].poid) {
            anypo = true;
            poid = ll[j].poid;
            break;
          }
        }
        //console.log('processPo anypo', anypo);
        if (anypo === true) {          
          this.addItemInPo(ll, poid);
        } else {                    
          this.createPo(ll);           
        }
      }
  } 

  handleAddItemInPO = (matitm) => {
    //console.log('handleAddItemInPO', matitm);
    if (!matitm.vendorid) {
      alert("First find a vendor and assign it to a material. Then you can add it to purchase order.");
    } else if (matitm.iteminpo === true) {
      alert("This material is already part of PO: " + matitm.ponum);
    } else {
      let vendors = [];
      vendors.push(matitm);
      this.processPo(vendors); 
    }
  }

  handlePurchaseOrderCreate = () => {
    if (this.state.status != 99 && this.state.status != 5)
    {
      alert("You can't create Purchase Order in a closed order. First re-open the order!");
      return;
    }
    let vendors = [];
    //console.log('lineobjs', this.state.lineobjs)
    //console.log('lineobjs length', this.state.lineobjs.length)
    for (let i=0; i<this.state.lineobjs.length; i++) {
      //console.log(this.state.lineobjs[i].vendorid);
      if (this.state.lineobjs[i].vendorid && this.state.lineobjs[i].vendorid !== null) { 
        //console.log('pushed...');    
        vendors.push(this.state.lineobjs[i]);
      }
    }

    if (vendors.length === 0) {
      alert("First find a vendor and assign it to a material. Then you can create purchase order.");
    } else {
      // 1. check purchase orders are already created for all the materials.
      let allposdone = true;
      for (let i=0; i<vendors.length; i++) {
        if (!vendors[i].iteminpo) {
          allposdone = false;
        }
      }
      if (allposdone === true) {
        alert('Purchase orders are already created for all materials where vendors are assigned.');
      } else {
        // get PO for all this service order 
        this.processPo(vendors);                 
      }       
    }
  }

  handleOpenPO = (row) => {
    var { from } = this.props.location.state || { from: { pathname: "/eipurchaseorder/" + row.poid + '/serviceid/' + this.state.id} };  
    this.props.history.push(from);
  }

  getMaterialBody() {
    return (<>
        <div className="h6 small">
              <div style={{float: "left"}} > 
              {this.state.status != 99 && this.state.status != 5 &&
                <Button variant="outline-primary mb-1" size="sm" onClick={this.handleAddLineItem} >add material</Button>                 
              }
              </div>
              <BootstrapTable
                keyField='id' s
                data={ this.state.lineobjs }
                columns={ this.state.linecolumns }
                //selectRow={ this.state.selectLineRow }
              />              
              </div>
    </>)
  }

  getEquipmentBody() {
    this.state.selectedassetid = undefined;
    return (  <>   

          {this.state.status != 99 && this.state.status != 5 &&  
          <>
          <Button variant="outline-primary mb-3 mr-4"   size="sm" onClick={this.handleSelectEquipmentWithBackendCall}>link existing equipment from inventory</Button>               
          <Button variant="outline-primary mb-3"   size="sm" onClick={this.handleAddNewEquipment}>add new equipment for this customer</Button>
          </>
          }
           { (this.state.orderequips && this.state.orderequips.length > 0) &&
                        
          <Table  bordered  size="sm">
            <thead>
              <tr style={{height: '42px'}}>
                <th style={{verticalAlign: 'middle', backgroundColor: '#edeff1', fontSize: '0.75rem'}}>Description</th>
                <th style={{verticalAlign: 'middle', backgroundColor: '#edeff1', fontSize: '0.75rem'}}>Tag #</th>
                <th style={{verticalAlign: 'middle', backgroundColor: '#edeff1', fontSize: '0.75rem'}}>Model #</th>
                <th style={{verticalAlign: 'middle', backgroundColor: '#edeff1', fontSize: '0.75rem'}}>Lot #</th>
                <th style={{verticalAlign: 'middle', backgroundColor: '#edeff1', fontSize: '0.75rem'}}>Serial #</th>
                <th style={{verticalAlign: 'middle', backgroundColor: '#edeff1', fontSize: '0.75rem'}}>Maker</th>                
                <th style={{verticalAlign: 'middle', backgroundColor: '#edeff1', fontSize: '0.75rem'}}>Warranty Exp.</th>                
                <th style={{verticalAlign: 'middle', backgroundColor: '#edeff1', fontSize: '0.75rem'}}>Locatioon</th>                
                <th style={{verticalAlign: 'middle', backgroundColor: '#edeff1', fontSize: '0.75rem'}}></th>
              </tr>
            </thead>
            <tbody>
            {  
            this.state.orderequips.map((e, idx) => (
              <tr key={e.id}>
                <td style={{verticalAlign: 'middle', fontSize: '0.75rem'}}>{e.description}</td>
                <td style={{verticalAlign: 'middle', fontSize: '0.75rem'}}>{e.assetnumber}</td>
                <td style={{verticalAlign: 'middle', fontSize: '0.75rem'}}>{e.model}</td>
                <td style={{verticalAlign: 'middle', fontSize: '0.75rem'}}>{e.lotnumber}</td>
                <td style={{verticalAlign: 'middle', fontSize: '0.75rem'}}>{e.serial_no}</td>
                <td style={{verticalAlign: 'middle', fontSize: '0.75rem'}}>{e.manufacturer}</td> 
                <td style={{verticalAlign: 'middle', fontSize: '0.75rem'}}>{e.warrantyexpiration}</td>
                <td style={{verticalAlign: 'middle', fontSize: '0.75rem'}}>{e.location}</td>                
                <td style={{verticalAlign: 'middle', fontSize: '0.75rem'}}>    
                  {this.state.status != 99 && this.state.status != 5 &&   
                  <>           
                  <Button variant="link"  style={{marginLeft: '-12px'}}  onClick={() => { this.state.selectedassetid = e.id; this.handleAddNewEquipment() }}>edit</Button>                                                      
                  <Button variant="link"  style={{marginLeft: '-12px'}} onClick={() => {if(window.confirm('Remove the item?')){this.removeEquipmentFromOrder(e.id)};}}>de-link</Button>                  
                  </>
                  }
                </td>
              </tr>
            ))}
            </tbody>
          </Table>   
          }     
      </>
      );
  }

  getEquipmentBodyOrig() {
    return (<>
      
      <div class="row" >
        <div class="col-md-4">
          <Card.Text>
            <div class="textgroup">
              <span class="textlabel">Description</span>
              <div className="textunderline">{this.state.assetdescription ? this.state.assetdescription : 'NA'}</div>
            </div>
            <div class="textgroup">
              <span class="textlabel">Serial #</span>
              <div className="textunderline">{this.state.assetserial ? this.state.assetserial : 'NA'}</div>
            </div>
          </Card.Text> 
        </div>
        <div class="col-md-4">
          <Card.Text> 
            <div class="textgroup">
              <span class="textlabel">Asset Tag #</span>
              <div className="textunderline">{this.state.assetnumber ? this.state.assetnumber : 'NA'}</div>
            </div>
            <div class="textgroup">
              <span class="textlabel">Model</span>
              <div className="textunderline">{this.state.assetmodel ? this.state.assetmodel : 'NA'}</div>
            </div>
          </Card.Text>
        </div>  
        <div class="col-md-4">
          <Card.Text>             
            <div class="textgroup">
              <span class="textlabel">Manufacturer</span>
              <div className="textunderline">{this.state.assetmanufacturer ? this.state.assetmanufacturer : 'NA'}</div>
            </div>
          </Card.Text>
        </div>
        <div class="col-md-4">
          <Card.Text>             
            <div class="textgroup">
              <span class="textlabel">Lot #</span>
              <div className="textunderline">{this.state.assetlotnumber ? this.state.assetlotnumber : 'NA'}</div>
            </div>
          </Card.Text>
        </div>        
      </div>
      <span style={{float: 'left'}}>
      {this.state.assetid && 
      <Button variant="outline-primary" size="sm"   onClick={this.handleAddNewEquipment}>edit</Button>
      }
      {this.state.assetid && 
      <Button variant="outline-primary"  size="sm"  onClick={() => {if(window.confirm('Remove the item?')){this.removeEquipmentFromOrder()};}}>remove from this order</Button>
      }
      {!this.state.assetid && 
      <Button variant="outline-primary" size="sm"  onClick={this.handleSelectEquipmentWithBackendCall}>select existing equipment</Button>
      }
      {!this.state.assetid && 
      <Button variant="outline-primary" size="sm"  onClick={this.handleAddNewEquipment}>add new equipment for this customer</Button>
      }
      </span>
      {/* <Card.Link href="#" style={{color: 'blue'}}>Edit</Card.Link>
      <Card.Link onClick={this.handleAddNewEquipment} style={{color: 'blue'}}>Add New</Card.Link> */}
      </>);
  }
  
  getSalesOrderBody() {
    
    servicerequestService.getSalesOrders(this.state.id)
      .then(data => {
        //alert(JSON.stringify(data));
        
        let so = (<>
          {this.state.status != 99 && this.state.status != 5 &&
          <Button variant="outline-primary mb-1"   size="sm" onClick={this.createNewEISorder}>add new</Button>
          }
          <Card.Text>No sales order is found</Card.Text>          
          </>);

        if (data && data.length > 0) {
          let tmp = [];
          let url = [];
          for (let i=0; i<data.length; i++) {
            tmp.push(data[i].docnumber);
            url.push("/eisordercreate/" + data[i].docid + '/serviceid/' + this.state.id);
          }
          let i = 0;
          so = (<>
            {/* {this.state.status != 99 && this.state.status != 5 &&
            <Button variant="outline-primary"  size="sm" onClick={this.createNewEISorder}>add new</Button>
            } */}
            <ListGroup variant="flush">
            {tmp.map(listitem => (
              <ListGroup.Item><a href={url[i++]} style={{color: 'blue', marginLeft: '-16px', fontSize: '12px'}}>{listitem}</a></ListGroup.Item>                  
          ))}
            </ListGroup>
            
            </>);
        }
        this.setState({
            cardbody: so
        })
    });
    
  }

  editActivity = (id) =>
  {
    var { from } = this.props.location.state || { from: { pathname: "/serviceactivity/" + this.state.id + '/activity/' + id} };  
    this.props.history.push(from);
  }

  addNewActivity = () => {
    var { from } = this.props.location.state || { from: { pathname: "/serviceactivity/" + this.state.id } };  
    this.props.history.push(from);
  }

  getActivityBody() {
    activityService.getAllActivity(this.state.id)
    .then(data => {
      //console.log(data);

      if (data && data.length > 0) {
        let activities = [];
        for (let i=0; i<data.length; i++) {
          if (data[i].type === 1 || data[i].type === 2 ||  data[i].type === 3 || data[i].type === 4 || data[i].type === 5) {
            let t = 'Closed';
            if (data[i].type === 1) {
              t = 'Service note';
              if (data[i].heading && data[i].heading.length > 0) {
                t = data[i].heading;
              }
            }
            else if (data[i].type === 2) {
              t = 'Customer note';
            } else if (data[i].type === 3) {
              t = 'Other Note';
            } else if (data[i].type === 5) {
              t = 'Re-opened';
            } 
            let a = {};
            a.type = t;
            a.date = (data[i].updatedAt ? utility.utcToLocalDateTime(data[i].updatedAt) : '');
            a.user = data[i].userwholastupdated;
            a.detail = data[i].detail;  
            if (data[i].type != 5) {       
            a.edit = <Button variant="link" style={{marginTop: '-8px'}} onClick={() => {this.editActivity(data[i].id)}} ><EditIcon fontSize="small" /></Button>;            
            }
            activities.push(a)           
          }
        }
        let ac = (<>
          {this.state.status != 99 && this.state.status != 5 &&
          <Button variant="outline-primary mb-1" size="sm" onClick={this.addNewActivity}>add new</Button>
          }
          <div style={{overflowY: 'scroll'}}>          
            {activities.map(it => (
              <div>
                <div style={{fontWeight: 'bold'}}>{it.type}</div>
                <div>{it.date} by {it.user}</div>
                {this.state.status != 99 && this.state.status != 5 &&
                <div style={{fontStyle: 'italic'}}>{it.detail}  {it.edit}</div>
                }
                {(this.state.status === 99 || this.state.status === 5) &&
                <div style={{fontStyle: 'italic'}}>{it.detail} </div>
                }
                <hr></hr>
              </div>
            ))}
            <div>
            
            </div>
          </div></>);
          this.setState({
            cardbody: ac
        });         
      } else {
        let ac = (
          <>
          {this.state.status != 99 && this.state.status != 5 &&
          <Button variant="outline-primary mb-1" size="sm" onClick={this.addNewActivity}>add new</Button>
          }
          <div style={{overflowY: 'scroll'}}>
            
            No activities found!
            <div>
            
            </div>
          </div></>);  
          this.setState({
            cardbody: ac
        });        
      }
    });
    let ac = (
      <div style={{overflowY: 'scroll'}}>
        No activities found!
        <div>
        {this.state.status != 99 && this.state.status != 5 &&  
        <Button variant="link"  style={{marginLeft: '-12px'}} onClick={this.addNewActivity}>add new</Button>
        }
        </div>
      </div>);    
      this.setState({
        cardbody: ac
    });  
  }

  getInvoiceBody() {
    servicerequestService.getInvoices(this.state.id)
      .then(data => {
        //alert(JSON.stringify(data));
        
        let so = (<>
          {this.state.status != 99 && this.state.status != 5 &&
          <Button variant="outline-primary mb-1"  size="sm" onClick={this.createNewInvoice}>add new</Button>
          }
          <Card.Text>No invoice is found</Card.Text>          
          </>);

        if (data && data.length > 0) {
          let tmp = [];
          let url = [];
          for (let i=0; i<data.length; i++) {
            tmp.push(data[i].docnumber);
            url.push("/ecinvoice/" + data[i].docid + '/serviceid/' + this.state.id);
          }
          let i = 0;
          so = (<>
            {this.state.status != 99 && this.state.status != 5 &&
            <Button variant="outline-primary mb-1"  size="sm" onClick={this.createNewInvoice}>add new</Button>
            }
            <ListGroup variant="flush">
            {tmp.map(listitem => (
              <ListGroup.Item><a href={url[i++]} style={{color: 'blue', marginLeft: '-16px', fontSize: '12px'}}>{listitem}</a></ListGroup.Item>                  
          ))}
            </ListGroup>
            
            </>);
        }
        this.setState({
            cardbody: so
        })
    });
  }

  getQuoteBody() {
    servicerequestService.getQuotes(this.state.id)
      .then(data => {
        //alert(JSON.stringify(data));
        
        let so = (<>
          {this.state.status != 99 && this.state.status != 5 &&
          <Button variant="outline-primary mb-1"  size="sm" onClick={this.createNewQuote}>add new</Button>
          }
          <Card.Text>No quote is found</Card.Text>          
          </>);

        if (data && data.length > 0) {
          let tmp = [];
          let url = [];
          for (let i=0; i<data.length; i++) {
            tmp.push(data[i].docnumber);
            url.push("/ecquote/" + data[i].docid + '/serviceid/' + this.state.id);
          }
          let i = 0;
          so = (<>
            {this.state.status != 99 && this.state.status != 5 &&
            <Button variant="outline-primary mb-1" size="sm" onClick={this.createNewQuote}>add new</Button>
            }
            <ListGroup variant="flush">
            {tmp.map(listitem => (
              <ListGroup.Item><a href={url[i++]} style={{color: 'blue', marginLeft: '-16px', fontSize: '12px'}}>{listitem}</a></ListGroup.Item>                  
          ))}
            </ListGroup>
            
            </>);
        }
        this.setState({
            cardbody: so
        })
    });
  }

  getSignBody = () => {
    signService.getSignature(this.state.id)
      .then(data => {
        if (data && data === 401) {
          this.props.history.push("/login");
        } else if (data && data.id) {
          //console.log(data.signature);
          let so = (<>
            {this.state.status != 99 && this.state.status != 5 &&
            <Button variant="outline-primary mb-1"  size="sm" onClick={this.sign}>re-sign</Button>
            }
            <Card.Text>Signed date {moment(data.signaturedate).format('ll')}</Card.Text>
            <div><img src={data.signature} width="300px"/></div>          
            </>);
          this.setState({
            cardbody: so
          })
        } else {
          this.setState({
            cardbody: <Button variant="outline-primary mb-1"  size="sm" onClick={this.sign}>sign</Button>
          })          
        }
    });
  }

  sign = () => {
    var { from } = this.props.location.state || { from: { pathname: "/sign/" + this.state.id} };  
    this.props.history.push(from);
  }

  navSelected = (e) => {
      ////alert(e);
      //////console.log(e);      
      if (e === '#activity') {
        this.setState({
          cardbody: this.getActivityBody()
        });
      } else if (e === '#pic') {
        this.setState({
          cardbody: this.getPictureBody()
        });
      }  else if (e === '#equip') {
        this.setState({
          cardbody: this.getEquipmentBody()
        });
      } else if (e === '#estimate') {
        this.setState({
          cardbody: this.getQuoteBody()
        });
      } else if (e === '#invoice') {
        this.setState({
          cardbody: this.getInvoiceBody()
        });
      } else if (e === '#material') {        
          this.getMaterials();        
      } else if (e === '#salesorder') {
        // this.setState({
        //   cardbody: this.getSalesOrderBody()
        // });
        this.getSalesOrderBody()
      } else if (e === '#signature') {
        // this.setState({
        //   cardbody: this.getSalesOrderBody()
        // });
        this.getSignBody();
      } else {
        this.setState({
          cardbody: this.getDefaultCardBody()
        });
      }
  }

  closeAndRescheduleOrder = () => {
    var { from } = this.props.location.state || { from: { pathname: "/serviceclose/" + this.state.id} };  
    this.props.history.push(from);
  }

  reopenOrder = () => {
    let order = {};
    order.id = this.state.id;
    order.status = 3;

    servicerequestService.updateServiceRequest(order, this.state.id).then( ord => {
      if (ord && ord === 401) {
        this.props.history.push("/login");
      } else {    
        let user = JSON.parse(localStorage.getItem('user'));
        let activity = {
          "serviceid": this.state.id,
          "type": 5,
          "heading": "Re-opened",
          "detail" : 'Re-opened this order.',
          "closeddate": new Date(),
          "userwholastupdated": user.username
        } 
        activityService.createActivity(activity).then( ord => {
          if (ord && ord === 401) {
            this.props.history.push("/login");
          } else {    
            this.loadServiceOrder(this.state.id);
          }
        });
      }
    });
  }

  sendToProvider = () => {
    servicerequestService.sendToProvider(this.state.id).then( ord => {
      if (ord && ord === 401) {
        this.props.history.push("/login");
      } else {
        alert('A new PDF is generated. You can download now.');        
        this.loadServiceOrder(this.state.id);
      }
    });
  }

  createNewEISorder = () => {    
    let usr = JSON.parse(localStorage.getItem('user'));

    let order = {};
    order.currency = usr.config.currency;
    order.companyId = this.state.companyid;
    order.customerVisibleNote = "Service Order: #" + this.state.id;            
		//order.bookedDate = new Date();
		order.status = 1;
		order.clientid = this.state.customerid;
		order.clientName = this.state.customername;
		order.shipToId = (this.state.siteaddrid ? this.state.siteaddrid : this.state.billingaddrid);
    order.billToId = this.state.billingaddrid;
    order.poId = this.state.id;
    //order.paymentTerm
    //order.shippingMethod
    
    // check if material for this service order exists - if so convert them to line items.
    materialService.getMaterials(this.state.id).then(mat => {
      if (mat && mat === 401) {
        this.props.history.push("/login");
      } else {
        let items = [];
        if (mat && mat.length > 0) {          
          for (let i=0; i<mat.length; i++) {            
            let item = {};
            item.itemId = mat[i].itemid;
            item.item = mat[i].item;
            item.description = mat[i].description;
            item.qty = mat[i].qty;
            item.listPrice = mat[i].listprice;
            item.discount = mat[i].discount;
            item.unitPrice = mat[i].unitprice;
            item.tax1 = mat[i].tax1;
            item.tax01 = mat[i].tax01;
            items.push(item);
          }          
          
        }
        // check service notes and also make them part of items...
        activityService.getAllServiceNoteActivity(this.state.id)
        .then(acts => {
          if (acts && acts === 401) {
            this.props.history.push("/login");
          } else {
            if (acts && acts.length > 0) {          
              for (let i=0; i<acts.length; i++) {            
                let item = {};
                item.item = 'SERVICE';
                if (acts[i].heading && acts[i].heading.length > 0) {
                  item.item = acts[i].heading;
                }
                item.description = acts[i].detail;
                //item.qty = 1;       
                item.qty = (acts[i].timetofinishactivity ? acts[i].timetofinishactivity : 1);         
                item.unitPrice = 0.0;
                items.push(item);
              }                  
            }
            
            order.lineItem = items;
            ////
            //console.log(order);
            slareorderService.addNewSo(order, this.state.id)
              .then(so => {
                if (so && so === 401) {
                  this.props.history.push("/login");
                } else {
                  var { from } = this.props.location.state || { from: { pathname: "/eisordercreate/" + so.id + "/serviceid/" + this.state.id } };
                  this.props.history.push(from);
                }
            });
          }
        })        
      }
    });


    
    // var { from } = this.props.location.state || { from: { pathname: "/eisordercreate/" + this.state.id
    //                 + '/customerid/' + this.state.customerid + '/billingid/' + this.state.billingaddrid
    //                 + '/siteid/' + this.state.siteaddrid} };  
    // this.props.history.push(from);
  }

  createNewInvoice = () => {    
    let usr = JSON.parse(localStorage.getItem('user'));

    let order = {};
    order.currency = usr.config.currency;
    order.companyId = this.state.companyid;
    order.notes = "Service Order: #" + this.state.id;    
    order.type = 1;        
		//order.bookedDate = new Date();
		order.status = 1;
		order.clientId = this.state.customerid;
		order.clientName = this.state.customername;
		order.shipToId = (this.state.siteaddrid ? this.state.siteaddrid : this.state.billingaddrid);
    order.billToId = this.state.billingaddrid;
    //order.paymentTerm
    //order.shippingMethod
    
    // check if material for this service order exists - if so convert them to line items.
    materialService.getMaterials(this.state.id).then(mat => {
      if (mat && mat === 401) {
        this.props.history.push("/login");
      } else {
        let items = [];
        if (mat && mat.length > 0) {          
          for (let i=0; i<mat.length; i++) {            
            let item = {};
            item.itemId = mat[i].itemid;
            item.item = mat[i].item;
            item.description = mat[i].description;
            item.qty = mat[i].qty;
            item.listPrice = mat[i].listprice;
            item.discount = mat[i].discount;
            item.unitPrice = mat[i].unitprice;
            item.tax1 = mat[i].tax1;
            item.tax01 = mat[i].tax01;
            item.whLocation = mat[i].warehouseloc;
            items.push(item);
          }          
        }

        // check service notes and also make them part of items...
        activityService.getAllServiceNoteActivity(this.state.id)
        .then(acts => {
          if (acts && acts === 401) {
            this.props.history.push("/login");
          } else {
            if (acts && acts.length > 0) {          
              for (let i=0; i<acts.length; i++) {            
                let item = {};
                item.item = 'SERVICE';
                if (acts[i].heading && acts[i].heading.length > 0) {
                  item.item = acts[i].heading;
                }
                item.description = acts[i].detail;
                item.qty = (acts[i].timetofinishactivity ? acts[i].timetofinishactivity : 1);                
                item.unitPrice = 0.0;
                items.push(item);
              }                  
            }
            
            order.lineItem = items;
            ////
            //console.log(order);
            invoiceService.addNewInv(order, this.state.id)
                  .then(so => {
                    if (so && so === 401) {
                      this.props.history.push("/login");
                    } else {
                      var { from } = this.props.location.state || { from: { pathname: "/ecinvoice/" + so.id + "/serviceid/" + this.state.id } };
                      this.props.history.push(from);
                    }
              });
          }
        });
      }
    });
  }

  createNewQuote = () => {    
    let usr = JSON.parse(localStorage.getItem('user'));

    let order = {};
    order.currency = usr.config.currency;
    order.companyId = this.state.companyid;
    order.notes = "Service Order: #" + this.state.id;    
    order.type = 1;        
		//order.bookedDate = new Date();
		order.status = 1;
		order.clientid = this.state.customerid;
		order.clientName = this.state.customername;
		order.shipToId = (this.state.siteaddrid ? this.state.siteaddrid : this.state.billingaddrid);
    order.billToId = this.state.billingaddrid;
    //order.paymentTerm
    //order.shippingMethod
    
    // check if material for this service order exists - if so convert them to line items.
    materialService.getMaterials(this.state.id).then(mat => {
      if (mat && mat === 401) {
        this.props.history.push("/login");
      } else {
        let items = [];
        if (mat && mat.length > 0) {          
          for (let i=0; i<mat.length; i++) {            
            let item = {};
            item.itemId = mat[i].itemid;
            item.item = mat[i].item;
            item.description = mat[i].description;
            item.qty = mat[i].qty;
            item.listPrice = mat[i].listprice;
            item.discount = mat[i].discount;
            item.unitPrice = mat[i].unitprice;
            item.tax1 = mat[i].tax1;
            item.tax01 = mat[i].tax01;
            items.push(item);
          }          
        }

        // check service notes and also make them part of items...
        activityService.getAllServiceNoteActivity(this.state.id)
        .then(acts => {
          if (acts && acts === 401) {
            this.props.history.push("/login");
          } else {
            if (acts && acts.length > 0) {          
              for (let i=0; i<acts.length; i++) {            
                let item = {};
                item.item = 'SERVICE';
                if (acts[i].heading && acts[i].heading.length > 0) {
                  item.item = acts[i].heading;
                }
                item.description = acts[i].detail;
                item.qty = 1;                
                item.unitPrice = 0.0;
                items.push(item);
              }                  
            }
            
            order.lineItem = items;
            ////
            quoteService.addNewQt(order, this.state.id)
                  .then(so => {
                    if (so && so === 401) {
                      this.props.history.push("/login");
                    } else {
                      var { from } = this.props.location.state || { from: { pathname: "/ecquote/" + so.id + "/serviceid/" + this.state.id } };
                      this.props.history.push(from);
                    }
            });
          }
        });
      }
    });
  }

  render() {

    let categoryOpt = [];
    categoryOpt.push(<option>Chosse...</option>);
    if (this.state.categoryOption && this.state.categoryOption.length > 0) {
      for (let i=0; i<this.state.categoryOption.length; i++) {
        //////console.log(this.state.categoryOption[i]);
        categoryOpt.push(<option>{this.state.categoryOption[i]}</option>);
      }
    }

    let typeOpt = [];
    typeOpt.push(<option>Chosse...</option>);
    if (this.state.typeOption && this.state.typeOption.length > 0) {
      for (let i=0; i<this.state.typeOption.length; i++) {
        typeOpt.push(<option>{this.state.typeOption[i]}</option>);
      }
    }

    let typePrt = [];
    typePrt.push(<option>Chosse...</option>);
    if (this.state.priorityOption) {
      var keys = Object.keys(this.state.priorityOption);
      //////console.log(keys)
      //////console.log(this.state.priorityOption[keys[0]]);
      for (let i=0; i<keys.length; i++) {
        typePrt.push(<option key={keys[i]} value={this.state.priorityOption[keys[i]]}>{this.state.priorityOption[keys[i]]}</option>);
      }
    }

    let statusOpt= [];
    statusOpt.push(<option>Chosse...</option>);
    if (this.state.statusOption) {
      var keys = Object.keys(this.state.statusOption);
      //////console.log(keys)
      //////console.log(this.state.priorityOption[keys[0]]);
      for (let i=0; i<keys.length; i++) {
        statusOpt.push(<option key={keys[i]} value={this.state.statusOption[keys[i]]}>{this.state.statusOption[keys[i]]}</option>);
      }
    }

  
    return (
      <div className="content-screen">
      
        
  <Card className="mb-3" style={{ width: '73rem', marginTop: '20px'  }}>
    <Card.Header>
        <h5><small>Order # {this.state.id} &nbsp;Created on: {this.getLocalDate(this.state.createdAt)}</small><small style={{fontWeight: 'bold'}}> {' | ' + this.getStatusName(this.state.status)}</small>           
        <div style={{float: 'right'}}>
        {(this.state.status === 99 || this.state.status === 5) &&
        <Button className="btn-sm" variant="link" onClick={this.reopenOrder}>re-open</Button>
        }  
        {this.state.status === 99 &&
        <Button className="btn-sm" variant="link"  onClick={this.closeAndRescheduleOrder}>reschedule</Button>
        }          
        {this.state.status !== 99 &&
        <Button className="btn-sm" variant="link"  onClick={this.closeAndRescheduleOrder}>close</Button>
        }
        {this.state.state !== 99 && this.state.providerid &&
        <Button className="btn-sm" variant="link"  onClick={this.sendToProvider}>send new pdf to provider</Button>
        }
        {this.state.state !== 99 && !this.state.providerid &&
        <Button className="btn-sm" variant="link"  onClick={this.sendToProvider}>generate pdf</Button>
        }
        {this.state.genpdf &&
        <a href={this.state.genpdf}>
          <Button className="btn-sm" variant="link">download pdf</Button>
         </a>
        }
        {this.state.status != 99 && this.state.status != 5 &&
        <Button className="btn-sm" variant="link"  style={{color: 'red'}} onClick={() => {if(window.confirm('Remove the order?')){this.deleteOrder()};}}>delete</Button>
        }
        {/* {(this.state.status === 99 || this.state.status === 5) &&
        <Button className="btn-sm" variant="link"  style={{color: 'red'}} onClick={() => {if(window.confirm('Remove the order?')){this.deleteOrder()};}}>archive</Button>
        } */}
        </div></h5>
    </Card.Header>
    <Card.Body>
      
      <div class="row">
        {this.state.providerid &&
         <div class="col-md-4">
          <Card.Text>
          <div style={{fontSize: '14px', fontWeight: 'bold'}}>
                Service Provider                
            </div>
            <div><small style={{fontWeight: 'bold'}}>{this.state.providername}</small></div>
            {this.state.providercontactname &&
            <div><small>{this.state.providercontactname}</small></div> 
            }
            <div><small>{this.state.provideraddr1}</small></div>
            <div><small>{this.state.provideraddr2}</small></div>            
            <div><small>{this.state.provideremailphone}</small></div>
          </Card.Text>
        </div>
        }
        <div class="col-md-4">
          <Card.Text>
            <div style={{fontSize: '14px', fontWeight: 'bold'}}>
                Billing Address                
            </div>
            <div><small style={{fontWeight: 'bold'}}>{this.state.customername}</small></div>
            <div><small>{this.state.contactname ? this.state.contactname : ''}</small></div> 
            <div><small>{this.state.billaddr1}</small></div>
            <div><small>{this.state.billaddr2}</small></div>
            {/* <div><small>{this.state.billaddr3}</small></div> */}
            <div><small>{this.state.billemail} Ph:<a href={"tel:" + this.state.billphone}>{this.state.billphone}</a></small></div>
            
            {this.state.billingaddrid && this.state.status != 99 && this.state.status != 5 && 
              <Button variant="outline-primary mr-3  mt-2 mb-3"  size="sm" onClick={this.handleEditBillingAddrModal}>edit</Button>
            }    
            {this.state.status != 99 && this.state.status != 5 &&       
            <Button variant="outline-primary  mt-2 mb-3" size="sm"  onClick={this.handleBillingSelect}>select existing</Button>         
            }
            {/* <Button variant="link"  onClick={this.handleAddrSaveModal}>add new</Button> */}
          </Card.Text>
        </div>
        <div class="col-md-4">
          <Card.Text>
            <div style={{fontSize: '14px', fontWeight: 'bold'}}>Site Address</div>
            <div><small>{this.state.siteaddr1}</small></div>
            <div><small>{this.state.siteaddr2}</small></div>
            {/* <div><small>{this.state.siteaddr3}</small></div> */}
            <div><small>{this.state.siteemail} Ph:<a href={"tel:" + this.state.sitephone}>{this.state.sitephone}</a></small></div>
          
            {this.state.siteaddrid && this.state.status != 99 && this.state.status != 5 && 
              <Button variant="outline-primary mr-3  mt-2 mb-3" size="sm"  onClick={this.handleEditSiteAddrModal}>edit</Button>
            } 
            {this.state.status != 99 && this.state.status != 5 &&         
            <Button variant="outline-primary  mt-2 mb-3" size="sm"  onClick={this.handleSiteSelect}>select existing</Button>
            }
            {/* <Button variant="link"  onClick={this.handleAddrSaveModal}>add new</Button> */}
          </Card.Text>
        </div>  
               
      </div>
    </Card.Body>
  </Card>

  <Card style={{ width: '83em' }}>
  <Card.Header style={{paddingTop: '2px'}}>
      <Nav  variant="tabs" defaultActiveKey="#order" onSelect={selectedKey => this.navSelected(selectedKey)}>
        <Nav.Item>
          <Nav.Link  href="#order"  style={{color: 'black'}}>Order Detail</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link  href="#equip" style={{color: 'black'}}>Asset</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="#activity" style={{color: 'black'}}>Activity</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="#pic" style={{color: 'black'}}>Picture</Nav.Link>
        </Nav.Item>                  
        <Nav.Item>
          <Nav.Link href="#material" style={{color: 'black'}}>Material</Nav.Link>
        </Nav.Item>        
        <Nav.Item>
          <Nav.Link href="#estimate" style={{color: 'black'}}>Estimate</Nav.Link>
        </Nav.Item> 
        <Nav.Item>
          <Nav.Link href="#invoice" style={{color: 'black'}}>Invoice</Nav.Link>
        </Nav.Item>   
        <Nav.Item>
          <Nav.Link href="#salesorder" style={{color: 'black'}}>Salesorder</Nav.Link>
        </Nav.Item> 
        <Nav.Item>
          <Nav.Link href="#signature" style={{color: 'black'}}>Signature</Nav.Link>
        </Nav.Item>                         
      </Nav>
    </Card.Header>    
    <Card.Body>   
      {this.state.cardbody} 
      {/* <a href="#" class="btn btn-primary float-right">Edit</a>       */}
      {/* <>
      <Card.Text className="textunderline">{this.state.detail}</Card.Text>
      
      <div class="row" >
        <div class="col-md-4">
          <Card.Text>
            <div class="textgroup">
              <span class="textlabel">Category</span>
              <div className="textunderline">{this.state.problemtype ? this.state.problemtype : 'NA'}</div>
            </div>
            <div class="textgroup">
              <span class="textlabel">Type</span>
              <div className="textunderline">{this.state.type ? this.state.type : 'NA'}</div>
            </div>
            <div class="textgroup">
              <span class="textlabel">Priority</span>
              <div className="textunderline">{this.getPriorityName(this.state.priority)}</div>
            </div>
            <div class="textgroup">
              <span class="textlabel">Status</span>
              <div className="textunderline">{this.getStatusName(this.state.status)}</div>
            </div>
          </Card.Text>
        </div>
        <div class="col-md-4">
          <Card.Text>
            <div class="textgroup">
              <span class="textlabel">Start Date</span>
              <div className="textunderline">{this.getLocalDate(this.state.startdate)}</div>
            </div>
            <div class="textgroup">
              <span class="textlabel">End Date</span>
              <div className="textunderline">{this.getLocalDate(this.state.enddate)}</div>
            </div>
            <div class="textgroup">
              <span class="textlabel">Scheduled for</span>
              <div className="textunderline">{this.getLocalDate(this.state.schduletime)}</div>
            </div>
            <div class="textgroup">
              <span class="textlabel">Resources</span>
              <div className="textunderline">{this.state.leaderassigned ? this.state.leaderassigned : 'NA'}</div>
            </div>
          </Card.Text>
        </div> 
        <div class="col-md-4">
          <Card.Text>
          <div class="textgroup">
              <span class="textlabel">Notes</span>
              <div className="textunderline">{this.state.instruction ? this.state.instruction : 'NA'}</div>
            </div>
            <div class="textgroup">
              <span class="textlabel">Skills</span>
              <div className="textunderline">{this.getSkills(this.state.skills)}</div>
            </div>
          </Card.Text>
        </div>        
      </div>
      <Card.Link href="#" style={{color: 'blue'}}>Edit</Card.Link>
      </> */}
    </Card.Body>
    <Card.Footer className="text-muted">&nbsp;</Card.Footer>
  </Card>
  <div>
          <>                
                <Modal show={this.state.newequipmentmodal} onHide={this.handleAddNewEquipment.bind(this)}
                 
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Header closeButton>              
                    <Modal.Title>Equipment for {this.state.customername}</Modal.Title>              
                  </Modal.Header>
                  <Modal.Body>
                    <Form onSubmit={this.handleSaveOrUpdateSubmit}>
                        <Form.Row>
                          <Form.Group as={Col} controlId="assetdescription" 
                              onChange={this.handleInputChange}>
                            <Form.Label style={{fontSize: "14px"}}>Description</Form.Label>
                            <Form.Control required="true" placeholder="Enter asset description" defaultValue={this.state.assetdescription} />
                          </Form.Group>
                          <Form.Group as={Col} controlId="assetnumber" 
                              onChange={this.handleInputChange}>
                            <Form.Label style={{fontSize: "14px"}}>Asset Tag #</Form.Label>
                            <Form.Control placeholder="Enter asset model" defaultValue={this.state.assetnumber} />
                          </Form.Group>
                        </Form.Row> 

                        <Form.Row>
                          <Form.Group as={Col} controlId="assetmodel" 
                              onChange={this.handleInputChange}>
                            <Form.Label style={{fontSize: "14px"}}>Model</Form.Label>
                            <Form.Control placeholder="Enter asset model" defaultValue={this.state.assetmodel} />
                          </Form.Group>
                          
                          <Form.Group as={Col} controlId="assetserial" 
                              onChange={this.handleInputChange}>
                            <Form.Label style={{fontSize: "14px"}}>Serial #</Form.Label>
                            <Form.Control placeholder="Enter asset serial number" defaultValue={this.state.assetserial} />
                          </Form.Group>
                          
                        </Form.Row> 
                        <Form.Row>
                          
                          <Form.Group as={Col} controlId="assetmanufacturer" 
                              onChange={this.handleInputChange}>
                            <Form.Label style={{fontSize: "14px"}}>Manufacturer</Form.Label>
                            <Form.Control placeholder="Enter manufacturer name" defaultValue={this.state.assetmanufacturer} />
                          </Form.Group>
                          <Form.Group as={Col} controlId="assetlotnumber" 
                              onChange={this.handleInputChange}>
                            <Form.Label style={{fontSize: "14px"}}>Lot #</Form.Label>
                            <Form.Control placeholder="Enter lot #" defaultValue={this.state.assetlotnumber} />
                          </Form.Group>
                        </Form.Row> 
                        
                        <div style={{float: "left"}} >                   
                          <Button variant="outline-primary  ml-3"  size="sm" type="submit">Save</Button> 
                          <Button variant="outline-primary  mr-3"  size="sm" onClick={this.handleAddNewEquipment.bind(this)} >Close</Button>
                                                       
                        </div>
                </Form>  
              </Modal.Body>
            </Modal>
            </>                                         
      </div>

      <div>
            <>
              <Modal show={this.state.editordermodal} onHide={this.handleOrderModal.bind(this)}
                 
                 size="lg"
                 aria-labelledby="contained-modal-title-vcenter"
                 centered
               >
                <Modal.Header closeButton>              
                   <Modal.Title>Edit order#: {this.state.id}</Modal.Title>              
                </Modal.Header>
                <Modal.Body>
                <Form onSubmit={this.handleOrderSaveSubmit}>
                  <Form.Row>
                    <Form.Group as={Col} controlId="detail" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Detail</Form.Label>
                      <Form.Control required="true" placeholder="Enter the detail" defaultValue={this.state.detail} />
                    </Form.Group>
                  </Form.Row> 

                  <Form.Row>
                  <Form.Group as={Col} controlId="status" onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Status</Form.Label>
                      <Form.Control as="select" defaultValue={this.getStatusName(this.state.status)}>
                            {statusOpt}
                            
                      </Form.Control>            
                    </Form.Group>

                    <Form.Group as={Col} controlId="problemtype" onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Category</Form.Label>
                      <Form.Control as="select" defaultValue={this.state.problemtype}>
                            {categoryOpt}
                            
                      </Form.Control>            
                    </Form.Group>
                    <Form.Group as={Col} controlId="type" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Type</Form.Label>
                      <Form.Control as="select" defaultValue={this.state.type}>
                            {typeOpt}
                      </Form.Control> 
                    </Form.Group>
                    <Form.Group as={Col} controlId="priority" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Priority</Form.Label>
                      <Form.Control as="select" defaultValue={utility.getPriorityName(this.state.priority)}>
                          {typePrt}                 
                      </Form.Control> 
                    </Form.Group>          
                  </Form.Row>

                  <Form.Row>  
                    <Form.Group as={Col} controlId="requesteddate">
                      <Form.Label  style={{fontSize: "14px"}}>Customer requested date</Form.Label>  
                      <div>
                      <DatePicker style={{displayValue: 'block'}}
                          selected={this.state.requesteddate ? moment(this.state.requesteddate).local().toDate() : new Date()}
                          onChange={date => this.setRequestedDate(date)}
                          dateFormat="MMM d, yyyy HH:mm"
                          placeholderText="Enter a date"
                          
                      /> </div>                   
                    </Form.Group>

                    <Form.Group as={Col} controlId="startdate">
                      <Form.Label  style={{fontSize: "14px"}}>Start date</Form.Label>  
                      <div>
                      <DatePicker style={{displayValue: 'block'}}
                          selected={this.state.startdate ? moment(this.state.startdate).local().toDate() : new Date()}
                          onChange={date => this.setStartDate(date)}
                          dateFormat="MMM d, yyyy HH:mm"
                          placeholderText="Enter a date"
                          
                      /> </div>                   
                    </Form.Group>
                    
                    <Form.Group as={Col} controlId="enddate">
                      <Form.Label  style={{fontSize: "14px"}}>End date</Form.Label>
                      <div>
                      <DatePicker style={{displayValue: 'block'}}
                          selected={this.state.enddate ? moment(this.state.enddate).local().toDate() : new Date()}                          
                          onChange={date => this.setEndDate(date)}
                          dateFormat="MMM d, yyyy HH:mm"
                          placeholderText="Select a date"
                          
                      /></div>
                    </Form.Group>                    
                  </Form.Row>

                  <Form.Row>  
                    <Form.Group as={Col} controlId="instruction" onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Note for technician</Form.Label>
                      <Form.Control placeholder="Enter instructions" defaultValue={this.state.instruction}/>
                    </Form.Group>                    
                  </Form.Row>

                  <Form.Row>  
                    <Form.Group controlId="skills">
                      <Form.Label style={{fontSize: "14px"}}>Skills</Form.Label>
                      <Multiselect 
                        options={this.state.skillOption} 
                        displayValue="name" 
                        selectedValues={this.getSkills(this.state.skills)} 
                        onSelect={this.setMultiSkills}
                        onRemove={this.setMultiSkills}
                      />                      
                    </Form.Group>
                   
                    
                  </Form.Row>
                  <div style={{float: "left"}} >                   
                    <Button variant="outline-primary  mr-3" size="sm" type="submit">Save</Button>                              
                    <Button variant="outline-primary  mr-3"  size="sm" onClick={this.handleOrderModal.bind(this)} size="sm">Close</Button>                    
                  </div>
              </Form>
              </Modal.Body>
            </Modal>
            </> 

            <>
                <Modal show={this.state.selectequipmodal} onHide={this.handleSelectEquipmentClose.bind(this)}
                  
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Header closeButton>              
                    <Modal.Title>Select existing equipement of this client</Modal.Title>              
                  </Modal.Header>
                  <Modal.Body>
                        <ToolkitProvider
                        bootstrap4
                        keyField='id'
                        data={ this.state.equipobjs }
                        columns={ this.state.equipcolumns }
                        search                        
                      >
                        {
                          props => (
                            <div>    
                              <SearchBar { ...props.searchProps } />                    
                              <BootstrapTable style={{scrollY: "200px",scrollCollapse: true}}
                                bootstrap4
                                { ...props.baseProps }
                                { ...props.paginationFactory }
                                { ...props.striped }
                                { ...props.defaultEquipSorted }
                                selectRow={ this.state.selectEquipRow }
                                //pagination={ paginationFactory( {sizePerPage: 6}) }                     
                                pagination={ paginationFactory({hideSizePerPage: true}) }                     
                                bordered = {false} 
                                condensed = {true}  
                                //defaultSorted = { this.state.defaultSorted } 
                                cellEdit={ cellEditFactory({ mode: 'click', blurToSave: true }) }
                              />
                            </div>
                          )
                        }
                      </ToolkitProvider>
                      <div style={{float: 'left'}}>
                    <Button variant="outline-primary mr-3"  size="sm" onClick={this.handleSelectEquipment.bind(this)}>
                      Save
                    </Button>
                    <Button variant="outline-primary"  size="sm" onClick={this.handleSelectEquipmentClose.bind(this)}>
                      Close
                    </Button>                    
                    </div>
                  </Modal.Body>
                  
                </Modal>
                </>

                <>
                <Modal show={this.state.selectaddressmodal} onHide={this.handleSelectAddressClose.bind(this)}
                  
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Header closeButton>              
                    <Modal.Title>Select existing address of this client</Modal.Title>              
                  </Modal.Header>
                  <Modal.Body>
                        <ToolkitProvider
                        bootstrap4
                        keyField='id'
                        data={ this.state.addrobjs }
                        columns={ this.state.addrcolumns }
                        search                        
                      >
                        {
                          props => (
                            <div> 
                                                       
                              <BootstrapTable style={{scrollY: "200px",scrollCollapse: true}}
                                bootstrap4
                                { ...props.baseProps }
                                { ...props.paginationFactory }
                                { ...props.striped }
                                { ...props.defaultEquipSorted }
                                selectRow={ this.state.selectAddressRow }
                                //pagination={ paginationFactory( {sizePerPage: 6}) }                     
                                pagination={ paginationFactory({hideSizePerPage: true}) }                     
                                bordered = {false} 
                                condensed = {true}  
                                //defaultSorted = { this.state.defaultSorted } 
                                cellEdit={ cellEditFactory({ mode: 'click', blurToSave: true }) }
                              />
                            </div>
                          )
                        }
                      </ToolkitProvider>
                      <div style={{float: 'left'}}>
                        <Button variant="outline-primary mr-3"  size="sm" onClick={this.handleSelectAddress.bind(this)}>
                          Save
                        </Button>
                        <Button variant="outline-primary"  size="sm" onClick={this.handleSelectAddressClose.bind(this)}>
                          Close
                        </Button>                    
                    </div>
                  </Modal.Body>                  
                </Modal>
                </>

                <>
              <Modal show={this.state.addrsavemodal} onHide={this.handleAddrModalChange.bind(this)}
                 
                 size="lg"
                 aria-labelledby="contained-modal-title-vcenter"
                 centered
               >
                <Modal.Header closeButton>              
                   <Modal.Title>Address</Modal.Title>              
                </Modal.Header>
                <Modal.Body>
                <Form onSubmit={this.handleAddressSaveSubmit}>
                  <Form.Row>
                    <Form.Group as={Col} controlId="addrfname" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>First Name</Form.Label>
                      <Form.Control placeholder="Enter first name" defaultValue={this.state.addrfname} />
                    </Form.Group>
                    <Form.Group as={Col} controlId="addrlname" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Last Name</Form.Label>
                      <Form.Control placeholder="Enter last name" defaultValue={this.state.addrlname} />
                    </Form.Group>
                    <Form.Group as={Col} id="addrisacontact" 
                        onChange={this.handleContactCheckBox}>
                      <Form.Label style={{fontSize: "14px"}}>Contact</Form.Label>
                      <Form.Check type="checkbox" checked={this.state.addrisacontact}/>                      
                    </Form.Group>
                  </Form.Row> 
                  <Form.Row>
                    <Form.Group as={Col} controlId="addremail" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Email</Form.Label>
                      <Form.Control type="email" placeholder="Enter email" defaultValue={this.state.addremail} />
                    </Form.Group>
                    <Form.Group as={Col} controlId="addrphone" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Phone</Form.Label>
                      <Form.Control type="phone" placeholder="Enter phone number" defaultValue={this.state.addrphone} />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="addraddr1" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Address</Form.Label>
                      <Form.Control placeholder="Enter street address" defaultValue={this.state.addraddr1} />
                    </Form.Group>
                    <Form.Group as={Col} controlId="addraddr2" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Address Cont'</Form.Label>
                      <Form.Control type="phone" placeholder="Enter address" defaultValue={this.state.addraddr2} />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="addrcity" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>City</Form.Label>
                      <Form.Control placeholder="Enter city" defaultValue={this.state.addrcity} />
                    </Form.Group>
                    <Form.Group as={Col} controlId="addrstate" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>State</Form.Label>
                      <Form.Control type="phone" placeholder="Enter state" defaultValue={this.state.addrstate} />
                    </Form.Group>
                    <Form.Group as={Col} controlId="addrzip" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Zip</Form.Label>
                      <Form.Control type="phone" placeholder="Enter state" defaultValue={this.state.addrzip} />
                    </Form.Group>
                  </Form.Row>
                  <div style={{float: "left"}} >    
                    <Button type="submit" variant="outline-primary mr-3" size="sm" onClick={this.handleAddrSaveModal.bind(this)}>
                      Save
                    </Button>                
                    <Button variant="outline-primary mr-3" size="sm"  onClick={this.handleAddrModalChange.bind(this)}>
                      Close
                    </Button>                    
                  </div>
              </Form>
              </Modal.Body>
            </Modal>
            </> 

            </div>

</div>
);
  }
}

export default ServiceDetail;