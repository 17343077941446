import { authHeader } from './authHeader.js';
import {userService} from './userService.js'

var config = require('./config.js');

export const servicerequestService = {
    getServiceRequestList,
    getOpenServiceRequestList,
    getServiceRequest,
    addServiceRequest,
    updateServiceRequest,
    deleteServiceRequest,
    addActivity,
    getActivity,
    getActivityList,
    updateActivity,
    conditionalAddClosedActivity,
    getSalesOrders,
    getServiceOrdersAssignedToPeopleRange,
    getServiceOrderLeaderHelpers,
    getInvoices,
    getQuotes,
    getServiceRequestListByAsset,
    sendToProvider,
    getPurchaseOrders,
};

function sendToProvider(serviceid) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + `/api/serviceordertoprovider/` + serviceid, requestOptions).then(handleResponse);
}

function getServiceOrderLeaderHelpers(serviceid) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + `/api/serviceorderleaderhelpers/` + serviceid, requestOptions).then(handleResponse);
}

function getServiceRequestList() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + `/api/serviceorder`, requestOptions).then(handleResponse);
}

function getServiceRequestListByAsset(assetid) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + `/api/serviceorderbyasset/` + assetid, requestOptions).then(handleResponse);
}

function getOpenServiceRequestList() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + `/api/serviceorderopen`, requestOptions).then(handleResponse);
}

function getServiceRequest(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + `/api/serviceorderid/` + id, requestOptions).then(handleResponse);
}

function addServiceRequest(order, assetid) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(order)   
    };
    if (assetid) {
        // create this service order for this assetid
        return fetch(config.app.url + '/api/serviceorderasset/' + assetid, requestOptions).then(handleResponse);
    }
    else {
        // create service order without association with assetid
        return fetch(config.app.url + '/api/serviceorder' , requestOptions).then(handleResponse);
    }
    
}

function updateServiceRequest(order, id) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(order)   
    };
    return fetch(config.app.url + '/api/serviceorder/' + id, requestOptions).then(handleResponse);
}

function deleteServiceRequest(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + `/api/serviceorder/` + id, requestOptions).then(handleResponse);
}

function addActivity(activity) {
        const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(activity)   
    };
    return fetch(config.app.url + '/api/activity' , requestOptions).then(handleResponse);
}

function getActivityList(orderid) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + `/api/activitybyservice/` + orderid, requestOptions).then(handleResponse);
}

function getActivity(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + `/api/activitybyid/` + id, requestOptions).then(handleResponse);
}

function updateActivity(act, id) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(act)   
    };
    return fetch(config.app.url + '/api/activity/' + id, requestOptions).then(handleResponse);
}

function conditionalAddClosedActivity(activity, status) {

    if (status === 99 && activity.type === 4) {
        return new Promise(function(resolve, reject) {
            resolve('No operation');
          });
    }
    const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    credentials: 'include',
    body: JSON.stringify(activity)   
    };
    return fetch(config.app.url + '/api/activity' , requestOptions).then(handleResponse);
}

function getPurchaseOrders(serviceid) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + `/api/serviceorderpo/` + serviceid, requestOptions).then(handleResponse);
}

function getSalesOrders(serviceid) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + `/api/serviceorderso/` + serviceid, requestOptions).then(handleResponse);
}

function getInvoices(serviceid) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + `/api/serviceorderinv/` + serviceid, requestOptions).then(handleResponse);
}

function getQuotes(serviceid) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + `/api/serviceorderqt/` + serviceid, requestOptions).then(handleResponse);
}

function getServiceOrdersAssignedToPeopleRange(peopleid, from, to) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + `/api/serviceorder/` + peopleid + '/from/' + from + '/to/' + to, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    if (response.status === 401 || response.status === 304) {
        return 401;
    }
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                //location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        if (data.data) {
            return data.data;
        } else {
            return data;
        }
    });
}

