import { authHeader } from './authHeader.js';
import {userService} from './userService.js'

var config = require('./config.js');

export const scheduleService = {

    getScheduleList,
    addSchedule,
    updateSchedule,
    deleteSchedule,
    updateLeader,
    closeScheduleNonLeader,
    getSchedules,
};

function getSchedules(resourceid, scheduledate, starttime, startminute, endtime, endminute) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
   return fetch(config.app.url + '/api/schedulebyresource/' + resourceid + '/date/' + scheduledate + '/starthr/' + starttime + '/startmn/' + startminute + '/endhr/' + endtime + '/endmn/' + endminute, requestOptions).then(handleResponse);
}

function closeScheduleNonLeader(schid, note) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',   
        body: JSON.stringify(note)  
    };
    return fetch(config.app.url + '/api/scheduleclosefornonleader/' + schid, requestOptions).then(handleResponse); 
}

function addSchedule(sch) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',     
        body: JSON.stringify(sch)        
    };
    return fetch(config.app.url + '/api/schedule' , requestOptions).then(handleResponse);
}

function updateLeader(serviceid, peopleid) {
    //console.log(resource);
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',             
    };
    return fetch(config.app.url + '/api/scheduleorder/' + serviceid + '/people/' + peopleid , requestOptions).then(handleResponse);
}

function getScheduleList(startdate, enddate) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + '/api/schedulebyrange/' + startdate + '/to/' + enddate, requestOptions).then(handleResponse);
}

function updateSchedule(resource) {
    //console.log(resource);
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(resource)     
    };
    return fetch(config.app.url + '/api/schedule/' + resource.id , requestOptions).then(handleResponse);
}

function deleteSchedule(id, serviceid) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        credentials: 'include',
    };
    return fetch(config.app.url + '/api/schedule/' + id  + '/service/' + serviceid, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    if (response.status === 401) {
        return 401;
    }
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                //location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        //console.log(data.data);
        return data.data;
    });
}

