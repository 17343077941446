import React from "react";
import { customerService } from '../../service/customerService';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { Button, Form, Col, Modal } from 'react-bootstrap';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
var config = require('../../service/config');

const { SearchBar } = Search;

class CustomerDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      customerid: undefined,
      customername: undefined,
      sales_tax_id: undefined,
      client_link: undefined,
      currency: undefined,

      addrobjs: [],
      addrcolumns: [{
        dataField: 'id',
        text: 'id',
        hidden: true
      }, {
        dataField: 'fname',
        text: 'First Name',
        hidden: true,
      }, {
        dataField: 'lname',
        text: 'Last Name',
        hidden: true,
      }, {
        dataField: 'addr1',
        text: 'Address',
        hidden: true,
      }, {
        dataField: 'addr2',
        text: 'Address Cont',
        hidden: true,
      }, {
        dataField: 'city',
        text: 'City',
        hidden: true,
      }, {
        dataField: 'state',
        text: 'State',
        hidden: true,
      }, {
        dataField: 'zip',
        text: 'Zip',
        hidden: true,
      }, {
        dataField: 'email',
        text: 'Email',
        hidden: true,
      }, {
        dataField: 'phone',
        text: 'Phone',
        hidden: true,
      }, {
        dataField: 'fullname',
        text: 'Name',
        editable: false,
        sort: true,
          headerStyle: {
            backgroundColor: '#D9D8D9'
          },
      }, {
        dataField: 'address',
        text: 'Address',
        editable: false,
        sort: true,
        headerStyle: {
          backgroundColor: '#D9D8D9'
        },
      },  {
        dataField: 'is_a_contact',
        text: 'Contact',
        editable: false,
        sort: true,
          headerStyle: {
            backgroundColor: '#D9D8D9'
          },
        formatter: (cellContent, row) => {
          let disp = "";
          if (row.is_a_contact === true) {
            disp = "Yes";
          }
          return (
            <h5>
              <small className="label label-success">{disp}</small>
            </h5>
          );            
        }        
      }          
      ],
      selectRow: {
        mode: 'radio',
        clickToSelect: true,
        hideSelectColumn: true,
        bgColor: '#adb5bd',
        onSelect: (row, isSelect, rowIndex, e) => {            
          this.handleEditAddrModal(row);
        }
      },
      selectaddressmodal: false,
      
      defaultSorted: [{
        dataField: 'fname',
        order: 'asc'
      }],

      ////   
      addrid: undefined,
      addrfname: undefined, 
      addrlname: undefined, 
      addremail: undefined, 
      addrphone: undefined, 
      addraddr1: undefined, 
      addraddr2: undefined, 
      addrcity: undefined, 
      addrstate: undefined, 
      addrzip: undefined, 
      addrisacontact: false,

      addrsavemodal: false,

      ///
      countryopt: [],
    };
    this.updateCustomerNameSubmit = this.updateCustomerNameSubmit.bind(this);   
    this.handleInputChange = this.handleInputChange.bind(this);  
  }

  handleInputChange = (event) => {    
    const target = event.target;
    const name = target.id;    
    this.setState({
      [name]: target.value
    });    
  }
  
  componentDidMount() {
      const { id } = this.props.match.params;
      this.state.customerid = id;
      //console.log(this.state.customerid)

      customerService.getCustomer(this.state.customerid).then(custs => {
        if (custs && custs === 401) {
          this.props.history.push("/login");
        } else {
          //console.log(custs);
          this.setState({
            customername: custs.name,
            sales_tax_id: custs.sales_tax_id,
            client_link: custs.client_link,
            currency: custs.currency,
          });
                       
          for (let i=0; i<config.currencies.length; i++) {
              //console.log(this.state.categoryOption[i]);
              if (this.state.currency === config.currencies[i].value) {
                this.state.countryopt.push(<option selected value={config.currencies[i].value}>{config.currencies[i].label}</option>);
              } else {
                this.state.countryopt.push(<option value={config.currencies[i].value}>{config.currencies[i].label}</option>);
              }
          }

          //console.log(this.state.customername);    
          customerService.getAddressList(this.state.customerid).then(addrs => {
            if (addrs && addrs === 401) {
              this.props.history.push("/login");
            } else {
              ////console.log(addrs);
                                
              for (let i=0; i<addrs.length; i++) {            
                addrs[i].address = (addrs[i].addr1 ? (addrs[i].addr1 + ", ") : '') + (addrs[i].city ? addrs[i].city + ', ': '') + 
                      (addrs[i].state ? addrs[i].state + ' ': '') +
                      (addrs[i].zip ? addrs[i].zip: '');          
                addrs[i].fullname =  (addrs[i].fname ? addrs[i].fname + ' ': '') + (addrs[i].lname ? addrs[i].lname : '');     
                
              }
              this.setState({ addrobjs: addrs });
              ////console.log(this.state.addrobjs);
              this.setState({
                selectaddressmodal: !this.state.selectaddressmodal
              });
            }
          });     
        }
      })
  }

  handleAddNew = () => {
    this.props.history.push('/resourcedetail', '', this.state);
  };

  handleJobList = () => {
    this.props.history.push('/jobslist/' + this.state.customerid, '', this.state);
  };

  handleServiceOrderCreate = () => {
    this.props.history.push('/serviceedit/' + this.state.customerid, '', this.state);
  }

  updateCustomerNameSubmit = () => {
    let cust = {};
    cust.id = this.state.customerid;
    cust.name = this.state.customername;
    cust.sales_tax_id = this.state.sales_tax_id;
    cust.client_link = this.state.client_link;
    cust.currency = this.state.currency;

    customerService.updateCustomer(cust).then(cust => {
      if (cust && cust === 401) {
        this.props.history.push("/login");
      } else {
        this.setState({customername: cust.name});
      }
    });
  }
  
  handleAddrModalChange = (event) => {
    this.setState({   
      addrid: undefined,
      addrfname: undefined, 
      addrlname: undefined, 
      addremail: undefined, 
      addrphone: undefined, 
      addraddr1: undefined, 
      addraddr2: undefined, 
      addrcity: undefined, 
      addrstate: undefined, 
      addrzip: undefined,   
      addrisacontact: false,
      addrsavemodal: !this.state.addrsavemodal
    });
  }
 
  handleEditAddrModal(row) { 
    //alert('handleEditBillingAddrModal....' + this.state.billingaddrid);   
    this.setState({
      addrid: row.id,
      addrfname: row.fname, 
      addrlname: row.lname, 
      addremail: row.email, 
      addrphone: row.phone, 
      addraddr1: row.addr1, 
      addraddr2: row.addr2, 
      addrcity: row.city, 
      addrstate: row.state, 
      addrzip: row.zip, 
      addrisacontact: row.is_a_contact,
      addrsavemodal: !this.state.addrsavemodal,
    });
  }

  changeAddrSaveModal = (event) => {
    event.preventDefault();
    this.setState({
      addrsavemodal: !this.state.addrsavemodal
    });
  }

  handleAddrSaveModal = (event) => {
    //event.preventDefault();
    this.setState({
      addrsavemodal: !this.state.addrsavemodal
    });
  }

  handleAddressSaveSubmit = (event) => {
    
    //alert(this.state.addrid);
    if (this.state.addrid) {
      this.updateAddress();
    } else {
      this.addNewAddress();
    }
    this.setState({
      addrsavemodal: !this.state.addrsavemodal
    });
  }

  updateAddress = () => {
    ////alert('updateAddress...')
    let address = {};
    address.id = this.state.addrid;
    address.fname = this.state.addrfname;
    address.lname = this.state.addrlname;
    address.email = this.state.addremail;
    address.phone = this.state.addrphone;
    address.addr1 = this.state.addraddr1;
    address.addr2 = this.state.addraddr2;
    address.city = this.state.addrcity;
    address.state = this.state.addrstate;
    address.zip = this.state.addrzip;
    address.is_a_contact = this.state.addrisacontact;

    customerService.updateAddress(address).then(addr => {
      if (addr && addr === 401) {
        this.props.history.push("/login");
      } else {
        // initialize the address list
        this.loadServiceOrder(this.state.id);
      }
    });
  }

  addNewAddress = () => {
    ////alert('addAddress..................>>')
    let address = {};
    address.clientid = this.state.customerid;
    address.fname = this.state.addrfname;
    address.lname = this.state.addrlname;
    address.email = this.state.addremail;
    address.phone = this.state.addrphone;
    address.addr1 = this.state.addraddr1;
    address.addr2 = this.state.addraddr2;
    address.city = this.state.addrcity;
    address.state = this.state.addrstate;
    address.zip = this.state.addrzip;
    address.is_a_contact = this.state.addrisacontact;

    customerService.addAddress(address).then(addr => {
      if (addr && addr === 401) {
        this.props.history.push("/login");
      } else {
      }
    });
  }

  handleContactCheckBox = () => {
    this.setState({addrisacontact: !this.state.addrisacontact});
  }

  render() {
    let email = this.state.addremail;
    if (!email) {
      email = "a@a.com";
    }

    return (   
      <div className="content-screen">
            <div style={{ width: '73rem' }}>
                <div style={{textAlign: 'center', fontSize: '18px', fontWeight: 'bold', color: '#655b79', marginBottom: '20px'}}>Customer Detail</div>
                <Form onSubmit={this.updateCustomerNameSubmit}>
                    <Form.Row>
                      {/* <div style={{float: "left"}} >                   */}
                        <Form.Group as={Col} controlId="customername" 
                            onChange={this.handleInputChange}>
                          <Form.Label style={{fontSize: "16px"}}>Customer name</Form.Label>
                          <Form.Control type="phone" placeholder="Enter state" defaultValue={this.state.customername} />                          
                        </Form.Group>     
                        
                      {/* </div> */}
                                          
                    <Form.Group as={Col} controlId="currency" onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Currency</Form.Label>
                      <Form.Control as="select" defaultValue={this.state.currency}>
                            {this.state.countryopt}
                            
                      </Form.Control>            
                    </Form.Group>

                    <Form.Group as={Col} controlId="sales_tax_id" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Tax Id</Form.Label>
                      <Form.Control placeholder="Enter tax id" defaultValue={this.state.sales_tax_id} />
                    </Form.Group>

                    <Form.Group as={Col} controlId="client_link" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Customer Connection</Form.Label>
                      <Form.Control placeholder="Enter client connection" defaultValue={this.state.client_link} />
                    </Form.Group>

                  </Form.Row>                  
                  <Button type="submit" variant="outline-primary mb-5"  size="sm">Update</Button>                   
                  <Button type="submit" onClick={this.handleJobList.bind(this)} variant="outline-primary mb-5 ml-2"  size="sm">Job Setting (QB)</Button>  
                  <Button type="submit" onClick={this.handleServiceOrderCreate.bind(this)} variant="outline-primary mb-5 ml-2"  size="sm">Create Service Order</Button>  
                  </Form>  
        <div style={{fontWeight: 'bold'}}>Customer Addresses</div>
        <div className="content-screen">
          <ToolkitProvider
            bootstrap4
            keyField='id'
            data={ this.state.addrobjs }
            columns={ this.state.addrcolumns }
            
            
          >
            {
              props => (
                <div  className="h6">
                  
                  
                  <BootstrapTable
                    bootstrap4
                    { ...props.baseProps }
                    { ...props.paginationFactory }
                    { ...props.striped }
                    { ...props.defaultSorted }
                    selectRow={ this.state.selectRow }
                    //pagination={ paginationFactory( {sizePerPage: 6}) }                     
                    //pagination={ paginationFactory({hideSizePerPage: true}) }                     
                    bordered = {false} 
                    condensed = {true}  
                    defaultSorted = { this.state.defaultSorted } 
                    hover
                  />
                </div>
              )
            }
          </ToolkitProvider>
          <div style={{float: "left"}} >    
                        <button className="btn btn-outline-primary" onClick={ this.changeAddrSaveModal }>Add Address</button>  
          </div>
        </div>
      
      
        <>
              <Modal show={this.state.addrsavemodal} onHide={this.handleAddrModalChange.bind(this)}
                 
                 size="lg"
                 aria-labelledby="contained-modal-title-vcenter"
                 centered
               >
                <Modal.Header closeButton>              
                   <Modal.Title>Address</Modal.Title>              
                </Modal.Header>
                <Modal.Body>
                <Form onSubmit={this.handleAddressSaveSubmit}>
                  <Form.Row>
                    <Form.Group as={Col} controlId="addrfname" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>First Name</Form.Label>
                      <Form.Control placeholder="Enter first name" defaultValue={this.state.addrfname} />
                    </Form.Group>
                    <Form.Group as={Col} controlId="addrlname" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Last Name</Form.Label>
                      <Form.Control placeholder="Enter last name" defaultValue={this.state.addrlname} />
                    </Form.Group>
                    <Form.Group as={Col} id="addrisacontact" 
                        onChange={this.handleContactCheckBox}>
                      <Form.Label style={{fontSize: "14px"}}>Contact</Form.Label>
                      <Form.Check type="checkbox" checked={this.state.addrisacontact}/>                      
                    </Form.Group>
                  </Form.Row> 
                  <Form.Row>
                    <Form.Group as={Col} controlId="addremail" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px", color: 'red'}}>Email</Form.Label>
                      <Form.Control type="email" placeholder="Enter email" defaultValue={email} />
                    </Form.Group>
                    <Form.Group as={Col} controlId="addrphone" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Phone</Form.Label>
                      <Form.Control type="phone" placeholder="Enter phone number" defaultValue={this.state.addrphone} />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="addraddr1" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px", color: 'red'}}>Address</Form.Label>
                      <Form.Control required="true" placeholder="Enter street address" defaultValue={this.state.addraddr1} />
                    </Form.Group>
                    <Form.Group as={Col} controlId="addraddr2" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Address Cont'</Form.Label>
                      <Form.Control type="phone" placeholder="Enter address" defaultValue={this.state.addraddr2} />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="addrcity" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>City</Form.Label>
                      <Form.Control placeholder="Enter city" defaultValue={this.state.addrcity} />
                    </Form.Group>
                    <Form.Group as={Col} controlId="addrstate" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>State</Form.Label>
                      <Form.Control type="phone" placeholder="Enter state" defaultValue={this.state.addrstate} />
                    </Form.Group>
                    <Form.Group as={Col} controlId="addrzip" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Zip</Form.Label>
                      <Form.Control type="phone" placeholder="Enter state" defaultValue={this.state.addrzip} />
                    </Form.Group>
                  </Form.Row>
                  <div style={{float: "right"}} >                   
                  <Button variant="secondary mr-3" onClick={this.handleAddrModalChange.bind(this)}>
                      Close
                    </Button>
                    <Button type="submit" variant="primary" onClick={this.handleAddrSaveModal.bind(this)}>
                      Save
                    </Button> 
                  </div>
              </Form>
              </Modal.Body>
            </Modal>
            </> 
      </div>
      </div>
    );
  }
}

export default CustomerDetail;