import React,  { forwardRef } from 'react';
import { Container, Row, Button, Nav, Col, Card, ListGroup } from 'react-bootstrap';
import { servicerequestService } from '../../service/servicerequestService';
import { assetService } from '../../service/assetService';
import { uploadService } from '../../service/uploadService';

import '../../css/Chip.css';
import Chip from '@material-ui/core/Chip';
import moment from "moment";

import MaterialTable from "material-table";

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

import {DropzoneDialog} from 'material-ui-dropzone'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

class AssetDetail extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        companyid: undefined,
        clientid: undefined,
        assetid: undefined,
        tabbody: undefined,
        locationobjs: [],
        locationcolms: [
          {
            field: 'location',
            title: 'Location',
            sort: true,            
          },
          {
            field: 'sublocation',
            title: 'Sub Location',
            sort: true,            
          },
          {
            field: 'department',
            title: 'Department',
            sort: true,            
          },
          {
            field: 'personname',
            title: 'Contact',
            sort: true,            
          },
          {
            field: 'personid',
            title: 'Contact #',
            sort: true,            
          },
          {
            field: 'assigneddate',
            title: 'Assigned Date',
            sort: true,            
            render: rowData => {
              var date = moment(rowData.assigneddate);
              return (
                  <span> {date.format('ll')}</span>
              );            
            }
          },
          {
            field: 'otherinformation',
            title: 'Remark',
            sort: true,            
          },
        ],
        workorderobjs: [],
        workordercolms: [
          {
            field: 'id',
            title: 'Order #',
            sort: true,            
          },
          {
            field: 'detail',
            title: 'Detail',
            sort: true,            
          },
          {
            field: 'problemtype',
            title: 'Category',
            sort: true,            
          },
          {
            field: 'type',
            title: 'Type',
            sort: true,            
          },
          {
            field: 'priority',
            title: 'Priority',
            sort: true,            
            render: rowData => {
              let config = JSON.parse(localStorage.getItem('config'));
              let st = JSON.parse(config.serviceorderpriotities);
  
              if (st[rowData.priority] === 'High') {
                return (
                    // <span className="high"> {st[rowData.priority]}</span>  
                    <Chip label={st[rowData.priority]} className="chip redchip" variant="default" style={{marginBottom: '-3px'}}/>                
                )
              } else if (st[rowData.priority] === 'Medium') {
                return (
                    // <span className="medium"> {st[rowData.priority]}</span>
                    <Chip label={st[rowData.priority]} className="chip yellowchip" variant="default" style={{marginBottom: '-3px'}} /> 
                );            
              } else if (st[rowData.priority] === 'Low') {
                return (
                    // <span className="low"> {st[rowData.priority]}</span>
                    <Chip label={st[rowData.priority]} className="chip bluechip" variant="default" style={{marginBottom: '-3px'}} />
                );            
              } else {
                return (
                  <span>&nbsp;</span>
                );
              }
            }
          },
          {
            field: 'status',
            title: 'Status',
            sort: true,            
            render: rowData => {
              let config = JSON.parse(localStorage.getItem('config'));
              let st = JSON.parse(config.serviceorderstatuses);
              if (config.serviceorderstatuses === 1) {
                return (
                    <span> {st[rowData.status]}</span>
                );            
              } else if (config.serviceorderstatuses === 1) {
                return (
                    <span> {st[rowData.status]}</span>
                );            
              } else {
                return (
                    <span> {st[rowData.status]}</span>
                );            
              }
            }
          },
          {
            field: 'requesteddate',
            title: 'Requested Date',
            sort: true,            
            render: rowData => {
              var date = moment(rowData.requesteddate);
              return (
                  <span> {date.format('ll')}</span>
              );            
            }
          },           
        ],
        description: undefined,
        model: undefined,
        serial_no: undefined,
        manufacturer: undefined,
        addressid: undefined,
        assetnumber: undefined,
        asset_type: undefined,
        asset_status: undefined,
        vendorid: undefined,
        supplier: undefined,
        picture: undefined,
        document: undefined,     
        serviceproviderid: undefined,
        serviceprovider: undefined,
        acquisiondate: undefined,
        warrantyexpiration: undefined,

        assetdetailbody: undefined,

        docbody: undefined,
        picbody: undefined,
        // the below props for file upload
        open: false,
        files: [],
        imgaccesstype: ['image/jpeg', 'image/png', 'image/bmp'],
        docaccesstype: ['application/*'],
        // end of file upload props

        servicereports: undefined,
      }        
    }

    componentDidMount() {
      let user = JSON.parse(localStorage.getItem('user'));
      //console.log(user);
      this.state.companyid = JSON.parse(user.companyid);
      //console.log(this.state.companyid);
      
      const { assetid } = this.props.match.params;
      const { id } = this.props.match.params;
      this.state.clientid = id;
      if (assetid) {
        this.state.assetid = assetid;
        this.getAsset();
        this.getAssetLocations();
      }
    }

    getAsset() {
      assetService.getAsset(this.state.assetid).then(dataobjs => {
        if (dataobjs && dataobjs === 401) {
          this.props.history.push("/login");
        } else {    
            //console.log(dataobjs);  
            this.setState({
            description: dataobjs.description,
            model: dataobjs.model,
            serial_no: dataobjs.serial_no,
            manufacturer: dataobjs.manufacturer,
            lotnumber: dataobjs.lotnumber,
            addressid: dataobjs.addressid,
            assetnumber: dataobjs.assetnumber,
            asset_type: dataobjs.asset_type,
            asset_status: dataobjs.asset_status,
            vendorid: dataobjs.vendorid,
            supplier: dataobjs.supplier,
            serviceproviderid: dataobjs.serviceproviderid,
            serviceprovider: dataobjs.serviceprovider,
            picture: dataobjs.picture,
            document: dataobjs.document,
            acquisiondate: dataobjs.acquisiondate,
            warrantyexpiration: dataobjs.warrantyexpiration,
          })
          this.assetDetail();
          this.getDocumentBody();
          this.getImageBody();
        }
      });
    }

    getAssetLocations() {
      this.setState({tabbody: undefined});
      assetService.getAssetLocationList(this.state.assetid).then(dataobjs => {
        if (dataobjs && dataobjs === 401) {
          this.props.history.push("/login");
        } else {    
          //console.log('loc.............');
          //console.log(dataobjs);  
          this.state.locationobjs = dataobjs;        
          this.setAssetLocationBody();    
        }
      });
    }

    addNewAssetAssignment = () => {
      this.props.history.push('/assetlocation/' + this.state.assetid + '/desc/' + this.state.description, '', this.state)  
    }

    setAssetLocationBody = () => {
       
      let body =  (  
        
        <div style={{ width: '73rem' }}>            
        <MaterialTable
          icons={tableIcons}
          title={<button className="btn btn-outline-primary" style={{padding: '2px'}}  size="sm" onClick={ this.addNewAssetAssignment }>assign asset</button>  }
          columns={this.state.locationcolms}
          data={this.state.locationobjs}
          //title="Demo Title"
          options={{
            search: true,
            searchFieldAlignment: 'left',
            sorting: true,
            exportButton: true,
            //selection: true,
            pageSize: 10,
            headerStyle: {
              backgroundColor: '#edeff1',
              color: '#000',
              borderColor: '#9da6af',
              height:50,
            }                
          }}
          onRowClick={((evt, selectedRow) => {
            this.props.history.push('/assetlocation/' + this.state.assetid + '/desc/' + this.state.description + '/locid/' + selectedRow.id, '', this.state)                 
          })}
        />
      </div>

      );
      this.setState({tabbody: body});   
    }
    

    getWorkOrders() {
      this.setState({tabbody: undefined});
      servicerequestService.getServiceRequestListByAsset(this.state.assetid).then(dataobjs => {
        if (dataobjs && dataobjs === 401) {
          this.props.history.push("/login");
        } else {      
          this.state.workorderobjs = dataobjs;        
          this.setWorkOrderBody();    
        }
      });
    }

    getServiceReports() {
      this.setState({tabbody: undefined});
      assetService.getServiceReports(this.state.assetid).then(dataobjs => {
        if (dataobjs && dataobjs === 401) {
          this.props.history.push("/login");
        } else {      
          this.state.servicereports = dataobjs;    
          console.log('this.state.servicereports', this.state.servicereports);    
          this.setServiceReportBody();    
        }
      });
    }

    handleUploadReport = () => {
      //serviceedit/642
      this.props.history.push('/fileupload/' + this.state.assetid + '/client/' + this.state.clientid + '/report/true', '', this.state)  
    }

    deleteReport(file) {
      uploadService.deleteReport(file, this.state.assetid, this.state.clientid).then(dataobjs => {
        if (dataobjs && dataobjs === 401) {
          this.props.history.push("/login");
        } else {                      
            this.getServiceReports();
        }
      });
    }

    setServiceReportBody = () => {            
      let docs = (<Card><Card.Body>
        <button className="btn btn-outline-primary mb-1" size="sm"  style={{marginTop: '-10px', padding: '2px'}} onClick={ this.handleUploadReport }>upload report</button>
        <Card.Text>No service report is found</Card.Text>      
        </Card.Body></Card>);
      if (this.state.servicereports && this.state.servicereports != null && this.state.servicereports.length > 0) {
        //var tmp = this.state.document.split(',');
        docs = (<Card><Card.Body>
          <button className="btn btn-outline-primary mb-1" size="sm"  style={{marginTop: '-10px', padding: '2px'}} onClick={ this.handleUploadReport }>upload report</button>
          <ListGroup variant="flush">
          {this.state.servicereports.map(listitem => (
      <ListGroup.Item style={{padding: '0px'}}><Button  variant="link" onClick={() => {if(window.confirm('Remove the report?')){this.deleteReport(listitem.report)};}}><DeleteForeverIcon size="sm" /></Button>{'  ' + moment(listitem.createDate).format('ll')} - <a href={process.env.PUBLIC_URL + '/report/' + this.state.companyid + '/' + this.state.clientid + '/' + listitem.report} style={{color: 'blue', fontSize: '12px'}}>{listitem.report}</a></ListGroup.Item>                  
        ))}
          </ListGroup>        
          </Card.Body></Card>);
      }
      this.setState({
        tabbody: docs
      })
    }      
  

    handleAddNewOrder = () => {
      //serviceedit/642
      this.props.history.push('/serviceedit/' + this.state.clientid + '/asset/' + this.state.assetid, '', this.state)  
    }

    setWorkOrderBody = () => {            
      let body =  (   
        
        <div style={{ width: '73rem' }}>            
        <MaterialTable
          icons={tableIcons}
          title={<button className="btn btn-outline-primary" style={{padding: '2px'}} size="sm"  onClick={ this.handleAddNewOrder }>add service request</button>  }
          columns={this.state.workordercolms}
          data={this.state.workorderobjs}
          //title="Demo Title"
          options={{
            search: true,
            searchFieldAlignment: 'left',
            sorting: true,
            exportButton: true,
            //selection: true,
            pageSize: 10,
            headerStyle: {
              backgroundColor: '#edeff1',
              color: '#000',
              borderColor: '#9da6af',
            }                
          }}
          onRowClick={((evt, selectedRow) => {
            this.props.history.push('/servicedetail/' + selectedRow.id, '', this.state)                
          })}
        />
      </div>
      
      );
      this.setState({tabbody: body});   
    }

    navSelected = (e) => {     
      if (e === '#location') {
        this.getAssetLocations();
      } else if (e === '#order') {
        this.getWorkOrders();
      } else if (e === '#report') {
        this.getServiceReports();
      }

    }  

    editAsset = () => {
      this.props.history.push('/assetedit/' + this.state.clientid + '/asset/' + this.state.assetid, '', this.state) ;               
    }

    deleteAsset = () => {
      assetService.softDeleteAsset(this.state.assetid).then(dataobjs => {
        if (dataobjs && dataobjs === 401) {
          this.props.history.push("/login");
        } else {      
          this.props.history.goBack();
        }
      });      
    }

    goBack = () => {      
      this.props.history.goBack();
    }

    getLotTitle() {
      let config = JSON.parse(localStorage.getItem('config'));
      ////
      if (config.other) {
        let tmp = JSON.parse(config.other);      
        if (tmp.assetlot) {
          return tmp.assetlot;
        }  else {
          return 'Lot #';
        }
      } else {
        return 'Lot #';
      }
    }    

    assetDetail = () => {

        this.setState({assetdetailbody: (<>
          <Button variant="outline-primary mb-2" size="sm"   style={{marginLeft: '-2px', padding: '2px'}}  onClick={this.goBack}>Back</Button>&nbsp;&nbsp;&nbsp;
          <Button variant="outline-primary mb-2" size="sm"   style={{marginLeft: '-2px', padding: '2px'}}  onClick={this.editAsset}>Edit</Button>               
          <div style={{float: 'right'}}>
            <Button variant="outline-danger mb-2" size="sm"   style={{marginLeft: '-2px', padding: '2px'}}  onClick={() => {if(window.confirm('Remove the asset?')){this.deleteAsset()};}}>Delete</Button>
          </div>
          <div class="row" style={{marginTop: '10px'}}>
            <div class="col">
              <div class="textgroup">
                  <span class="textlabel">Description</span>
                  <div className="textunderline" style={{fontWeight: 'bold'}}>{this.state.description}</div>
                </div>
                <div class="textgroup">
                  <span class="textlabel">Asset Tag</span>
                  <div className="textunderline">{this.state.assetnumber ? this.state.assetnumber : 'NA'}</div>
                </div>
                <div class="textgroup">
                  <span class="textlabel">{this.getLotTitle()}</span>
                  <div className="textunderline" >{this.state.lotnumber ? this.state.lotnumber: 'NA'}</div>
                </div>
                
            </div>
            <div class="col">
              <div class="textgroup">
                  <span class="textlabel">Model</span>
                  <div className="textunderline">{this.state.model ? this.state.model : 'NA'}</div>
                </div>                
                <div class="textgroup">
                  <span class="textlabel">Serial #</span>
                  <div className="textunderline">{this.state.serial_no ? this.state.serial_no : 'NA'}</div>
                </div>
                <div class="textgroup">
                  <span class="textlabel">Type</span>
                  <div className="textunderline" >{this.state.asset_type ? this.state.asset_type: 'NA'}</div>
              </div>               

            </div> 
            <div class="col">
              <div class="textgroup">
                  <span class="textlabel">Status</span>
                  <div className="textunderline">{this.state.asset_status ? this.state.asset_status : 'NA'}</div>
              </div>
              <div class="textgroup">
                  <span class="textlabel">Acquisition date</span>
                  <div className="textunderline">{this.state.acquisiondate ? moment(this.state.acquisiondate).format('ll') : 'NA'}</div>
                </div>                                
              <div class="textgroup">
                  <span class="textlabel">Warranty expiration date</span>
                  <div className="textunderline">{this.state.warrantyexpiration ? moment(this.state.warrantyexpiration).format('ll') : 'NA'}</div>
              </div>                                
            </div>
            <div class="col">                              
                <div class="textgroup">
                  <span class="textlabel">Manufacturer</span>
                  <div className="textunderline">{this.state.manufacturer ? this.state.manufacturer : 'NA'}</div>
                </div> 
                <div class="textgroup">
                  <span class="textlabel">Service Provider</span>
                  <div className="textunderline">{this.state.serviceprovider ? this.state.serviceprovider : 'NA'}</div>
                </div>   
                <div class="textgroup">
                  <span class="textlabel">Supplier</span>
                  <div className="textunderline">{this.state.supplier ? this.state.supplier : 'NA'}</div>
              </div>              
            </div>           
          </div>
          
          </>)})
    
    }

    // imageUpload = () => {
    //   this.props.history.push('/fileupload/' + this.state.assetid + '/client/' + this.state.clientid + '/image/true' , '', this.state) ;               
    // }
    
    docUpload = () => {
       this.props.history.push('/fileupload/' + this.state.assetid + '/client/' + this.state.clientid, '', this.state) ;               
    }

    render() {
    
        return (
          <div className="content-screen">
            <div style={{ width: '73rem' }}>
          <Container>
            <div style={{textAlign: 'center', fontSize: '18px', fontWeight: 'bold', color: '#655b79'}}>Asset Detail</div>
            <Row style={{marginTop: '-10px'}}>
              <Col sm>
                
                  {this.state.assetdetailbody}
                
              </Col>
            </Row>
            <hr style={{border: '1px solid #8cb5bb', marginTop: '30px'}}></hr>
            <Row  style={{marginTop: '-10px'}}>
              
              <Col sm >   
              <span style={{fontWeight: 'bold'}}>Pictures</span>              
                <Card style={{  marginTop: '10px'}}>
                  <Card.Body style={{padding: '0px'}}>
                    {this.state.picbody}
                  </Card.Body>
                </Card>               
                <div>
                  <Button variant="outline-primary mt-1" size="sm" style={{padding: '2px'}}  onClick={this.handleOpen.bind(this)}>upload</Button>
                  <Button variant="outline-primary mt-1 ml-3" size="sm" style={{padding: '2px'}}  onClick={this.takePicture.bind(this)}>take picture</Button>
                  <DropzoneDialog
                      key="1"
                      open={this.state.open}
                      onSave={this.handleSaveImg.bind(this)}
                      acceptedFiles={this.state.imgaccesstype}
                      showPreviews={true}
                      maxFileSize={5000000}
                      onClose={this.handleClose.bind(this)}
                      filesLimit={1}
                  />
                </div>
              </Col>
              <Col sm>
                <span style={{fontWeight: 'bold'}}>Documents</span>
                <Card style={{marginTop: '10px'}}>
                  <Card.Body style={{padding: '0px'}}>                    
                    {this.state.docbody}
                  </Card.Body>
                </Card>  
                <div>
                    <Button variant="outline-primary mt-1" size="sm" style={{padding: '2px'}}  onClick={this.docUpload}>upload</Button>                    
                 </div>
              </Col>
            </Row>
            <hr style={{border: '1px solid #8cb5bb', marginTop: '50px'}}></hr>
            <Row  style={{marginTop: '10px'}} variant="tabs">
              <Col sm>
                <Nav  variant="tabs" defaultActiveKey="#location" onSelect={selectedKey => this.navSelected(selectedKey)}>
                  <Nav.Item>
                    <Nav.Link  href="#location"  style={{color: 'black'}}>Location and Assignment</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link  href="#order" style={{color: 'black'}}>Service Requests</Nav.Link>
                  </Nav.Item>                  
                  <Nav.Item>
                    <Nav.Link  href="#report" style={{color: 'black'}}>Service Reports</Nav.Link>
                  </Nav.Item>                  
                </Nav>
                <div style={{paddingTop: '20px', paddingBottom: '20px', width: '70em'}}>
                  {this.state.tabbody}
                </div>
              </Col>
            </Row>
        </Container>
        </div>
        </div>
        );
      }

      // this is for file upload
      handleClose() {
        this.setState({
            open: false
        });
      }

    //   handleSaveDoc(files) {
    //     this.setState({
    //         files: files, 
    //         open: false
    //     });
    //     console.log(files[0].name)
    //     files.forEach((file) => {
    //         const reader = new FileReader()
      
    //         reader.onabort = () => console.log('file reading was aborted')
    //         reader.onerror = () => console.log('file reading has failed')
    //         reader.onload = () => {
            
    //         const binaryStr = reader.result
    //         uploadService.uploadDocument(binaryStr, files[0].name, this.state.assetid, this.state.clientid).then(dataobjs => {
    //             if (dataobjs && dataobjs === 401) {
    //             var { from } = this.props.location.state || { from: { pathname: "/login" } };  
    //             this.props.history.push(from);
    //             } else {    
    //                 this.props.history.goBack();                    
    //             }
    //         });
    
    //         }
    //         reader.readAsArrayBuffer(file)
    //     })
    // }

    handleSaveImg(files) {
      //Saving files to state for further use and closing Modal.
      this.setState({
          files: files, 
          open: false
      });
      console.log(files[0].name)
      files.forEach((file) => {
          const reader = new FileReader()
    
          reader.onabort = () => console.log('file reading was aborted')
          reader.onerror = () => console.log('file reading has failed')
          reader.onload = () => {
          const binaryStr = reader.result
          console.log(binaryStr) ;
          uploadService.uploadImage(binaryStr, files[0].name, this.state.assetid, this.state.clientid).then(dataobjs => {
              if (dataobjs && dataobjs === 401) {
                this.props.history.push("/login");
              } else {    
                  //this.props.history.goBack();                    
                  this.getAsset();
              }
          });
           
          }
          reader.readAsArrayBuffer(file)
      })
  }

  handleOpen() {
      this.setState({
          open: true,
      });
  }

  takePicture () {
    this.props.history.push('/picture/' + this.state.assetid + '/client/' + this.state.clientid, '', this.state) ;               
  }

  getDocumentBody() {
    let docs = (<>
      <Card.Text style={{paddingTop: '10px', paddingBottom: '10px'}}> No document is found</Card.Text>      
      </>);
    if (this.state.document && this.state.document != null) {
      var tmp = this.state.document.split(',');
      docs = (<>
        <ListGroup variant="flush">
        {tmp.map(listitem => (
          <ListGroup.Item style={{padding: '0px'}}><Button  variant="link" onClick={() => {if(window.confirm('Remove the document?')){this.deleteDoc(listitem)};}}><DeleteForeverIcon size="sm" /></Button><a href={process.env.PUBLIC_URL + '/doc/' + this.state.companyid + '/' + this.state.clientid + '/' + listitem} style={{color: 'blue', fontSize: '12px'}}>{listitem}</a></ListGroup.Item>                  
      ))}
        </ListGroup>        
      </>);
    }
    this.setState({
      docbody: docs
    })
  }

  getImageBody() {
    let pics = (<>
      <Card.Text style={{paddingTop: '10px', paddingBottom: '10px'}}>No picture is found</Card.Text>      
      </>);
    if (this.state.picture && this.state.picture != null) {
      var tmp = this.state.picture.split(',');
      pics = (<>
        <Row>
        {tmp.map(listitem => (
          <div class="img-wrap">
          <Button variant="link" onClick={() => {if(window.confirm('Remove the picture?')){this.deleteImage(listitem)};}}>
          <span class="close">&times;</span>
          </Button>
          <a href={process.env.PUBLIC_URL + '/img/' + this.state.companyid + '/' + this.state.clientid + '/' + listitem}>
          <img width={191} className="mr-1 mb-1" src={process.env.PUBLIC_URL + '/img/' + this.state.companyid + '/' + this.state.clientid + '/' + listitem} alt="asset pic"/>                  
          </a>
          </div>
      ))}
        </Row>        
      </>);
    }
    this.setState({
      picbody: pics
    })
  }

  deleteImage(file) {
    uploadService.deleteFile(file, this.state.assetid, this.state.clientid, 'img').then(dataobjs => {
      if (dataobjs && dataobjs === 401) {
        this.props.history.push("/login");
      } else {                      
          this.getAsset();
      }
    });
  }

  deleteDoc(file) {
    uploadService.deleteFile(file, this.state.assetid, this.state.clientid, 'doc').then(dataobjs => {
      if (dataobjs && dataobjs === 401) {
        this.props.history.push("/login");
      } else {                      
          this.getAsset();
      }
    });
  }
}

    

export default AssetDetail;