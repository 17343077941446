import React, { Component, Fragment } from 'react'
import { Link, withRouter } from 'react-router-dom'
import {
  AppBar, Toolbar, IconButton, Typography, Hidden,
  Drawer, CssBaseline, MenuList, MenuItem, ListItemIcon, Button
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { Menu } from '@material-ui/icons'
import { compose } from 'recompose'
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';


import DateRangeIcon from '@material-ui/icons/DateRange';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import DevicesOtherIcon from '@material-ui/icons/DevicesOther';
import TableChartIcon from '@material-ui/icons/TableChart';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import BusinessIcon from '@material-ui/icons/Business';
import StorefrontIcon from '@material-ui/icons/Storefront';
import SettingIcon from '@material-ui/icons/Settings';
import SettingAppIcon from '@material-ui/icons/SettingsApplications';
import AlarmIcon from '@material-ui/icons/AlarmAdd';


const drawerWidth = 230

const styles = theme => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    overflow: 'scroll',
    position: 'relative',
    display: 'flex',
    width: '100%',
    //backgroundColor: '#354052',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#425c84',
    height: '50px',  
  },
  navIconHide: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.up('md')]: {
        width: drawerWidth,
        overflow: "scroll",
        position: 'relative',
        height: "100%",  
    },
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    paddingLeft: theme.spacing(2),
    marginTop: '-10px',
    overflowY: 'scroll',
    overflowX: 'scroll',
    height: "100%"
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
})

class Layout extends Component {
  state = {
    mobileOpen: false,
    settingOpen: false,
  }

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen })
  }

  handleClick = () => {
    this.setState({settingOpen: !this.state.settingOpen});
  };

  render() {
    let tmp = JSON.parse(localStorage.getItem('user')); 
    //console.log(tmp);
    let companyname = '';
    if (tmp) {
      companyname = tmp.config.compnayname;
    }

    const { classes, location: { pathname }, children, writers } = this.props
    const { mobileOpen } = this.state

    const drawer = (
      <div
           className={classes.toolbar}>
        <Hidden smDown>
          <div className={classes.toolbar} />
        </Hidden>
        <MenuList>
          <MenuItem component={Link} to="/services" selected={'/services' === pathname}>
          <ListItemIcon>
            <FileCopyIcon fontSize="small" />
          </ListItemIcon>
            Service Orders
          </MenuItem>                            

          <MenuItem component={Link} to="/myactivity" selected={'/myactivity' === pathname}>
          <ListItemIcon>
            <FormatListBulletedIcon fontSize="small" />
          </ListItemIcon>
            My Activity
          </MenuItem>

          <MenuItem component={Link} to="/clientlist" selected={'/clientlist' === pathname}>
            <ListItemIcon>
              <BusinessIcon fontSize="small" />
            </ListItemIcon>
              Customers
          </MenuItem>

          <MenuItem component={Link} to="/calendar" selected={'/calendar' === pathname}>
          <ListItemIcon>
            <DateRangeIcon fontSize="small" />
          </ListItemIcon>
            Scheduling
          </MenuItem>          

          <MenuItem component={Link} to="/vendoritemlist" selected={'/vendoritemlist' === pathname}>
            <ListItemIcon>
              <StorefrontIcon fontSize="small" />
            </ListItemIcon>
              Vendor Catalog
          </MenuItem>

          <MenuItem component={Link} to="/assets" selected={'/clntassets' === pathname}>
          <ListItemIcon>
            <DevicesOtherIcon fontSize="small" />
          </ListItemIcon>
            Assets
          </MenuItem>

          <MenuItem component={Link} to="/recallreport" selected={'/recallreport' === pathname}>
          <ListItemIcon>
            <TableChartIcon fontSize="small" />
          </ListItemIcon>
            Asset Recall
          </MenuItem>
          
          <MenuItem component={Link} to="/timesheet" selected={'/timesheet' === pathname}>
          <ListItemIcon>
            <AvTimerIcon fontSize="small" />
          </ListItemIcon>
            Timesheet
          </MenuItem>


          <MenuItem button onClick={this.handleClick}>
            <ListItemIcon>
            <SettingIcon fontSize="small" />
            </ListItemIcon>
            Setting&nbsp;&nbsp;&nbsp;&nbsp;
            {this.state.settingOpen ? <ExpandLess /> : <ExpandMore />}
          </MenuItem>

          <Collapse in={this.state.settingOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>                                        

              <MenuItem component={Link} to="/recurrancelist" selected={'/recurrancelist' === pathname}className={classes.nested}>
                <ListItemIcon>
                  <AlarmIcon fontSize="small" />
                </ListItemIcon>
                  Recurrance Order
              </MenuItem>

              <MenuItem component={Link} to="/resources" selected={'/resources' === pathname}className={classes.nested}>
                <ListItemIcon>
                  <PeopleAltIcon fontSize="small" />
                </ListItemIcon>
                  Resources
              </MenuItem>
              
              <MenuItem component={Link} to="/checklistformlist" selected={'/checklistformlist' === pathname}className={classes.nested}>
                <ListItemIcon>
                  <MenuBookIcon fontSize="small" />
                </ListItemIcon>
                  Checklist Forms
              </MenuItem>

              <MenuItem component={Link} to="/skills" selected={'/skills' === pathname}className={classes.nested}>
                <ListItemIcon>
                  <MenuBookIcon fontSize="small" />
                </ListItemIcon>
                  Skills
              </MenuItem>

              <MenuItem component={Link} to="/providers" selected={'/providers' === pathname} className={classes.nested}>
                <ListItemIcon>
                  <LocalShippingIcon fontSize="small" />
                </ListItemIcon>
                  Providers
              </MenuItem>
              
              <MenuItem component={Link} to="/appconfig" selected={'/appconfig' === pathname}className={classes.nested}>
                <ListItemIcon>
                  <SettingAppIcon fontSize="small" />
                </ListItemIcon>
                  App Config
              </MenuItem>

            </List>
          </Collapse>

          
          {/* <MenuItem component={Link} to="/providers" selected={'/providers' === pathname}>
          <ListItemIcon>
            <LocalShippingIcon fontSize="small" />
          </ListItemIcon>
            Providers
          </MenuItem> */}
          
          {/* <MenuItem component={Link} to="/clientlist" selected={'/clientlist' === pathname}>
          <ListItemIcon>
            <BusinessIcon fontSize="small" />
          </ListItemIcon>
            Customers
          </MenuItem>

          <MenuItem component={Link} to="/vendorlist" selected={'/vendorlist' === pathname}>
          <ListItemIcon>
            <StorefrontIcon fontSize="small" />
          </ListItemIcon>
            Vendor Catalog
          </MenuItem>

          <MenuItem component={Link} to="/resources" selected={'/resources' === pathname}>
          <ListItemIcon>
            <PeopleAltIcon fontSize="small" />
          </ListItemIcon>
            Resources
          </MenuItem>
          <MenuItem component={Link} to="/skills" selected={'/skills' === pathname}>
          <ListItemIcon>
            <MenuBookIcon fontSize="small" />
          </ListItemIcon>
            Skills
          </MenuItem> */}

          
          {/* <MenuItem component={Link} to="/login" selected={'/login' === pathname}>
          <ListItemIcon>
            <ExitToAppIcon fontSize="small" />
          </ListItemIcon>
            Logout
          </MenuItem> */}
          
          {/* <MenuList>
            {writers.map(({ id, name }) => {
              const to = `/writers/${id}`

              return <MenuItem
                to={to}
                key={id}
                className={classes.nested}
                component={Link}
                selected={to === pathname}
              >
                {name}
              </MenuItem>
            })}
          </MenuList> */}
        </MenuList>
      </div>
    )

    return <Fragment>
      <CssBaseline/>

      <div className={classes.root}>
        <AppBar elevation={0} position="absolute" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={this.handleDrawerToggle}
              className={classes.navIconHide}
            >
              <Menu />
            </IconButton>
            {/* <Typography variant="h6" color="inherit" noWrap> */}
               <div className="h6">
                <div style={{float: 'left', flex: '1'}}>{companyname}</div>                    
              </div>          
            {/* </Typography> */}
            <section style={{marginLeft: 'auto',marginRight: '10px'}}>
            {
              <div className="h6">
                <MenuItem component={Link} to="/login" selected={'/login' === pathname}>                  
                  Logout
                </MenuItem>                  
            </div>     
            }
            </section>
          </Toolbar>
        </AppBar>
        <Hidden mdUp>
          <Drawer            
            variant="temporary"
            open={mobileOpen}
            onClose={this.handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            variant="permanent"
            open
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {children}     
        </main>
      </div>              
    </Fragment>
  }
}

export default compose(
  withRouter,
  withStyles(styles)
)(Layout)