import React from "react";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { Form, Button, Col } from 'react-bootstrap';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { Multiselect } from "multiselect-react-dropdown";
import { configService } from '../../service/configService';

class SkillDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: undefined,
      skill: undefined,
      description: undefined,
    };

    this.handleCancel = this.handleCancel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);   
    this.handleInputChange = this.handleInputChange.bind(this);       
  }

  componentDidMount() {
    const { skl } = this.props.match.params;
    //console.log(skl);    

    let config = JSON.parse(localStorage.getItem('config'));
    let peopleskills = JSON.parse(config.peopleskills);
    let desc;
    let found = false;
    if (skl) {
      for (let i=0; i<peopleskills.length; i++) {        
        if (skl ===   peopleskills[i].skill) {
          found = true;
          desc = peopleskills[i].description; 
          break;    
        }
      }
    }

    if (skl && found) {
      this.setState({id: skl, skill: skl, description: desc});
    }    
  }
  
  handleInputChange(event) {
    const target = event.target;
    const name = target.id;    
    this.setState({
      [name]: target.value
    });
    
  }

  handleSubmit = (event) => {
    event.preventDefault();

    let config = JSON.parse(localStorage.getItem('config'));
    let peopleskills = JSON.parse(config.peopleskills);
    
    /*
    if (this.state.id) {  
      for (let i=0; i<peopleskills.length; i++) {        
        if (this.state.id ===   peopleskills[i].skill) {
          peopleskills[i].skill = this.state.skill;
          peopleskills[i].description = this.state.description;
          break;    
        }
      }
    } else {
      let skl = {};
      skl.skill = this.state.skill;
      skl.description = this.state.description;
      peopleskills.push(skl);
    }*/
    
    let found = false;
    for (let i=0; i<peopleskills.length; i++) {        
      if (this.state.skill ===   peopleskills[i].skill) {
        peopleskills[i].skill = this.state.skill;
        peopleskills[i].description = this.state.description;
        found = true;
        break;    
      }
    }
    if (!found) {
      let skl = {};
      skl.skill = this.state.skill;
      skl.description = this.state.description;
      peopleskills.push(skl);
    }

    let skl = {
      "peopleskills": JSON.stringify(peopleskills),
      "id": config.id
    }

    configService.updateConfig(skl)
      .then(cfg => {
        if (cfg && cfg === 401) {
          this.props.history.push("/login");
        } else {
          var { from } = this.props.location.state || { from: { pathname: "/skills" } };
          this.props.history.push(from);
        }
      });    
  };
  
  handleCancel = (event) => {
    event.preventDefault();
    var { from } = this.props.location.state || { from: { pathname: "/skills" } };
    this.props.history.push(from);
  }

  handleDelete = (event) => {
    event.preventDefault();
    let config = JSON.parse(localStorage.getItem('config'));
    let peopleskills = JSON.parse(config.peopleskills);
    
    if (this.state.id) {  
      let found = false;
      for (let i=0; i<peopleskills.length; i++) {        
        if (this.state.id ===   peopleskills[i].skill) {
          peopleskills.splice(i, 1);
          found = true;
          break;    
        }
      }
      if (found) {
          let skl = {
            "peopleskills": JSON.stringify(peopleskills),
            "id": config.id
          }
          configService.updateConfig(skl)
            .then(cfg => {
              if (cfg && cfg === 401) {
                this.props.history.push("/login");
              } else {
                var { from } = this.props.location.state || { from: { pathname: "/skills" } };
                this.props.history.push(from);
              }
            }); 
      }  
    }
  }

  render() {
    return (   
      <div className="content-screen" style={{padding: '10px'}}>
        <div style={{width: '40rem'}}>
      <span style={{fontWeight: 'bold'}}> Edit Skill </span>
      <Form onSubmit={this.handleSubmit} style={{marginTop: '20px'}}>
        
        
       
        <Form.Row>
          <Form.Group as={Col} controlId="skill" 
              onChange={this.handleInputChange}>
            <Form.Label style={{fontSize: "14px"}}>Skill</Form.Label>
            <Form.Control size="sm" required="true" placeholder="Enter skill" defaultValue={this.state.skill} />
          </Form.Group>

          <Form.Group as={Col} controlId="description" onChange={this.handleInputChange}>
            <Form.Label style={{fontSize: "14px"}}>Description</Form.Label>
            <Form.Control size="sm" placeholder="Enter description" defaultValue={this.state.description} />
          </Form.Group>                
        </Form.Row>
        <Form.Row>
          
          <Button className="pull-right" variant="outline-primary" type="submit">Save</Button>
          <Button className="pull-right" variant="outline-primary ml-3" type="button"  onClick={this.handleCancel}>Cancel</Button>
          <Button className="pull-right" variant="outline-primary ml-3" type="button"  onClick={this.handleDelete}>Delete</Button>
          
        </Form.Row>
      </Form>
      </div>
    </div>
    );
  }
}

export default SkillDetail;