import React, { forwardRef } from "react";
import { resourceService } from '../../service/resourceService';

import MaterialTable from "material-table";

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

class ResourcesList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        resources: [],
        resource: {test: true},
        columns: [
        {
          field: 'id',
          title: 'Employee #',          
        },
        {
          field: 'fname',
          title: 'First Name',
        },{
          field: 'lname',
          title: 'Last Name',
        } , {
          field: 'phone',
          title: 'Phone',
        } , {
          field: 'position',
          title: 'Position',
        } , {
          field: 'department',
          title: 'Department',
        } , {
          field: 'region',
          title: 'Region',
        } , {
          field: 'skills',
          title: 'Skills',
          render: rowData => {
            let sk = rowData.skills;
            if (sk && sk != null) {
              let o = JSON.parse(sk);
              sk = '';
              for (let i=0; i<o.length; i++) {
                sk = sk + o[i].name;
                if ((i+1)<o.length) {
                  sk = sk + ', '
                }
              }
            }
            return (              
                <span > {sk}</span>
            );            
          }
        }
      
      ],
      // selectRow: {
      //   mode: 'radio',
      //   clickToSelect: true,
      //   hideSelectColumn: true,
      //   bgColor: '#adb5bd',
      //   onSelect: (row, isSelect, rowIndex, e) => {
      //     this.props.history.push('/resourcedetail/' + row.id);
      //   }
      // },      

    };
  }

  componentDidMount() {
    resourceService.getResourceList().then(resources => {
      if (resources && resources === 401) {
        this.props.history.push("/login");
      } else {
        this.setState({ resources })
      }
    });
    
  }

  handleAddNew = () => {
    this.props.history.push('/resourcedetail', '', this.state);
  };
  
  render() {
    return (   

      <div className="content-screen">
      <div style={{ width: '73rem' }} > 
        <MaterialTable
          icons={tableIcons}
          title={<button className="btn btn-outline-primary" onClick={ this.handleAddNew }>Add Resource</button>    }
          columns={this.state.columns}
          data={this.state.resources}
          //title="Demo Title"
          options={{
            search: true,
            searchFieldAlignment: 'left',
            sorting: true,
            exportButton: true,
            //selection: true,
            pageSize: 10,
            headerStyle: {
              backgroundColor: '#edeff1',
              color: '#000',
              borderColor: '#9da6af',
            }                
          }}
          onRowClick={((evt, selectedRow) => {
            this.props.history.push('/resourcedetail/' + selectedRow.id);             
          })}
        />
      </div>
      <div>&nbsp;</div>
    </div> 
    );
  }
}

export default ResourcesList;