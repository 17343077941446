import React from "react";
import { render } from "react-dom";
import { materialService } from '../../service/materialService';
import { assetService } from '../../service/assetService';
import { inventoryService } from '../../service/inventoryService';
import utility from '../../service/utility'
import { Card, Button, InputGroup, FormControl, Form, Col, Modal } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { Redirect } from "react-router-dom";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory from 'react-bootstrap-table2-editor';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';

class AddLineItemMaterial extends React.Component {  
  constructor(props) {
    super(props);
    this.state = {

      id: undefined,      
      serviceid: undefined,
      assetid: undefined,
      itemid: undefined,
      item: undefined,
      description: undefined,
      qty: undefined,
      listprice: undefined,
      discount: undefined,
      unitprice: undefined,
      tax1: undefined,
      tax01: undefined,
      purchase: undefined,
      labour: undefined,
      warehouseloc: undefined,
      ////
      editable: true,
      ////
      edititem: undefined,
      origitem: undefined,
      origItemId: undefined,
      // editdescription: undefined,
      // editqty: undefined,      
      // editdiscount: undefined,
      // editunitPrice: undefined,
      // edittax1: undefined,
      // edittax01: undefined,
      ///

      // searchitemId: undefined,
      // searchitem: undefined,
      // searchdescription: undefined,
      // searchqty: undefined,
      // searchlistPrice: undefined,
      // searchdiscount: undefined,
      // searchunitPrice: undefined,

      taxOptions:[],
      mouOptions: [],
      gobackto: undefined,
      searchmodal: false,
      invitems: [],
      invcolumns: [
        {
          dataField: 'id',
          text: 'id',
          hidden: true
        },{
          dataField: 'uniqueid',
          text: 'uniqueid',
          hidden: true
        }, {
          dataField: 'item',
          text: 'Item',
          hidden: false,
          sort: true,
          headerStyle: {
            backgroundColor: '#D9D8D9'
          },          
          formatter: (cellContent, row) => {
            let config = JSON.parse(localStorage.getItem('config'));
            //console.log(config);
            let st = JSON.parse(config.serviceorderstatuses);
            return (
              <h6><small>
                {row.item &&
                <div className="label label-success">{row.item}</div>
                }
                {row.description &&
                <div className="label label-success">{row.description}</div>
                }   
                {row.warehouseloc &&
                  <div className="label label-success">Loc # {row.warehouseloc}</div>
                }             
              </small></h6>
            );            
          }
        }, {
          dataField: 'description',
          text: 'Description',
          hidden: true,
          headerStyle: {
            backgroundColor: '#D9D8D9'
          }
        }, {
          dataField: 'inventory',
          text: 'Quantity',
          hidden: true,
          headerStyle: {
            backgroundColor: '#D9D8D9'
          }
        },{
          dataField: 'discount',
          text: 'Discount',
          hidden: true,
          headerStyle: {
            backgroundColor: '#D9D8D9'
          }
        },{
          dataField: 'warehouseloc',
          text: 'Warehouse',
          hidden: true,
          headerStyle: {
            backgroundColor: '#D9D8D9'
          }
        },{
          dataField: 'unitofmeasure',
          text: 'UoM',
          hidden: true,
          headerStyle: {
            backgroundColor: '#D9D8D9'
          }
        },{
          dataField: 'actualunitcost',
          text: 'Purchased Cost',
          hidden: true,
          headerStyle: {
            backgroundColor: '#D9D8D9'
          }
        }, {
          dataField: 'unitcost',
          text: 'Qty and Unit Price',
          hidden: false,
          headerStyle: {
            backgroundColor: '#D9D8D9'
          },
          formatter: (cellContent, row) => {
            let config = JSON.parse(localStorage.getItem('config'));
            //console.log(config);
            let st = JSON.parse(config.serviceorderstatuses);
            let qt = 0;
            if (row.inventory) {
              qt = row.inventory;
            }
            let up = 0;
            if (row.unitcost) {
              up = Number(row.unitcost).toFixed(2);
            }
            return (
              <h6><small>              
                {
                <div className="label label-success">Qty: {qt} </div>
                }
                {
                <div className="label label-success">Unit Price: {up}</div>
                }
              </small></h6>
            );            
          }
        },
        {
          dataField: 'location_name',
          text: 'Warehouse',
          sort: true,
          // filter: textFilter(),
          // headerFormatter: this.warehouseFormatter,
          headerStyle: {
            backgroundColor: '#D9D8D9'
          }
        },],
        selectInvRow: {
          mode: 'radio',
          clickToSelect: true,
          hideSelectColumn: true,
          bgColor: '#adb5bd',
          onSelect: (row, isSelect, rowIndex, e) => {
            this.handleSelectedLineItem(row);
          }
        },
        selectedRow: undefined,
        assets: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);   
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSelectItem = this.handleSelectItem.bind(this);
    this.handleSelectItemClose = this.handleSelectItemClose.bind(this);
    this.handleSelectedLineItem = this.handleSelectedLineItem.bind(this);
    this.searchItems = this.searchItems.bind(this);
  }

  warehouseFormatter(column, colIndex, { sortElement, filterElement }) {
    return (
      <div style={ { display: 'flex', flexDirection: 'column' } }>
        { filterElement }
        { column.text }
        { sortElement }
      </div>
    );
  }

  componentDidMount() {  
    
    let usr = JSON.parse(localStorage.getItem('user'));
    if (usr.config.mou) {
      this.setState({mouOptions: usr.config.mou});
    }
    if (usr.config.tax) {
      this.setState({taxOptions: usr.config.tax});
    }

    const { id } = this.props.match.params; //serviceid
    const { item } = this.props.match.params; //itemid - optional
    const { material } = this.props.match.params; //material - optional

    //this.state.itemid = item;
    this.state.materialid = material;
    this.state.serviceid = id;

    //console.log(this.state.id, this.state.invoiceId);
    if (this.state.materialid) {
      // get the sales order item from the database...
      materialService.getMaterial(this.state.materialid).then(mat => {
        if (mat && mat === 401) {
          this.props.history.push("/login");
        } else if (mat) {
          this.setState({
            id: mat.id,      
            serviceid: mat.serviceid,   
            assetid: mat.assetid,      
            itemid: mat.itemid,      
            item: mat.item,      
            description: mat.description,      
            qty: mat.qty,      
            listprice: mat.listprice,      
            discount: mat.discount,      
            unitprice: mat.unitprice,      
            tax1: mat.tax1,      
            tax01: mat.tax01,      
            purchase: mat.purchase,      
            labour: mat.labour,   
            warehouseloc: mat.warehouseloc,   
            editable: false,
          });          
        }
      });
    }

    // get asset info so that a material/labour can be associated with an asset
    assetService.getAssetForOrder(this.state.serviceid).then(ast => {
      if (ast && ast === 401) {
        this.props.history.push("/login");
      } else if (ast && ast.length > 0) {
        let tmp = [];
        for (let i=0; i<ast.length; i++) {
          let o = {};
          o.id = ast[i].id;
          o.name = ast[i].description + ' Tag# ' + ast[i].assetnumber;
          tmp.push(o);
        }
        this.setState({assets: tmp});
        //console.log('getAssetForOrder...', this.state.assets);
      }
    });
  }

  handleInputChange(event) {
    const target = event.target;
    //alert(target.id + ', ' + target.value)
    const name = target.id;
    //alert(name + ', ' + target.value);
    if (name === 'tax01' && target.value === "Choose...") {
      this.setState({tax1: undefined,
                     tax01: undefined})
    }    
    else {
      this.setState({
        [name]: target.value
      });
      if (name === 'tax01') {
        //alert(JSON.stringify(this.state.taxOptions));
        for (let i=0; i<this.state.taxOptions.length; i++) {
          let o = this.state.taxOptions[i];
          if (o["name"] === target.value) {
            //alert(o["rate"]);         
            this.setState({tax1: o["rate"]});         
          }
        }
      } 
    }
  }
  
  handleSubmit(event) {
    event.preventDefault();
    //alert('handleSubmit...:' + this.state.invoiceId);
    let item = {};
    if (this.state.unitprice) {
      this.state.unitprice = Number(this.state.unitprice).toFixed(2);
    }
    
    if (this.state.id) {
      // this is an update operation...
      // did user change item?
      if (this.state.item != this.state.origitem) {
        // user changed the item - this means itemid must be changed
        if (this.state.itemid && this.state.origItemId && (this.state.itemid === this.state.origItemId)) {
          // user directly change the item code without getting it from inventory
          this.state.itemId = undefined;
        } 
      }
    }
    
    if (this.state.id) {
      item.id = this.state.id;      
    } 
    item.serviceid = this.state.serviceid;
    item.assetid = this.state.assetid;
    item.itemid = this.state.itemid;
    item.item = this.state.item;
    item.description = this.state.description;
    item.qty = this.state.qty;
    item.listprice = this.state.listprice;
    item.discount = this.state.discount;
    item.unitprice = this.state.unitprice;
    item.tax1 = this.state.tax1;
    item.tax01 = this.state.tax01;
    item.purchase = this.state.purchase;
    item.labour = this.state.labour;
    item.warehouseloc = this.state.warehouseloc;

    if (this.state.id) {  
      materialService.updateMaterial(item, this.state.id).then(solitm => {
        if (solitm && solitm === 401) {
          this.props.history.push("/login");
        } else {
          //goback...        
          //this.setState({gobackto: '/ecinvoice/' + this.state.invoiceId + '/serviceid/' + this.state.serviceid});        
          this.props.history.goBack();
        }
      });
    } else {
      materialService.addMaterial(item).then(solitm => {
        if (solitm && solitm === 401) {
          this.props.history.push("/login");
        } else {
          //goback...        
          //this.setState({gobackto: '/ecinvoice/' + this.state.invoiceId + '/serviceid/' + this.state.serviceid});        
          this.props.history.goBack();
        }
      });
    }
  }

  handleDelete(event) {
 
    materialService.removeMaterial(this.state.id).then(solitm => {
      if (solitm && solitm === 401) {
        this.props.history.push("/login");
      } else  {
        //goback...
        //this.setState({gobackto: '/ecinvoice/' + this.state.invoiceId + '/serviceid/' + this.state.serviceid});        
        this.props.history.goBack();
      }
    });
  }

  handleCancel(event) {
    //this.setState({gobackto: '/ecinvoice/' + this.state.invoiceId + '/serviceid/' + this.state.serviceid});        
    this.props.history.goBack();
  }

  searchItems = () => {
    //alert(this.state.item);
    //inventoryService
    inventoryService.searchItemByWarehouse(this.state.edititem).then(items => {
      if (items && items === 401) {
        this.props.history.push("/login");
      } else {
        console.log(items)
        this.setState({
          invitems: items,
          searchmodal: !this.state.searchmodal});            
      }
    });    
    
  }

  handleSelectItemClose = () => {
    this.setState({searchmodal: !this.state.searchmodal});
  }

  handleSelectItem = () => {
    if (this.state.selectedRow && this.state.selectedRow.id) {
      //alert(this.state.selectedRow.unitCost);
      this.setState({
        item: this.state.selectedRow.item,
        itemid: this.state.selectedRow.id,         
        description: this.state.selectedRow.description,
        qty: this.state.selectedRow.inventory,
        listprice: this.state.selectedRow.actualunitcost,
        discount: this.state.selectedRow.discount,
        unitprice: this.state.selectedRow.unitcost,
        warehouseloc: this.state.selectedRow.location_name,
        searchmodal: !this.state.searchmodal
      });
      //alert(JSON.stringify(this.state.selectedRow));
      //alert(this.state.selectedRow.item + ',' + this.state.edititem + ', ' + this.state.description);
    } else {
      this.setState({        
        searchmodal: !this.state.searchmodal
      });
    }    
  }

  handleSelectedLineItem = (row) => {
    //alert(JSON.stringify(row));
    this.setState({selectedRow: row});
    
    // this.setState({
    //   itemId: row.id,   
    //   item: row.item,
    //   description: row.description,
    //   qty: row.inventory,
    //   listPrice: row.actualUnitCost,
    //   discount: row.discount,
    //   unitPrice: Number(row.unitCost).toFixed(2)
    // });
  }

  render() {

    if (this.state.gobackto) {
      return <Redirect to={this.state.gobackto} />
    }

    let txOpt = [];
    txOpt.push(<option>Choose...</option>);
    if (this.state.taxOptions) {      
      for (let i=0; i<this.state.taxOptions.length; i++) {
        let obj = this.state.taxOptions[i];
        //console.log(obj);
        // txOpt.push(<option key={obj.name} value={obj.name}>{obj.name}</option>);
        if (this.state.tax01 && this.state.tax01 === obj.name) {
          txOpt.push(<option value={obj.name} selected>{obj.name}</option>);
        } else {
          txOpt.push(<option value={obj.name}>{obj.name}</option>);
        }
      }
    }

    let astOpt = [];
    astOpt.push(<option>Choose...</option>);
    console.log('render...', this.state.assets);
    if (this.state.assets) {      
      for (let i=0; i<this.state.assets.length; i++) {
        let obj = this.state.assets[i];
        //console.log(obj);
        // txOpt.push(<option key={obj.name} value={obj.name}>{obj.name}</option>);
        if (this.state.assetid && this.state.assetid === obj.id) {
          astOpt.push(<option value={obj.id} selected>{obj.name}</option>);
        } else {
          astOpt.push(<option value={obj.id}>{obj.name}</option>);
        }
      }
    }

    
    return (
      <div className="content-screen">    
        <Card style={{ width: '36rem' }}>
          <Card.Header>
            
            <Form>
                  <div style={{width: "70%", display: "inline-block"}}>
                  {this.state.editable &&  
                  <Form.Group as={Col} style={{marginBottom: '1px'}} controlId="edititem" onChange={this.handleInputChange}>
                      <InputGroup>
                      <Form.Control placeholder="Search item in inventory" defaultValue={this.state.edititem} />  
                      <InputGroup.Append>
                          <Button onClick={this.searchItems} variant="outline-primary">Search</Button>
                      </InputGroup.Append>
                      </InputGroup>
                  </Form.Group>
                  }
                  </div>
                  <div style={{float: 'right', display: "inline-block"}}>
                  {!this.state.editable &&  
                      <Button className="btn-sm" variant="link"  style={{color: 'red', textDecorationLine: 'underline', textDecorationColor: 'red'}} onClick={() => {if(window.confirm('Remove this line item?')){this.handleDelete()};}}>delete</Button>
                  }
                  </div>
                 
            </Form>
            
          </Card.Header>
          <Card.Body>      

              <Form noValidate onSubmit={this.handleSubmit}>
              <Form.Row></Form.Row>
                  <Form.Row>
                    {this.state.editable ?
                    <Form.Group as={Col} controlId="item" onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Item #</Form.Label>
                      <Form.Control placeholder="Enter item code" defaultValue={this.state.item} />                        
                    </Form.Group>
                    : <Form.Group as={Col} controlId="item" onChange={this.handleInputChange}>
                    <Form.Label style={{fontSize: "14px"}}>Item #</Form.Label>
                    <Form.Control disabled defaultValue={this.state.item} />                        
                  </Form.Group> 
                    }

                    {/* <Form.Group as={Col} controlId="edititem" onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Item #</Form.Label>
                      <InputGroup>
                      <Form.Control placeholder="Enter item code" defaultValue={this.state.edititem} />  
                      <InputGroup.Append>
                          <Button onClick={this.searchItems} variant="secondary">Search</Button>
                      </InputGroup.Append>
                      </InputGroup>
                    </Form.Group> */}
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="description" onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Description</Form.Label>
                      <Form.Control required="true" placeholder="Enter item description" defaultValue={this.state.description}></Form.Control>            
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="qty" onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Quantity</Form.Label>
                      <Form.Control required="true" type="Number" placeholder="Enter item quantity" defaultValue={this.state.qty}></Form.Control>            
                    </Form.Group>
                  </Form.Row>
                  {this.state.warehouseloc &&
                    <Form.Row>
                    <Form.Group as={Col} controlId="warehouseloc" onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Warehouse</Form.Label>
                      <Form.Control disabled defaultValue={this.state.warehouseloc}></Form.Control>            
                    </Form.Group>
                  </Form.Row>
                  }
                  <Form.Row>
                    <Form.Group as={Col} controlId="unitprice" onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Unit Price</Form.Label>
                      <Form.Control required="true" type="Number" placeholder="Enter unit price" defaultValue={this.state.unitprice}></Form.Control>            
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="discount" onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Discount</Form.Label>
                      <Form.Control type="Number" placeholder="Enter item discount" defaultValue={this.state.discount}></Form.Control>                        
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="tax01" onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Tax</Form.Label>
                      <Form.Control as="select" defaultValue={this.state.tax01} ref="templateSelect">
                            {txOpt}                            
                      </Form.Control>            
                    </Form.Group>
                   </Form.Row>

                   <Form.Row>
                    <Form.Group as={Col} controlId="assetid" onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Asset</Form.Label>
                      <Form.Control as="select" defaultValue={this.state.assetid} ref="templateSelect">
                            {astOpt}                            
                      </Form.Control>            
                    </Form.Group>
                   </Form.Row>

                   <div style={{float: "left"}} >                     
                    {this.state.id && 
                      <Button type="submit" variant="outline-primary  mr-3" size="sm" >Update</Button>
                      }
                    {!this.state.id && 
                      <Button type="submit" variant="outline-primary  mr-3" size="sm">Save</Button>
                      } 
                      <Button onClick={this.handleCancel} variant="outline-primary  mr-3" size="sm" >Cancel</Button>
                    </div>
              </Form>
              
          </Card.Body>
          
        </Card>

        <>
                <Modal show={this.state.searchmodal} onHide={this.handleSelectItemClose.bind(this)}
                  
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Header closeButton>              
                    <Modal.Title>Select an inventory item</Modal.Title>              
                  </Modal.Header>
                  <Modal.Body>
                        <ToolkitProvider
                        bootstrap4
                        keyField='uniqueid'
                        data={ this.state.invitems }
                        columns={ this.state.invcolumns }
                        search                        
                      >
                        {
                          props => (
                            <div> 
                                                       
                              <BootstrapTable style={{scrollY: "200px",scrollCollapse: true}}
                                bootstrap4
                                { ...props.baseProps }
                                { ...props.paginationFactory }
                                { ...props.striped }
                                { ...props.defaultEquipSorted }
                                filter={ filterFactory() }
                                selectRow={ this.state.selectInvRow }
                                //pagination={ paginationFactory( {sizePerPage: 6}) }                     
                                pagination={ paginationFactory({hideSizePerPage: true}) }                     
                                bordered = {false} 
                                condensed = {true}  
                                //defaultSorted = { this.state.defaultSorted } 
                                cellEdit={ cellEditFactory({ mode: 'click', blurToSave: true }) }
                              />
                            </div>
                          )
                        }
                      </ToolkitProvider>

                  </Modal.Body>
                  <Modal.Footer style={{justifyContent: 'left'}}>
                  <div style={{float: "left"}} > 
                    <Button variant="outline-primary mr-3" onClick={this.handleSelectItem.bind(this)}>
                      Select
                    </Button> 
                    <Button variant="outline-primary" onClick={this.handleSelectItemClose.bind(this)}>
                      Cancel
                    </Button>                      
                    </div>                 
                  </Modal.Footer>
                </Modal>
                </>

      </div>
    );
  }
}

export default AddLineItemMaterial;