import { authHeader } from './authHeader.js';
import {userService} from './userService.js'

var config = require('./config.js');

export const reportService = {
    getResourceUtilizationRange,
    getRecallAssets,
};

function getRecallAssets(asset) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',    
        body: JSON.stringify(asset)
    };
    return fetch(config.app.url + '/api/reportrecallasset', requestOptions).then(handleResponse);
}

function getResourceUtilizationRange(from, to) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + '/api/reportresourceutilization/' + from + '/enddate/' + to , requestOptions).then(handleResponse);
}

function handleResponse(response) {
    if (response.status === 401) {
        return 401;
    }
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                //location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        console.log(data.data);
        return data.data;
    });
}




