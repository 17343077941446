import React from 'react'
import { Button } from 'react-bootstrap';
import SignaturePad from 'react-signature-pad-wrapper'
import { Redirect } from "react-router-dom";

import { signService } from '../../service/signService';

class Signature extends React.Component {
        constructor(props) {
          super(props);
      
          this.state = {
              serviceid: undefined,
              signid: undefined,
              goBack: undefined,
          }
    }

    componentDidMount() {
        const { id } = this.props.match.params;
        this.setState({serviceid: id});
        this.signaturePad.penColor = 'rgb(66, 133, 244)';
        this.signaturePad.backgroundColor= 'rgb(255,255,255)';
        this.signaturePad.instance.clear();
        signService.getSignature(id).then(ord => {
            if (ord && ord === 401) {
                this.props.history.push("/login");
            } else {
                if (ord.id) {
                    this.state.signid = ord.id;
                }
            }
        });          
    }



    handleClear() {
        this.signaturePad.instance.clear();
    }

    handlegoBack() {
        this.props.history.goBack();
    }

    handleSave() {
        if (this.signaturePad.isEmpty()) {
            // eslint-disable-next-line no-alert
            alert('Please provide a signature first.');
        } else {
            //console.log(this.signaturePad.toDataURL("image/jpeg")); 
            let order = {};
            order.serviceid = this.state.serviceid;
            order.Signature = this.signaturePad.toDataURL("image/jpeg");
            order.signaturedate = new Date();
            if (this.state.signid) {
                order.id = this.state.signid;
                signService.updateSignature(order, this.state.signid).then(ord => {
                    if (ord && ord === 401) {
                        this.props.history.push("/login");
                    } else {
                        this.props.history.goBack();
                        this.setState({goBack: true});
                    }
                }); 
            } else {
                signService.addSignature(order).then(ord => {
                    if (ord && ord === 401) {
                        this.props.history.push("/login");
                    } else {
                        this.props.history.goBack();
                        this.setState({goBack: true});                    
                    }
                });     
            }      
        }
    }

    renderTitle() {
        return (
            <div className="columns">
                <div className="column">
        <h6 className="title" style={{fontWeight: 'bold', marginTop: '6px'}}>Sign the Service Order # {this.state.serviceid}</h6>        
                </div>
            </div>
        );
    }
    

    renderSignaturePad() {
        
        return (
            <div className="columns">
                <div className="column is-10-tablet is-offset-1-tablet is-8-desktop is-offset-2-desktop">
                    <div className="card" style={{ width: '27rem' }}>
                        <div className="card-content">
                            {/* <div className="content"> */}
                                <SignaturePad
                                    redrawOnResize={true}                                    
                                    ref={ref => this.signaturePad = ref} height={130}  />
                            {/* </div> */}
                        </div>
                    </div>
                    <Button variant="outline-primary mt-1" size="sm"  onClick={this.handleClear.bind(this)}>Clear</Button>
                    <Button variant="outline-primary ml-3 mt-1" size="sm"  onClick={this.handlegoBack.bind(this)}>Cancel</Button>
                    <Button variant="outline-primary ml-3 mt-1" size="sm" onClick={this.handleSave.bind(this)}>Save</Button>                               
                </div>
            </div>
        );
    }

    render() {
        if (this.state.goBack) {
            return <Redirect to={"/servicedetail/" + this.state.serviceid } />            
        }

        return (
            <section className="section">
                <div className="container">
                    {this.renderTitle()}
                    {this.renderSignaturePad()}
                </div>
            </section>
        );
    }

}

export default Signature;