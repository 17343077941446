import React, { forwardRef } from "react";
import { catalogService } from '../../service/catalogService';

import MaterialTable from "material-table";

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

class VendorItemList extends React.Component {
  constructor(props) {
    super(props);
    props.location.search= localStorage.getItem('vendoritemsearch');
    this.state = {
        clients: [],
        
        columns: [
        {
          field: 'id',
          title: 'catalo #',          
          hidden: true,
        },
        {
          field: 'item',
          title: 'Item',          
        },
        {
          field: 'name',
          title: 'Vendor',
        },
        {
          field: 'vendorcode',
          title: 'Vendor Code',
        },
        {
          field: 'unitprice',
          title: 'Vendor Price',
          render: rowData => {
            let sk = (rowData.currency ? rowData.currency + ' ': '');
            sk = sk + (rowData.unitprice ? rowData.unitprice : '');            
            return (              
                <span > {sk}</span>
            );            
          }
        },
        {
          field: 'description',
          title: 'Vendor Description',
        }               
      ],
    };
  }

  componentDidMount() {
    //console.log(this.props.match);
    
    catalogService.getCatalogItemList().then(custs => {
        if (custs && custs === 401) {
          this.props.history.push("/login");
        } else {
          let tt = [];

          for (let i=0; i<custs.length; i++) {
            let oo = {};
            oo.id = custs[i].id;
            oo.name = custs[i].name;
            oo.item = custs[i].item;
            oo.vendorcode = custs[i].vendorcode;
            oo.unitprice = custs[i].unitprice;
            oo.currency = custs[i].currency;
            oo.description = custs[i].description;
            oo.vendorid = custs[i].vendorid;
            tt.push(oo);
          }
          this.setState({ clients: tt })
        }
      });
    
  }
  
  handleVendor = () => {
    var { from } = this.props.location.state || { from: { pathname: "/vendorlist" } };  
    this.props.history.push(from);
  }

  render() {
    return (   

      <div className="content-screen">
      <div style={{ width: '73rem' }}>            
        <MaterialTable
          icons={tableIcons}
          title={<button className="btn btn-outline-primary" size="sm"  onClick={ this.handleVendor }>By Vendor</button>}
          columns={this.state.columns}
          data={this.state.clients}
          //title="Demo Title"
          onSearchChange={(e) => {localStorage.setItem('vendoritemsearch', e)}}
          options={{
            searchText: this.props.location.search,
            search: true,
            searchFieldAlignment: 'left',
            sorting: true,
            exportButton: true,
            //selection: true,
            pageSize: 20,
            headerStyle: {
              backgroundColor: '#edeff1',
              color: '#000',
              borderColor: '#9da6af',
            }                
          }}
          onRowClick={((evt, selectedRow) => {
            this.props.history.push('/vendordetail/' + selectedRow.vendorid);
          })}
        />
      </div>
      <div>&nbsp;</div>
    </div> 
    );
  }
}

export default VendorItemList;