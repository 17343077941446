import React, { Component } from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import Layout from './components/content/Layout'
import Login from "./components/screens/Login";
import HomeMenu from "./HomeMenu"

export default class extends Component {
  render() {
    return (
      <div>
      <Switch>
        <Route path={'/login'} component={Login} />
        <Route path={'/'} component={HomeMenu} /> 
        <Redirect to="/" />
      </Switch>
      </div>
    );
  }
}
