import { authHeader } from './authHeader.js';
import {userService} from './userService.js'

var config = require('./config.js');

export const slareorderService = {
    getSo,
    addNewSo,
    updateSo,
    deleteSo,
    getSoLineItem,
    addOrUpdateSoLineItem,
    deleteSoItem
};

function getSo(soid) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + '/api/salesorder/' + soid , requestOptions).then(handleResponse);
}

function addNewSo(so, serviceid) {    
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(so)   
    };
    return fetch(config.app.url + '/api/salesorder/' + serviceid, requestOptions).then(handleResponse);
}

function updateSo(resource) {
    //console.log(resource);
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(resource)     
    };
    return fetch(config.app.url + '/api/salesorder/' + resource.id , requestOptions).then(handleResponse);
}

function deleteSo(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        credentials: 'include',
    };
    return fetch(config.app.url + '/api/salesorder/' + id , requestOptions).then(handleResponse);
}

function getSoLineItem(id, orderid) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + '/api/salesorderitem/' + id + '/order/' + orderid, requestOptions).then(handleResponse);
}

function addOrUpdateSoLineItem(orderid, item) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',  
        body: JSON.stringify(item)  
    };
    return fetch(config.app.url + '/api/salesorderitem/' + orderid, requestOptions).then(handleResponse);
}

function deleteSoItem(id, orderid) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        credentials: 'include',
    };
    return fetch(config.app.url + '/api/salesorderitem/' + id + '/order/' + orderid , requestOptions).then(handleResponse);
}

function handleResponse(response) {
    if (response.status === 401) {
        return 401;
    }
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                //location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        //console.log(data.data);
        return data.data;
    });
}

