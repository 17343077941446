import moment from "moment";
import React, { forwardRef } from "react";
import { servicerequestService } from '../../service/servicerequestService';
import MaterialTable from "material-table";

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import '../../css/Chip.css';
import Chip from '@material-ui/core/Chip';
import util from '../../service/utility';
import { CsvBuilder } from 'filefy';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };

class ServicesList extends React.Component {
  constructor(props) {
    super(props);
    props.location.search= localStorage.getItem('ordersearch');
    this.state = {
        dataobjs: [],
        searchtext: undefined,
        columns: [{
          field: 'id',
          title: 'Order #',          
        },
        {
          field: 'customername',
          title: 'Customer',
          render: rowData => {
            let txt = util.truncate(rowData.customername, 13);
            return (
              <span> {txt}</span>
            );
          }
        },
        {
          field: 'city',
          title: 'City',
          render: rowData => {
            let txt = util.truncate(rowData.city, 13);
            return (
              <span> {txt}</span>
            );
          }
        },{
          field: 'detail',
          title: 'Detail',
          render: rowData => {
            let txt = util.truncate(rowData.detail, 13);
            return (
              <span> {txt}</span>
            );
          }
        } , {
          field: 'status',
          title: 'Status',
          render: rowData => {
            let config = JSON.parse(localStorage.getItem('config'));
            let st = JSON.parse(config.serviceorderstatuses);
            if (config.serviceorderstatuses === 1) {
              return (
                  <span> {st[rowData.status]}</span>
              );            
            } else if (config.serviceorderstatuses === 1) {
              return (
                  <span> {st[rowData.status]}</span>
              );            
            } else {
              return (
                  <span> {st[rowData.status]}</span>
              );            
            }
          }
        } , {
          field: 'priority',
          title: 'Priority',
          render: rowData => {
            let config = JSON.parse(localStorage.getItem('config'));
            let st = JSON.parse(config.serviceorderpriotities);

            if (st[rowData.priority] === 'High') {
              return (
                  // <span className="high"> {st[rowData.priority]}</span>  
                  <Chip label={st[rowData.priority]} className="chip redchip" variant="default" style={{marginBottom: '-3px'}}/>                
              )
            } else if (st[rowData.priority] === 'Medium') {
              return (
                  // <span className="medium"> {st[rowData.priority]}</span>
                  <Chip label={st[rowData.priority]} className="chip yellowchip" variant="default" style={{marginBottom: '-3px'}} /> 
              );            
            } else if (st[rowData.priority] === 'Low') {
              return (
                  // <span className="low"> {st[rowData.priority]}</span>
                  <Chip label={st[rowData.priority]} className="chip bluechip" variant="default" style={{marginBottom: '-3px'}} />
              );            
            } else {
              return (
                <span>&nbsp;</span>
              );
            }
          }
        } , {
          field: 'problemtype',
          title: 'Category',
        } , {
          field: 'startdate',
          title: 'Start Date',
          render: rowData => {
            var date = moment(rowData.startdate);
            return (
                <span> {date.format('ll')}</span>
            );            
          }
        }      
      ],
      // selectRow: {
      //   mode: 'radio',
      //   clickToSelect: true,
      //   hideSelectColumn: true,
      //   bgColor: '#adb5bd',
      //   onSelect: (row, isSelect, rowIndex, e) => {
      //     this.props.history.push('/servicedetail/' + row.id, '', this.state);
      //   }
      // }
    };
  }

  componentDidMount() {
    servicerequestService.getServiceRequestList().then(dataobjs => {
      if (dataobjs && dataobjs === 401) {
        this.props.history.push("/login");
      } else {
        //console.log('testing...serviceorders...')
        //console.log(dataobjs);
        this.setState({ dataobjs })
      }
    });       
  }

  handleAddNew = () => {
    this.props.history.push('/serviceedit', '', this.state);
  };
  
  render() {    
    return (   

        <div className="content-screen">
          <div style={{ width: '73rem' }}>            
            <MaterialTable
              icons={tableIcons}
              title={<button className="btn btn-outline-primary"  onClick={ this.handleAddNew }>Add Service Order</button>  }
              columns={this.state.columns}
              data={this.state.dataobjs}
              //title="Demo Title"
              onSearchChange={(e) => {localStorage.setItem('ordersearch', e)}}
              options={{
                searchText: this.props.location.search,
                search: true,                
                searchFieldAlignment: 'left',
                sorting: true,
                exportButton: {
                  csv: true,
                  pdf: false
                },
        
                exportCsv: (data, columns) => {
                  //console.log(this.state.columns);
                  const columnTitles = this.state.columns
                      .map(columnDef => columnDef.title);
                  //console.log(columnTitles, this.state.dataobjs);
                  
                  const csvData = this.state.dataobjs.map(rowData => 
                        this.state.columns.map(columnDef =>
                          rowData[columnDef.field] ),
                        );
                  //console.log(csvData)
                  const builder = new CsvBuilder(`data.csv`)
                        .setColumns(columnTitles)
                        .addRows(csvData)
                        .exportFile();
                
                  return builder;
                },
                //selection: true,
                pageSize: 10,
                headerStyle: {
                  backgroundColor: '#edeff1',
                  color: '#000',
                  borderColor: '#9da6af',
                }                
              }}
              onRowClick={((evt, selectedRow) => {
                this.props.history.push('/servicedetail/' + selectedRow.id, '', this.state)                
              })}
            />
          </div>
          <div>&nbsp;</div>
        </div>  
    );
  }
}

export default ServicesList;