import React from "react";
import { Card, Button, Form, Col } from 'react-bootstrap';


import { assetService } from '../../service/assetService';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { TransferWithinAStationSharp } from "@material-ui/icons";


class AssetLocation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        description: undefined,
        assetid: undefined,
        id: undefined,
        
        location: undefined,
        sublocation: undefined,
        department: undefined,
        personname: undefined,
        personid: undefined,
        assigneddate: new Date(),
        otherinformation: undefined,
    }
  }

  componentDidMount() {  
    const {id} = this.props.match.params;
    const {desc} = this.props.match.params;      
    const {locid} = this.props.match.params;
    this.setState({description: desc, assetid: id});
    

    if (locid) {
      this.state.id = locid;
      // editing an existing location.
      assetService.getAssetLocation(this.state.id).then(loc => {
        this.setLocation(loc);
      });
    } 
  }

  setLocation(e) {
    this.setState({      
      location: e.location,
      sublocation: e.sublocation,
      department: e.department,
      personname: e.personname,
      personid: e.personid,
      assigneddate: (e.assigneddate ? new Date(e.assigneddate) : new Date()),
      otherinformation: e.otherinformation,
    });
  }

  
  handleInputChange = (event) => {    
    const target = event.target;
    const name = target.id;    
    this.setState({
      [name]: target.value
    });    
  }

  setDate = (date) => {
    this.setState({assigneddate: date});
  }

  handleSubmit = (event) => {
    event.preventDefault();
    
    let loc = {};
    loc.assetid = this.state.assetid;
    loc.id = this.state.id;
    loc.location = this.state.location;
    loc.sublocation = this.state.sublocation;
    loc.department = this.state.department;
    loc.personname = this.state.personname;
    loc.personid = this.state.personid;
    loc.assigneddate = this.state.assigneddate;
    loc.otherinformation = this.state.otherinformation;

    if (this.state.id) {
      // update...
      assetService.updateAssetLocation(loc, this.state.id).then(ast => {
        if (ast && ast === 401) {
          this.props.history.push("/login");
        } else {      
          this.props.history.goBack();
        }
      });
    } else {
      // add new
      assetService.addAssetLocation(loc).then(ast => {
        if (ast && ast === 401) {
          this.props.history.push("/login");
        } else {      
          this.props.history.goBack();
        }
      });
    }
    
  }

  goBack = (e) => {
    this.props.history.goBack();
  }
  
  render() {
  
    return (
      <div className="content-screen" style={{height: '100%', overflow: 'scroll'}}>
      
        <Card style={{ width: '40rem' }}>
          <Card.Header style={{fontWeight: 'bold'}}>Assignment for Asset: {this.state.description}</Card.Header>
          <Card.Body>            
            <div>
                <Form onSubmit={this.handleSubmit}>
                <Form.Row>  
                    <Form.Group as={Col} controlId="assigneddate">
                      <Form.Label  style={{fontSize: "14px"}}>Assign date</Form.Label>  
                      <div>
                      <DatePicker style={{displayValue: 'block'}}
                          selected={this.state.assigneddate}
                          onChange={date => this.setDate(date)}
                          dateFormat="MMM d, yyyy HH:mm"
                          placeholderText="Enter a date"
                          
                      /> </div>                   
                    </Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Form.Group as={Col} controlId="location" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Location</Form.Label>
                      <Form.Control required="true" placeholder="Enter location" defaultValue={this.state.location} />
                    </Form.Group>
                  </Form.Row> 

                  <Form.Row>
                    <Form.Group as={Col} controlId="sublocation" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Sub Location</Form.Label>
                      <Form.Control placeholder="Enter sub-location" defaultValue={this.state.sublocation} />
                    </Form.Group>
                  </Form.Row> 

                  <Form.Row>
                    <Form.Group as={Col} controlId="department" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Department</Form.Label>
                      <Form.Control placeholder="Enter department" defaultValue={this.state.department} />
                    </Form.Group>
                  </Form.Row> 

                  <Form.Row>
                    <Form.Group as={Col} controlId="personname" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Contact Name</Form.Label>
                      <Form.Control placeholder="Enter contact name" defaultValue={this.state.personname} />
                    </Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Form.Group as={Col} controlId="personid" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Contact ID</Form.Label>
                      <Form.Control placeholder="Enter contact id" defaultValue={this.state.personid} />
                    </Form.Group>
                  </Form.Row> 

                  <Form.Row>
                    <Form.Group as={Col} controlId="otherinformation" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Remark</Form.Label>
                      <Form.Control as="textarea" placeholder="Enter remark" defaultValue={this.state.otherinformation} />
                    </Form.Group>
                  </Form.Row> 
                  

                  <div style={{float: "left"}} >                                       
                    <Button variant="outline-primary mr-2" size="sm" type="submit">Save</Button> 
                    <Button variant="outline-primary" size="sm" onClick={() => {this.goBack()}}>Cancel</Button>                              
                  </div>
              </Form>
            </div>
          </Card.Body>
          <Card.Footer className="text-muted">&nbsp;</Card.Footer>       
        </Card>      

      </div>
    );
  }
}


export default AssetLocation;