import React from "react";
import { Card, Button, Form, Col } from 'react-bootstrap';
import { Redirect } from "react-router-dom";
import { configService } from '../../service/configService';



class AppConfig extends React.Component {
  constructor(props) {
    super(props);
    this.state = {        
        assettypes: [],
        serviceorderproblemtypes: [], //category
        serviceordertypes: [], //type
        startworkingtime: undefined,
        endworkingtime: undefined,
        other: undefined,        
        costcenter: undefined,
        managemyasset: false,
        servicenotes: undefined,
        autoassettag: false,
        assetagprefix: 'CT',

        assetlot: undefined,

        as1: undefined,
        as2: undefined,
        as3: undefined,
        as4: undefined,

        ct1: undefined,
        ct2: undefined,
        ct3: undefined,
        ct4: undefined,

        ty1: undefined,
        ty2: undefined,
        ty3: undefined,
        ty4: undefined,

        pdffrontpage: undefined,
    }
  }

  componentDidMount() {  
    //"{"costcenter":"CC01","managemyasset":true,"servicenotes":"Hotel, Meal, Car, Gas, Air, Travel on site hours","autoassettag":false,"assetagprefix":"CT2344","assetlot":"AssetID","pdffrontpage":"Dear Customer,\n\nIt is pleasure to serve you. Please find the attached service order report.\n\nRegards,\nHeller Tech Support\n123 Holler Dr\nDublin, MO"}"
    let config = JSON.parse(localStorage.getItem('config'));
    //console.log(config);
    this.setState({assettypes: JSON.parse(config.assettypes),
                  serviceorderproblemtypes: JSON.parse(config.serviceorderproblemtypes),
                  serviceordertypes: JSON.parse(config.serviceordertypes),
                  startworkingtime: config.startworkingtime,
                  endworkingtime: config.endworkingtime,        
    });
    
    if (config.other) {
      let tmp = JSON.parse(config.other);
      this.state.other = tmp;      
    }
        
    if (this.state.other && this.state.other.costcenter) {
      this.setState({costcenter: this.state.other.costcenter});
    }
    
    if (this.state.other && this.state.other.managemyasset) {
      this.setState({managemyasset: this.state.other.managemyasset});
    }

    if (this.state.other && this.state.other.servicenotes) {
      this.setState({servicenotes: this.state.other.servicenotes});
    }

    if (this.state.other && this.state.other.autoassettag) {
      this.setState({autoassettag: this.state.other.autoassettag});
    }

    if (this.state.other && this.state.other.assetagprefix) {
      this.setState({assetagprefix: this.state.other.assetagprefix});
    }

    if (this.state.other && this.state.other.assetlot) {
      this.setState({assetlot: this.state.other.assetlot});
    }

    if (this.state.other && this.state.other.pdffrontpage) {
      this.setState({pdffrontpage: this.state.other.pdffrontpage});
    }

    let t = JSON.parse(config.assettypes);    
    if (t && t.length) { 
      for (let i=0; i<t.length; i++) {
        if (t[i]) {
          let name = 'as' + (i + 1);
          //console.log(t[i], name);
          this.setState({ [name]: t[i]});
        }
      }
    }

    t = JSON.parse(config.serviceorderproblemtypes);  
    if (t && t.length) {   
      for (let i=0; i<t.length; i++) {
        if (t[i]) {
          let name = 'ct' + (i + 1);
          //console.log(t[i], name);
          this.setState({ [name]: t[i]});
        }
      }
    }

    t = JSON.parse(config.serviceordertypes);    
    if (t && t.length) { 
      for (let i=0; i<t.length; i++) {
        if (t[i]) {
          let name = 'ty' + (i + 1);
          //console.log(t[i], name);
          this.setState({ [name]: t[i]});
        }
      }
    }

  }

  
  handleInputChange = (event) => {    
    const target = event.target;
    const name = target.id;    

    this.setState({
      [name]: (target.value ? target.value : '')
    });  
    //console.log(this.state[name], this.state.assetlot)  
  }

  handleChange = (evt) => {
    this.setState({ managemyasset: evt.target.checked });
  }

  handleAssetChange = (evt) => {
    this.setState({ autoassettag: evt.target.checked });
    if (this.state.autoassettag === false) {
      this.setState({assetagprefix: 'CT'});
    }
  }

  pushValue(array, val1, val2, val3, val4) {
    if (val1) {
      array.push(val1);
    }
    if (val2) {
      array.push(val2);
    }
    if (val3) {
      array.push(val3);
    }
    if (val4) {
      array.push(val4);
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    let config = JSON.parse(localStorage.getItem('config'));

    let cfg = {};
    cfg.id = config.id;
    
    this.state.assettypes = [];
    this.pushValue(this.state.assettypes, this.state.as1, this.state.as2, this.state.as3, this.state.as4);

    this.state.serviceorderproblemtypes = [];
    this.pushValue(this.state.serviceorderproblemtypes, this.state.ct1, this.state.ct2, this.state.ct3, this.state.ct4);

    this.state.serviceordertypes = [];
    this.pushValue(this.state.serviceordertypes, this.state.ty1, this.state.ty2, this.state.ty3, this.state.ty4);
    
    
    cfg.assettypes = JSON.stringify(this.state.assettypes);
    cfg.serviceorderproblemtypes = JSON.stringify(this.state.serviceorderproblemtypes);
    cfg.serviceordertypes = JSON.stringify(this.state.serviceordertypes);
    cfg.startworkingtime = this.state.startworkingtime;
    cfg.endworkingtime = this.state.endworkingtime;
    
    if (this.state.other) {      
    } else {
      this.state.other = {};
    }
    
    this.state.other.costcenter = this.state.costcenter;
    this.state.other.managemyasset = this.state.managemyasset;    
    this.state.other.servicenotes = this.state.servicenotes;
    this.state.other.pdffrontpage = this.state.pdffrontpage;
    this.state.other.autoassettag = this.state.autoassettag;
    this.state.other.assetagprefix = this.state.assetagprefix; 
    this.state.other.assetlot = undefined;
    //console.log('this.state.assetlot', this.state.assetlot)
    if (this.state.assetlot) {
      this.state.other.assetlot = this.state.assetlot;    
    }

    cfg.other = JSON.stringify(this.state.other);
    //console.log('cfg.other', cfg.other);
    configService.updateConfig(cfg).then(mat => {
      if (mat && mat === 401) {
        this.props.history.push("/login");
      } else  {
        this.props.history.goBack();
      }
    });
  }

  goBack = () => {
    this.props.history.goBack();
  }
  

  render() {
    
    let stOpt = [];
    let enOpt = [];

    if (this.state.startworkingtime) {
      for (let i=0; i<24; i++) {
        if (i===Number(this.state.startworkingtime) ) {          
          stOpt.push(<option selected>{i}</option>);
        } else {
          stOpt.push(<option >{i}</option>);
        }
      }
    }

    if (this.state.endworkingtime) {    
      for (let i=0; i<24; i++) {
        if (i===Number(this.state.endworkingtime)) {
          enOpt.push(<option selected>{i}</option>);
        } else {
          enOpt.push(<option >{i}</option>);
        }
      }
    }

        
    return (
      <div className="content-screen" style={{height: '100%', overflow: 'scroll'}}>
      
        <Card style={{ width: '40rem' }}>
          <Card.Header style={{fontWeight: 'bold'}}>Application Configurations - you can overwrite default values and save</Card.Header>
          <Card.Body>            
            <div>
                <Form onSubmit={this.handleSubmit}>
                  <div style={{fontWeight: 'bolder', fontSize: '18px', marginBottom: '8px'}}>Service Order</div>
                  <div style={{fontWeight: 'bold', fontSize: '14px', marginBottom: '8px'}}>Order Category</div>
                  <Form.Row>
                    <Form.Group as={Col} controlId="ct1" 
                        onChange={this.handleInputChange}>
                      
                      <Form.Control  placeholder="Enter orde category" defaultValue={this.state.ct1} />
                    </Form.Group>
                  </Form.Row> 

                  <Form.Row>
                    <Form.Group as={Col} controlId="ct2" 
                        onChange={this.handleInputChange}>
                      
                      <Form.Control  placeholder="Enter orde category" defaultValue={this.state.ct2} />
                    </Form.Group>
                  </Form.Row> 

                  <Form.Row>
                    <Form.Group as={Col} controlId="ct3" 
                        onChange={this.handleInputChange}>
                      
                      <Form.Control placeholder="Enter orde category" defaultValue={this.state.ct3} />
                    </Form.Group>
                  </Form.Row> 

                  <Form.Row>
                    <Form.Group as={Col} controlId="ct4" 
                        onChange={this.handleInputChange}>
                      
                      <Form.Control  placeholder="Enter orde category" defaultValue={this.state.ct4} />
                    </Form.Group>
                  </Form.Row>                 

                  <div style={{fontWeight: 'bold', fontSize: '14px', marginBottom: '8px'}}>Order Type</div>

                  <Form.Row>
                    <Form.Group as={Col} controlId="ty1" 
                        onChange={this.handleInputChange}>
                      <Form.Control placeholder="Enter order type" defaultValue={this.state.ty1} />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="ty2" 
                        onChange={this.handleInputChange}>
                      <Form.Control placeholder="Enter order type" defaultValue={this.state.ty2} />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="ty3" 
                        onChange={this.handleInputChange}>
                      <Form.Control placeholder="Enter order type" defaultValue={this.state.ty3} />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="ty4" 
                        onChange={this.handleInputChange}>
                      <Form.Control placeholder="Enter order type" defaultValue={this.state.ty4} />
                    </Form.Group>
                  </Form.Row>

                  <div style={{fontWeight: 'bolder', fontSize: '18px', marginTop: '16px', marginBottom: '8px'}}>Asset</div>
                  <div style={{fontWeight: 'bold', fontSize: '14px', marginBottom: '8px'}}>Asset Type</div>

                  <Form.Row>
                    <Form.Group as={Col} controlId="as1" 
                        onChange={this.handleInputChange}>
                      <Form.Control placeholder="Enter asset type" defaultValue={this.state.as1} />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="as2" 
                        onChange={this.handleInputChange}>
                      <Form.Control placeholder="Enter asset type" defaultValue={this.state.as2} />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="as3" 
                        onChange={this.handleInputChange}>
                      <Form.Control placeholder="Enter asset type" defaultValue={this.state.as3} />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="as4" 
                        onChange={this.handleInputChange}>
                      <Form.Control placeholder="Enter asset type" defaultValue={this.state.as4} />
                    </Form.Group>
                  </Form.Row>

                  <div style={{fontWeight: 'bold', fontSize: '14px', marginBottom: '8px'}}>Asset Input Form</div>

                  <Form.Row>
                    <Form.Group as={Col} controlId="assetlot"onChange={this.handleInputChange}>
                      <Form.Control placeholder="Enter label to change lot#" defaultValue={this.state.assetlot} />
                    </Form.Group>
                  </Form.Row>

                  {false && 
                  <Form.Row>
                    <Form.Group as={Col} controlId="autoassettag" 
                        onChange={this.handleAssetChange}>
                      <Form.Check inline label="Automatically generate asset tag number for customers assets - default tag prefix is CT" type="checkbox" checked={this.state.autoassettag} id={this.state.autoassettag} />                          
                    </Form.Group>
                  </Form.Row>
                  }
                  {this.state.autoassettag &&
                      <Form.Row>
                      <Form.Group as={Col} controlId="assetagprefix" 
                          onChange={this.handleInputChange}>
                        <Form.Control placeholder="Enter prefix for asset tag" defaultValue={this.state.assetagprefix} />
                      </Form.Group>
                    </Form.Row>
                  }
                 

                  <div style={{fontWeight: 'bolder', fontSize: '18px', marginBottom: '8px', marginTop: '16px'}}>Schedule Calendar
</div>

                  <Form.Row>
                    <Form.Group as={Col} controlId="startworkingtime" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Starting work hour</Form.Label>
                      <Form.Control as="select" defaultValue={this.state.startworkingtime}>
                            {stOpt}
                      </Form.Control> 
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="endworkingtime" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Ending work hour</Form.Label>
                      <Form.Control as="select" defaultValue={this.state.endworkingtime}>
                            {enOpt}
                      </Form.Control> 
                    </Form.Group>
                  </Form.Row>
                  <div style={{fontWeight: 'bolder', fontSize: '18px', marginBottom: '8px', marginTop: '16px'}}>Other
</div>
                  <Form.Row>
                    <Form.Group as={Col} controlId="costcenter" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Default Cost Center for PO</Form.Label>
                      <Form.Control  placeholder="Enter cost center name" defaultValue={this.state.costcenter} />                      
                    </Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Form.Group as={Col} controlId="managemyasset" 
                        onChange={this.handleChange}>
                      <Form.Check inline label="Setup as a service provider" type="checkbox" checked={this.state.managemyasset} id={this.state.managemyasset} />                          
                    </Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Form.Group as={Col} controlId="servicenotes" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Service activity billable notes (comma separated list)</Form.Label>
                      <Form.Control  as="textarea" rows={2} defaultValue={this.state.servicenotes} />                      
                    </Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Form.Group as={Col} controlId="pdffrontpage" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Front page text for service order PDF</Form.Label>
                      <Form.Control  as="textarea" rows={10} defaultValue={this.state.pdffrontpage} />                      
                    </Form.Group>
                  </Form.Row>
                  
                  <div style={{float: "left"}} >                                       
                    <Button variant="outline-primary mr-2" size="sm" type="submit">Save</Button>  
                    <Button variant="outline-primary" size="sm" onClick={() => {this.goBack()}}>Cancel</Button>                            
                  </div>
              </Form>
            </div>
          </Card.Body>
          <Card.Footer className="text-muted">&nbsp;</Card.Footer>       
        </Card>      

      </div>
    );
  }
}


export default AppConfig;