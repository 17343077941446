import React, { forwardRef }  from "react";
import { timesheetService } from '../../service/timesheetService';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import MaterialTable from "material-table";

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import FormControl from '@material-ui/core/FormControl';


import util from '../../service/utility.js';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

class TimeSheet extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        operation: undefined,
        anchorEl: null,
        open: Boolean(null),
        timesheets: [],
        selectedRow: undefined,
        columns: [
        {
          field: 'customername',
          title: 'Customer',
        },
        {
          field: 'resource',
          title: 'Resource',
        },{
          field: 'serviceid',
          title: 'Order#',
        } , {
          field: 'timesheetstatus',
          title: 'Status',
          render: rowData => {
            let sk = rowData.timesheetstatus;
            let status = 'Pending';
            if (sk && sk != null) {
              if (sk === 2) {
                status = 'Approved';
              } else if (sk === 3) {
                status = 'Duplicate';
              } else if (sk === 4) {
                status = 'Declined';
              } else if (sk === 5) {
                status = 'Change Required';
              } else if (sk === 6) {
                status = 'In Process';
              } 
            }
            return (
                <span> {status}</span>
            );            
          }
        } , {
          field: 'scheduledate',
          title: 'Scheduled',
          render: rowData => {
            let sk = (rowData.scheduledate ? util.utcToLocalDateTime(rowData.scheduledate) : '');            
            return (
                <span className="label label-success"> {sk}</span>
            );            
          }
        } , {
          field: 'starttime',
          title: 'Sch Duration',
          render: rowData => {
            let tdiff = Math.abs((rowData.endtime ? rowData.endtime : 0) - (rowData.starttime ? rowData.starttime : 0));
            if (tdiff && tdiff > 0) {
              tdiff = tdiff * 60;
            }
            
            let tm = tdiff/60 + ' h ' +  tdiff %60 + ' m'
                     
            return (
                <span className="label label-success"> {tm}</span>
            );            
          }
        } , {
          field: 'actualservicedeliverydate',
          title: 'Service Date',
          render: rowData => {
            let sk = (rowData.actualservicedeliverydate ? util.utcToLocalDateTime(rowData.actualservicedeliverydate) : '');            
            return (
                <span className="label label-success"> {sk}</span>
            );            
          }
        },  {
          field: 'actualtotalservicehour',
          title: 'Duration',
          render: rowData => {                 
            return (
              <>
                 <div style={{float: 'left'}}>
                 <span> {rowData.actualtotalservicehour} h {rowData.actualtotalservicemin} m</span>
                 </div>
                 <div style={{float: 'right'}}>
                 
                 <div>
                 
                    <FormControl>
                     <Select onChange={this.handleSelect}>
                        <MenuItem value={'1-' + rowData.id} onClick={this.approve}>Approve</MenuItem>
                        <MenuItem value={'2-' + rowData.id} onClick={this.decline}>Decline</MenuItem>
                        <MenuItem value={'3-' + rowData.id} onClick={this.edit}>Edit</MenuItem>
                        <MenuItem value={'4-' + rowData.id} onClick={this.changeRequire}>Request for change</MenuItem>
                      </Select>
                      </FormControl>
                </div>
            </div>
            </>
            );
          }
        }
      ],
    };
  }

  componentDidMount() {
    timesheetService.getTimeSheet().then(resources => {
      if (resources && resources === 401) {
        this.props.history.push("/login");
      } else {
        this.setState({ timesheets: resources })
      }
    });
    
  }

  handleSelect = (event) => {
    event.preventDefault();
    if(event.target.value) {
      let id = event.target.value;
      id = id.substring(2);
      if (this.state.timesheets && this.state.timesheets.length > 0) {
        for (let i=0; i<this.state.timesheets.length; i++) {
          let o = this.state.timesheets[i];
          console.log(o.id, Number(id));
          if (o.id === Number(id)) {  
            this.state.selectedRow = o;          
            break;  
          }
        }
      }      
    }

    if (event.target.value.startsWith("1-")) {
      this.approve();
    } else if (event.target.value.startsWith("2-")) {
      this.decline();
    } else if (event.target.value.startsWith("3-")) {
      this.edit();
    } else if (event.target.value.startsWith("4-")) {
      this.changeRequire();
    } 
  }

  onRowSelect(row) {

  }

  approve = (e) => {
    let ts = {};
    ts.id = this.state.selectedRow.id;
    ts.timesheetstatus = 2;
    timesheetService.updateTimeSheet(ts).then(resources => {
      if (resources && resources === 401) {
        this.props.history.push("/login");
      } else {
        timesheetService.getTimeSheet().then(resources => {
          if (resources && resources === 401) {
            this.props.history.push("/login");
          } else {
            this.setState({ timesheets: resources })
          }
        });
      }
    });
  }

  decline = (e) => {
    if (this.state.selectedRow.timesheetstatus != 1) {
      alert('You can only decline a Pending timesheet!', this.state.state);
    } else {
      let ts = {};
      ts.id = this.state.selectedRow.id;
      ts.timesheetstatus = 4;
      timesheetService.updateTimeSheet(ts).then(resources => {
        if (resources && resources === 401) {
          this.props.history.push("/login");
        } else {
          timesheetService.getTimeSheet().then(resources => {
            if (resources && resources === 401) {
              this.props.history.push("/login");
            } else {
              this.setState({ timesheets: resources })
            }
          });
        }
      });
    }
  }

  edit = (e) => {
    //e.preventDefault();
    console.log(this.state.selectedRow);
    if (this.state.selectedRow.timesheetstatus === 1) {
      var { from } = this.props.location.state || { from: { pathname: "/edittimesheet/" + this.state.selectedRow.id} };  
      this.props.history.push(from);
    } else {
      alert('You can only edit a Pendig timesheet. Click "Request for change" to make change state to Pending.');
    }
  }

  changeRequire = (e) => {
    let ts = {};
    ts.id = this.state.selectedRow.id;
    ts.timesheetstatus = 1;
    timesheetService.updateTimeSheet(ts).then(resources => {
      if (resources && resources === 401) {
        this.props.history.push("/login");
      } else {        
        timesheetService.getTimeSheet().then(resources => {
          if (resources && resources === 401) {
            this.props.history.push("/login");
          } else {
            this.setState({ timesheets: resources })
          }
        });
      }
    });
  }

  handleAddNew = () => {
    var { from } = this.props.location.state || { from: { pathname: "/addtimesheet" } };  
    this.props.history.push(from);
  }


  render() {
    return (
      <div className="content-screen">
          <div style={{ width: '73rem' }}>            
            <MaterialTable
              icons={tableIcons}
              title={<button className="btn btn-outline-primary" onClick={ this.handleAddNew }>Add Timesheet</button>  }
              columns={this.state.columns}
              data={this.state.timesheets}
              //title="Demo Title"
              options={{
                search: true,
                searchFieldAlignment: 'left',
                sorting: true,
                selection: true,
                pageSize: 10,
                exportButton: true,
                headerStyle: {
                  backgroundColor: '#edeff1',
                  color: '#000',
                  borderColor: '#9da6af',
                }                
              }}
              onRowClick={((evt, selectedRow) => {
                this.state.selectedRow = selectedRow;                
              })}
              onSelectionChange={(selectedRow) => {
                this.state.selectedRow = selectedRow; 
              }}
            />
          </div>
          <div>&nbsp;</div>
        </div>  
    );
  }
}

export default TimeSheet;