import React from "react";
import CalendarHeatmap from './Calendar-heatmap.js'


class Utilization extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    data: [{
          "details": [{
           }]
        }]
      } 
    }
  
  shouldComponentUpdate() {
    return true;
  }
 
  render() {
    return (
        <div className="content-screen" style ={{padding: '4px'}}>
            <div>
              <h5>Resource Utilization</h5>              

                <CalendarHeatmap
                  data={this.state.data}
                  color="#1E90FF"
                  overview="month">
                  {/* handler={print}> */}
                </CalendarHeatmap>

            </div>
        </div>
      
    );
  }
}

export default Utilization;



