import React, { Component } from 'react'
import {DropzoneDialog} from 'material-ui-dropzone'
import Button from '@material-ui/core/Button';
import { uploadService } from '../../service/uploadService';

export default class FileUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            assetid: undefined,
            clientid: undefined,
            open: false,
            files: [],
            imgtype: true,            
            accesstype: undefined,
            report: false,
        };
    }

    componentDidMount() {  
        const {image} = this.props.match.params;
        const {report} = this.props.match.params;
        const {id} = this.props.match.params;
        const {client} = this.props.match.params;

        if (report) {
            this.state.report = report;
        }

        if (!id || !client) {
            this.props.history.goBack();
        } else {        
            this.state.assetid = id;
            this.state.clientid = client;
            this.state.imgtype = image;
            if (image) {
                this.state.accesstype = ['image/jpeg', 'image/png', 'image/bmp'];
            } else {
                this.state.accesstype = ['application/*'];
            }
        }
    }

    handleClose() {
        this.setState({
            open: false
        });
    }

    handleSave(files) {
        //Saving files to state for further use and closing Modal.
        this.setState({
            files: files, 
            open: false
        });
        console.log(files[0].name)
        files.forEach((file) => {
            const reader = new FileReader()
      
            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
            // Do whatever you want with the file contents
              const binaryStr = reader.result
              //console.log(binaryStr)
              if (this.state.report) {
                    uploadService.uploadReport(binaryStr, files[0].name, this.state.assetid, this.state.clientid).then(dataobjs => {
                        if (dataobjs && dataobjs === 401) {
                            this.props.history.push("/login");
                        } else {    
                            this.props.history.goBack();                    
                        }
                    });
              }
              else if (this.state.imgtype) {                  
                    uploadService.uploadImage(binaryStr, files[0].name, this.state.assetid, this.state.clientid).then(dataobjs => {
                        if (dataobjs && dataobjs === 401) {
                            this.props.history.push("/login");
                        } else {    
                            this.props.history.goBack();                    
                        }
                    });
              } else {
                    uploadService.uploadDocument(binaryStr, files[0].name, this.state.assetid, this.state.clientid).then(dataobjs => {
                        if (dataobjs && dataobjs === 401) {
                            this.props.history.push("/login");
                        } else {    
                            this.props.history.goBack();                    
                        }
                    });
                }
            }
            //reader.readAsBinaryString(file)
            reader.readAsArrayBuffer(file)
          })
    }

    handleOpen() {
        this.setState({
            open: true,
        });
    }

    render() {
        return (
            <div className="content-screen">
            <div>
                <Button variant="outlined" color="primary" onClick={this.handleOpen.bind(this)}>
                  Add File
                </Button>
                <DropzoneDialog
                    open={this.state.open}
                    onSave={this.handleSave.bind(this)}
                    acceptedFiles={this.state.accesstype}
                    showPreviews={true}
                    maxFileSize={5000000}
                    onClose={this.handleClose.bind(this)}
                    filesLimit={1}
                />
            </div>
            </div>
        );
    }
}