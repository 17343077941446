import { Card, Nav } from 'react-bootstrap';
import React , { forwardRef } from "react";
import { servicerequestService } from '../../service/servicerequestService';
import utility from '../../service/utility'

import MaterialTable from "material-table";

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

class MyActivity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardbody: undefined,
      resourceid: undefined,
      resourcename: undefined,
      sch: [],
      schcolumns: [{
        field: 'id',
        title: 'id',
        hidden: true
      }, {
        field: 'customername',
        title: 'customername',
        hidden: true
      }, {
        field: 'scheduledate',
        title: 'scheduledate',
        hidden: true
      }, {
        field: 'starttime',
        title: 'Time',
        headerStyle: {
          backgroundColor: '#edeff1'
        },
        render: row => {    
          let tm = utility.utcToLocal(row.scheduledate);               
          return (
            <h5><small>
              <div className="label label-success">{utility.formatSchTime(row.starttime)}</div>
              <div className="label label-success">{tm}</div>
              </small></h5>
          );            
        } 
      }, {
        field: 'detail',
        title: 'Order',
        headerStyle: {
          backgroundColor: '#edeff1'
        },
        render: row => {        
          return (
            <h5><small>
              <div className="label label-success">#{row.id}</div>
              <div className="label label-success">{row.detail}</div>
              </small></h5>
          );            
        }  
      }, {
        field: 'city',
        title: 'Site',
        headerStyle: {
          backgroundColor: '#edeff1'
        },
        render: row => {          
          return (
            <h5><small>
              <div className="label label-success">{row.customername}</div>
              <div className="label label-success">{row.city}</div>
              </small></h5>
          );            
        }
      }
    ],
      // selectRow: {
      //   mode: 'radio',
      //   clickToSelect: true,
      //   hideSelectColumn: true,
      //   onSelect: (row, isSelect, rowIndex, e) => {   
      //     var { from } = this.props.location.state || { from: { pathname: "/servicedetail/" + row.id} };  
      //     this.props.history.push(from);          
      //   }
      // }
    }
  }

  componentDidMount() {
    let usrCfg = JSON.parse(localStorage.getItem('user'));
    //console.log(usrCfg);   
    this.state.resourceid = usrCfg.peopleid;
    this.state.resourcename = usrCfg.username;
    this.setState({
      resourcename: usrCfg.username,
      resourceid: usrCfg.peopleid,
    });

    // var from = new Date();
    // from.setDate(from.getDate() - 7);
    // var to = new Date();
    // to.setDate(to.getDate() + 3);
    var d = new Date();      
    this.getServiceOrders(this.formatStartOfDay(new Date()), this.formatEndOfDay(new Date()));

    //this.getServiceOrders(utility.getPgDateFormat(from), utility.getPgDateFormat(to));
  }

  getServiceOrders(from, to) {
      //console.log('resource', this.state.resourceid);
      if (!this.state.resourceid) {
        return;
      }
      servicerequestService.getServiceOrdersAssignedToPeopleRange(this.state.resourceid, from, to)
        .then(order => {
          if (order && order === 401) {
            this.props.history.push("/login");
          } else if (order && order.length > 0){     
              //console.log(order);         
              let od = [];
              for (let i=0; i<order.length; i++) {
                //console.log(order[i]);
                let o = {};
                o.id = order[i].id;
                o.customername = order[i].customername;
                o.city = order[i].city;
                o.starttime = order[i].starttime;
                o.endtime = order[i].endtime;
                o.scheduledate = order[i].scheduledate;
                o.detail = order[i].detail;
                od.push(o);
              }
              this.state.sch = od;
              this.setState({cardbody: this.getCardBody()});
          } else {
            this.state.sch = [];
            this.setState({cardbody: this.getCardBody()});
          }
      });
  }

  formatStartOfDay(d) {        
    d.setHours(0,0,0,-1);
    return d.toISOString();
}

formatEndOfDay(d) {        
    d.setHours(24,0,0,1);
    return d.toISOString();
}


  // getScheduleFor(d) {
  //   let k = [{
  //     id: 1,
  //     serviceid: 1546,
  //     time: '9:15 AM',
  //     date: 'Feb 29',
  //     order: '#12345',
  //     detail: 'HVAC issue',
  //     address: 'Fremont'
  //   }];
  //   return k;
  // }

  // getScheduleUpto(d) {
  //   let k  = [{
  //     id: 1,
  //     serviceid: 1546,
  //     time: '10:15 AM',
  //     date: 'Feb 2',
  //     order: '#34561',
  //     detail: 'Pump issue',
  //     address: 'Palo Alto'
  //   }];
  //   return k;
  // }

  // getScheduleAfter(d) {
  //   let k  = [{
  //     id: 1,
  //     serviceid: 1546,
  //     time: '19:15 PM',
  //     date: 'Mar 10',
  //     order: '#9999',
  //     detail: 'Boiler issue',
  //     address: 'Dublin'
  //   }];
  //   return k;
  // }
  

  navSelected = (e) => {     
    if (e === '#today') {      
      this.getServiceOrders(this.formatStartOfDay(new Date()), this.formatEndOfDay(new Date()));
    } else if (e === '#pending') {      
      var from = new Date();
      from.setDate(from.getDate() - 30);
      let d = new Date();
      d.setHours(0,0,0,0);
      this.getServiceOrders(utility.getPgDateFormat(from), d.toISOString());
    }  else if (e === '#upcoming') {
      // this.state.sch = this.getScheduleAfter(new Date);
      // this.setState({
      //   cardbody: this.getCardBody()
      // });
      var from = new Date();
      from.setDate(from.getDate() + 1);
      var to = new Date();
      to.setDate(to.getDate() + 30);
      this.getServiceOrders(this.formatStartOfDay(from), utility.getPgDateFormat(to));

    }
}

  getCardBody() {
    let title = <p style={{fontSize: '16px', marginBottom: '-3px'}}>Service orders</p>
    return (<>
          {/* <BootstrapTable
              bootstrap4
              keyField='id'
              data={this.state.sch }
              columns={this.state.schcolumns }
              selectRow={ this.state.selectRow }
            />         */}

            <div style={{ width: '73rem'}}>            
                    <MaterialTable
                      icons={tableIcons}
                      title={title}
                      columns={this.state.schcolumns}
                      data={this.state.sch}
                      //title="Demo Title"
                      options={{
                        search: true,
                        searchFieldAlignment: 'left',
                        sorting: true,
                        //exportButton: true,
                        //selection: true,
                        pageSize: 15,
                        headerStyle: {
                          backgroundColor: '#edeff1',
                          color: '#000',
                          borderColor: '#9da6af',           
                        }                
                      }}
                      onRowClick={((evt, selectedRow) => {
                        //this.props.history.push('/skilldetail/' + selectedRow.skill);            
                        var { from } = this.props.location.state || { from: { pathname: "/servicedetail/" + selectedRow.id} };  
                        this.props.history.push(from);  
                      })}
                    />
                  </div>

      </>);
  }

  render() {
    return (
        
      <div className="content-screen">
        <div style={{ width: '73rem', marginTop: '20px' }}>
      <Card>
      <Card.Header style={{paddingTop: '2px', backgroundColor: '#eeeff1'}}>  
          <Nav style={{backgroundColor: '#eeeff1'}} variant="tabs" defaultActiveKey="#today" onSelect={selectedKey => this.navSelected(selectedKey)}>
            <Nav.Item>
              <Nav.Link href="#today"  style={{color: 'black'}}>Today's Schedule</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="#pending" style={{color: 'black'}}>Pending</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="#upcoming" style={{color: 'black'}}>Upcoming</Nav.Link>
            </Nav.Item>
          </Nav>
        </Card.Header>    
        <Card.Body style={{marginLeft: '-20px', marginRight: '-20px'}}>  
          {this.state.sch && this.state.sch.length > 0 && 
          this.state.cardbody}           
          {this.state.sch && this.state.sch.length === 0 && 
          <p style={{marginLeft: '8px'}}>There are no schedules to display for {this.state.resourcename}</p>
          }           
        </Card.Body>
        
      </Card>
        </div>
      </div>
    );
  }
}

export default MyActivity;