module.exports = {
    app : {
        url: 'https://api.datamoto.com'
        //url: 'http://localhost:8181'
    },
    currencies: [
        { label: 'United States Dollar', value: 'USD'},
        { label: 'Canadian Dollar', value: 'CAD'},
        { label: 'Euro', value: 'EUR'},
        { label: 'Chinese Yuan', value: 'CNY'},
        { label: 'Indian Rupee', value: 'INR'},
        { label: 'Indonesian Rupiah', value: 'IDR'},
        { label: 'Japanese Yen', value: 'JPY'},
        { label: 'Mexican Peso', value: 'MXN'},
        { label: 'Singapore Dollar', value: 'SGD'},
        { label: 'South African Rand', value: 'ZAR'},
        { label: 'British Pound Sterlin', value: 'GBP'}
    ],
    countries: [
        { label: 'United States', value: 'US'},
        { label: 'Canada', value: 'CA'},
        { label: 'United Kingdom', value: 'GB'},
        { label: 'Australia', value: 'AU'},
        { label: 'India', value: 'IN'},
    ], 
};
