import React from "react";
import { Card, Button, Form, Col } from 'react-bootstrap';
import { Redirect } from "react-router-dom";
import { providerService } from '../../service/providerService';
var config = require('../../service/config');

class ProviderEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        
        id: undefined,
        name: undefined,
        currency: undefined,
        language: undefined,
        sales_tax_id: undefined,
        since: undefined,
        archive: undefined,
        provider_accno: undefined,        
        contact_first_name: undefined,
        contact_last_name: undefined,
        addr1: undefined,
        addr2: undefined,
        city: undefined,
        state: undefined,
        zip: undefined,
        country: undefined,
        email: undefined,  
        phone: undefined,  
        service_link: undefined,  
        note: undefined,  
        status: undefined,  
        
        gobackto: undefined,
    }
  }

  componentDidMount() {  
    const {id} = this.props.match.params;

    if (id) {
      this.state.id = id;
      providerService.getProvider(this.state.id).then(pr => {
        if (pr && pr === 401) {
          this.props.history.push("/login");
        } else {  
          this.setState({
            name: pr.name,
            currency: pr.currency,
            language: pr.language,
            sales_tax_id: pr.sales_tax_id,
            since: pr.since,
            archive: pr.archive,
            provider_accno: pr.provider_accno,        
            contact_first_name: pr.contact_first_name,
            contact_last_name: pr.contact_last_name,
            addr1: pr.addr1,
            addr2: pr.addr2,
            city: pr.city,
            state: pr.state,
            zip: pr.zip,
            country: pr.country,
            email: pr.email,  
            phone: pr.phone,  
            service_link: pr.service_link,  
            note: pr.note,  
            status: pr.status, 
          });
        }
      });
    }
  }
  
  handleInputChange = (event) => {    
    const target = event.target;
    const name = target.id;    
    this.setState({
      [name]: target.value
    });    
  }


  handleSubmit = (event) => {
    event.preventDefault();
    
    let pr = {};
    if (this.state.id) {
      pr.id = this.state.id;
    }

    
    pr.name = this.state.name;
    pr.currency = this.state.currency;
    pr.language = this.state.language;
    pr.sales_tax_id = this.state.sales_tax_id;
    
    
    pr.provider_accno = this.state.provider_accno;        
    pr.contact_first_name = this.state.contact_first_name;
    pr.contact_last_name = this.state.contact_last_name;
    pr.addr1 = this.state.addr1;
    pr.addr2 = this.state.addr2;
    pr.city = this.state.city;
    pr.state = this.state.state;
    pr.zip = this.state.zip;
    pr.country = this.state.country;
    pr.email = this.state.email;
    pr.phone = this.state.phone;
    pr.service_link = this.state.service_link;  
    pr.note = this.state.note;
    pr.status = this.state.status;

    if (this.state.country && this.state.country.startsWith('Choose')) {
      this.state.country = null;
      pr.country = null;      
    } 

    if (this.state.id) {
      providerService.updateProvider(pr, this.state.id).then(ast => {
        if (ast && ast === 401) {
          this.props.history.push("/login");
        } else {  
          this.setState({gobackto: '/providers'});   
          this.props.history.goBack();
        }
      });
    } else {
      providerService.addProvider(pr).then(ast => {
        if (ast && ast === 401) {
          this.props.history.push("/login");
        } else {            
          this.props.history.goBack();
        }
      });
    }
  }

  goBack = () => {
    this.props.history.goBack();
  }
  
  archive = () => {
    if (!this.state.id) {
      alert('No provider to archive')
    } else {
      let pr = {}
      pr.id = this.state.id;
      pr.archive = true;

      providerService.updateProvider(pr, this.state.id).then(ast => {
        if (ast && ast === 401) {
          this.props.history.push("/login");
        } else {  
          this.setState({gobackto: '/providers'});   
          this.props.history.goBack();
        }
      });
    }
  }

  render() {
    if (this.state.gobackto) {
      return <Redirect to={this.state.gobackto} />
    }  

    let countries = [];
    let selected = false;
    countries.push(<option>Choose...</option>);
    if (config.countries && config.countries.length > 0) {
      for (let i=0; i<config.countries.length; i++) {
        if (this.state.country && this.state.country === config.countries[i].value) {
          countries.push(<option selected value={config.countries[i].value}>{config.countries[i].label}</option>);
          selected = true;
        } else {
          countries.push(<option value={config.countries[i].value}>{config.countries[i].label}</option>);
        }        
      }
    }

    if (!selected) {
      let cfg = JSON.parse(localStorage.getItem('config'));
      console.log(cfg)
      let found = -1;
      for (let i=0; i<config.countries.length; i++) {
        if (cfg.country && cfg.country === config.countries[i].value) {
          found = i;
          break;
        }
      }

      if (found > -1) {
        // this means company's country is part of the config's country list
        countries[found] = (<option selected value={config.countries[found].value}>{config.countries[found].label}</option>);
        this.state.country = config.countries[found].value;
      }
    }

    let status = [];
    status.push(<option value="Active">Active</option>);
    status.push(<option value="Inactive">Inactive</option>);
    status.push(<option value="Preferred">Preferred</option>);
    status.push(<option value="Black listed">Black listed</option>);

    if (this.state.status && this.state.status === "Black listed") {
      status[3] = (<option selected value="Black listed">Black listed</option>);
      this.state.status = "Black listed";
    } else if (this.state.status && this.state.status === "Preferred") {
      status[2] = (<option selected value="Preferred">Preferred</option>);
      this.state.status = "Preferred";
    } else if (this.state.status && this.state.status === "Inactive") {
      status[1] = (<option selected value="Inactive">Inactive</option>);
      this.state.status = "Inactive";
    } else {
      status[0] = (<option selected value="Active">Active</option>);
      this.state.status = "Active";
    }  

    return (
      <div className="content-screen" style={{height: '100%', overflow: 'scroll'}}>
      
        <Card style={{ width: '40rem' }}>         
           <Card.Header style={{fontWeight: 'bold'}}>Service Provider
           <div style={{float: 'right'}}>
           <Button variant="link" size="sm" style={{color: 'red', textDecorationLine: 'underline', textDecorationColor: 'red'}} onClick={() => {if(window.confirm('Archive the service provider?')){this.archive()};}}>archive</Button>
           </div>
           </Card.Header>
          
          <Card.Body>            
            <div>
                <Form onSubmit={this.handleSubmit}>

                  <Form.Row>
                    <Form.Group as={Col} controlId="name" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Provider Name</Form.Label>
                      <Form.Control required="true" placeholder="Enter provider name" defaultValue={this.state.name} />
                    </Form.Group>
                  </Form.Row> 

                  <Form.Row>
                    <Form.Group as={Col} controlId="provider_accno" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Account #</Form.Label>
                      <Form.Control  placeholder="Enter account number if any" defaultValue={this.state.provider_accno} />
                    </Form.Group>
                  </Form.Row> 

                  <Form.Row>
                    <Form.Group as={Col} controlId="sales_tax_id" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Sales Tax ID</Form.Label>
                      <Form.Control placeholder="Enter sales tax/VAT number" defaultValue={this.state.sales_tax_id} />
                    </Form.Group>
                  </Form.Row> 

                  <Form.Row>
                    <Form.Group as={Col} controlId="contact_first_name" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Contact First Name</Form.Label>
                      <Form.Control  placeholder="Enter first name" defaultValue={this.state.contact_first_name} />
                    </Form.Group>
                  </Form.Row> 

                  <Form.Row>
                    <Form.Group as={Col} controlId="contact_last_name" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Contact Last Name</Form.Label>
                      <Form.Control placeholder="Enter last name" defaultValue={this.state.contact_last_name} />
                    </Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Form.Group as={Col} controlId="email" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Email</Form.Label>
                      <Form.Control type="email" placeholder="Enter email" defaultValue={this.state.email} />
                    </Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Form.Group as={Col} controlId="phone" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Phone</Form.Label>
                      <Form.Control type="phone" placeholder="Enter phone" defaultValue={this.state.phone} />
                    </Form.Group>

                  </Form.Row>
                  <Form.Row>                   
                    <Form.Group as={Col} controlId="addr1" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Address</Form.Label>
                      <Form.Control placeholder="Enter street address" defaultValue={this.state.addr1} />
                    </Form.Group>
                  </Form.Row> 
                  
                  <Form.Row>
                    <Form.Group as={Col} controlId="addr2" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Address Continue</Form.Label>
                      <Form.Control  placeholder="Enter address" defaultValue={this.state.addr2} />
                    </Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Form.Group as={Col} controlId="city" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>City</Form.Label>
                      <Form.Control placeholder="Enter city" defaultValue={this.state.city} />
                    </Form.Group>
                  </Form.Row> 

                  <Form.Row>
                    <Form.Group as={Col} controlId="state" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>State</Form.Label>
                      <Form.Control placeholder="Enter state" defaultValue={this.state.state} />
                    </Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Form.Group as={Col} controlId="zip" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Zip</Form.Label>
                      <Form.Control placeholder="Enter zip or pin" defaultValue={this.state.zip} />
                    </Form.Group>
                  </Form.Row> 

                  <Form.Row>
                    <Form.Group as={Col} controlId="country" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Country</Form.Label>
                      <Form.Control as="select" defaultValue={this.state.country}>
                            {countries}
                      </Form.Control> 
                    </Form.Group>
                  </Form.Row> 

                  <Form.Row>
                    <Form.Group as={Col} controlId="status" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Status</Form.Label>
                      <Form.Control as="select" defaultValue={this.state.status}>
                            {status}
                      </Form.Control> 
                    </Form.Group>
                  </Form.Row> 

                  <Form.Row>
                    <Form.Group as={Col} controlId="note" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Remark</Form.Label>
                      <Form.Control as="textarea" placeholder="Enter note" defaultValue={this.state.note} />
                    </Form.Group>
                  </Form.Row> 

                  <Form.Row>
                    <Form.Group as={Col} controlId="service_link" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Service Provider Connection</Form.Label>
                      <Form.Control placeholder="Enter service provider connection" defaultValue={this.state.service_link} />
                    </Form.Group>
                  </Form.Row> 

                  <div style={{float: "left"}} >                                       
                    <Button variant="outline-primary mr-2" size="sm" type="submit">Save</Button>  
                    <Button variant="outline-primary" size="sm" onClick={() => {this.goBack()}}>Cancel</Button>                            
                  </div>
              </Form>
            </div>
          </Card.Body>
          <Card.Footer className="text-muted">&nbsp;</Card.Footer>       
        </Card>      

      </div>
    );
  }
}


export default ProviderEdit;