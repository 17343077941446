import Cookies from 'js-cookie'

export function authHeader() {
    // return authorization header with basic auth credentials
    let user = JSON.parse(localStorage.getItem('user'));

    if (user && user.authdata) {
        return { 
            'Accept': 'application/json', 
            'Content-Type': 'application/json', 
            'connection': 'keep-alive',
            'Authorization': 'Bearer ' + user.authdata,
            'cookie': Cookies.get('access_token') 
        };
    } else {
        return {};
    }
}