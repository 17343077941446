import React, { Component } from 'react';

import Toolbar from './Toolbar';
import Calendar from './Calendar';
import Orders from './Orders';
import util from './../../service/utility'

import '../../css/App.css';
import { servicerequestService } from '../../service/servicerequestService';
import { scheduleService } from '../../service/scheduleService';
import { resourceService } from '../../service/resourceService';
import moment from 'moment';

export default class OrderCalendar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            //host: 'http://localhost:4000',
            calendar: [],
            date: new Date(),
            datelabel: "Today",
            days: 1,
            hour_count: 24,
            start_hour: 2,
            end_hour: 11,
            cellwidth: 140,
            resources: [{"id": "0", "fname": "", "lname": ""},{"id": "0", "fname": "", "lname": ""},{"id": "0", "fname": "", "lname": ""}],
            unscheduled_orders: [{"id": "0", "customerid": "0", "customername": null, "city": null, "detail": null, "scheduled": false}],
            scheduled_orders: [],
            hours: []
        };
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleDateButtonHandler = this.handleDateButtonHandler.bind(this);
        this.handleUtilClick = this.handleUtilClick.bind(this);
        this.handleOrderClick = this.handleOrderClick.bind(this);
        this.setCalendar = this.setCalendar.bind(this);

        this.onDragStart = this.onDragStart.bind(this);    
        this.onDragOver = this.onDragOver.bind(this); 
        this.onDragEnd = this.onDragEnd.bind(this);
        this.onDropHandlerNew = this.onDropHandlerNew.bind(this);
        this.onResizeStopNew = this.onResizeStopNew.bind(this);

        this.rescheduleOrder = this.rescheduleOrder.bind(this);
        this.completeSchedule = this.completeSchedule.bind(this);
        this.deleteSchedule =  this.deleteSchedule.bind(this);
        this.addTeamMember = this.addTeamMember.bind(this);
    }

    componentDidMount() {
        // fetch(this.state.host + "/resources")
        //   .then(res => res.json())
        //   .then(
        //     (result) => { 
        //           this.setState({
        //               resources: result
        //           });
        //           this.fetchSchedules();  
        //           this.fetchOrders();   
        //     },
        //     (error) => {
        //         this.setCalendar(); 
        //     }
        //   )
        let config = JSON.parse(localStorage.getItem('config'));
        this.setState({start_hour: config.startworkingtime, end_hour: config.endworkingtime})
          resourceService.getResourceList().then(dataobjs => {
            if (dataobjs && dataobjs === 401) {
              var { from } = this.props.location.state || { from: { pathname: "/login" } };  
              this.props.history.push(from);
            } else {              
                this.setState({
                    resources: dataobjs
                });
                this.fetchSchedules();  
                this.fetchOrders(); 
            }
          });

      }

      fetchSchedules() {
        // fetch(this.state.host + "/schedules")
        // .then(res => res.json())
        // .then(
        //   (result) => {
        //         this.setState({
        //             scheduled_orders: result
        //         });
        //        this.setCalendar();               
        //   },
        //   (error) => {
        //     console.log(error);
        //   }
        // ) //

        scheduleService.getScheduleList( this.formatStartOfDay(this.state.date), this.formatEndOfDay(this.state.date)).then(dataobjs => {
            if (dataobjs && dataobjs === 401) {
              var { from } = this.props.location.state || { from: { pathname: "/login" } };  
              this.props.history.push(from);
            } else {                    
                //console.log('Received schedules count: ' + dataobjs.length)          
              this.setState({
                scheduled_orders: dataobjs
                }); 
              this.setCalendar();
            }
          });

      }

      fetchOrders() {
        // fetch(this.state.host + "/orders?status=1")
        // .then(res => res.json())
        // .then(
        //   (result) => {
        //         this.setState({
        //             unscheduled_orders: result
        //         });   
        //   },
        //   (error) => {
        //     console.log(error);
        //   }
        // )

        servicerequestService.getOpenServiceRequestList().then(dataobjs => {
            if (dataobjs && dataobjs === 401) {
              var { from } = this.props.location.state || { from: { pathname: "/login" } };  
              this.props.history.push(from);
            } else {              
              this.setState({
                unscheduled_orders: dataobjs
            }); 
            }
          });
      }

      setSchedule(order) {
        //   fetch(this.state.host + "/schedules", {
        //       method: "post",
        //       headers: {'Content-Type':'application/json'},
        //       body: JSON.stringify(order)
        //   }).then(res => res.json())
        //     .then(
        //     (result) => {
        //         this.fetchSchedules();
        //     },
        //     (error) => {
        //         console.log(error);
        //     }
        // )

        scheduleService.addSchedule(order).then(dataobjs => {
            if (dataobjs && dataobjs === 401) {
              var { from } = this.props.location.state || { from: { pathname: "/login" } };  
              this.props.history.push(from);
            } else {              
                this.fetchSchedules();
            }
          });
      }

      updateSchedule(id,order) {
    //     fetch(this.state.host + "/schedules/" + id , {
    //         method: "put",
    //         headers: {'Content-Type':'application/json'},
    //         body: JSON.stringify(order)
    //     }).then(res => res.json())
    //       .then(
    //       (result) => {
    //           this.fetchSchedules();
    //       },
    //       (error) => {
    //           console.log(error);
    //       }
    //   )

      scheduleService.updateSchedule(order).then(dataobjs => {
        if (dataobjs && dataobjs === 401) {
          var { from } = this.props.location.state || { from: { pathname: "/login" } };  
          this.props.history.push(from);
        } else {              
            this.fetchSchedules();
        }
      }); 

    }

      updateOrder(id,order) {
        //   fetch(this.state.host + "/orders/" + id, {
        //       method: "put",
        //       headers: {'Content-Type':'application/json'},
        //       body: JSON.stringify(order)
        //   }).then(res => res.json())
        //   .then(
        //   (result) => {
        //       //console.log(result);
        //   },
        //   (error) => {
        //       console.log(error);
        //   }
        // )
        servicerequestService.updateServiceRequest(order, id).then(dataobjs => {
            if (dataobjs && dataobjs === 401) {
              var { from } = this.props.location.state || { from: { pathname: "/login" } };  
              this.props.history.push(from);
            } else {              
              // do nothing...
            }
          });
      }

      rescheduleOrder(sch) {
        //console.log(sch)
        var { from } = this.props.location.state || { from: { pathname: "/serviceclose/" + sch.key} };  
        this.props.history.push(from);
      }

      
      completeSchedule(sch) {
        //console.log(sch)
        if (sch.amleader) {
            // This is a leader. It will close the order and all associated schedules of the order. 
            //It also creates timesheet for all which are not yet created.
            var { from } = this.props.location.state || { from: { pathname: "/serviceclose/" + sch.key} };  
            this.props.history.push(from);
        } else {
            // This is not a leader's schedule. For non leader do the following:
            // 1. Close the schedule, generate timesheet and update the "Note" in associated order
            var { from } = this.props.location.state || { from: { pathname: "/scheduleclose/" + sch.id} };  
            this.props.history.push(from);
        }
      }

      deleteSchedule(sch) {
        //console.log(sch)
        scheduleService.deleteSchedule(sch.id, sch.key).then(dataobjs => {
            if (dataobjs && dataobjs === 401) {
              var { from } = this.props.location.state || { from: { pathname: "/login" } };  
              this.props.history.push(from);
            } else {              
                this.fetchSchedules();
                this.fetchOrders();
            }
          }); 
      }

      addTeamMember(hour, new_member_resourceid) {
          //console.log('addTeamMember:' + new_member_resourceid);
          //1. Find the new member is available? If not alert and exit
          //2. Add new schedule for this user.
          scheduleService.getSchedules(new_member_resourceid, this.formatUtcDate(new Date(hour.startdate)), hour.starttime, hour.startminute, hour.endtime, hour.endminute).then(ret => {
            if (ret && ret.length > 0) {
                alert('This resource is already booked on this time slot: ' + this.formatDateDisplay(new Date(hour.startdate)) + ' - ' + hour.starttime + ':' + hour.startminute + ' to ' + hour.endtime + ':' + hour.endminute);
            } else {
                let oScheduledOrder = {
                    "serviceid": Number(hour.key),
                    "peopleid": Number(new_member_resourceid),
                    "scheduledate": new Date(hour.startdate).toISOString(),
                    "starttime": hour.starttime,
                    "startminute": hour.startminute,
                    "endtime": hour.endtime,
                    "endminute": hour.endminute,
                    "width": hour.width,
                    "state": 1,
                    "amleader": false,
                    "originalscheduledid": (hour.originalscheduledid ? Number(hour.originalscheduledid) : Number(hour.id) )
                }
                //console.log(oScheduledOrder);
                this.setSchedule(oScheduledOrder);
            }
          });
      }

      formatDateDisplay(d) {        
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        let year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }

      formatDate(d) {        
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        let year = d.getFullYear();
        //(day, month, year);
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }

    formatUtcDate(d) {        
        let month = '' + (d.getUTCMonth() + 1);
        let day = '' + d.getUTCDate();
        let year = d.getFullYear();
        //console.log(day, month, year);
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }

    formatStartOfDay(d) {        
        d.setHours(0,0,0,0);
        return d.toISOString();
    }

    formatEndOfDay(d) {        
        d.setHours(23,59,59,999);
        return d.toISOString();
    }

      setCalendar() {
          //console.log('setCalnedar...1')
        let resources = this.state.resources;  
        let orders = this.state.scheduled_orders;

        let dailyhours = []; 
        //for (let i = 0; i < this.state.hour_count; i++) {
        for (let i = this.state.start_hour -1; i < this.state.end_hour; i++) {    
            let hobj = {"key": i+"_hour", "value": i+1};
            dailyhours.push(hobj);
        } 

        resources.forEach((window, index) => {
            let windowarray = [];
            window.orders = [];
            orders.forEach(order => {
                //console.log(Number(order.peopleid), Number(window.id));
                if (Number(order.peopleid) === Number(window.id)) {
                    //console.log('Matched', Number(order.peopleid), Number(window.id));
                    if (this.testUtcDate(order.scheduledate)) {
                        window.orders.push(order);
                    }
                }
            })
           
            //console.log('Before creating hour obj', this.state.start_hour -1, this.state.end_hour);
            //for (let j = 0; j < this.state.hour_count; j++) {
            for (let j = this.state.start_hour -1; j < this.state.end_hour; j++) {
                  let wobj = {"key": (j+index)+"_window", "hour" : (j+1), "resourcekey" : index, "startdate": "", "value": false};
                  for (let m = 0; m < window.orders.length; m++) {     
                    //console.log('comparing', window.orders[m].starttime, (j+1));                   
                    if (Number(window.orders[m].starttime) === (j+1)) {
                        wobj = {"id": window.orders[m].id, "key": window.orders[m].serviceid, "hour" : (j+1), "resourcekey" : index, 
                        "startdate": window.orders[m].scheduledate, "starttime": window.orders[m].starttime, 
                        "endtime": window.orders[m].endtime, "startminute": ''+ window.orders[m].startminute, 
                        "endminute": '' + window.orders[m].endminute, "width": window.orders[m].width, "value": window.orders[m].serviceid,
                        "state": window.orders[m].state, "city": window.orders[m].city, "customer": window.orders[m].customername,
                        "detail": window.orders[m].detail, "category": window.orders[m].problemtype, "type": window.orders[m].type,
                        "priority":  window.orders[m].priority, "assetid": window.orders[m].assetid, "assetdescription": window.orders[m].assetdescription,
                        "assetmodel": window.orders[m].assetmodel, "instruction": window.orders[m].instruction, "amleader": window.orders[m].amleader,
                        "originalscheduledid": window.orders[m].originalscheduledid
                        };
                        //console.log(wobj);
                    }
                  }     
                windowarray.push(wobj);
             } 
             window.hours = windowarray;
        });
       this.setState({
           calendar: resources,
           hours: dailyhours
       })
   }


   determineDay(date) {
    let currdate = new Date(date).setHours(0,0,0,0);
    let today = new Date().setHours(0,0,0,0); 
    let difference = Math.floor(((today - currdate) / (1000 * 60 * 60)));
    let dlabel = "Today";
    if (difference === 24) {    
        dlabel = "Yesterday";
    } 
    else if (difference > 24 || difference < -24) {
        dlabel = date.toLocaleDateString();
    }
    else if (difference === -24) {
        dlabel = "Tomorrow";
    }
    return dlabel;
   }

   testUtcDate(orderdate) {
        //console.log('testdate', orderdate);
        orderdate = util.utcToLocal(orderdate);
        //console.log('get local date', orderdate);
        let currentdate = false;
        let odate = new Date(orderdate).setHours(0,0,0,0);
        let today = this.state.date.setHours(0,0,0,0);
        if (odate === today) {            
            currentdate = true;
        }
        //console.log(currentdate);
        return currentdate;
    }

   testDate(orderdate) {
       //console.log('testdate', orderdate);
       //console.log('get local date');
       let currentdate = false;
       let odate = new Date(orderdate).setHours(0,0,0,0);
       let today = this.state.date.setHours(0,0,0,0);
       if (odate === today) {
        currentdate = true;
       }
       return currentdate;
   }

   processEndTime(currentwidth, updatewidth) {
       let diff = updatewidth/currentwidth;
       return diff.toFixed(2);
   }


    //event handlers
    handleDateChange = date => {
        let datelabel = this.determineDay(date);
        this.setState({
        date: new Date(date),
        datelabel: datelabel
        }, 
        function() {
            //this.setCalendar();
            this.fetchSchedules();
        });
    };

    handleDateButtonHandler = value => {
        var cdate = new Date(this.state.date);

        if (value === 1) {
              cdate.setDate(cdate.getDate() + 1);

        }
        else if (value === -1) {
            cdate.setDate(cdate.getDate() - 1);
        }
        this.handleDateChange(cdate);
    }

    handleUtilClick = () => {
        console.log('util click');
      } 

    handleOrderClick = orderId => {
        //console.log(orderId);
        var { from } = this.props.location.state || { from: { pathname: "/servicedetail/" + orderId} };  
        this.props.history.push(from);
    }

    onDragStart = (oEvent, id, type) => {
        //store clientx and clienty of existing order when item is dragged
        if (type === undefined) {
            this.dragorder = {id: id, startX: oEvent.clientX, startY: oEvent.clientY};
        }
        oEvent.target.classList.add('inDrag');
        oEvent.dataTransfer.effectAllowed = "clone";
        oEvent.dataTransfer.setData("id", id);
    }
    
    onDragOver = (oEvent) => {
        oEvent.preventDefault();
    }

    onDragEnd = (oEvent) => {
        oEvent.preventDefault();
        oEvent.target.classList.remove('inDrag');
    }

    onResizeStopNew = (oEvent, oDirection, oRef, oDimensions, order) => {
        let oScheduledOrders = this.state.scheduled_orders,
            oResizeOrder;
        //console.log('onResizeStopNew...........');
            // no change in dragging
        if (oDimensions.width === 0) {
            return;
        }
        //check to see if order is scheduled and update accordingly
        oScheduledOrders.forEach(current_order => {
            if (Number(current_order.id) === Number(order.id))
                oResizeOrder = current_order;
        });
        let updatedwidth, updatedposition, minute = "00";

        updatedwidth = order.width + oDimensions.width;
        if (updatedwidth < 70) {
            return; // at minimum 30 minutes must schedule
        }

        oResizeOrder.endtime = oResizeOrder.starttime + Math.floor(updatedwidth / this.state.cellwidth);

        let mn = updatedwidth % this.state.cellwidth;

        if (mn === 35) {
            oResizeOrder.endminute = 15
        } else if (mn > 35 && mn <= 70 ) {
            oResizeOrder.endminute = 30
        } else if (mn > 70 && mn <= 105 ) {
            oResizeOrder.endminute = 45
        } else {
            oResizeOrder.endminute = 0
        }

        oResizeOrder.width = updatedwidth;
        //oResizeOrder.minute = minute;
        
        this.updateSchedule(oResizeOrder.id, oResizeOrder);
        this.setCalendar(); //DILIP
    };
    
    
    onDropHandlerNew = (oEvent, oCell) => {
        let todaysDate = new Date();
        let schDate = new Date(this.state.date);
        let minute = "00";
        let hourchange = 0;
        let moveHr = 0;
        let positive = true;

        if (todaysDate.setHours(0, 0, 0, 0) > schDate.setHours(0, 0, 0, 0)) {
            alert("You can't schedule order in the past. Change start date to today or coming days.");
            return;
        }
        let id = oEvent.dataTransfer.getData("id");
        let oResource = this.state.resources[oCell.resourcekey];
        let oOpenOrders = this.state.unscheduled_orders,
            oScheduledOrder, oOpenOrder, oCurrentSchedule;           
        let oScheduledOrders = this.state.scheduled_orders;


        //check to see if order is scheduled and update accordingly
        oScheduledOrders.forEach(order => {
            if (Number(order.serviceid) === Number(id))
                oCurrentSchedule = order;
        });
        
        if (oCurrentSchedule) {
            //get updated clientx and clienty of dragged target
            //also use the x from currentTarget which is the cell in order to calculate the differential because of the location of the mouse click
            if (this.dragorder) {
                let adjustedX = (oEvent.clientX - this.dragorder.startX);
                positive = adjustedX < 0 ? false : true;
                           
                if (positive)
                {

                    let rem = adjustedX % this.state.cellwidth;
                    moveHr = Math.floor(adjustedX / this.state.cellwidth);
                    if (rem != 0 && (rem > 120)) {
                        moveHr = moveHr + 1;
                        rem = 0;
                    }
                    if (rem > 0) {
                        if (rem >= 25 && rem <= 52) {
                            minute = "15";
                        } else if (rem >= 53 && rem <= 87) {
                            minute = "30";
                        } else if (rem >= 88 && rem <= 120) {
                            minute = "45";
                        }
                        
                    }


                    let mn = Number(minute) + Number(oCurrentSchedule.startminute);
                    oCurrentSchedule.starttime = oCurrentSchedule.starttime + moveHr + Math.floor(mn/60);
                    mn = mn % 60;
                    oCurrentSchedule.startminute = mn;
                    mn = Number(minute) + Number(oCurrentSchedule.endminute);
                    oCurrentSchedule.endtime = oCurrentSchedule.endtime + moveHr +  Math.floor(mn/60);
                    mn = mn % 60;
                    oCurrentSchedule.endminute = mn;
                    oCurrentSchedule.peopleid = Number(oResource.id);  

                    let d = new Date(this.state.date).setHours(oCurrentSchedule.starttime, oCurrentSchedule.startminute,0,0);
                    oCurrentSchedule.scheduledate = new Date(d).toISOString();                  
                    
                } else {
                    
                    //console.log('adjustedX: ' + adjustedX + ' positive: ' + positive); 
                    //console.log('moveHr0: ' + moveHr);
                    let rem = Math.abs(adjustedX % this.state.cellwidth);
                    moveHr = Math.floor(Math.abs(adjustedX) / this.state.cellwidth);
                    //console.log('moveHr1: ' + moveHr);
                    if (rem != 0 && (rem > 120)) {
                        moveHr = moveHr + 1;
                        rem = 0;
                    }
                    //console.log('moveHr2: ' + moveHr);
                    if (rem > 0) {
                        if (rem >= 25 && rem <= 52) {
                            minute = "15";
                        } else if (rem >= 53 && rem <= 87) {
                            minute = "30";
                        } else if (rem >= 88 && rem <= 120) {
                            minute = "45";
                        }
                        //console.log('minute: ' + minute);
                    }                                        
        
                    let mn = Number(oCurrentSchedule.startminute) - Number(minute);
                    if (mn < 0) {
                        oCurrentSchedule.starttime = oCurrentSchedule.starttime - (moveHr + 1);
                    } else {
                        oCurrentSchedule.starttime = oCurrentSchedule.starttime - moveHr;
                    }                    
                    mn = Math.abs(mn);
                    oCurrentSchedule.startminute = mn;

                    mn = Number(oCurrentSchedule.endminute) - Number(minute);
                    if (mn < 0) {
                        oCurrentSchedule.endtime = oCurrentSchedule.endtime - (moveHr + 1);
                    } else {
                        oCurrentSchedule.endtime = oCurrentSchedule.endtime - moveHr;
                    }
                    mn = Math.abs(mn);
                    oCurrentSchedule.endminute = mn;
                    //console.log(this.state.date, oCurrentSchedule.starttime);
                    let d = new Date(this.state.date).setHours(oCurrentSchedule.starttime, oCurrentSchedule.startminute,0,0);                    
                    oCurrentSchedule.scheduledate = new Date(d).toISOString();                  
                    //oCurrentSchedule.scheduledate = new Date(this.state.date).toISOString();
                    oCurrentSchedule.peopleid = Number(oResource.id);                    
                }
            }

            //let currenthour = oCell.hour + hourchange;
            //oCurrentSchedule.starttime = currenthour;            
            //oCurrentSchedule.startminute = minute;
            //oCurrentSchedule.endminute = minute;            
            //oCurrentSchedule.endtime = currenthour + Math.floor(oCurrentSchedule.width / this.state.cellwidth);
            
            //oCurrentSchedule.peopleid = Number(oResource.id);
        }

        //select current open order to update scheduled status
        oOpenOrders.forEach(order => {
            if (Number(order.id) === Number(id))
                oOpenOrder = order;
        });

        if (oOpenOrder) {
            //oOpenOrder.scheduled = true;
            oOpenOrder.status = 2;
        }


        //create new scheduled order to be posted to API   
        if (!oCurrentSchedule) {           
            let d = new Date(this.state.date).setHours(oCell.hour,0,0,0);
            oScheduledOrder = {
                "serviceid": Number(id),
                "peopleid": Number(oResource.id),
                "scheduledate": new Date(d).toISOString(),
                "starttime": oCell.hour,
                "startminute": 0,
                "endtime": (oCell.hour + 1),
                "endminute": 0,
                "width": 140,
                "state": 1
            }
            //console.log('create schedule..........>>>>>')
            //console.log(oScheduledOrder);
        }
        if (oOpenOrder) {
            this.updateOrder(id, oOpenOrder);
        }

        if (oCurrentSchedule) {
            this.updateSchedule(oCurrentSchedule.id, oCurrentSchedule);
        }
        if (oScheduledOrder) {
            this.setSchedule(oScheduledOrder);
        }

        this.fetchOrders();
    }

    render() { 
      
        const { calendar, hours, unscheduled_orders, date, datelabel } = this.state;
    
      return (
        <div className="appContainer" style={{marginTop: '20px'}}>
            <Toolbar 
            dateHandler={this.handleDateChange}
            dateButtonHandler={this.handleDateButtonHandler} 
            utilHandler={this.handleUtilClick} 
            currentdate={date}
            datelabel={datelabel} />  
       <div>
            <Calendar 
            currenthours={hours} 
            calendar={calendar}
            dragStartHandler={this.onDragStart} 
            dragEndHandler={this.onDragEnd}
            dragHandler={this.onDragOver} 
            dropHandler={this.onDropHandlerNew}
            resizeHandler={this.onResizeStopNew}
            rescheduleOrder={this.rescheduleOrder}
            completeSchedule={this.completeSchedule} 
            deleteSchedule={this.deleteSchedule}
            addTeamMember={this.addTeamMember}
            />
      </div>
      <div>
            <Orders 
            orders={unscheduled_orders} 
            orderHandler={this.handleOrderClick} 
            dragStartHandler={this.onDragStart} 
            dragEndHandler={this.onDragEnd}
            currentdate={date} />
      </div>
    </div>
      );
    }

}