import React from "react";
import { render } from "react-dom";
import { timesheetService } from '../../service/timesheetService';
import utility from '../../service/utility'
import { Card, Button, InputGroup, FormControl, Form, Col, Modal } from 'react-bootstrap';
import { Redirect } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

class EditTimeSheet extends React.Component {  
  constructor(props) {
    super(props);
    this.state = {

      id: undefined,      
      customername: undefined,
      resource: undefined,
      serviceid: undefined,
      timesheetstatus: undefined,
      scheduledate: undefined,
      starttime: undefined,
      endtime: undefined,
      actualservicedeliverydate: new Date(),
      actualtotalservicehour: undefined,
      actualtotalservicemin: undefined,
      note: undefined,
      ////    

    }
  }

  componentDidMount() {  
    const { id } = this.props.match.params; //shceudler id
    this.state.id = id;
    
    if (id) {
      timesheetService.getTimeSheetById(id).then(res => {
        if (res && res === 401) {
          this.props.history.push("/login");
        } else if (res) {
          console.log(res);
          this.setState({                
            customername: res.customername,
            resource: res.resource,
            serviceid: res.serviceid,
            timesheetstatus: res.timesheetstatus,
            scheduledate: res.scheduledate,
            starttime: res.starttime,
            endtime: res.endtime,
            actualservicedeliverydate: (res.actualservicedeliverydate ? new Date(res.actualservicedeliverydate) : new Date()),
            actualtotalservicehour: res.actualtotalservicehour,
            actualtotalservicemin: res.actualtotalservicemin,
            note: res.note,
          });
        }
      });
    }
  }

  handleInputChange = (event) => {    
    const target = event.target;
    const name = target.id;    
    this.setState({
      [name]: target.value
    });    
  }
  
  handleSubmit = (event) => {
    event.preventDefault();
    let ts = {};
    ts.id = this.state.id;
    ts.actualservicedeliverydate = new Date(this.state.actualservicedeliverydate).toISOString();
    ts.actualtotalservicehour = this.state.actualtotalservicehour;
    ts.actualtotalservicemin = this.state.actualtotalservicemin;
    ts.note = this.state.note;
    timesheetService.updateTimeSheet(ts).then(resources => {
      if (resources && resources === 401) {
        this.props.history.push("/login");
      } else {
        this.setState({gobackto: '/timesheet'});        
      }
    });
  }

  handleCancel = (event) => {
    this.setState({gobackto: '/timesheet'});        
  }

  setDate = (date) => {
    this.setState({actualservicedeliverydate: date});
  }
 
  render() {

    if (this.state.gobackto) {
      return <Redirect to={this.state.gobackto} />
    }    
    
    return (
      <div className="content-screen">    
        <Card style={{ width: '36rem' }}>
          <Card.Header>                       
            TimeSheet - Order #{this.state.serviceid} | {this.state.customername}
          </Card.Header>
          <Card.Body>      

              <Form onSubmit={this.handleSubmit}>
              <Form.Row></Form.Row>
                  <Form.Row>
                  <Form.Group as={Col} controlId="actualservicedeliverydate">
                      <Form.Label  style={{fontSize: "14px"}}>Actual service delivery date</Form.Label>  
                      <div>
                      <DatePicker style={{displayValue: 'block'}}
                          selected={this.state.actualservicedeliverydate}
                          onChange={date => this.setDate(date)}
                          dateFormat="MMM d, yyyy HH:mm"
                          placeholderText="Enter a date"
                          
                      /> </div>                   
                    </Form.Group>
                    
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="actualtotalservicehour" onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Hours</Form.Label>
                      <Form.Control type="number" placeholder="Enter only hours" defaultValue={this.state.actualtotalservicehour}></Form.Control>            
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                  <Form.Group as={Col} controlId="actualtotalservicemin" onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Minutes</Form.Label>
                      <Form.Control type="number" placeholder="Enter only minutes" defaultValue={this.state.actualtotalservicemin}></Form.Control>            
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="note" onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Note</Form.Label>
                      <Form.Control placeholder="Enter remarks" defaultValue={this.state.note}></Form.Control>            
                    </Form.Group>
                  </Form.Row>
                   <div style={{float: "right"}} > 
                    <Button onClick={this.handleCancel} variant="secondary  mr-3" size="sm" >Cancel</Button>                    
                    <Button type="submit" variant="primary  mr-3" size="sm">Save</Button>                      
                    </div>
              </Form>
              
          </Card.Body>
        </Card>
      </div>
    );
  }
}

export default EditTimeSheet;