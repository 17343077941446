import React from "react";
import { render } from "react-dom";
import { customerService } from '../../service/customerService';
import { purchaseService } from '../../service/purchaseService';
import utility from '../../service/utility'
import { Card, Button, InputGroup, FormControl, Form, Col } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';

var config = require('../../service/config');

class EIPurchaseOrder extends React.Component {  
  constructor(props) {
    super(props);
    this.state = {
      serviceid: undefined,

      id: undefined,
      poNum: undefined,
      type: undefined,
      poDate: undefined,
      currency: undefined,
      clientid: undefined,
      clientName: undefined,
      requestedDate: undefined,
      status: undefined,
      companyId: undefined,      
      conversionRate: undefined,
      ordertotal: undefined,
      totalDiscount: undefined,
      totalTax: undefined,
      vendorAddrId: undefined,
      billingAddr: undefined,
      shippingAddr: undefined,
      customerVisibleNote: undefined,
      activityLog: undefined,
      paymentTerm: undefined,
      shippingMethod: undefined,

      lineobjs: [],
      linecolumns: [{
        dataField: 'id',
        text: 'id',
        hidden: true
      }, {
        dataField: 'poId',
        text: 'poId',
        hidden: true
      }, {
        dataField: 'vendorItemCode',
        text: 'vendorItemCode',
        hidden: true
      }, {
        dataField: 'item',
        text: 'Item',
        hidden: false,
        headerStyle: {
          backgroundColor: '#D9D8D9'
        },
        formatter: (cellContent, row) => {
          let config = JSON.parse(localStorage.getItem('config'));
          //console.log(config);
          let st = JSON.parse(config.serviceorderstatuses);
          return (
            <h6><small>
              {row.item &&
              <div className="label label-success">Item code# {row.item}</div>
              }
              {row.vendorItemCode &&
              <div className="label label-success">Vendor code# {row.vendorItemCode}</div>
              }
              {row.description &&
              <div className="label label-success">{row.description}</div>
              }
              {row.discount != undefined && 
              <div className="label label-success">Discount: {row.discount}</div>
              }
              {row.tax01 &&
              <div className="label label-success">Tax: {row.tax01}</div>
              }
            </small></h6>
          );            
        }
      }, {
        dataField: 'description',
        text: 'Description',
        hidden: true,
        headerStyle: {
          backgroundColor: '#D9D8D9'
        }
      }, {
        dataField: 'qty',
        text: 'Quantity',
        hidden: true,
        headerStyle: {
          backgroundColor: '#D9D8D9'
        }
      }, {
        dataField: 'unitPrice',
        text: 'Qty and Unit Price',
        hidden: false,
        headerStyle: {
          backgroundColor: '#D9D8D9'
        },
        formatter: (cellContent, row) => {
          let config = JSON.parse(localStorage.getItem('config'));
          //console.log(config);
          let st = JSON.parse(config.serviceorderstatuses);
          let qt = 0;
          if (row.qty) {
            qt = row.qty;
          }
          let up = 0;
          if (row.unitPrice) {
            up = Number(row.unitPrice).toFixed(2);
          }
          return (
            <h6><small>              
              {
              <div className="label label-success">Qty: {qt} </div>
              }
              {
              <div className="label label-success">Unit Price: {up}</div>
              }
            </small></h6>
          );            
        }
      }, {
        dataField: 'discount',
        text: 'Discount',
        hidden: true,
        headerStyle: {
          backgroundColor: '#D9D8D9'
        }
      }, {
        dataField: 'tax1',
        text: 'Tax',
        hidden: true,
        headerStyle: {
          backgroundColor: '#D9D8D9'
        }
      }],
      selectRow: {
        mode: 'radio',
        clickToSelect: true,
        hideSelectColumn: true,
        bgColor: '#adb5bd',
        onSelect: (row, isSelect, rowIndex, e) => {
          this.handleSelectedLineItem(row);
        }
      },
      availableaddrs: [],
      paymentOptions: [],
      shippingOptions: [],
      taxOptions:[],
      mouOptions: [],
      selectedsiteaddr: undefined,
    };
    this.handleSubmit = this.handleSubmit.bind(this);   
    this.handleInputChange = this.handleInputChange.bind(this);    
  }

  componentDidMount() {  
    
    let usr = JSON.parse(localStorage.getItem('user'));
    if (usr.config.term) {
      this.setState({paymentOptions: usr.config.term});
    }
    if (usr.config.ship) {
      this.setState({shippingOptions: usr.config.ship});
    }
    if (usr.config.tax) {
      this.setState({taxOptions: usr.config.tax});
    }
    if (usr.config.mou) {
      this.setState({mouOptions: usr.config.mou});
    }

    const { id } = this.props.match.params;
    const { serviceid } = this.props.match.params;
    this.state.id = id;

    if (!serviceid) {
      return;
    }
    this.state.serviceid = serviceid;

    purchaseService.getPo(id).then(po => {
      if (po && po === 401) {
        this.props.history.push("/login");
      } else if (po.clientid){
          //alert(JSON.stringify(so));  
             
          this.state.poNum = po.poNum;
          this.state.type = po.type;
          this.state.poDate = po.poDate;
          this.state.currency = po.currency;
          this.state.clientid = po.clientid;
          this.state.clientName = po.clientName;
          this.state.requestedDate = po.requestedDate;
          this.state.status = po.status;
          this.state.companyId = po.companyId;  
          this.state.conversionRate = po.conversionRate;
          this.state.ordertotal = po.ordertotal;
          this.state.totalDiscount = po.totalDiscount;
          this.state.totalTax = po.totalTax;
          this.state.billingAddr = po.billingAddr;
          this.state.shippingAddr = po.shippingAddr;
          this.state.customerVisibleNote = po.customerVisibleNote;
          this.state.activityLog = po.activityLog;
          this.state.vendorAddrId = po.vendorAddrId;
          this.state.paymentTerm = po.paymentTerm;
          this.state.shippingMethod = po.shippingMethod;

          if (po.lineItem) {
            this.setState({lineobjs: po.lineItem});
          }
        
        //alert(this.state.clientid);
        customerService.getAddressList(this.state.clientid).then(addrs => {
          if (addrs && addrs === 401) {
            this.props.history.push("/login");
          } else {
            //console.log(addrs);
            let tmp = [];
            for (let i=0; i<addrs.length; i++) {
              let ad = {};
              ad.name = addrs[i].addr1 + ", " + (addrs[i].city ? addrs[i].city + ', ': '') + 
                    (addrs[i].state ? addrs[i].state + ' ': '') +
                    (addrs[i].zip ? addrs[i].zip: '');    
              if (ad.name && ad.name != null) {            
                ad.id =  Number(addrs[i].id);     
                //console.log(addrs[i].id, so.shipToId)
                if (Number(addrs[i].id) === Number(po.vendorAddrId)) {
                  this.setState({selectedsiteaddr: {'name': ad.name, 'id': Number(addrs[i].id)}})
                }
                
                tmp.push(ad);
              }
            }
            //console.log(JSON.stringify(tmp))
            this.setState({ availableaddrs: tmp,
                            shippingMethod: po.shippingMethod,
                            paymentTerm: po.paymentTerm
                          });
          }
        });
      }    
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const name = target.id;
    //alert(name + ', ' + target.value);
    this.setState({
      [name]: target.value
    });
   
  }

  handleGoBack = (e) => {
    this.props.history.goBack();
  }
  
  handleDelete = (e) => {
    purchaseService.deletePo(this.state.id, this.state.poid).then(solitm => {
      if (solitm && solitm === 401) {
        this.props.history.push("/login");
      } else {
        //goback...
        this.props.history.goBack();
      }
    });    
  }

  handleSubmit(event) {

  }

  handleAddLineItem = (event) => {
    if (this.state.status === 1) {
      var { from } = this.props.location.state || { from: { pathname: "/addlineitempo/" + this.state.id + '/serviceid/' + this.state.serviceid} };  
      this.props.history.push(from);
    } else {
      alert("This order is in process. You can't edit it.")
    }
  }

  handleSelectedLineItem = (row) => {
    if (this.state.status === 1 ) {
      var { from } = this.props.location.state || { from: { pathname: "/addlineitempo/" + row.poId + '/serviceid/' + this.state.serviceid + '/item/' + row.id} };  
      this.props.history.push(from);
    } else {
      alert("This order is in process. You can't edit it.")
    }
  }

  handleSaveHeader = (event) => {
    //alert('handleSaveHeader...' + this.state.shipToId + ', ' + this.state.shippingMethod + ', ' + this.state.paymentTerm);
    let so ={};
    so.id = this.state.id;
    so.billingAddr = this.state.billingAddr;
    so.shippingAddr = this.state.shippingAddr;
    so.shippingMethod = this.state.shippingMethod;
    so.paymentTerm = this.state.paymentTerm;
    so.currency = this.state.currency;    
    so.status = this.state.status;
    so.vendorAddrId = this.state.vendorAddrId;

    purchaseService.updatePo(so).then(solitm => {
      if (solitm && solitm === 401) {
        this.props.history.push("/login");
      } else {
        //goback...
        //this.props.history.goBack();
        alert("Purchase order headers are saved!")
      }
    });  

  }

  render() {

    //vendor address option
    let addrOpt = [];
    addrOpt.push(<option>Choose...</option>);    
    if (this.state.availableaddrs) {      
      for (let i=0; i<this.state.availableaddrs.length; i++) {
        let obj = this.state.availableaddrs[i];
        if (this.state.vendorAddrId === obj.id) {
          addrOpt.push(<option key={obj.name} value={obj.id} selected>{obj.name}</option>);
        } else {
          addrOpt.push(<option key={obj.name} value={obj.id}>{obj.name}</option>);
        }        
      }
    }
    
    //payment options...
    let pmtOpt = [];
    pmtOpt.push(<option>Choose...</option>);
    if (this.state.paymentOptions && this.state.paymentOptions.length > 0) {
      for (let i=0; i<this.state.paymentOptions.length; i++) {
        if (this.state.paymentTerm === this.state.paymentOptions[i]) {
          pmtOpt.push(<option selected>{this.state.paymentOptions[i]}</option>);
        } else {
          pmtOpt.push(<option>{this.state.paymentOptions[i]}</option>);
        }        
      }
    }

    let shpOpt = [];
    shpOpt.push(<option>Choose...</option>);
    if (this.state.shippingOptions && this.state.shippingOptions.length > 0) {
      for (let i=0; i<this.state.shippingOptions.length; i++) {
          if (this.state.shippingMethod === this.state.shippingOptions[i]) {
            shpOpt.push(<option key={this.state.shippingOptions[i]} value={this.state.shippingOptions[i]} selected>{this.state.shippingOptions[i]}</option>);
          } else {
            shpOpt.push(<option key={this.state.shippingOptions[i]} value={this.state.shippingOptions[i]}>{this.state.shippingOptions[i]}</option>);
          }
      }
    }

    let txOpt = [];
    txOpt.push(<option>Choose...</option>);
    if (this.state.taxOptions) {      
      for (let i=0; i<this.state.taxOptions.length; i++) {
        let obj = this.state.taxOptions[i];
        txOpt.push(<option key={obj.name} value={obj.rate}>{obj.name}</option>);
      }
    }
    
    let currOpt = [];
    for (let i=0; i<config.currencies.length; i++) {
      //console.log(this.state.categoryOption[i]);
      if (this.state.currency === config.currencies[i].value) {
        currOpt.push(<option selected value={config.currencies[i].value}>{config.currencies[i].label}</option>);
      } else {
        currOpt.push(<option value={config.currencies[i].value}>{config.currencies[i].label}</option>);
      }
  }

    //console.log(JSON.stringify(this.state.selectedsiteaddr));
    let deletable = false;
    if (this.state.status && (this.state.status === 1)) {
      deletable = true;
    }
    console.log(this.state.status);
    console.log(deletable);

    return (
      <div className="content-screen">        
        <Card className="mb-3">
          <Card.Header>
              <div style={{float: 'left', display: "inline-block"}}>
              <>Purchase Order# {this.state.poNum} {' | ' + this.state.poDate} {' | ' + utility.getSalesOrderStatus(this.state.status)}</>                   
              </div>
              {deletable === true &&
              <div style={{float: 'right', display: "inline-block"}}>
                      <Button className="btn-sm" variant="link"  onClick={() => {if(window.confirm('Delete this sales order?')){this.handleDelete()};}}>delete</Button>
              </div>
              }
          </Card.Header>
          <Card.Body>  
              <Card.Text>
                <div style={{float: 'left', display: "inline-block"}}>  
                    <Card.Text style={{color: '#41417b', fontWeight: 'bold'}}>{this.state.clientName}</Card.Text>
                    <Card.Text style={{color: '#41417b', fontWeight: 'bold', marginTop: '-16px'}}>Order Total: {this.state.currency} {utility.formatMoney(this.state.ordertotal)}</Card.Text>
                </div>
                <div style={{float: 'right', display: "inline-block"}}>
                    <Button variant="outline-primary  mr-3" size="sm" onClick={this.handleGoBack} >Go Back</Button>
                </div>
                <div>&nbsp;</div>
              </Card.Text>  
              <Form onSubmit={this.handleAddCustomerSubmit} style={{marginTop: '60px'}}>
                  <Form.Row>
                    
                    <Form.Group as={Col} controlId="billingAddr" onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Billing Address</Form.Label>
                      <Form.Control size="sm" as="textarea" rows="3" defaultValue={this.state.billingAddr}>                                                        
                      </Form.Control>            
                    </Form.Group>

                    <Form.Group as={Col} controlId="shippingAddr" onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Shipping Address</Form.Label>
                      <Form.Control size="sm" as="textarea" rows="3" defaultValue={this.state.shippingAddr}>                                                        
                      </Form.Control>            
                    </Form.Group>
                    <Form.Group as={Col} controlId="vendorAddrId" onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Vendor Address</Form.Label>
                      <Form.Control size="sm" as="select" defaultValue={this.state.vendorAddrId}>
                            {addrOpt}                            
                      </Form.Control>            
                    </Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Form.Group as={Col} controlId="paymentTerm" onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Payment Term</Form.Label>
                      <Form.Control size="sm" as="select" defaultValue={this.state.paymentTerm}>
                            {pmtOpt}
                            
                      </Form.Control>            
                    </Form.Group>

                    <Form.Group as={Col} controlId="shippingMethod" onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Shipping Method</Form.Label>
                      <Form.Control size="sm" as="select" defaultValue={this.state.shippingMethod}>
                            {shpOpt}
                            
                      </Form.Control>            
                    </Form.Group>
                    <Form.Group as={Col} controlId="currency" onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Currency</Form.Label>
                      <Form.Control size="sm" as="select" defaultValue={this.state.currency}>
                            {currOpt}                            
                      </Form.Control> 
                    </Form.Group>
                  </Form.Row>             
                  {/* <Form.Row> 
                    {                    
                    <Form.Group as={Col} controlId="conversionRate" onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Exchange Rate</Form.Label>
                      <Form.Control size="sm" placeholder="Enter exchange rate" defaultValue={this.state.conversionRate}>
                            {shpOpt}
                            
                      </Form.Control>            
                    </Form.Group>                   
                    }
                    </Form.Row> */}
                   <div style={{float: "right"}} >                    
                   <Button variant="outline-primary  mr-3" size="sm" onClick={this.handleSaveHeader} >Save Header</Button>
                   </div>
              </Form>
              
          </Card.Body>
          <Card.Footer>
            <div className="h6 small">
              <BootstrapTable
                keyField='id'
                data={ this.state.lineobjs }
                columns={ this.state.linecolumns }
                selectRow={ this.state.selectRow }
              />
              <div style={{float: "right"}} > 
              <Button variant="outline-primary  mr-3" size="sm" onClick={this.handleAddLineItem} >Add Line Item</Button>
              </div>
              </div>
          </Card.Footer>
        </Card>
      </div>
    );
  }
}

export default EIPurchaseOrder;