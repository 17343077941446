import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import BusinessIcon from '@material-ui/icons/Business';
import BuildIcon from '@material-ui/icons/Build';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';

import { Resizable } from "re-resizable";

import '../../css/popover.css';
import '../../css/Chip.css';
import utility from '../../service/utility'
import { interpolateBasisClosed } from 'd3';

export default class OrderPopover extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            popOverAnchor: null,
            menuAnchor: null,            
            menuAddTechAnchor: null,
            hour: this.props.hour,
            resources: this.props.resources
        };
    }

    componentDidUpdate(prevProps) {
        let hour = this.state.hour;
        if (prevProps.hour !== this.props.hour) {
            hour = this.props.hour;
        }
       
        if (hour !== this.state.hour) {
          this.setState(prevState => ({
            ...prevState,
            hour: hour
          }))
        }
    
      }

    setPopOverAnchor = event => {
        if (event === null) {
            this.setState({
                popOverAnchor: event,
                menuAnchor: false
            });
        } else {
            this.setState({
                popOverAnchor: event
            });
        }
    }

    setMenuAnchor = event => {
        this.setState({
            menuAnchor: event
        });
    }

    setMenuAddTechAnchor = event => {        
        this.setState({
            menuAddTechAnchor: event
        });
    }

    getMinuteFormatted(m) {
        let ret = '' + m;
        if (ret.length === 1) {
            ret = '0' + ret;
        }
        return ret;
    } 

    getPriority(p) {            
        if (Number(p) === 1) {
            return (<Chip label={utility.getPriorityName(p)} className="chip redchip" variant="default" />);
        } else if (Number(p) === 2) {
            return (<Chip label={utility.getPriorityName(p)} className="chip yellowchip" variant="default" />);
        } else {
            return (<Chip label={utility.getPriorityName(p)} className="chip bluechip" variant="default" />);
        }
    }

    getMemberInfo(amleader) {
        if (amleader) {
            return (<Chip label="Leader" className="chip infochip" variant="default" />);
        } else {
            return (<Chip label="Member" className="chip infochip" variant="default" />);
        }
    }

    addTeamMember(id) {
        this.setMenuAddTechAnchor(null);
        this.setState({popOverAnchor: null});
        console.log('addTeamMember', id);  
        this.props.addTeamMember(this.state.hour, id);            
    }

    getResourcesMenuItems() {
        let menuItems = [];
        for (var i = 0; i < this.state.resources.length; i++) {
            //console.log(this.state.resources[i].id);
            let id = this.state.resources[i].id;
            menuItems.push(<MenuItem key={id} onClick={() => {this.addTeamMember(id)}}>{this.state.resources[i].fname + ' ' + this.state.resources[i].lname}</MenuItem>);
        }
        return <div>{menuItems}</div>;
    }

    render() {

        const { hour, popOverAnchor, menuAnchor, menuAddTechAnchor} = this.state;

        const testDateClass = (date, minute, state, amleader) => {
            var dateClass = "statusButton";
            var odate = new Date(date).setHours(0,0,0,0);
            var today = new Date().setHours(0,0,0,0);
            if (Number(state) === 5) {
              // No op -  gray button  
            } else {
                if (odate < today) {
                    if (amleader) { 
                        dateClass = "statusButton redStatus";
                    } else {
                        dateClass = "statusButton lredStatus";
                    }
                }
                //if (odate > today) {
                else {
                    console.log('amleader', amleader);
                    if (amleader) {    
                        dateClass = "statusButton greenStatus";
                    } else {
                        dateClass = "statusButton lgreenStatus";
                    }
                }
            }

            switch(minute) {
                case "15":
                    dateClass = dateClass + " minute_15";
                    break;
                case "30":
                    dateClass = dateClass + " minute_30";
                    break;
                case "45":
                    dateClass = dateClass + " minute_45";
                    break;
                default:
                    dateClass = dateClass + " minute_00";            
            }
            return dateClass;
        }

        const formatTime = (time, minute) => {
            if (time < 12) {
                return time + ":" + this.getMinuteFormatted(minute) + " am";
            } else {
                return time + ":" + this.getMinuteFormatted(minute) + " pm";
            }
            
        }

        const handlePopOverClick = event => {
            this.setPopOverAnchor(event.currentTarget);
        };

        const handleMenuClick = event => {
            this.setMenuAnchor(event.currentTarget);
        }

        const handleMenuAddTechClick = event => {
            this.setMenuAddTechAnchor(event.currentTarget);
        }
        
        const handlePopOverClose = () => {
           this.setPopOverAnchor(null);
        };

        const handleMenuClose = () => {
            this.setMenuAnchor(null);
        }

        const handleMenuAddTechClose = () => {
            this.setMenuAddTechAnchor(null);
        }

        const handleMenuItemClick = (item, hour) => {
            handleMenuClose();
            if (item === 'reschedule') {
                this.props.rescheduleOrder(hour);
            } else if (item === 'complete') {
                this.props.completeSchedule(hour);
            } else if (item === 'delete') {
                // var odate = new Date(hour.startdate).setHours(0,0,0,0);
                // var today = new Date().setHours(0,0,0,0);
                
                // if (odate < today) {
                //     alert('You cannot delete it. You can only reschedule a past order.');
                // }  else {    
                //     this.props.deleteSchedule(hour);
                // }

                this.props.deleteSchedule(hour);
            }             
        }

        const addTechnicians = event => {
            console.log('add technicians');
        }
        
        const isClosed = state => {
            if (state === 5) {
                return ' | CLOSED';
            }
            return '';
        }

        const popOverOpen = Boolean(popOverAnchor);       

        return (
            <div>
                <Resizable 
                grid={[35, 35]} 
                snapGap={35} 
                defaultSize={{width: hour.width, height: 36}}
                enable={{top: false, right:true, bottom:false, left: false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
                onResizeStop={(e, direction, ref, d) => this.props.resizeHandler(e, direction, ref, d, hour)}>
                    <Button 
                        variant="contained"
                        className={testDateClass(hour.startdate, hour.startminute, hour.state, hour.amleader)}
                        fullWidth={true}
                        onClick={handlePopOverClick}
                        onDragStart = {(e) => this.props.dragStartHandler(e, hour.value)}
                        onDragEnd = {(e) => this.props.dragEndHandler(e)} 
                        disableRipple={true}
                        disableElevation
                        draggable>
                            {hour.value}
                    </Button>
                </Resizable>
                <Popover
                    open={popOverOpen}
                    anchorEl={popOverAnchor}
                    onClose={handlePopOverClose}
                    anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                    }}
                    transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                    }}
                    >
                    <Menu className="popperMenu" open={Boolean(menuAnchor)} keepMounted anchorEl={menuAnchor} onClose={handleMenuClose}>
                        {/* <MenuItem onClick={() => handleMenuItemClick("notify")}> Notify </MenuItem> */}
                        <MenuItem onClick={() => handleMenuItemClick("complete", hour)}> Complete </MenuItem>
                        <MenuItem onClick={() => handleMenuItemClick("reschedule", hour)}> Complete &#38; Reschedule </MenuItem>
                        <MenuItem onClick={() => handleMenuItemClick("delete", hour)}> Unassign </MenuItem>
                        {/* <MenuItem onClick={() => handleMenuItemClick("members")}> Team Members </MenuItem> */}
                    </Menu>   
                      <div className="popOver">
                          <div className="popToolbar">
                            {formatTime(hour.starttime, hour.startminute)} - {formatTime(hour.endtime, hour.endminute)} {isClosed(hour.state)}
                            
                            <div className="utilMenuButton">
                            <Button size="small" onClick={handleMenuClick}>
                                <MoreVertIcon />
                            </Button>
                            </div>
                            
                          </div>
                          <div className="popContent">
                              <div className="popAddress">
                                  {hour.city}
                              </div>
                              <div className="popStatus">
                                  Category - {hour.category}
                              </div>
                              {/* <div className="popParameter">
                                  Temperature
                              </div> */}
                              <div className="popChips">
                                  {this.getPriority(hour.priority)}
                              {/* <Chip label="Medium" className="chip yellowchip" variant="default" /> */}
                                <Chip label={hour.type} className="chip greenchip" variant="default" />
                                    {this.getMemberInfo(hour.amleader)}
                              </div>
                              <div className="workOrderInfo">

                                  <div className="workOrder">
                                      <div className="workIcon">
                                            <BusinessIcon className="iconColor" />
                                      </div>
                                      <div className="workDetails">
                                          <div className="detailTitle">
                                                Customer
                                          </div>
                                          <div className="detailContent">
                                                {hour.customer}
                                          </div>
                                      </div>
                                  </div>

                                  <div className="workOrder">
                                      <div className="workIcon">
                                            <BuildIcon className="iconColor" />
                                      </div>
                                      <div className="workDetails">
                                          <div className="detailTitle">
                                                Equipment
                                          </div>
                                          <div className="detailContent">
                                                {hour.assetmodel} - {hour.assetdescription}
                                          </div>

                                          <div className="detailTitle">
                                                Notes (for the technician)
                                          </div>
                                          <div className="detailContent">
                                               {hour.instruction}
                                          </div>
                                      </div>
                                  </div>

                                  <div className="workOrder">
                                      <div className="workIcon">
                                            <LocalShippingIcon className="iconColor" />
                                      </div>
                                      <div className="workDetails">
                                          <div className="detailTitle">
                                                Service Request
                                          </div>
                                          <div className="detailContent" style={{color: 'blue'}}>
                                                <a href={'/servicedetail/' + hour.value} >{hour.value}</a>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div className="technicianLink">
                                  {/* <a href="#" onClick={addTechnicians}>Add Technicians</a> */}
                                  <a href="#" onClick={handleMenuAddTechClick} style={{color: 'blue'}}>Add Team Member</a>
                                  <Menu className="popperMenu" open={Boolean(menuAddTechAnchor)} keepMounted anchorEl={menuAddTechAnchor} onClose={handleMenuAddTechClose}>
                                    {this.getResourcesMenuItems()}
                                </Menu>                          
                              </div>
                          </div>
                     </div>  
                </Popover>
            </div>
        );
    }
}