import React from "react";
import { render } from "react-dom";
import { customerService } from '../../service/customerService';
import { slareorderService } from '../../service/salesorderService';
import utility from '../../service/utility'
import { Card, Button, InputGroup, FormControl, Form, Col } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';

class EISorderCreate extends React.Component {  
  constructor(props) {
    super(props);
    this.state = {
      id: undefined,
      orderNum: undefined,
      status: undefined,
      orderdate: undefined,
      serviceorderid: undefined,
      customerid: undefined,
      clientName: undefined,
      billToId: undefined,
      shipToId: undefined,
      billingaddr: undefined,
      siteaddr: undefined,
      currency: undefined,
      paymentTerm: undefined,
      shippingMethod: undefined,      
      internalnotes: undefined,
      ordertotal: undefined,
      currency: undefined,

      lineobjs: [],
      linecolumns: [{
        dataField: 'id',
        text: 'id',
        hidden: true
      }, {
        dataField: 'item',
        text: 'Item',
        hidden: false,
        headerStyle: {
          backgroundColor: '#D9D8D9'
        },
        formatter: (cellContent, row) => {
          let config = JSON.parse(localStorage.getItem('config'));
          //console.log(config);
          let st = JSON.parse(config.serviceorderstatuses);
          return (
            <h6><small>
              {row.item &&
              <div className="label label-success">{row.item}</div>
              }
              {row.description &&
              <div className="label label-success">{row.description}</div>
              }
              {row.discount != undefined && 
              <div className="label label-success">Discount: {row.discount}%</div>
              }
              {row.tax01 &&
              <div className="label label-success">Tax: {row.tax01}</div>
              }
            </small></h6>
          );            
        }
      }, {
        dataField: 'description',
        text: 'Description',
        hidden: true,
        headerStyle: {
          backgroundColor: '#D9D8D9'
        }
      }, {
        dataField: 'qty',
        text: 'Quantity',
        hidden: true,
        headerStyle: {
          backgroundColor: '#D9D8D9'
        }
      }, {
        dataField: 'unitPrice',
        text: 'Qty and Unit Price',
        hidden: false,
        headerStyle: {
          backgroundColor: '#D9D8D9'
        },
        formatter: (cellContent, row) => {
          let config = JSON.parse(localStorage.getItem('config'));
          //console.log(config);
          let st = JSON.parse(config.serviceorderstatuses);
          let qt = 0;
          if (row.qty) {
            qt = row.qty;
          }
          let up = 0;
          if (row.unitPrice) {
            up = Number(row.unitPrice).toFixed(2);
          }
          return (
            <h6><small>              
              {
              <div className="label label-success">Qty: {qt} </div>
              }
              {
              <div className="label label-success">Unit Price: {up}</div>
              }
            </small></h6>
          );            
        }
      }, {
        dataField: 'discount',
        text: 'Discount',
        hidden: true,
        headerStyle: {
          backgroundColor: '#D9D8D9'
        }
      }, {
        dataField: 'tax',
        text: 'Tax',
        hidden: true,
        headerStyle: {
          backgroundColor: '#D9D8D9'
        }
      }],
      selectRow: {
        mode: 'radio',
        clickToSelect: true,
        hideSelectColumn: true,
        bgColor: '#adb5bd',
        onSelect: (row, isSelect, rowIndex, e) => {
          this.handleSelectedLineItem(row);
        }
      },
      availableaddrs: [],
      paymentOptions: [],
      shippingOptions: [],
      taxOptions:[],
      mouOptions: [],
      selectedsiteaddr: undefined,
    };
    this.handleSubmit = this.handleSubmit.bind(this);   
    this.handleInputChange = this.handleInputChange.bind(this);    
  }

  componentDidMount() {  
    
    let usr = JSON.parse(localStorage.getItem('user'));
    if (usr.config.term) {
      this.setState({paymentOptions: usr.config.term});
    }
    if (usr.config.ship) {
      this.setState({shippingOptions: usr.config.ship});
    }
    if (usr.config.tax) {
      this.setState({taxOptions: usr.config.tax});
    }
    if (usr.config.mou) {
      this.setState({mouOptions: usr.config.mou});
    }

    const { id } = this.props.match.params;
    const { serviceid } = this.props.match.params;
    this.state.id = id;

    if (!serviceid) {
      return;
    }

    slareorderService.getSo(id).then(so => {
      if (so && so === 401) {
        this.props.history.push("/login");
      } else if (so.clientid){
          //alert(JSON.stringify(so));   
          this.state.clientid = so.clientid;
          this.state.serviceorderid = serviceid;
          this.state.billToId = so.billToId;
          this.state.shipToId = so.shipToId;
          this.state.orderNum = so.orderNum;
          this.state.clientName = so.clientName;
          this.state.currency = so.currency;
          this.state.shippingMethod = so.shippingMethod;
          this.state.paymentTerm = so.paymentTerm;
          this.state.status = so.status;
          this.state.orderdate = so.poDate;
          this.state.ordertotal = so.ordertotal;
          this.state.currency = so.currency;
          if (so.lineItem) {
            this.setState({lineobjs: so.lineItem});
          }
        
        //alert(this.state.clientid);
        customerService.getAddressList(this.state.clientid).then(addrs => {
          if (addrs && addrs === 401) {
            this.props.history.push("/login");
          } else {
            //console.log(addrs);
            let tmp = [];
            for (let i=0; i<addrs.length; i++) {
              let ad = {};
              ad.name = addrs[i].addr1 + ", " + (addrs[i].city ? addrs[i].city + ', ': '') + 
                    (addrs[i].state ? addrs[i].state + ' ': '') +
                    (addrs[i].zip ? addrs[i].zip: '');    
              if (ad.name && ad.name != null) {            
                ad.id =  Number(addrs[i].id);     
                //console.log(addrs[i].id, so.shipToId)
                if (Number(addrs[i].id) === Number(so.shipToId)) {
                  this.setState({selectedsiteaddr: {'name': ad.name, 'id': Number(addrs[i].id)}})
                }
                
                tmp.push(ad);
              }
            }
            //console.log(JSON.stringify(tmp))
            this.setState({ availableaddrs: tmp,
                            shippingMethod: so.shippingMethod,
                            paymentTerm: so.paymentTerm
                          });
          }
        });
      }    
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const name = target.id;
    //alert(name + ', ' + target.value);
    this.setState({
      [name]: target.value
    });
   
  }

  handleGoBack = (e) => {
    this.props.history.goBack();
  }
  
  handleDelete = (e) => {
    slareorderService.deleteSo(this.state.id, this.state.orderId).then(solitm => {
      if (solitm && solitm === 401) {
        this.props.history.push("/login");
      } else {
        //goback...
        this.props.history.goBack();
      }
    });    
  }

  handleSubmit(event) {

  }

  handleAddLineItem = (event) => {
    if (this.state.status === 1 || this.state.status === 7) {
      var { from } = this.props.location.state || { from: { pathname: "/addlineitem/" + this.state.id + '/serviceid/' + this.state.serviceorderid} };  
      this.props.history.push(from);
    } else {
      alert("This order is in process. You can't edit it.")
    }
  }

  handleSelectedLineItem = (row) => {
    if (this.state.status === 1 || this.state.status === 7) {
      var { from } = this.props.location.state || { from: { pathname: "/addlineitem/" + this.state.id + '/serviceid/' + this.state.serviceorderid + '/item/' + row.id} };  
      this.props.history.push(from);
    } else {
      alert("This order is in process. You can't edit it.")
    }
  }

  handleSaveHeader = (event) => {
    //alert('handleSaveHeader...' + this.state.shipToId + ', ' + this.state.shippingMethod + ', ' + this.state.paymentTerm);
    let so ={};
    so.id = this.state.id;
    so.billToId = this.state.billToId;
    so.shipToId = this.state.shipToId;
    so.shippingMethod = this.state.shippingMethod;
    so.paymentTerm = this.state.paymentTerm;
    so.status = this.state.status;
    slareorderService.updateSo(so).then(solitm => {
      if (solitm && solitm === 401) {
        this.props.history.push("/login");
      } else {
        //goback...
        //this.props.history.goBack();
        alert("Sales order headers are saved!")
      }
    });  

  }

  render() {

    let addrOpt = [];
    addrOpt.push(<option>Choose...</option>);    
    if (this.state.availableaddrs) {      
      for (let i=0; i<this.state.availableaddrs.length; i++) {
        let obj = this.state.availableaddrs[i];
        if (this.state.shipToId === obj.id) {
          addrOpt.push(<option key={obj.name} value={obj.id} selected>{obj.name}</option>);
        } else {
          addrOpt.push(<option key={obj.name} value={obj.id}>{obj.name}</option>);
        }        
      }
    }
    
    let pmtOpt = [];
    pmtOpt.push(<option>Choose...</option>);
    if (this.state.paymentOptions && this.state.paymentOptions.length > 0) {
      for (let i=0; i<this.state.paymentOptions.length; i++) {
        if (this.state.paymentTerm === this.state.paymentOptions[i]) {
          pmtOpt.push(<option selected>{this.state.paymentOptions[i]}</option>);
        } else {
          pmtOpt.push(<option>{this.state.paymentOptions[i]}</option>);
        }        
      }
    }

    let shpOpt = [];
    shpOpt.push(<option>Choose...</option>);
    if (this.state.shippingOptions && this.state.shippingOptions.length > 0) {
      for (let i=0; i<this.state.shippingOptions.length; i++) {
          if (this.state.shippingMethod === this.state.shippingOptions[i]) {
            shpOpt.push(<option key={this.state.shippingOptions[i]} value={this.state.shippingOptions[i]} selected>{this.state.shippingOptions[i]}</option>);
          } else {
            shpOpt.push(<option key={this.state.shippingOptions[i]} value={this.state.shippingOptions[i]}>{this.state.shippingOptions[i]}</option>);
          }
      }
    }

    let txOpt = [];
    txOpt.push(<option>Choose...</option>);
    if (this.state.taxOptions) {      
      for (let i=0; i<this.state.taxOptions.length; i++) {
        let obj = this.state.taxOptions[i];
        txOpt.push(<option key={obj.name} value={obj.rate}>{obj.name}</option>);
      }
    }

    //console.log(JSON.stringify(this.state.selectedsiteaddr));
    let deletable = false;
    if (this.state.status && (this.state.status === 1 || this.state.status === 7)) {
      deletable = true;
    }
    console.log(this.state.status);
    console.log(deletable);

    return (
      <div className="content-screen">        
        <Card className="mb-3">
          <Card.Header>
              <div style={{float: 'left', display: "inline-block"}}>
              <>Sales Order# {this.state.orderNum} {' | ' + this.state.orderdate} {' | ' + utility.getSalesOrderStatus(this.state.status)}</>                   
              </div>
              {deletable === true &&
              <div style={{float: 'right', display: "inline-block"}}>
                      <Button className="btn-sm" variant="link"  onClick={() => {if(window.confirm('Delete this sales order?')){this.handleDelete()};}}>delete</Button>
              </div>
              }
          </Card.Header>
          <Card.Body>    
              <div style={{float: 'left', display: "inline-block"}}>  
                  <Card.Text style={{color: '#41417b', fontWeight: 'bold'}}>Order Total: {this.state.currency} {utility.formatMoney(this.state.ordertotal)}</Card.Text>
              </div>
              <div style={{float: 'right', display: "inline-block"}}>
                  <Button variant="outline-primary  mr-3" size="sm" onClick={this.handleGoBack} >Go Back</Button>
              </div>
              <div>&nbsp;</div>
  
              <Form onSubmit={this.handleAddCustomerSubmit} style={{marginTop: '40px'}}>
                  <Form.Row>
                    <Form.Group as={Col} controlId="shipToId" onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Shipping Address</Form.Label>
                      <Form.Control size="sm"  as="select" defaultValue={this.state.shipToId}>
                            {addrOpt}
                            
                      </Form.Control>            
                    </Form.Group>

                    <Form.Group as={Col} controlId="paymentTerm" onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Payment Term</Form.Label>
                      <Form.Control size="sm"  as="select" defaultValue={this.state.paymentTerm}>
                            {pmtOpt}
                            
                      </Form.Control>            
                    </Form.Group>

                    <Form.Group as={Col} controlId="shippingMethod" onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Shipping Method</Form.Label>
                      <Form.Control size="sm"  as="select" defaultValue={this.state.shippingMethod}>
                            {shpOpt}
                            
                      </Form.Control>            
                    </Form.Group>

                   </Form.Row>
                   <div style={{float: "right"}} >                    
                   <Button variant="outline-primary  mr-3" size="sm" onClick={this.handleSaveHeader} >Save Header</Button>
                   </div>
              </Form>
              
          </Card.Body>
          <Card.Footer>
            <div className="h6 small">
              <BootstrapTable
                keyField='id'
                data={ this.state.lineobjs }
                columns={ this.state.linecolumns }
                selectRow={ this.state.selectRow }
              />
              <div style={{float: "right"}} > 
              <Button variant="outline-primary  mr-3" size="sm" onClick={this.handleAddLineItem} >Add Line Item</Button>
              </div>
              </div>
          </Card.Footer>
        </Card>
      </div>
    );
  }
}

export default EISorderCreate;