import React from "react";
import { render } from "react-dom";
import { customerService } from '../../service/customerService';
import { invoiceService } from '../../service/invoiceService';
import utility from '../../service/utility'
import { Card, Button, InputGroup, FormControl, Form, Col } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';

class EIInvoiceCreate extends React.Component {  
  constructor(props) {
    super(props);
    this.state = {
      id: undefined,
      clientId: undefined,
      serviceorderid: undefined,
      invdate: undefined,
      billToId: undefined,
      shippToId: undefined,
      invoiceId: undefined,
      clientName: undefined,
      currencyCode: undefined,
      exchangeRate: undefined,
      exchangeRateDate: undefined,
      email: undefined,
      shippingAgent: undefined,
      term: undefined,
      status: undefined,
      orderdate: undefined,
      amountInvoiced: undefined,
      outstandingAmount: undefined,

      lineobjs: [],
      linecolumns: [{
        dataField: 'id',
        text: 'id',
        hidden: true
      }, {
        dataField: 'item',
        text: 'Item',
        hidden: false,
        headerStyle: {
          backgroundColor: '#D9D8D9'
        },
        formatter: (cellContent, row) => {
          let config = JSON.parse(localStorage.getItem('config'));
          //console.log(config);
          let st = JSON.parse(config.serviceorderstatuses);
          return (
            <h6><small>
              {row.item &&
              <div className="label label-success">{row.item}</div>
              }
              {row.description &&
              <div className="label label-success">{row.description}</div>
              }
              {row.discount != undefined && 
              <div className="label label-success">Discount: {row.discount}%</div>
              }
              {row.tax01 &&
              <div className="label label-success">Tax: {row.tax1}% ({row.tax01})</div>
              }
              {row.whLocation &&
              <div className="label label-success">Warehouse: {row.whLocation}</div>
              }
            </small></h6>
          );            
        }
      }, {
        dataField: 'description',
        text: 'Description',
        hidden: true,
        headerStyle: {
          backgroundColor: '#D9D8D9'
        }
      }, {
        dataField: 'whLocation',
        text: 'Warehouselo',
        hidden: true,
        headerStyle: {
          backgroundColor: '#D9D8D9'
        }
      }, {
        dataField: 'qty',
        text: 'Quantity',
        hidden: true,
        headerStyle: {
          backgroundColor: '#D9D8D9'
        }
      }, {
        dataField: 'unitPrice',
        text: 'Qty and Unit Price',
        hidden: false,
        headerStyle: {
          backgroundColor: '#D9D8D9'
        },
        formatter: (cellContent, row) => {
          let config = JSON.parse(localStorage.getItem('config'));
          //console.log(config);
          let st = JSON.parse(config.serviceorderstatuses);
          let qt = 0;
          if (row.qty) {
            qt = row.qty;
          }
          let up = 0;
          if (row.unitPrice) {
            up = Number(row.unitPrice).toFixed(2);
          }
          return (
            <h6><small>              
              {
              <div className="label label-success">Qty: {qt} </div>
              }
              {
              <div className="label label-success">Unit Price: {up}</div>
              }
            </small></h6>
          );            
        }
      }, {
        dataField: 'discount',
        text: 'Discount',
        hidden: true,
        headerStyle: {
          backgroundColor: '#D9D8D9'
        }
      }, {
        dataField: 'tax1',
        text: 'Tax',
        hidden: true,
        headerStyle: {
          backgroundColor: '#D9D8D9'
        }
      }],
      selectRow: {
        mode: 'radio',
        clickToSelect: true,
        hideSelectColumn: true,
        bgColor: '#adb5bd',
        onSelect: (row, isSelect, rowIndex, e) => {
          this.handleSelectedLineItem(row);
        }
      },
      availableaddrs: [],
      paymentOptions: [],
      shippingOptions: [],
      taxOptions:[],
      mouOptions: [],
      selectedsiteaddr: undefined,
    };
    this.handleSubmit = this.handleSubmit.bind(this);   
    this.handleInputChange = this.handleInputChange.bind(this);    
  }

  componentDidMount() {  
    
    let usr = JSON.parse(localStorage.getItem('user'));
    if (usr.config.term) {
      this.setState({paymentOptions: usr.config.term});
    }
    if (usr.config.ship) {
      this.setState({shippingOptions: usr.config.ship});
    }
    if (usr.config.tax) {
      this.setState({taxOptions: usr.config.tax});
    }
    if (usr.config.mou) {
      this.setState({mouOptions: usr.config.mou});
    }

    const { id } = this.props.match.params;
    const { serviceid } = this.props.match.params;
    this.state.id = id;

    if (!serviceid) {
      return;
    }

    invoiceService.getInv(id).then(so => {
      if (so && so === 401) {
        this.props.history.push("/login");
      } else if (so.clientId){
          //console.log(so);   
          this.state.clientId = so.clientId;
          this.state.serviceorderid = serviceid;
          this.state.invdate = so.invdate;
          this.state.billToId = so.billToId;
          this.state.shippToId = so.shippToId;
          this.state.invoiceId = so.invoiceId;
          this.state.clientName = so.clientName;
          this.state.currencyCode = so.currencyCode;
          this.state.exchangeRate = so.exchangeRate;
          this.state.exchangeRateDate = so.exchangeRateDate;
          this.state.email = so.email;
          this.state.shippingAgent = so.shippingAgent; //shippingMethod
          this.state.term = so.term;
          this.state.status = so.status;
          this.state.orderdate = so.poDate;
          this.state.amountInvoiced = so.amountInvoiced;
          this.state.outstandingAmount = so.outstandingAmount;
          
          
          if (so.lineItem) {
            this.setState({lineobjs: so.lineItem});
          }
        
        //alert(this.state.clientid);
        customerService.getAddressList(this.state.clientId).then(addrs => {
          if (addrs && addrs === 401) {
            this.props.history.push("/login");
          } else {
            //console.log(addrs);
            let tmp = [];
            for (let i=0; i<addrs.length; i++) {
              let ad = {};
              ad.name = addrs[i].addr1 + ", " + (addrs[i].city ? addrs[i].city + ', ': '') + 
                    (addrs[i].state ? addrs[i].state + ' ': '') +
                    (addrs[i].zip ? addrs[i].zip: '');    
              if (ad.name && ad.name != null) {            
                ad.id =  Number(addrs[i].id);     
                //console.log(addrs[i].id, so.shippToId)
                if (Number(addrs[i].id) === Number(so.shippToId)) {
                  this.setState({selectedsiteaddr: {'name': ad.name, 'id': Number(addrs[i].id)}})
                }
                
                tmp.push(ad);
              }
            }
            //console.log(JSON.stringify(tmp))
            this.setState({ availableaddrs: tmp,
                            shippingMethod: so.shippingAgent,
                            term: so.term
                          });
          }
        });
      }    
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const name = target.id;
    //alert(name + ', ' + target.value);
    this.setState({
      [name]: target.value
    });
   
  }

  handleGoBack = (e) => {
    this.props.history.goBack();
  }
  
  handleDelete = (e) => {
    invoiceService.deleteSo(this.state.id, this.state.serviceorderid).then(solitm => {
      if (solitm && solitm === 401) {
        this.props.history.push("/login");
      } else {
        //goback...
        this.props.history.goBack();
      }
    });    
  }

  handleSubmit(event) {

  }

  handleAddLineItem = (event) => {
    if (this.state.status === 1 || this.state.status === 3) {
      var { from } = this.props.location.state || { from: { pathname: "/addinvitem/" + this.state.id + '/serviceid/' + this.state.serviceorderid} };  
      this.props.history.push(from);
    } else {
      alert("This invoice is in-processed. You cann't edit it");
    }
  }

  handleSelectedLineItem = (row) => {
    if (this.state.status === 1 || this.state.status === 3) {
      var { from } = this.props.location.state || { from: { pathname: "/addinvitem/" + this.state.id + '/serviceid/' + this.state.serviceorderid + '/item/' + row.id} };  
      this.props.history.push(from);
    } else {
      alert("This invoice is in-processed. You cann't edit it");
    }
  }

  handleSaveHeader = (event) => {
    //alert('handleSaveHeader...' + this.state.shipToId + ', ' + this.state.shippingMethod + ', ' + this.state.paymentTerm);
    let so ={};
    so.id = this.state.id;
    so.clientId = this.state.clientId;
    so.billToId = this.state.billToId;
    if (this.state.shippToId) {
      so.shippToId = Number(this.state.shippToId);
    }    
    so.shippingAgent = this.state.shippingAgent;
    so.term = this.state.term;
    so.status = this.state.status;
    so.invoiceId = this.state.invoiceId
    so.clientName = this.state.clientName
    
    //console.log(so);
    invoiceService.updateInv(so).then(solitm => {
      if (solitm && solitm === 401) {
        this.props.history.push("/login");
      } else {
        //goback...
        //this.props.history.goBack();
        alert("Invoice headers are saved!")
      }
    });  

  }

  sendEmail =(e) => {
    var { from } = { from: { pathname: "/sendemail"} };  
    var email = {};
    email.id = this.state.id;
    email.module = 'invoice';
    email.customerid = this.state.clientId;    
    this.props.history.push(from, email);
  }

  render() {
    //console.log('state', this.state);
    let addrOpt = [];
    addrOpt.push(<option>Choose...</option>);    
    if (this.state.availableaddrs) {      
      for (let i=0; i<this.state.availableaddrs.length; i++) {
        let obj = this.state.availableaddrs[i];
        if (this.state.shippToId === obj.id) {
          addrOpt.push(<option key={obj.name} value={obj.id} selected>{obj.name}</option>);
        } else {
          addrOpt.push(<option key={obj.name} value={obj.id}>{obj.name}</option>);
        }        
      }
    }
    
    let pmtOpt = [];
    pmtOpt.push(<option>Choose...</option>);
    if (this.state.paymentOptions && this.state.paymentOptions.length > 0) {
      for (let i=0; i<this.state.paymentOptions.length; i++) {
        if (this.state.term === this.state.paymentOptions[i]) {
          pmtOpt.push(<option selected>{this.state.paymentOptions[i]}</option>);
        } else {
          pmtOpt.push(<option>{this.state.paymentOptions[i]}</option>);
        }        
      }
    }

    let shpOpt = [];
    shpOpt.push(<option>Choose...</option>);
    if (this.state.shippingOptions && this.state.shippingOptions.length > 0) {
      for (let i=0; i<this.state.shippingOptions.length; i++) {
          if (this.state.shippingMethod === this.state.shippingOptions[i]) {
            shpOpt.push(<option key={this.state.shippingOptions[i]} value={this.state.shippingOptions[i]} selected>{this.state.shippingOptions[i]}</option>);
          } else {
            shpOpt.push(<option key={this.state.shippingOptions[i]} value={this.state.shippingOptions[i]}>{this.state.shippingOptions[i]}</option>);
          }
      }
    }

    let txOpt = [];
    txOpt.push(<option>Choose...</option>);
    if (this.state.taxOptions) {      
      for (let i=0; i<this.state.taxOptions.length; i++) {
        let obj = this.state.taxOptions[i];
        txOpt.push(<option key={obj.name} value={obj.rate}>{obj.name}</option>);
      }
    }

    //console.log(JSON.stringify(this.state.selectedsiteaddr));
    let deletable = false;
    if (this.state.status && this.state.status === 1 ) {
      deletable = true;
    }
    //console.log(this.state.status);
    //console.log(deletable);

    return (
      <div className="content-screen">        
        <Card className="mb-3">
          <Card.Header>
              <div style={{float: 'left', display: "inline-block"}}>
              <>Invoice# {this.state.invoiceId} {' | ' + this.state.invdate} {' | ' + utility.getInvoiceOrQuoteStatus(this.state.status)}</>                   
              </div>
              {/* {deletable === true &&
              <div style={{float: 'right', display: "inline-block"}}>
                      <Button className="btn-sm" variant="link"  onClick={() => {if(window.confirm('Delete this invoice?')){this.handleDelete()};}}>delete</Button>
              </div>
              } */}
          </Card.Header>
          <Card.Body>    
              <div style={{float: 'left', display: "inline-block"}}>  
                  <Card.Text style={{color: '#41417b', fontWeight: 'bold'}}>Invoice Total: {this.state.currency} {utility.formatMoney(this.state.amountInvoiced)}</Card.Text>
              </div>
              <div style={{float: 'right', display: "inline-block"}}>
                  <Button variant="outline-primary" size="sm" onClick={this.handleGoBack} >Go Back</Button>
                  <Button variant="outline-primary ml-3" size="sm" onClick={this.sendEmail} >Email</Button>
              </div>
              <div>&nbsp;</div>
  
              <Form style={{marginTop: '40px'}}>
                  <Form.Row>
                    <Form.Group as={Col} controlId="shippToId" onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Shipping Address</Form.Label>
                      <Form.Control size="sm"  as="select" defaultValue={this.state.shippToId}>
                            {addrOpt}
                            
                      </Form.Control>            
                    </Form.Group>

                    <Form.Group as={Col} controlId="term" onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Payment Term</Form.Label>
                      <Form.Control size="sm"  as="select" defaultValue={this.state.term}>
                            {pmtOpt}
                            
                      </Form.Control>            
                    </Form.Group>

                    <Form.Group as={Col} controlId="shippingAgent" onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Shipping Method</Form.Label>
                      <Form.Control size="sm"  as="select" defaultValue={this.state.shippingAgent}>
                            {shpOpt}
                            
                      </Form.Control>            
                    </Form.Group>

                   </Form.Row>
                   <div style={{float: "right"}} >                    
                   <Button variant="outline-primary" size="sm" onClick={this.handleSaveHeader} >Save Header</Button>
                   </div>
              </Form>
              
          </Card.Body>
          <Card.Footer>
            <div className="h6 small">
              <BootstrapTable
                keyField='id'
                data={ this.state.lineobjs }
                columns={ this.state.linecolumns }
                selectRow={ this.state.selectRow }
              />
              <div style={{float: "right"}} > 
              <Button variant="outline-primary" size="sm" onClick={this.handleAddLineItem} >Add Line Item</Button>
              </div>
              </div>
          </Card.Footer>
        </Card>
      </div>
    );
  }
}

export default EIInvoiceCreate;