import React, { forwardRef } from "react";
import { Card, Button, Form, Col } from 'react-bootstrap';
import { reportService } from '../../service/reportService';
import "react-datepicker/dist/react-datepicker.css";

import MaterialTable from "material-table";

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

class RecallReport extends React.Component {
  constructor(props) {
    super(props);   
    props.location.search= localStorage.getItem('recallsearch');
    this.state = {
        model: undefined,
        serial_no: undefined,
        manufacturer: undefined,
        lotnumber: undefined,
        serial_no_from: undefined,
        serial_no_to: undefined,

        reportobjs: [],
        
        columns: [
        {
          field: 'id',
          title: 'Asset #',          
          hidden: true,
        },
        {
          field: 'model',
          title: 'Model',
        },
        {
          field: 'serial_no',
          title: 'Serial#',
        }, 
        {
          field: 'manufacturer',
          title: 'Manufacturer',          
        },
        {
          field: 'lotnumber',
          title: 'Lot #',   
          hidden: true,       
        },          
        {
          field: 'asset_status',
          title: 'Status',
        },
        {
          field: 'clientid',
          title: 'Client #',          
          hidden: true,
        },
        {
          field: 'name',
          title: 'Organization',          
        },
        {
          field: 'addr1',
          title: 'Address',
        },
        {
          field: 'city',
          title: 'City',
        },
        {
          field: 'state',
          title: 'State',
        }, 
        {
          field: 'fname',
          title: 'Contact',
          render: rowData => {
            let sk = (rowData.fname ? rowData.fname + ' ': '');
            sk = sk + (rowData.lname ? rowData.lname : '');            
            return (              
                <span > {sk}</span>
            );            
          }
        }, 
        {
          field: 'phone',
          title: 'Phone',
        }, 
        {
          field: 'email',
          title: 'Email',
        }         
      ],
    }
  }

  componentDidMount() {   
  }

  
  handleInputChange = (event) => {    
    const target = event.target;
    const name = target.id;    
    this.setState({
      [name]: target.value
    });    
  }


  handleSubmit = (event) => {
    event.preventDefault();
    
    let asst = {};
    asst.manufacturer = this.state.manufacturer;
    asst.lotnumber = this.state.lotnumber;
    asst.model = this.state.model;
    asst.serial_no = this.state.serial_no;
    asst.serial_no_from = this.state.serial_no_from;
    asst.serial_no_to = this.state.serial_no_to;
    console.log(asst);

    reportService.getRecallAssets(asst).then(ast => {
      if (ast && ast === 401) {
        this.props.history.push("/login");
      } else {  
        this.setState({reportobjs: ast});           
      }
    });
  }

  
  render() {
 
    return (
      <div className="content-screen" style={{height: '100%', overflow: 'scroll'}}>
      
        <Card style={{ width: '73rem', marginTop: '20px' }}>
          <Card.Header style={{backgroundColor: 'white'}}>
              <Form onSubmit={this.handleSubmit}>

                  <Form.Row>
                    <Form.Group as={Col} controlId="manufacturer" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}} size="sm">Manufacturer</Form.Label>
                      <Form.Control size="sm" placeholder="Enter asset model" defaultValue={this.state.manufacturer} />
                    </Form.Group>
                    <Form.Group as={Col} controlId="model" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}} size="sm">Model</Form.Label>
                      <Form.Control size="sm" placeholder="Enter asset model" defaultValue={this.state.model} />
                    </Form.Group>
                    <Form.Group as={Col} controlId="serial_no" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}} size="sm">Serial #</Form.Label>
                      <Form.Control size="sm" placeholder="Enter serial #" defaultValue={this.state.serial_no} />
                    </Form.Group>
                  </Form.Row> 
                  <Form.Row>                    
                  <Form.Group as={Col} controlId="lotnumber" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}} size="sm">Lot #</Form.Label>
                      <Form.Control  size="sm" placeholder="Enter lot #" defaultValue={this.state.lotnumber} />
                    </Form.Group>
                    <Form.Group as={Col} controlId="serial_no_from" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}} size="sm">From Serial #</Form.Label>
                      <Form.Control  size="sm" placeholder="Enter serial #" defaultValue={this.state.serial_no_from} />
                    </Form.Group>
                    <Form.Group as={Col} controlId="serial_no_to" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}} size="sm">To Serial #</Form.Label>
                      <Form.Control  size="sm" placeholder="Enter serial #" defaultValue={this.state.serial_no_to} />
                    </Form.Group>
                  </Form.Row> 

                  <div style={{float: "left"}} >                                       
                    <Button variant="outline-primary" size="sm" type="submit">Get Report</Button>                              
                  </div>
              </Form>

          </Card.Header>
          <Card.Body style={{marginRight: '-20px', marginLeft: '-20px'}}>            
            <div>
            <div style={{ maxWidth: '100%' }}>            
              <MaterialTable
                icons={tableIcons}
                title={<span style={{fontSize: '1rem'}}>Recall Report</span>}
                columns={this.state.columns}
                data={this.state.reportobjs}
                //title="Demo Title"
                onSearchChange={(e) => {localStorage.setItem('recallsearch', e)}}
                options={{
                  searchText: this.props.location.search,
                  search: true,
                  searchFieldAlignment: 'left',
                  sorting: true,
                  exportButton: true,
                  //selection: true,
                  pageSize: 20,
                  headerStyle: {
                    backgroundColor: '#edeff1',
                    color: '#000',
                    borderColor: '#9da6af',
                  }                
                }}
                onRowClick={((evt, selectedRow) => {
                  //this.props.history.push('/assetsforclnt/' + selectedRow.id);             
                })}
              />
            </div>
            </div>
          </Card.Body>          
        </Card>      

      </div>
    );
  }
}


export default RecallReport;