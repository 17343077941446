import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { Route, Switch, Redirect } from "react-router-dom";
//import Home from "./components/screens/Scheduling";
import OrderCalendar from "./components/calendar/OrderCalendar";
import MyActivity from "./components/screens/MyActivity";
import Resource from "./components/screens/ResourcesList";
import Home from "./components/screens/ServicesList";
import Skill from "./components/screens/SkillsList";
import Setting from "./components/screens/Setting";
import Analytics from "./components/screens/Analytics";
import ResourceDetail from "./components/screens/ResourceDetail";
import SkillDetail from "./components/screens/SkillDetail";
import ServiceDetail from "./components/screens/ServiceDetail";
import ServiceCreate from "./components/screens/ServiceCreate";
import CustomerDetail from "./components/screens/CustomerDetail";
import CustomerCreate from "./components/screens/CustomerCreate";
import ServiceClose from "./components/screens/ServiceCloseAndReschedule";
import ScheduleClose from "./components/screens/ScheduleClose";
import EISorderCreate from "./components/screens/EISorderCreate";
import Utilization from "./components/screens/Utilization";
import TimeSheet from "./components/screens/TimeSheetList";
import EditTimeSheet from "./components/screens/EditTimeSheet";
import ServiceResources from "./components/screens/ServiceResources";
import AddTimeSheet from "./components/screens/AddTimeSheet";
import AddServiceActivity from "./components/screens/AddServiceActivity";
import EIInvoiceCreate from "./components/screens/EIInvoiceCreate";
import EIQuoteCreate from "./components/screens/EIQuoteCreate";

import { PrivateRoute } from './PrivateRoute';
import AddLineItem from "./components/screens/AddLineItem";
import AddLineItemPo from "./components/screens/AddLineItemPo";
import AddLineItemInv from "./components/screens/AddLineItemInv";
import AddLineItemQt from "./components/screens/AddLineItemQt";

import ClientList from "./components/screens/ClientList";
import VendorList from "./components/screens/VendorList";
import VendorDetail from "./components/screens/VendorDetail";
import AssetList from "./components/screens/AssetList";
import AssetDetail from "./components/screens/AssetDetail";
import AssetLocation from "./components/screens/AssetLocation";
import AssetEdit from "./components/screens/AssetEdit";
import FileUpload from "./components/screens/FileUpload";
import AddLineItemMaterial from "./components/screens/AddLineItemMaterial";
import RecallReport from "./components/screens/RecallReport";
import Picture from "./components/screens/Picture";
import ProviderList from "./components/screens/ProviderList";
import ProviderEdit from "./components/screens/ProviderEdit";
import VendorCatalogLineItem from "./components/screens/VendorCatalogLineItem";
import VendorLinkMaterial from "./components/screens/VendorLinkMaterial";
import EIPurchaseOrder from "./components/screens/EIPurchaseOrder";
import AppConfig  from "./components/screens/AppConfig";
import Signature  from "./components/screens/Signature";
import WorkOrderScheduler from "./components/screens/WorkOrderScheduler";
import WorkOrderRecurranceList from "./components/screens/WorkOrderRecurranceList";
import ChecklistFormList from "./components/screens/ChecklistFormList";
import ChecklistForm from "./components/screens/ChecklistForm";
import ChecklistFormEdit from "./components/screens/ChecklistFormEdit";
import ChecklistFormView from "./components/screens/ChecklistFormView";
import JobsList from "./components/screens/JobsList";
import JobAdd from "./components/screens/JobAdd";
import VendorItemList from "./components/screens/VendorItemList"; // This is for vendor catalog
import Assets from './components/screens/Assets';
import EmailSend from "./components/screens/EmailSend";

export default function Routes() {
  localStorage.getItem('user')  
  return (
    <Switch>
      <PrivateRoute path="/" exact component={Home} />
      <PrivateRoute path="/myactivity" exact component={MyActivity} />
      <PrivateRoute path="/resources" exact component={Resource} />
      <PrivateRoute path="/calendar" exact component={OrderCalendar} />
      <PrivateRoute path="/services" exact component={Home} />
      <PrivateRoute path="/skills" exact component={Skill} />
      <PrivateRoute path="/setting" exact component={Setting} />
      <PrivateRoute path="/analytics" exact component={Analytics} />
      <PrivateRoute path="/resourcedetail" exact component={ResourceDetail} />
      <PrivateRoute path="/resourcedetail/:id" exact component={ResourceDetail} />     
      <PrivateRoute path="/skilldetail" exact component={SkillDetail} />     
      <PrivateRoute path="/skilldetail/:skl" exact component={SkillDetail} /> 
      <PrivateRoute path="/servicedetail/" exact component={ServiceDetail} /> 
      <PrivateRoute path="/servicedetail/:id" exact component={ServiceDetail} />     
      <PrivateRoute path="/serviceedit/" exact component={ServiceCreate} /> 
      <PrivateRoute path="/serviceedit/:id" exact component={ServiceCreate} /> 
      <PrivateRoute path="/serviceedit/:id/asset/:assetid" exact component={ServiceCreate} />   
      <PrivateRoute path="/customerdetail/:id" exact component={CustomerDetail} /> 
      <PrivateRoute path="/customercreate" exact component={CustomerCreate} /> 
      <PrivateRoute path="/vendorcreate" exact component={CustomerCreate} />     
      <PrivateRoute path="/serviceclose/:id" exact component={ServiceClose} /> 
      {/* <PrivateRoute path="/eisordercreate/:id/customerid/:customerid/billingid/:billingid/siteid/:siteid"  component={EISorderCreate} />  */}
      <PrivateRoute path="/eisordercreate/:id/serviceid/:serviceid"  component={EISorderCreate} /> 
      <PrivateRoute path="/addlineitem/:id/serviceid/:serviceid"  exact component={AddLineItem} /> 
      <PrivateRoute path="/addlineitem/:id/serviceid/:serviceid/item/:item"  exact component={AddLineItem} /> 
      <PrivateRoute path="/addlineitempo/:id/serviceid/:serviceid"  exact component={AddLineItemPo} /> 
      <PrivateRoute path="/addlineitempo/:id/serviceid/:serviceid/item/:item"  exact component={AddLineItemPo} /> 
      <PrivateRoute path="/addinvitem/:id/serviceid/:serviceid/item/:item"  exact component={AddLineItemInv} />
      <PrivateRoute path="/addinvitem/:id/serviceid/:serviceid"  exact component={AddLineItemInv} /> 
      <PrivateRoute path="/addqtitem/:id/serviceid/:serviceid/item/:item"  exact component={AddLineItemQt} />
      <PrivateRoute path="/addqtitem/:id/serviceid/:serviceid"  exact component={AddLineItemQt} />
      <PrivateRoute path="/utilization"  exact component={Utilization} />
      <PrivateRoute path="/timesheet"  exact component={TimeSheet} /> 
      <PrivateRoute path="/edittimesheet/:id"  exact component={EditTimeSheet} /> 
      <PrivateRoute path="/resourcesforservice/:id"  exact component={ServiceResources} />
      <PrivateRoute path="/addtimesheet"  exact component={AddTimeSheet} />  
      <PrivateRoute path="/serviceactivity/:id"  exact component={AddServiceActivity} />
      <PrivateRoute path="/serviceactivity/:id/activity/:activity"  exact component={AddServiceActivity} />  
      <PrivateRoute path="/ecinvoice/:id/serviceid/:serviceid"  exact component={EIInvoiceCreate} />  
      <PrivateRoute path="/ecquote/:id/serviceid/:serviceid"  exact component={EIQuoteCreate} />  
      <PrivateRoute path="/scheduleclose/:id"  exact component={ScheduleClose} />
      <PrivateRoute path="/assets"  exact component={Assets} />
      <PrivateRoute path="/clntassets"  exact component={ClientList} />
      <PrivateRoute path="/clientlist"  exact component={ClientList} />
      <PrivateRoute path="/vendorlist"  exact component={VendorList} />
      <PrivateRoute path="/vendoritemlist"  exact component={VendorItemList} />
      <PrivateRoute path="/vendordetail/:id" exact component={VendorDetail} /> 
      <PrivateRoute path="/assetsforclnt/:id"  exact component={AssetList} />
      <PrivateRoute path="/assetdetail/:id/asset/:assetid"  exact component={AssetDetail} />
      <PrivateRoute path="/assetlocation/:id/desc/:desc" exact component={AssetLocation} />
      <PrivateRoute path="/assetlocation/:id/desc/:desc/locid/:locid" exact component={AssetLocation} />
      <PrivateRoute path="/assetedit/:id/asset/:asset" exact component={AssetEdit} />
      <PrivateRoute path="/assetedit/:id" exact component={AssetEdit} />
      <PrivateRoute path="/fileupload/:id/client/:client/image/:image" exact component={FileUpload} />
      <PrivateRoute path="/fileupload/:id/client/:client" exact component={FileUpload} />
      <PrivateRoute path="/fileupload/:id/client/:client/report/:report" exact component={FileUpload} />
      <PrivateRoute path="/materialitem/:id" exact component={AddLineItemMaterial} />
      <PrivateRoute path="/materialitem/:id/material/:material" exact component={AddLineItemMaterial} />
      <PrivateRoute path="/recallreport" exact component={RecallReport} />
      <PrivateRoute path="/picture/:id/client/:client" exact component={Picture} />
      <PrivateRoute path="/pictureord/:id/client/:client" exact component={Picture} />
      <PrivateRoute path="/providers"  exact component={ProviderList} />
      <PrivateRoute path="/provideredit"  exact component={ProviderEdit} />
      <PrivateRoute path="/provideredit/:id"  exact component={ProviderEdit} />
      <PrivateRoute path="/vendorcataloglineitem/:id"  exact component={VendorCatalogLineItem} />
      <PrivateRoute path="/vendorcataloglineitem/:id/item/:item"  exact component={VendorCatalogLineItem} />
      <PrivateRoute path="/vendormaterial/:id/item/:item"  exact component={VendorLinkMaterial} />
      <PrivateRoute path="/eipurchaseorder/:id/serviceid/:serviceid"  component={EIPurchaseOrder} />       
      <PrivateRoute path="/appconfig"  exact component={AppConfig} />
      <PrivateRoute path="/sign/:id"  exact component={Signature} />
      <PrivateRoute path="/recurrance"  exact component={WorkOrderScheduler} />
      <PrivateRoute path="/recurrance/:id"  exact component={WorkOrderScheduler} />
      <PrivateRoute path="/recurrancelist"  exact component={WorkOrderRecurranceList} />
      <PrivateRoute path="/checklistformlist"  exact component={ChecklistFormList} />
      <PrivateRoute path="/checklistform"  exact component={ChecklistForm} />
      <PrivateRoute path="/checklistformedit/:id"  exact component={ChecklistFormEdit} />
      <PrivateRoute path="/checklistform/:id"  exact component={ChecklistFormView} />
      <PrivateRoute path="/jobslist/:id"  exact component={JobsList} />
      <PrivateRoute path="/addjob/:parentid"  exact component={JobAdd} />
      <PrivateRoute path="/sendemail"  exact component={EmailSend} />
    </Switch>
  );
}
