import { authHeader } from './authHeader.js';
import {userService} from './userService.js'

var config = require('./config.js');

export const messageService = {
    sendEmail,
};

function sendEmail(email) {    
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(email)   
    };
    if (email.module === 'invoice') {
        return fetch(config.app.url + '/api/invoice/email/' + email.id, requestOptions).then(handleResponse);
    } else if (email.module === 'quote') {
        return fetch(config.app.url + '/api/estimate/email/' + email.id, requestOptions).then(handleResponse);
    }    
}


function handleResponse(response) {
    if (response.status === 401) {
        return 401;
    }
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                //location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        //console.log(data.data);
        return data.data;
    });
}

