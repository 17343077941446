import React from "react";
import { Card, Button, Form, Col, Table, Modal } from 'react-bootstrap';
import { Redirect } from "react-router-dom";
import { customerService } from '../../service/customerService';
import { recurranceService } from '../../service/recurranceService';
import { assetService } from '../../service/assetService';
import DatePicker from "react-datepicker";
import TimePicker from 'react-time-picker';
import { Multiselect } from "multiselect-react-dropdown";
import SelectSearch from 'react-select-search';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';

var moment = require('moment');
const { SearchBar } = Search;

class WorkOrderScheduler extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        id: undefined,
        starttime: '10:00',
        startdate: new Date(),
        enddate: (new Date()).setMonth((new Date()).getMonth() + 1),
        frequency: 'Daily',
        everyn: '1',
        onw: ['Monday'],
        onm: 'Day',
        ond: undefined,
        duration: 30,
        recurrance: undefined,

        everylabel: 'Enter number of days',
        dayofweek: ['Sunday', 'Monday', 'Tuesday', 'Weday', 'Thursday', 'Friday', 'Saturday'],
        whichday: ['Day', 'First', 'Second', 'Third', 'Fourth'],
        mndayofweek: ['Sunday', 'Monday', 'Tuesday', 'Weday', 'Thursday', 'Friday', 'Saturday'],
        gobackto: undefined,     
        
        dataobjs: [],
        addrobjs: [],
        customerid: undefined,
        customername: undefined,
        billingaddrid: undefined,
        contactaddr: undefined,
        siteaddrid: undefined,
        siteaddr: undefined,

        assettypes: undefined,
        assetstatus: undefined,
        skillOption: [],
        categoryOption: [],
        typeOption: [],
        priorityOption: {},        

        modal: false,
        search: '',

        detail: undefined,
        city: undefined,
        siteaddrid: undefined,
        billingaddrid: undefined,
        problemtype: undefined,
        type: undefined,
        priority: undefined,
        status: undefined,
        instruction: undefined,
        skills: undefined,

        selectassetid: undefined,
        selectassetdescription: undefined,
        selectassetmodel: undefined,
        selectassetserial: undefined,
        selectassetmanufacturer: undefined,
        selectassetlotnumber: undefined,
        selectassetnumber: undefined,
        orderequips: [],
        equipobjs: [],
        selectequipmodal: false,

        equipcolumns: [{
          dataField: 'id',
          text: 'id',
          hidden: true
        }, {
          dataField: 'description',
          text: 'Description',
          sort: true,
          searchable: true,
          headerStyle: {
            backgroundColor: '#D9D8D9'
          }
        }, {
          dataField: 'assetnumber',
          text: 'Asset Tag #',
          sort: true,
          searchable: true,
          headerStyle: {
            backgroundColor: '#D9D8D9'
          }
        }, {
          dataField: 'model',
          text: 'Model',
          sort: true,
          searchable: true,
          headerStyle: {
            backgroundColor: '#D9D8D9'
          }
        },
        {
          dataField: 'lotnumber',
          text: 'Lot #',
          sort: true,
          searchable: true,
          headerStyle: {
            backgroundColor: '#D9D8D9'
          }
        }, 
        {
          dataField: 'serial_no',
          text: 'Serial #',
          sort: true,
          searchable: true,
          headerStyle: {
            backgroundColor: '#D9D8D9'
          }
        }, {
          dataField: 'manufacturer',
          text: 'Manufacturer',
          sort: true,
          searchable: true,
          headerStyle: {
            backgroundColor: '#D9D8D9'
          }
        }
        
        
        ],
        selectEquipRow: {
          mode: 'radio',
          clickToSelect: true,
          hideSelectColumn: true,
          bgColor: '#adb5bd',
          onSelect: (row, isSelect, rowIndex, e) => {            
            this.setEquipment(row)
          }
        },
    }
  }

  is(type, obj) {
    var clas = Object.prototype.toString.call(obj).slice(8, -1);
    return obj !== undefined && obj !== null && clas === type;
  }

  componentDidMount() {  

    let config = JSON.parse(localStorage.getItem('config'));
    this.setState({assettypes: JSON.parse(config.assettypes),
                   assetstatus: JSON.parse(config.assetstatus)
    });
    
    let peopleskills = JSON.parse(config.peopleskills);
    let options = [];
    for (let i=0; i<peopleskills.length; i++) {
      let s = {};
      s.name=   peopleskills[i].skill;  
      s.id = peopleskills[i].skill;    
      options.push(s);     
    }

    this.setState({skillOption: options, 
                    categoryOption: JSON.parse(config.serviceorderproblemtypes),
                    typeOption: JSON.parse(config.serviceordertypes),
                    priorityOption: JSON.parse(config.serviceorderpriotities),
    });    

    // recurring id
    const {id} = this.props.match.params;
    if (id) {
      this.state.id = id;
      recurranceService.getRecurrance(id).then(r => {
        if (r && r === 401) {
          this.props.history.push("/login");
        } else {               
          this.setState({ 
            //starttime: r.starttime,
            startdate: new Date(r.startdate),
            enddate: new Date(r.enddate),
            //frequency: r.frequency,
            //every: Number(r.every),
            duration: Number(r.duration),
            recurrance: r.recurrance,
    
          });
          console.log('r', r);
          if (r.startdate) {
            let tmp = moment(r.startdate);
            this.setState({starttime: tmp.format('hh:mm')})
          }
          let order = JSON.parse(r.workorder);
          console.log('order loaded', order);
          this.setState({
            customerid: order.customerid,
            customername: order.customername,
            billingaddrid: order.billingaddrid,            
            siteaddrid: order.siteaddrid,           
            detail: order.detail,
            city: order.city,
            siteaddrid: order.siteaddrid,
            billingaddrid: order.billingaddrid,
            problemtype: order.problemtype,
            type: order.type,
            priority: order.priority,
            status: order.status,
            instruction: order.instruction,
            skills: this.is('String', order.skills) ? JSON.parse(order.skills): order.skills,
          });
          //console.log('load component r.recurrencerule', r.recurrencerule);
          let recRul =  JSON.parse(JSON.stringify(r.recurrencerule));  
          let kk = JSON.parse(recRul);
          console.log('load component recRul', recRul, kk.frequency);
          
          this.setState({
            frequency: kk.frequency,
          });
          this.state.frequency = kk.frequency;
          this.state.everyn = kk.every;

          console.log('this.state.frequency', this.state.frequency, 'kk.every', kk.every)
          this.setState({            
            everyn: kk.every            
          });

          if (kk.frequency === 'Daily') {
            console.log('Setting enddate.............', Number(kk.days));
            let d = new Date(r.startdate);            
            this.setState({ond: kk.day, everyn: kk.days, enddate: d.setDate(d.getDate() + Number(kk.days))});            
          } else if (kk.frequency === 'Monthly' && kk.on) {
            if (kk.on.day) {
              this.setState({onm: 'Day', everylabel: 'Every number of month(s) to repeat'});
            } else {              
              let keys = Object.keys(kk.on);
              console.log('keys', keys);
              let dnum = kk.on[keys[0]];
              console.log('keys[0]', keys[0], 'dnum', dnum);
              dnum = dnum - 1;
              this.setState({onm: keys[0], ond: this.state.mndayofweek[dnum], everylabel: 'Every number of month(s) to repeat'});
            }            
          } else if (kk.frequency === 'Weekly' && kk.on) {
            let tk = [];
            for (let t=0; t<kk.on.length; t++) {
              tk.push(this.state.dayofweek[kk.on[t]]);
            }
            this.setState({onw: tk, everylabel: 'Every number of week(s) to repeat'});
          }

          if (order.assets && order.assets.length > 0) {
            let t = [];
            for (let k=0; k<order.assets.length; k++) {
              assetService.getAsset(order.assets[k].id).then(asset => {
                t.push(asset);    
                this.setState({orderequips: t});          
              })
            }            
          }
        }        
      }); 
      customerService.getCustomerList().then(custs => {
        if (custs && custs === 401) {
          this.props.history.push("/login");
        } else {
          //console.log('get customer list')
          //console.log(custs)
          let tt = [];
          for (let i=0; i<custs.length; i++) {
            let oo = {};
            oo.value = custs[i].id;
            oo.name = custs[i].name;
            tt.push(oo);
            if (this.state.id && this.state.customerid == custs[i].id) {
              //console.log('calling setCustomer....1....');
              this.setCustomer({id: custs[i].id, customername: custs[i].name});
            }
          }
          this.setState({ dataobjs: tt }) 
          // Below three lines are added to render everyn. Without these three lines everyn is not rendered.
          let r = this.state.frequency;          
          this.setState({frequency: 'Yearly'});  
          this.setState({frequency: r});  
        }
      });
    } else {
      customerService.getCustomerList().then(custs => {
        if (custs && custs === 401) {
          this.props.history.push("/login");
        } else {
          //console.log('get customer list')
          //console.log(custs)
          let tt = [];
          for (let i=0; i<custs.length; i++) {
            let oo = {};
            oo.value = custs[i].id;
            oo.name = custs[i].name;
            tt.push(oo);
            if (this.state.id && this.state.customerid == custs[i].id) {
              //console.log('calling setCustomer....2....');
              this.setCustomer({id: custs[i].id, customername: custs[i].name});
            }
          }
          this.setState({ dataobjs: tt })   
        }
      });
    }
   
  }

  selectContactAddress  = (e) => {
    //alert(e.value + ' : ' + e.name);    
    this.setState({contactaddr: e.name, billingaddrid: e.value});
    let city = e.name.substring(e.name.indexOf(',') + 1);
    //console.log(city);
    city = city.substring(0, city.indexOf(','));
    //console.log(city);
    this.state.city = city;    
  }

  selectSiteAddress  = (e) => {
    //alert(e.value + ' : ' + e.name);
    this.setState({siteaddr: e.name, siteaddrid: e.value});
  }

  selectCustomer = (e) => {
      //console.log(e);
      //alert(e.value + ' : ' + e.name);
      let c = {};
      c.id = e.value;
      c.name = e.name;

      this.state.orderequips = [];

      this.setCustomer(c);
  }

  selectServiceProvider = (e) => {
    this.state.providerid = e.value;
}

  setCustomer = (c) =>
  {
    console.log('setCustomer: ' + c);
    this.setState({customername: c.name, customerid: c.id});
    customerService.getAddressList(c.id).then(addrs => {
      if (addrs && addrs === 401) {
        this.props.history.push("/login");
      } else {
        //console.log(addrs);
        let tmp = [];
        let isContactSet = false;
        for (let i=0; i<addrs.length; i++) {
          let o = {};
          let ad = {};
          o.contact = 'No';
          o.site = 'No';
          o.id = addrs[i].id;
          o.addr1 = addrs[i].addr1 + ", " + (addrs[i].city ? addrs[i].city + ', ': '') + 
                    (addrs[i].state ? addrs[i].state + ' ': '') +
                    (addrs[i].zip ? addrs[i].zip: '');
          
          if (addrs[i].is_a_contact === true && !isContactSet) {
            o.contact = 'Yes';
            o.site = 'Yes';
            isContactSet = true;
          }
          ad.name = addrs[i].addr1 + ", " + (addrs[i].city ? addrs[i].city + ', ': '') + 
                (addrs[i].state ? addrs[i].state + ' ': '') +
                (addrs[i].zip ? addrs[i].zip: '');          
          ad.value =  addrs[i].id;     
          
          tmp.push(ad);
          // if user is creating service order for an asset then he already knows the client and addresses
          if (this.state.assettoserviceordercreate) {
              if (addrs[i].is_a_contact === true) {
                this.selectContactAddress(ad);
                this.selectSiteAddress(ad);
              }
          }
        }
        console.log('state', this.state);
        this.setState({ addrobjs: tmp });
        //console.log(this.state.addrobjs);
        this.toggle();
      }
    });    
  }
  
  toggle() {
    this.setState({
      modal: !this.state.modal
    });
    //console.log(this.state.addrobjs);
    if (this.state.addrobjs && this.state.addrobjs.length > 0){
      for (let i=0; i<this.state.addrobjs.length; i++) {
        if (this.state.addrobjs[i].contact === 'Yes') {
          this.setState({contactaddr: ' | Contact: ' + this.state.addrobjs[i].addr1, billingaddrid: this.state.addrobjs[i].id});
        }
        if (this.state.addrobjs[i].site === 'Yes') {
          this.setState({siteaddr: ' | Site: ' +this.state.addrobjs[i].addr1, siteaddrid: this.state.addrobjs[i].id});
        }
      }
    }    
  }

  
  handleInputChange = (event) => {    
    const target = event.target;
    const name = target.id;    
    this.setState({
      [name]: target.value
    });  
    if (name === "frequency") {
      if (target.value === "Daily") {
        this.setState({
          everylabel: 'Enter number of days'
        })
      } else if (target.value === "Weekly") {
        this.setState({
          everylabel: 'Every number of week(s) to repeat'
        })
      } else if (target.value === "Monthly") {
        this.setState({
          everylabel: 'Every number of month(s) to repeat'
        })
      } else {
        let d = new Date();
        var year = d.getFullYear();
        var month = d.getMonth();
        var day = d.getDate();
        var c = new Date(year + 1, month, day);
        this.setState({enddate: c});
      }      
    } 
  }

  getDay(day) {
    for (let i=0; i<this.state.dayofweek.length; i++) {
      if (this.state.dayofweek[i] === day) {
        return i;
      }
    }
  }

  setMultiSkills = (option) => {
    //console.log(option);      
    let t = undefined;    
    this.state.skills = option;
    //console.log(this.state.skills);
  }

  getPriorityValue(name) {
    if (name) {
      let config = JSON.parse(localStorage.getItem('config'));
      let priority = JSON.parse(config.serviceorderpriotities);
      let keys = Object.keys(priority);
      for (let i=0; i<keys.length; i++) {
        if (priority[keys[i]] === name) {
          return keys[i];
        }
        }
    }
  }

  handleSelectEquipmentWithBackendCall = (event) => {
    if (!this.state.customerid) {
      alert('Select a Customer before selecting equipment or asset');
    } else {
      assetService.getAssetList(this.state.customerid).then(assets => {
        this.setState({
          selectequipmodal: !this.state.selectequipmodal,
          equipobjs: assets
        });
      })
    }
  }

  handleSelectEquipmentClose = (event) => {
    this.setState({
      selectequipmodal: !this.state.selectequipmodal
    });
  }

  setEquipment = (c) => {
    this.setState({
      selectassetid: c.id,
      selectassetdescription: c.description,
      selectassetmodel: c.model,
      selectassetserial: c.serial_no,
      selectassetmanufacturer: c.manufacturer,
      selectassetlotnumber: c.lotnumber,
      selectassetnumber: c.assetnumber,
    });
  }

  handleSelectEquipment = (event) => {
    //event.preventDefault();
    if (!this.state.customerid) {
        alert('Select a Customer before selecting equipment for this service request');
    } else {
      // if this asset is already linked to this order then do nothing
      if (this.state.orderequips && this.state.orderequips.length > 0) {
        for (let i=0; i<this.state.orderequips.length; i++) {
          if (Number(this.state.orderequips[i].id) === Number(this.state.selectassetid)) {
            this.setState({
              selectequipmodal: !this.state.selectequipmodal,              
            });
            return;
          }
        }
      }
      //alert('handleSelectEquipment...');
      let asst = {};
      asst.id = this.state.selectassetid;
      asst.description = this.state.selectassetdescription;
      asst.model = this.state.selectassetmodel;
      asst.serial_no = this.state.selectassetserial;
      asst.manufacturer = this.state.selectassetmanufacturer;
      asst.lotnumber = this.state.selectassetlotnumber;
      asst.assetnumber = this.state.selectassetnumber;

      this.state.orderequips.push(asst);
      this.setState({
        selectequipmodal: !this.state.selectequipmodal,              
      });
    }
  }

  removeEquipmentFromOrder(id){
    if (!this.state.orderequips || this.state.orderequips.length === 0) {
      // do nothing
    } else {
      for (let i=0; i<this.state.orderequips.length; i++) {        
        if (this.state.orderequips[i].id === id) {
          this.state.orderequips.splice(i, 1);
          let cn = this.state.customername;
          this.setState({customername: cn});
          return;
        }
      }
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    
    console.log('handleSubmit this.state.frequency', this.state.frequency);
    let rec = {};
    if (this.state.id) {
      rec.id = this.state.id;
    }
    
    rec.startDate = this.state.startdate;
    if (this.state.starttime) {
      let tm = this.state.starttime.split(':');
      rec.startDate.setHours(Number(tm[0]));
      let min = Number(tm[1]);
      if (min < 7) {
        min = 0;
      } else if (min < 22) {
        min = 15;
      } else if (min < 37) {
        min = 30;
      } else {
        min = 45;
      } 
      rec.startDate.setMinutes(min);
    }

    rec.endDate = new Date(this.state.enddate);    

    if (!this.state.enddate) {
      alert('You must enter the end date');
      return;
    }
    let sd = moment(this.state.startdate);
    let ed = moment(this.state.enddate);
    
    

    if (this.state.frequency === 'Daily') {
      rec.endDate = moment(this.state.startdate).add(Number(this.state.everyn), 'days');
      ed = rec.endDate;
    }

    if (ed.isBefore(sd)) {
      alert('End date cannot be before start date');
      return;
    }

    if (this.state.frequency === 'Monthly') {
      if ((ed.month() - sd.month()) < 1) {
        alert('End date cannot be before or same month of the Start date for Monthly repeats');
        return;
      }
    }

    if (this.state.frequency === 'Yearly') {
      console.log('enddate', ed);
      if ((ed.year() - sd.year()) < 1) {
        alert('End date cannot be before or same year of the Start date for Yearly repeats');
        return;
      }
    }

    if (!this.state.customerid) {      
        alert('You must select a customer');
        return;
    }

    if (!this.state.customerid) {      
      alert('You must select a customer');
      return;
    }

    if (this.state.duration) {
      let d = Number(this.state.duration);
      if (d >= 30) {
        let k = d / 30;
        rec.duration = 30 * k.toFixed(0);
      } else {
        rec.duration = 30;
      }
    } else {
      rec.duration = 30;
    }
    
    rec.recurrenceRule = {};
    rec.recurrenceRule.frequency = this.state.frequency;
    rec.recurrenceRule.every = Number(this.state.everyn);
    

    if (this.state.frequency === 'Daily') {
      // every should be the day
      delete rec.recurrenceRule.every;
      rec.recurrenceRule.days = this.state.everyn;
      delete rec.recurrenceRule.on;
    } else if (this.state.frequency === 'Weekly') {
      console.log('weekly days', this.state.onw);
      if (this.state.onw && this.state.onw.length > 0) {
        let onw = [];
        for (let k=0; k<this.state.onw.length; k++) {
          onw.push(this.getDay(this.state.onw[k]));
        }
        onw.sort();
        rec.recurrenceRule.on = onw;
      }
    } else if (this.state.frequency === 'Monthly') {
      rec.recurrenceRule.on = {};
      if (this.state.onm && this.state.onm.startsWith('"""')) {
        this.state.onm = this.state.onm.slice(3);
        this.state.onm = this.state.onm.slice(0, this.state.onm.length-3);
      } 
      if (!this.state.onm || this.state.onm === 'Day') {
        rec.recurrenceRule.on.day = rec.startDate.getDate();
      } else {     
        if (!this.state.ond) {
          this.state.ond = 'Sunday';
        }
        
        console.log('this.state.ond', this.state.ond);
        //let ko = this.state.onm.slice(3);
        //ko = ko.slice(0, ko.length-3);
        console.log('this.state.onm', this.state.onm);
        rec.recurrenceRule.on[this.state.onm] = this.getDay(this.state.ond) + 1;
      }
    }

    rec.workorder = {};
    rec.recurrance = this.state.recurrance;
    rec.workorder.customerid = this.state.customerid;
    rec.workorder.customername = this.state.customername;
    rec.workorder.city = this.state.city;
    rec.workorder.detail = this.state.detail;
    rec.workorder.siteaddrid = this.state.siteaddrid;
    rec.workorder.billingaddrid = this.state.billingaddrid;
    rec.workorder.problemtype = this.state.problemtype;
    rec.workorder.type = this.state.type;
    rec.workorder.priority = this.getPriorityValue(this.state.priority);
    rec.workorder.status = 1;
    
    //rec.workorder.requesteddate = this.state.requesteddate;
    //rec.workorder.startdate = this.getUtcDate(this.state.startdate);
    //rec.workorder.enddate = this.state.enddate;

    rec.workorder.assets = undefined;
    if (this.state.orderequips && this.state.orderequips.length > 0) {
      rec.workorder.assets = [];
      for (let j=0; j<this.state.orderequips.length; j++) {
        let at = {};
        at.id = this.state.orderequips[j].id;
        rec.workorder.assets.push(at);
      }
    }
    rec.workorder.skills = (this.state.skills ? JSON.stringify(this.state.skills) : undefined);
    rec.workorder.instruction = this.state.instruction;
    //rec.workorder.attachdocs = this.state.attachdocs;
    //rec.workorder.providerid = this.state.providerid;

    console.log(rec);
    console.log(this.state.starttime);
    if (this.state.id) {
      recurranceService.updateRecurrance(rec, this.state.id).then(ast => {
        if (ast && ast === 401) {
          this.props.history.push("/login");
        } else {  
          //this.setState({gobackto: '/assetdetail/' + this.state.clientid + '/asset/' + this.state.id});   
          this.props.history.goBack();
        }
      });
    } else {
      recurranceService.createRecurrance(rec).then(ast => {
        if (ast && ast === 401) {
          this.props.history.push("/login");
        } else {  
          this.props.history.goBack();
        }
      });
    }
  }

  goBack = () => {
    this.props.history.goBack();
  }
  
  setStartDate = (date) => {
    this.setState({startdate: date});
  }

  setEndDate = (date) => {
    this.setState({enddate: date});
  }

  setStartTime = (time) => {
    this.setState({starttime: time});
  }

  changeLabel = (freq) => {
    if (freq === 'Daily') {
      this.setState({everylabel: 'Enter days'});
    } else if (freq === 'Weekly') {
      this.setState({everylabel: 'Enter weeks'});
    } else if (freq === 'Monthly') {
      this.setState({everylabel: 'Enter months'});
    } else if (freq === 'Yearly') {
      this.setState({everylabel: 'Enter years'});
    }
  }

  setMultiDays = (options) => {
    this.setState({onw: options});
  }

  delete = () => {
    if (!this.state.id) {
      alert ('Nothing to delete!');
    } else {
      let obj = {};
      obj.deleted = true;
      recurranceService.updateRecurrance(obj, this.state.id).then(ast => {
        if (ast && ast === 401) {
          this.props.history.push("/login");
        } else {  
          this.props.history.goBack();
        }
      });
    }
  }

  render() {
    if (this.state.gobackto) {
      return <Redirect to={this.state.gobackto} />
    }  

    let repeatOpt = [];
    if (!this.state.frequency || this.state.frequency === 'Daily') {
      repeatOpt.push(<option selected>Daily</option>);
    } else {
      repeatOpt.push(<option>Daily</option>);
    }

    if (this.state.frequency === 'Weekly') {
      repeatOpt.push(<option selected>Weekly</option>);
    } else {
      repeatOpt.push(<option>Weekly</option>);
    }

    if (this.state.frequency === 'Monthly') {
      repeatOpt.push(<option selected>Monthly</option>);
    } else {
      repeatOpt.push(<option>Monthly</option>);
    }

    if (this.state.frequency === 'Yearly') {
      repeatOpt.push(<option selected>Yearly</option>);
    } else {
      repeatOpt.push(<option>Yearly</option>);
    }

    let onOpt = [];
    // if (!this.state.frequency || this.state.frequency === 'Day') {
    //   repeatOpt.push(<option selected>Day</option>);
    // } else {
    //   repeatOpt.push(<option>Day</option>);
    // }

    // if (this.state.frequency === 'First') {
    //   repeatOpt.push(<option selected>First</option>);
    // } else {
    //   repeatOpt.push(<option>First</option>);
    // }

    // if (this.state.frequency === 'Second') {
    //   repeatOpt.push(<option selected>Second</option>);
    // } else {
    //   repeatOpt.push(<option>Second</option>);
    // }

    // if (this.state.frequency === 'Third') {
    //   repeatOpt.push(<option selected>Third</option>);
    // } else {
    //   repeatOpt.push(<option>Third</option>);
    // }

    // if (this.state.frequency === 'Fourth') {
    //   repeatOpt.push(<option selected>Fourth</option>);
    // } else {
    //   repeatOpt.push(<option>Fourth</option>);
    // }

    // let wkOpt = [];
    // if (!this.state.frequency || this.state.frequency === 'Weekly') {
    //     for (let i=0; i<7; i++) {
    //       let exist = false;
    //       if (this.state.on && this.state.on.length > 0) {
    //         for (let j=0; j<this.state.on.length; j++) {
    //           if (this.state.on[j] === i) {
    //             exist = true;
    //           }
    //         }
    //       }
    //       if (exist) {
    //         wkOpt.push(<option selected>{this.state.dayofweek[i]}</option>);
    //       } else {
    //         wkOpt.push(<option>{this.state.dayofweek[i]}</option>);
    //       }
    //     }         
    // }

    let mnOpt = [];
    let whichOpt = [];
    if (!this.state.frequency || this.state.frequency === 'Monthly') {
        let which = undefined;
        let day = undefined;
        if (this.state.on) {
          if (this.state.on.First) {
            which = 'First';
            day = this.state.on.First;
          } else if (this.state.on.Second) {
            which = 'Second';
            day = this.state.on.Second;
          } else if (this.state.on.Third) {
            which = 'Third';
            day = this.state.on.Third;
          } else if (this.state.on.Fourth) {
            which = 'Fourth';
            day = this.state.on.Fourth;
          } else {
            which = 'Day';
            day = this.state.on.Day;
          }
        }

        let sel = false;
        for (let i=0; i<5; i++) {          
          if (which && which === this.state.whichday[i]) {
            whichOpt.push(<option selected>{this.state.whichday[i]}</option>);
            sel = true;
          } else {
            whichOpt.push(<option>{this.state.whichday[i]}</option>);
          }
          if (!sel) {
            whichOpt[0] = <option selected>{this.state.whichday[0]}</option>;
          }
        }

        sel = false;
        // for (let i=0; i<7; i++) {
        //   if (day && day === this.state.mndayofweek[i]) {
        //     mnOpt.push(<option selected>{this.state.mndayofweek[i]}</option>);
        //     sel = true;
        //   } else {
        //     mnOpt.push(<option>{this.state.mndayofweek[i]}</option>);
        //   }

        //   if (!sel) {
        //     mnOpt[0] = <option selected>{this.state.mndayofweek[0]}</option>;
        //   }
        // }

        for (let i=0; i<7; i++) {
          //console.log('render....', this.state.ond);
          if (this.state.ond &&  this.state.ond === this.state.mndayofweek[i]) {
            mnOpt.push(<option selected>{this.state.mndayofweek[i]}</option>);
            sel = true;
          } else {
            mnOpt.push(<option>{this.state.mndayofweek[i]}</option>);
          }

          if (!sel) {
            mnOpt[0] = <option selected>{this.state.mndayofweek[0]}</option>;
          }
        }
        
    }

    let categoryOpt = [];
    categoryOpt.push(<option>Chosse...</option>);
    if (this.state.categoryOption && this.state.categoryOption.length > 0) {
      for (let i=0; i<this.state.categoryOption.length; i++) {
        //console.log(this.state.categoryOption[i]);
        if (this.state.problemtype && this.state.problemtype === this.state.categoryOption[i]) {
          categoryOpt.push(<option selected>{this.state.categoryOption[i]}</option>);
        } else  {
          categoryOpt.push(<option>{this.state.categoryOption[i]}</option>);
        }
      }
    }

    let typeOpt = [];
    typeOpt.push(<option>Chosse...</option>);
    if (this.state.typeOption && this.state.typeOption.length > 0) {
      for (let i=0; i<this.state.typeOption.length; i++) {
        if (this.state.type && this.state.type === this.state.typeOption[i]) {
          typeOpt.push(<option selected>{this.state.typeOption[i]}</option>);
        } else {
          typeOpt.push(<option>{this.state.typeOption[i]}</option>);
        }
      }
    }

    let typePrt = [];
    typePrt.push(<option>Chosse...</option>);
    if (this.state.priorityOption) {
      var keys = Object.keys(this.state.priorityOption);
      //console.log(keys)
      //console.log(this.state.priorityOption[keys[0]]);
      for (let i=0; i<keys.length; i++) {
        if (this.state.priority && this.state.priority === this.getPriorityValue(this.state.priorityOption[keys[i]])) {
          typePrt.push(<option key={keys[i]} value={this.state.priorityOption[keys[i]]} selected>{this.state.priorityOption[keys[i]]}</option>);
        } else  {
          typePrt.push(<option key={keys[i]} value={this.state.priorityOption[keys[i]]}>{this.state.priorityOption[keys[i]]}</option>);
        }
      }
    }
    
    return (
      <div className="content-screen" style={{height: '100%', overflow: 'scroll'}}>
      
        <Card style={{ width: '40rem' }}>
          <Card.Header style={{fontWeight: 'bold'}}>Work Order Recurrance
          <div style={{float: 'right'}}>
           <Button variant="link" size="sm" style={{color: 'red', textDecorationLine: 'underline', textDecorationColor: 'red'}} onClick={() => {if(window.confirm('This will only delete all your associated work orders which are not assigned to resources yet. Are you sure you want to delete?')){this.delete()};}}>delete</Button>
           </div>
          </Card.Header>
          
          <Card.Body>            
            <div>
                <Form onSubmit={this.handleSubmit}>

                <Form.Row>
                    <Form.Group as={Col} controlId="recurrance" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Subject</Form.Label>
                      <Form.Control required="true" placeholder="Enter the subject" defaultValue={this.state.recurrance} />
                    </Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Form.Group as={Col} controlId="frequency" onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Repeats</Form.Label>
                      <Form.Control as="select" defaultValue={this.state.frequency} onChange={frequency => {this.changeLabel(frequency)}}>
                            {repeatOpt}
                      </Form.Control>
                    </Form.Group>
                  </Form.Row> 
                  
                  {this.state.frequency !== 'Yearly' && 
                  <Form.Row>
                    <Form.Group as={Col} controlId="everyn" onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>{this.state.everylabel}</Form.Label>
                      <Form.Control type="text" defaultValue={this.state.everyn} />
                    </Form.Group>
                  </Form.Row>                    
                  }

                  { this.state.frequency === 'Weekly' &&
                  <Form.Row>  
                    <Form.Group controlId="onw">
                      <Form.Label style={{fontSize: "14px"}}>On</Form.Label>
                      <Multiselect 
                        isObject={false}
                        options={this.state.dayofweek}                       
                        selectedValues={this.state.onw} 
                        onSelect={this.setMultiDays}
                        onRemove={this.setMultiDays}
                      />                      
                    </Form.Group> 
                  </Form.Row>
                  }
                  { this.state.frequency === 'Monthly' &&
                    <Form.Row>
                    <Form.Group as={Col} controlId="onm" onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>On</Form.Label>
                      <Form.Control as="select" defaultValue={this.state.onm}>
                            {whichOpt}
                      </Form.Control>
                    </Form.Group>
                  </Form.Row> 
                  }

                  { (this.state.frequency === 'Monthly' && this.state.onm != 'Day') &&                    
                  <Form.Row>
                  <Form.Group as={Col} controlId="ond" onChange={this.handleInputChange}>
                    <Form.Label style={{fontSize: "14px"}}>Day</Form.Label>
                    <Form.Control as="select" defaultValue={this.state.ond}>
                          {mnOpt}
                    </Form.Control>
                  </Form.Group>
                  </Form.Row> 
                  }
                  <Form.Row>
                     <Form.Group as={Col} controlId="startdate">
                      <Form.Label  style={{fontSize: "14px"}}>Start Date</Form.Label>  
                      <div>
                      <DatePicker style={{displayValue: 'block'}}
                          selected={this.state.startdate}
                          onChange={date => this.setStartDate(date)}
                          dateFormat="MMM d, yyyy"
                          placeholderText="Enter a date"                          
                      /> </div>                   
                    </Form.Group>
                    <Form.Group as={Col} controlId="enddate">
                      <Form.Label  style={{fontSize: "14px"}}>End Date</Form.Label>  
                      <div>
                      <DatePicker style={{displayValue: 'block'}}
                          selected={this.state.enddate}
                          onChange={date => this.setEndDate(date)}
                          dateFormat="MMM d, yyyy"
                          placeholderText="Enter a date"                          
                      /> </div>                   
                    </Form.Group>
                  </Form.Row>                   

                  <Form.Row>
                     <Form.Group as={Col} controlId="starttime">
                      <Form.Label  style={{fontSize: "14px"}}>Start Time</Form.Label>  
                      <div>
                      <TimePicker
                        amPmAriaLabel="Select AM/PM"
                        clearAriaLabel="Clear value"
                        clockAriaLabel="Toggle clock"
                        hourAriaLabel="Hour"
                        maxDetail="minute"
                        minuteAriaLabel="Minute"
                        nativeInputAriaLabel="Time"
                        onChange={time => this.setStartTime(time)}
                        //secondAriaLabel="Second"
                        value={this.state.starttime}
                      /> </div>                   
                    </Form.Group>
                    <Form.Group as={Col} controlId="duration" onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Duration in minutes</Form.Label>
                      <Form.Control type="text" pattern="[0-9]*" placeholder="Enter duration in minutes" defaultValue={this.state.duration} />
                    </Form.Group>
                  </Form.Row> 
                  
                  <Card.Text style={{fontWeight: 'bold', color: 'blue', marginTop: '20px'}}>ORDER DETAIL</Card.Text>
                  <Form.Row>
          
                    <SelectSearch options={this.state.dataobjs} 
                                  value={this.state.customerid} name={this.state.customername} placeholder="Select customer" 
                                  height={172} 
                                  onChange={this.selectCustomer}/>
                  </Form.Row>
                  <Form.Row>
                    <SelectSearch options={this.state.addrobjs} 
                                  value={this.state.billingaddrid} name={this.state.contactaddr} placeholder="Select billing contact address" 
                                  height={172} 
                                  onChange={this.selectContactAddress}/>
                  </Form.Row>
                  <Form.Row>
                    <SelectSearch options={this.state.addrobjs} 
                                  value={this.state.siteaddrid} name={this.state.siteaddr} placeholder="Select site address" 
                                  height={172} 
                                  onChange={this.selectSiteAddress}/>   
                              
                  </Form.Row>
                                    
                  
                  <Form.Row>
                    <Form.Group as={Col} controlId="detail" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Detail</Form.Label>
                      <Form.Control required="true" placeholder="Enter the detail" defaultValue={this.state.detail} />
                    </Form.Group>
                  </Form.Row> 

                  <Form.Row>
                    <Form.Group as={Col} controlId="problemtype" onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Category</Form.Label>
                      <Form.Control as="select" defaultValue={this.state.problemtype}>
                            {categoryOpt}
                            
                      </Form.Control>            
                    </Form.Group>
                    <Form.Group as={Col} controlId="type" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Type</Form.Label>
                      <Form.Control as="select" defaultValue={this.state.type}>
                            {typeOpt}
                      </Form.Control> 
                    </Form.Group>
                    <Form.Group as={Col} controlId="priority" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Priority</Form.Label>
                      <Form.Control as="select" defaultValue={this.state.priority}>
                          {typePrt}                 
                      </Form.Control> 
                    </Form.Group>          
                  </Form.Row>                  

                  <Form.Row>  
                    <Form.Group as={Col} controlId="instruction" onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Note for technician</Form.Label>
                      <Form.Control placeholder="Enter instructions" defaultValue={this.state.instruction}/>
                    </Form.Group>                    
                  </Form.Row>

                  <Form.Row>  
                    <Form.Group controlId="skills">
                      <Form.Label style={{fontSize: "14px"}}>Skills</Form.Label>
                      <Multiselect 
                        options={this.state.skillOption} 
                        displayValue="name" 
                        selectedValues={this.state.skills} 
                        onSelect={this.setMultiSkills}
                        onRemove={this.setMultiSkills}
                      />                      
                    </Form.Group>
                   
                    
                  </Form.Row>
                  <Form.Row>
                    <Button variant="outline-primary mb-3 mr-4"   size="sm" onClick={this.handleSelectEquipmentWithBackendCall}>link equipment</Button>

                    { (this.state.orderequips && this.state.orderequips.length > 0) &&
                        
                        <Table  bordered  size="sm">
                          <thead>
                            <tr style={{height: '42px'}}>
                              <th style={{verticalAlign: 'middle', backgroundColor: '#edeff1', fontSize: '0.75rem'}}>Description</th>
                              <th style={{verticalAlign: 'middle', backgroundColor: '#edeff1', fontSize: '0.75rem'}}>Tag #</th>
                              <th style={{verticalAlign: 'middle', backgroundColor: '#edeff1', fontSize: '0.75rem'}}>Model #</th>
                              <th style={{verticalAlign: 'middle', backgroundColor: '#edeff1', fontSize: '0.75rem'}}>Lot #</th>
                              <th style={{verticalAlign: 'middle', backgroundColor: '#edeff1', fontSize: '0.75rem'}}>Serial #</th>
                              <th style={{verticalAlign: 'middle', backgroundColor: '#edeff1', fontSize: '0.75rem'}}>Manufacturer</th>                
                              <th style={{verticalAlign: 'middle', backgroundColor: '#edeff1', fontSize: '0.75rem'}}>Warranty expiration</th>                
                              <th style={{verticalAlign: 'middle', backgroundColor: '#edeff1', fontSize: '0.75rem'}}></th>
                            </tr>
                          </thead>
                          <tbody>
                          {  
                          this.state.orderequips.map((e, idx) => (
                            <tr key={e.id}>
                              <td style={{verticalAlign: 'middle', fontSize: '0.75rem'}}>{e.description}</td>
                              <td style={{verticalAlign: 'middle', fontSize: '0.75rem'}}>{e.assetnumber}</td>
                              <td style={{verticalAlign: 'middle', fontSize: '0.75rem'}}>{e.model}</td>
                              <td style={{verticalAlign: 'middle', fontSize: '0.75rem'}}>{e.lotnumber}</td>
                              <td style={{verticalAlign: 'middle', fontSize: '0.75rem'}}>{e.serial_no}</td>
                              <td style={{verticalAlign: 'middle', fontSize: '0.75rem'}}>{e.manufacturer}</td> 
                              <td style={{verticalAlign: 'middle', fontSize: '0.75rem'}}>{e.warrantyexpiration}</td>                
                              <td style={{verticalAlign: 'middle', fontSize: '0.75rem'}}>    
                                <>           
                                <Button variant="link"  style={{marginLeft: '-12px'}} onClick={() => {if(window.confirm('Remove the item?')){this.removeEquipmentFromOrder(e.id)};}}>de-link</Button>                  
                                </>
                              </td>
                            </tr>
                          ))}
                          </tbody>
                        </Table>   
                        }

                  </Form.Row>
                  <div style={{float: "left"}} >                                       
                    <Button variant="outline-primary mt-3 mr-2" size="sm" type="submit">Save</Button>  
                    <Button variant="outline-primary mt-3" size="sm" onClick={() => {this.goBack()}}>Cancel</Button>                            
                  </div>
              </Form>
            </div>
          </Card.Body>
          <Card.Footer className="text-muted">&nbsp;</Card.Footer>       
        </Card>    

        <div>
              <>
              <Modal show={this.state.selectequipmodal} onHide={this.handleSelectEquipmentClose.bind(this)}
                  
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Header closeButton>              
                    <Modal.Title>Select existing equipement of this client</Modal.Title>              
                  </Modal.Header>
                  <Modal.Body>
                        <ToolkitProvider
                        bootstrap4
                        keyField='id'
                        data={ this.state.equipobjs }
                        columns={ this.state.equipcolumns }
                        search                        
                      >
                        {
                          props => (
                            <div>    
                              <SearchBar { ...props.searchProps } />                    
                              <BootstrapTable style={{scrollY: "200px",scrollCollapse: true}}
                                bootstrap4
                                { ...props.baseProps }
                                { ...props.paginationFactory }
                                { ...props.striped }
                                { ...props.defaultEquipSorted }
                                selectRow={ this.state.selectEquipRow }
                                //pagination={ paginationFactory( {sizePerPage: 6}) }                     
                                pagination={ paginationFactory({hideSizePerPage: true}) }                     
                                bordered = {false} 
                                condensed = {true}  
                                //defaultSorted = { this.state.defaultSorted } 
                                cellEdit={ cellEditFactory({ mode: 'click', blurToSave: true }) }
                              />
                            </div>
                          )
                        }
                      </ToolkitProvider>
                      <div style={{float: 'left'}}>
                    <Button variant="outline-primary mr-3"  size="sm" onClick={this.handleSelectEquipment.bind(this)}>
                      Save
                    </Button>
                    <Button variant="outline-primary"  size="sm" onClick={this.handleSelectEquipmentClose.bind(this)}>
                      Close
                    </Button>                    
                    </div>
                  </Modal.Body>
                  
                </Modal>
                </>
        </div>  

      </div>
    );
  }
}


export default WorkOrderScheduler;